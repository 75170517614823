import { useEffect, useContext } from 'react';
import Context from '../context';

export const serverDomain: string | undefined = process.env.REACT_APP_SERVER_IP;

export const fethTOken = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const token = queryParams.get('token');
	// return token ? token : 'token';
	return token;
};

export const useValidateToken = () => {
	const contextValue = useContext(Context);
	const token = fethTOken();

	useEffect(() => {
		const token = fethTOken();
		if (token) {
			localStorage.setItem('gsqToken', token);
			contextValue.dispatch({ type: 'add_token', payload: token });
		}
	}, []);

	let gsqToken = localStorage.getItem('gsqToken');
	gsqToken = gsqToken ? gsqToken : token;

	if (gsqToken === 'token') {
		gsqToken = '';
	}

	return { gsqToken };
};
