import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useConfirmationModalStyles } from './ConfirmationModal.styles';
import { useExportExcelReport, useExportCSVReport, useDownloadCSVReport } from '../../../services/api';

interface Item {
	id: Number | String;
	name: String;
}

interface ConfirmationModalProps {
	open: boolean;
	setOpen: any;
	filters: any;
	pandaDocClients: Item[];
	xeroClients: Item[];
	customers: Item[];
	allActiveJobs: Item[];
}

const ConfirmationModal = ({
	open,
	setOpen,
	filters,
	pandaDocClients,
	xeroClients,
	customers,
	allActiveJobs,
}: ConfirmationModalProps) => {
	const classes = useConfirmationModalStyles();
	const exportExcelReportXlsx = useExportExcelReport();
	const exportCsvReport = useExportCSVReport();
	const downloadCsvReport = useDownloadCSVReport();
	const handleOnClick = () => setOpen((prevValue: any) => !prevValue);

	const findId = (name: String, list: Item[]) => {
		const id = list.filter((item: Item) => item.name === name).map((item: Item) => item.id);
		return id[0];
	};

	const submitExportReport = () => {
		const projectTypes = filters.activeJobsFilters.projectType
			.filter((type: any) => type.status)
			.map((type: any) => ({
				value: type.value,
			}));

		const services = filters.activeJobsFilters.services
			.filter((service: any) => service.status)
			.map((service: any) => ({ value: service.value }));

		const dataTypes = filters.activeJobsFilters.includedDataTypes
			.filter((data: any) => data.status)
			.map((data: any) => ({ value: data.value }));

		const dataPayload = [
			{
				value: 'UNASSIGNED_DOCS',
				sub_filters: [
					{
						value: 'UNASSIGNED_DOCS_CLIENT',
						metadata: {
							client_id: filters.unassignedDocsClientFilters
								? findId(filters.unassignedDocsClientFilters, pandaDocClients)
								: null,
						},
					},
				],
			},
			{
				value: 'UNASSIGNED_POS',
				sub_filters: [
					{
						value: 'UNASSIGNED_POS_CUSTOMER',
						metadata: {
							customer_id: filters.unassignedPOsCustomerFilters
								? findId(filters.unassignedPOsCustomerFilters, customers)
								: null,
						},
					},
				],
			},
			{
				value: 'UNASSIGNED_INVOICES',
				sub_filters: [
					{
						value: 'UNASSIGNED_INVOICES_CLIENT',
						metadata: {
							client_id: filters.unassignedInvoicesClientFilters
								? findId(filters.unassignedInvoicesClientFilters, xeroClients)
								: null,
						},
					},
				],
			},
			{
				value: 'ACTIVE_JOBS',
				sub_filters: [
					{
						value: 'ACTIVE_JOBS_JOB',
						metadata: {
							job_id: filters.activeJobsFilters.activeJobs
								? findId(filters.activeJobsFilters.activeJobs, allActiveJobs)
								: null,
						},
					},
					{
						value: 'ACTIVE_JOBS_CLIENT',
						metadata: {
							client_id: filters.activeJobsFilters.jobClients
								? findId(filters.activeJobsFilters.jobClients, xeroClients)
								: null,
						},
					},
					{
						value: 'ACTIVE_JOBS_PROJECT_TYPES',
						sub_filters: projectTypes,
					},
					{
						value: 'ACTIVE_JOBS_SERVICES',
						sub_filters: services,
					},
					{
						value: 'ACTIVE_JOBS_DATA_TYPES',
						sub_filters: dataTypes,
					},
				],
			},
		];

		if (filters.exportType === 'XLSX') {
			exportExcelReportXlsx.mutate(dataPayload);
		} else {
			exportCsvReport.mutate(dataPayload);
		}
	};

	const downloadCSV = (name: string) => {
		if (name) {
			downloadCsvReport.mutate({ filename: name });
		}
	};

	if (exportExcelReportXlsx.isSuccess || exportExcelReportXlsx.data?.result) {
		setOpen(false);
	}

	return (
		<div>
			<Modal
				open={open}
				onClose={handleOnClick}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'
			>
				{exportExcelReportXlsx.isLoading || exportCsvReport.isLoading ? (
					<Box
						className={classes.modal}
						sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
					>
						<span style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '20px' }}>
							Generating report...
						</span>
						Please wait and dont close this screen. This might take a while...
					</Box>
				) : filters.exportType === 'CSV' && !exportCsvReport?.isLoading && exportCsvReport.data ? (
					<Box className={classes.modalDownload}>
						<Typography id='modal-modal-title' variant='h5' component='h2'>
							Download CSVs
						</Typography>
						<Typography id='modal-modal-description' sx={{ mt: 2 }}>
							<ul
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '1rem',
									paddingLeft: '0px',
								}}
							>
								{exportCsvReport.data?.result?.length > 0 &&
									exportCsvReport.data?.result?.map((item: string) => (
										<li style={{ display: 'flex' }}>
											<Typography
												color='error'
												style={{
													cursor: 'pointer',
													textDecoration: 'underline',
													textUnderlineOffset: '3px',
												}}
												onClick={() => downloadCSV(item)}
											>
												Download {item}
											</Typography>
										</li>
									))}
							</ul>
						</Typography>

						<Box className={classes.btnWrapper}>
							<Button
								className={classes.backBtn}
								variant='contained'
								onClick={handleOnClick}
								disableElevation
							>
								Close
							</Button>
						</Box>
					</Box>
				) : (
					<Box className={classes.modal}>
						<Typography id='modal-modal-title' variant='h5' component='h2'>
							Export Confirmation
						</Typography>
						<Typography id='modal-modal-description' sx={{ mt: 2 }}>
							Do you want to export this report?
						</Typography>
						<Box>{/* {exportCsvReport.data} */}</Box>
						<Box className={classes.btnWrapper}>
							<Button
								className={classes.backBtn}
								variant='contained'
								onClick={handleOnClick}
								disableElevation
							>
								Cancel
							</Button>
							<Button
								variant='contained'
								size='large'
								style={{ borderRadius: '4rem' }}
								color='error'
								onClick={submitExportReport}
							>
								Confirm
							</Button>
						</Box>
					</Box>
				)}
			</Modal>
		</div>
	);
};

export default ConfirmationModal;
