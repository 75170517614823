import React, { useEffect, useContext, useState } from 'react';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';
import { DataTable, DataTablePagination } from '../../../components';
import { jobPageDocumentDataTable } from '../../../data/tableColumns';
import { useDocumentAdd } from '../../../hooks/pages';
import {
	useGetDocument,
	usePostDocumentDownload,
	postDocumentDownload,
	useDeleteDocument,
} from '../../../services/api';
import { DocumentAdd } from './DocumentAdd';
import { DocumentRemove } from './DocumentRemove';
import Context from '../../../services/context';

export const Documents = ({ jobDetails, jobFilter, search, tbIndex }: any) => {
	const { dispatch, state } = useContext(Context);
	const useDocumentAddProps = useDocumentAdd({ jobDetails });
	const [documents, setDocuments] = useState<any>([]);
	// const { mutate } = usePostDocumentDownload();
	const { data: timeEntriesData, isLoading: timeEntriesLoading } = useGetDocument(jobDetails.jobId);

	const columns = [
		...jobPageDocumentDataTable.columns,
		{
			field: 'actions',
			headerName: (
				<span
					style={{
						cursor: 'pointer',
						textDecoration: 'underline',
						marginRight: '5px',
						color: '#c5332f',
						textUnderlineOffset: '3px',
					}}
					onClick={() => useDocumentAddProps.toggleAddDocument()}
				>
					Add new
				</span>
			),
		},
	];

	const handlwDownloadDocument = (documentId: any, file: any) => {
		if (documentId) {
			postDocumentDownload({ id: documentId, file: file });
		}
	};

	const formatDataTaskJob = (data: any) => {
		return data.map((value: any, index: any) => {
			// const dateFormat = new Date(value.created_at);
			// const dateForamtted = `${dateFormat.getFullYear()} ${dateFormat.toLocaleString('default', {
			// 	month: 'long',
			// })} ${dateFormat.getDay()}`;

			return {
				id: value.id,
				title: value.title,
				notes: value.notes,
				date: value.created_at,
				document: value.file_name,
				jobId: value.job_id,
				download: (
					<span
						style={{ cursor: 'pointer', textDecoration: 'underline', textUnderlineOffset: '3px' }}
						onClick={() => handlwDownloadDocument(value.id, value.file_name)}
					>
						Click here
					</span>
				),
			};
		});
	};

	useEffect(() => {
		if (timeEntriesData && timeEntriesData.result && timeEntriesData.result.length) {
			const filter = formatDataTaskJob(timeEntriesData.result);
			setDocuments(filter);
		}
	}, [timeEntriesData]);

	const addZeroSingleNumber = (length: any, mainNumber: any) => {
		length = length || 2;
		return (new Array(length).join('0') + mainNumber).slice(length * -1);
	};

	useEffect(() => {
		if (jobFilter.taskDateDisable) {
			if (search && timeEntriesData && timeEntriesData.result.length && tbIndex === 3) {
				let filtered = timeEntriesData.result.filter((item: any) => {
					return (
						item.file_name.toLowerCase().includes(search.toLowerCase()) ||
						item.created_at.toLowerCase().includes(search.toLowerCase()) ||
						item.notes.toLowerCase().includes(search.toLowerCase()) ||
						item.title.toLowerCase().includes(search.toLowerCase())
					);
				});

				const getData = filtered ? formatDataTaskJob(filtered) : [];

				setDocuments(getData);
			} else {
				const resultFormat = formatDataTaskJob(
					timeEntriesData && timeEntriesData.result ? timeEntriesData.result : []
				);
				setDocuments(resultFormat);
			}
		} else {
			if (
				tbIndex === 3 &&
				timeEntriesData &&
				timeEntriesData.result &&
				timeEntriesData.result.length &&
				!jobFilter.taskDateDisable
			) {
				let filtered = timeEntriesData.result.filter((item: any) => {
					const addZeroInMonth = addZeroSingleNumber(2, jobFilter.taskDate.getMonth() + 1);
					const formatDate = `${jobFilter.taskDate.getFullYear()}-${addZeroInMonth}`;

					return item.created_at.toLowerCase().includes(formatDate.toLowerCase());
				});

				if (search && filtered.length && tbIndex === 3) {
					let filteredFormat = filtered.filter((item: any) => {
						return (
							item.file_name.toLowerCase().includes(search.toLowerCase()) ||
							item.created_at.toLowerCase().includes(search.toLowerCase()) ||
							item.notes.toLowerCase().includes(search.toLowerCase()) ||
							item.title.toLowerCase().includes(search.toLowerCase())
						);
					});

					const getDataFormat = filteredFormat ? formatDataTaskJob(filteredFormat) : [];

					setDocuments(getDataFormat);
				} else {
					const getData = filtered ? formatDataTaskJob(filtered) : [];

					setDocuments(getData);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	// remove document =================================
	const { mutate, data: documentRemoveData, isLoading: documentRemoveLoading } = useDeleteDocument();
	const [showRemoveDocument, setShowRemoveDocument] = useState(false);
	const [removeDocument, setRemoveDocument] = useState<any>({});

	function toggleRemoveDocument() {
		setShowRemoveDocument((showRemoveDocument) => !showRemoveDocument);
	}

	const handleRemoveDocument = (value: any): void => {
		setRemoveDocument(value);
		toggleRemoveDocument();
	};

	const handleRemoveDocumentSubmit = (): void => {
		mutate({ id: removeDocument.id });
	};

	const modalPropsRemoveDocument = {
		handleSubmit: handleRemoveDocumentSubmit,
		isShowing: showRemoveDocument,
		hide: toggleRemoveDocument,
		currentPage: 1,
		pageLength: 1,
	};

	const documentRemoveProps = {
		modalPropsRemoveDocument,
		toggleRemoveDocument,
		removeDocument,
		documentRemoveLoading,
	};

	useEffect(() => {
		if (documentRemoveData && documentRemoveData.error === false) {
			toggleRemoveDocument();
		}
	}, [documentRemoveData]);
	// remove document =================================

	const reformatData = documents
		? documents.map((list: any) => ({
				...list,
				actions: (
					<span
						className='gs__icon__remove'
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => handleRemoveDocument({ ...list })}
					>
						<Tooltip placement='top' title='Remove' arrow>
							<DeleteForeverIcon />
						</Tooltip>
					</span>
				),
		  }))
		: [];

	const tableData = { rows: reformatData, columns, isLoading: timeEntriesLoading };

	return (
		<div>
			{/* <DataTable {...tableData} actions={true} /> */}
			<DataTablePagination {...tableData} actions={true} />
			<DocumentAdd {...useDocumentAddProps} jobDetails={jobDetails} />
			<DocumentRemove {...documentRemoveProps} />
		</div>
	);
};
