import NumberFormat from 'react-number-format';
import {
	Typography,
	Box,
	TextField,
	LinearProgress,
	Autocomplete,
	FormControl,
	InputLabel,
	InputAdornment,
	Input,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
// import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ClearIcon from '@mui/icons-material/Clear';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Modal } from '../../../components';

export const UpdateDetails = ({
	modalPropsDocumentAdd,
	toggleAddDocument,
	jobDetailsUpdateLoading,
	handleDocumentData,
	documentData,
	clientList,
	selectedType,
	typeList,
	invoiceMilestonePercentage,
	handleAddMilestone,
	handleUpdatePercent,
	handleRemoveMilestone,
	invFreq,
}: any) => {
	const defaultProps: any = {
		options: clientList,
		getOptionLabel: (option: any) => option.name,
	};

	const defaultType: any = {
		options: typeList,
		getOptionLabel: (option: any) => option.name,
	};

	const defaultTypeInvFreq: any = {
		options: invFreq,
		getOptionLabel: (option: any) => option.name,
	};

	const handleTotalInvoicePercent = invoiceMilestonePercentage.length
		? invoiceMilestonePercentage
				.map((item: any) => Number(item.percent))
				.reduce((prev: any, curr: any) => prev + curr, 0)
		: 0;

	return (
		<Modal {...modalPropsDocumentAdd}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleAddDocument}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Update Details
				{jobDetailsUpdateLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{jobDetailsUpdateLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Client:
					</Typography>
					<Box>
						<Autocomplete
							style={{ width: '100%' }}
							className='gs-activeJobs__basic-input--description'
							{...defaultProps}
							id='clear-on-escape'
							disableClearable
							value={documentData.client}
							onChange={(value: any, newValue: any) => {
								handleDocumentData(newValue, 'client');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select client'
									// error={!addJobValidation.clientName ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Job Name:
					</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							style={{ width: '100%' }}
							onChange={(event) => handleDocumentData(event, 'jobName')}
							defaultValue={documentData.jobName}
							id='standard-basic'
							variant='standard'
							// error={!documentData.notes ? true : false}
							placeholder='*Please enter description'
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Job Number:
					</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							style={{ width: '100%' }}
							onChange={(event) => handleDocumentData(event, 'jobNumber')}
							value={documentData.jobNumber}
							id='standard-basic'
							variant='standard'
							// error={!documentData.jobNumber ? true : false}
							placeholder='*Please enter job number'
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Description:
					</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							style={{ width: '100%' }}
							onChange={(event) => handleDocumentData(event, 'description')}
							defaultValue={documentData.description}
							id='standard-basic'
							variant='standard'
							// error={!documentData.description ? true : false}
							placeholder='*Please enter description'
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Job Category:
					</Typography>
					<Box className='gs__inline-field__container'>
						<Autocomplete
							style={{ width: '100%' }}
							className='gs-activeJobs__basic-input--description'
							{...defaultType}
							id='clear-on-escape'
							disableClearable
							value={documentData.jobType }
							onChange={(value: any, newValue: any) => {
								handleDocumentData(newValue, 'jobType');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select category'
									// error={!addJobValidation.clientName ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{ height: '32px', display: `${documentData.jobType && documentData.jobType.id === 2 ? 'flex' : 'none'}` }}
				>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Invoice Frequency:
					</Typography>
					<Box className='gs__inline-field__container'>
						<Autocomplete
							style={{ width: '100%' }}
							className='gs-activeJobs__basic-input--description'
							{...defaultTypeInvFreq}
							id='clear-on-escape'
							disableClearable
							value={documentData.invoice_frequency}
							onChange={(value: any, newValue: any) => {
								handleDocumentData(newValue, 'invoice_frequency');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select invoice frequency'
									// error={!addJobValidation.clientName ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						{documentData.jobCat === 2 ? 'Invoice ' : ''}Dates:
					</Typography>
					<Box>
						<Typography className='gs-pagetwo__text-mr'>Start</Typography>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={documentData.start}
								onChange={(newValue: any) => {
									handleDocumentData(newValue, 'start');
								}}
								renderInput={(params: any) => (
									<TextField style={{ width: '90px' }} className='gs__date-picker' {...params} />
								)}
							/>
						{/* </LocalizationProvider> */}
						<Typography className='gs-pagetwo__text-ml-mr'>End</Typography>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={documentData.end}
								onChange={(newValue: any) => {
									handleDocumentData(newValue, 'end');
								}}
								renderInput={(params: any) => (
									<TextField
										style={{ width: '90px', minWidth: '90px' }}
										className='gs__date-picker'
										{...params}
									/>
								)}
							/>
						{/* </LocalizationProvider> */}
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{
						display: documentData.jobType && documentData.jobType.id !== 3 ? 'none' : 'flex',
						marginBottom: '15px',
						height: '32px',
					}}
				>
					<Typography className='gs__inline-field__title' style={{ minWidth: '155px' }}>
						Invoice Milestones:
					</Typography>
					<Box style={{ display: 'flex', height: '32px', width: '100%' }}>
						<Typography
							style={{
								display: 'flex',
								alignItems: 'flex-end',
								justifyContent: 'space-between',
								width: '100%',
								color: handleTotalInvoicePercent > 100 ? 'red' : 'black',
							}}
						>
							{invoiceMilestonePercentage.length
								? invoiceMilestonePercentage.map((value: any, index: any) => {
										if (index === invoiceMilestonePercentage.length - 1) {
											return `${value.percent ? value.percent : 0}`;
										} else {
											return `${value.percent ? value.percent : 0}/`;
										}
								  })
								: '0'}{' '}
							={' '}
							{invoiceMilestonePercentage.length
								? invoiceMilestonePercentage
										.map((item: any) => Number(item.percent))
										.reduce((prev: any, curr: any) => prev + curr, 0)
								: 0}
							%
							<span style={{ display: 'flex' }}>
								<AddOutlinedIcon
									className='gs__icon__edit'
									style={{ cursor: 'pointer' }}
									onClick={handleAddMilestone}
								/>
							</span>
						</Typography>
					</Box>
				</Box>
				{documentData.jobType && documentData.jobType.id === 3
					? invoiceMilestonePercentage.map((value: any, index: any) => {
							return (
								<Box style={{ display: 'flex', marginBottom: '15px' }} key={index}>
									<Typography
										style={{ minWidth: '155px', display: 'flex', alignItems: 'flex-end' }}
									></Typography>
									<Box className='gs__text-field' style={{ display: 'flex' }}>
										<div style={{ width: '22%', marginRight: '12px' }}>
											<Input
												onChange={(event) => handleUpdatePercent(event, index, 'percent')}
												value={value.percent}
												id='standard-adornment-amount'
												// variant='standard'
												// placeholder='%'
												endAdornment={<InputAdornment position='start'>%</InputAdornment>}
												// endAdornment={
												// 	<InputAdornment position='end'>
												// 		<AddOutlinedIcon />
												// 	</InputAdornment>
												// }
												// error={clientName ? false : true}
											/>
										</div>
										<Input
											onChange={(event) => handleUpdatePercent(event, index, 'notes')}
											value={value.notes}
											id='standard-adornment-amount'
											placeholder='Notes'
											multiline
											endAdornment={
												<InputAdornment position='end'>
													<ClearIcon
														style={{ cursor: 'pointer' }}
														onClick={() => handleRemoveMilestone(index)}
														className='gs__icon__edit'
													/>
												</InputAdornment>
											}
											// error={clientName ? false : true}
										/>
									</Box>
								</Box>
							);
					  })
					: ''}

				{/* <Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						Fees:
					</Typography>
					<FormControl sx={{ m: 1 }} variant='standard'>
						<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
						<NumberFormat
							thousandsGroupStyle='thousand'
							onValueChange={(values) => {
								handleDocumentData(values, 'fees');
							}}
							value={documentData.fees}
							// error={addBill.billingOption !== '1' && !addBill.billValueDisplay ? true : false}
							decimalSeparator='.'
							customInput={Input}
							displayType='input'
							type='text'
							id='standard-basic'
							startAdornment={<InputAdornment position='start'>$</InputAdornment>}
							thousandSeparator={true}
							allowNegative={false}
						/>
					</FormControl>
				</Box>

				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						Costs:
					</Typography>
					<FormControl sx={{ m: 1 }} variant='standard'>
						<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
						<NumberFormat
							thousandsGroupStyle='thousand'
							onValueChange={(values) => {
								handleDocumentData(values, 'costs');
							}}
							value={documentData.costs}
							// defaultValue={0}
							// error={addBill.billingOption !== '1' && !addBill.billValueDisplay ? true : false}
							decimalSeparator='.'
							customInput={Input}
							displayType='input'
							type='text'
							id='standard-basic'
							startAdornment={<InputAdornment position='start'>$</InputAdornment>}
							thousandSeparator={true}
							allowNegative={false}
						/>
					</FormControl>
				</Box>

				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						Invoices:
					</Typography>
					<FormControl sx={{ m: 1 }} variant='standard'>
						<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
						<NumberFormat
							thousandsGroupStyle='thousand'
							onValueChange={(values) => {
								handleDocumentData(values, 'invoices');
							}}
							value={documentData.invoices}
							// error={addBill.billingOption !== '1' && !addBill.billValueDisplay ? true : false}
							decimalSeparator='.'
							customInput={Input}
							displayType='input'
							type='text'
							id='standard-basic'
							startAdornment={<InputAdornment position='start'>$</InputAdornment>}
							thousandSeparator={true}
							allowNegative={false}
						/>
					</FormControl>
				</Box> */}
			</div>
		</Modal>
	);
};
