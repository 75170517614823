import { Button, Typography, Popover, Box, List, ListItem } from '@mui/material';
import React, { useState } from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNotificationStyles } from './Notifications.styles';
import { useGetInAppNotifications, useClearInAppNotifications } from '../../services/api';
import { NotificationProps } from './Notifications.props';

export const Notifications = () => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	// QUERIES
	const { data, isLoading, isError } = useGetInAppNotifications();
	const clearNotification = useClearInAppNotifications();

	//RENDER LOADING & ERROR
	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (isError) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	const notifications = data.result;

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClearNotifications = () => {
		clearNotification.mutate();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<Button
				variant='outlined'
				sx={{
					color: '#686666',
					borderColor: '#dcd7d7',
					minWidth: '42px',
					height: '3em',
					':hover': {
						backgroundColor: '#fff',
						borderColor: '#dcd7d7',
						color: '#5a5a5a',
					},
				}}
				size='small'
				onClick={handleClick}
			>
				<NotificationsIcon sx={{ fontSize: 10 }} />
				<p
					style={{
						border: '1px solid red',
						padding: '0 8px',
						borderRadius: '50px',
						color: '#fff',
						backgroundColor: '#c5332f',
						fontSize: '11px',
					}}
				>
					{notifications.length}
				</p>
			</Button>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				{notifications.length > 0 ? (
					notifications.slice(0, 10).map((item: NotificationProps) => (
						<List>
							<NotificationItem
								key={item.notification_id}
								notification={item.message}
								jobNumber={item.job_number}
								type={item.Notification_type}
							/>
						</List>
					))
				) : (
					<Typography sx={{ marginRight: '1rem', fontSize: '16px', margin: '12px' }}>
						No notification
					</Typography>
				)}
				{notifications.length > 0 && (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							padding: '0 1rem',
							marginBottom: '8px',
							cursor: 'pointer',
						}}
						onClick={handleClearNotifications}
					>
						<p color='error' style={{ fontSize: '14px', color: '#c5332f', textDecoration: 'underline' }}>
							Clear Notifications
						</p>
					</Box>
				)}
			</Popover>
		</div>
	);
};

const NotificationItem = (props: { notification: string; jobNumber: string; type: string }) => {
	const { notification, jobNumber, type } = props;

	return (
		<ListItem>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					borderBottom: '1px solid #e0e0e0',
					paddingBottom: '12px',
					flexDirection: 'column',
				}}
			>
				<Typography sx={{ marginRight: '1rem', textTransform: 'capitalize' }}>
					{jobNumber} | {type}
				</Typography>
				<Typography sx={{ marginRight: '1rem', fontSize: '14px' }}>{notification}</Typography>
			</Box>
		</ListItem>
	);
};

// const notification = [
// 	{
// 		Notification_type: 'UNASSIGNED_INVOICES',
// 		job_number: 'GERP 0017',
// 		message: 'Station Five - for testing has an outstanding balance due on this 2023-04-13.',
// 		notification_id: 1,
// 		notification_inapp_read: null,
// 		sent_at: '2023-04-13T14:36:10.146+00:00',
// 	},
// 	{
// 		Notification_type: 'UNASSIGNED_INVOICES',
// 		job_number: 'GERP 0017',
// 		message: 'Station Five - for testing has an outstanding balance due on this 2023-04-13.',
// 		notification_id: 1,
// 		notification_inapp_read: null,
// 		sent_at: '2023-04-13T14:36:10.146+00:00',
// 	},
// ];
