import { formatCurrency } from '../../../utils/formatCurrency';

export const toArchivedJobsTableData = (rows: any) => {
	const activeJobsTableData = rows.map((row: any) => {
		return {
			id: row.id ? row.id : '',
			asana_project_id: row.asana_project_id ? row.asana_project_id : '',
			job_name: row.job_name ? row.job_name : '-',
			client: row.client ? row.client : '-',
			job_number: row.job_number ? row.job_number : '-',
			reason: row.description ? row.reason : '-',
			fees: row.fees ? formatCurrency(row.fees) : 0,
			total_cost: row.totalCost ? formatCurrency(row.totalCost) : 0,
			job_type: row.job_category ? row.job_category : '-',
			team_id: row.team_id ? row.team_id : '',
		};
	});

	return activeJobsTableData;
};
