import { useEffect } from 'react';
import { Typography } from '@mui/material';

export const Help = () => {
	useEffect(() => {
		window.open('https://gsquared.atlassian.net/wiki/spaces/GT/pages/687276036/GERP', '_blank');
	}, []);
	return (
		<Typography mb={5} variant='h4'>
			Redirecting...
		</Typography>
	);
};
