import { createContext } from 'react';
import { initialState, InitialState } from './states';
import { Actions, reducer } from './actions';

export { initialState, reducer };

export interface ContextProps {
	state: InitialState;
	dispatch: React.Dispatch<Actions>;
}

const Context = createContext<ContextProps>({
	state: initialState,
	dispatch: () => {},
});

export const ContextConsumer = Context.Consumer; // Note: Not used. We are using useContext hook instead
export const ContextProvider = Context.Provider;
export default Context;
