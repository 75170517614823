import { useEffect, useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	InputAdornment,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	ListItemText,
	Radio,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import InvoiceMilestones from '../../../components/InvoiceMilestones/InvoiceMilestones';
import { MilestoneProps } from '../../../components/InvoiceMilestones/InvoiceMilestones.props';
import { INITIAL_STATE_DOC, INITIAL_STATE_MILESTONE } from './AssignDoc.const';
import {
	AssignDocProps,
	ClientProps,
	JobTypeProps,
	TeamProps,
	ActiveJobProps,
	ServiceTypeProps,
	DocProps,
	DocTypeProps,
} from './AssignDoc.props';
import { SERVICE_TYPES } from '../../../constants/job';
import { useAssignDocStyles } from './AssignDoc.styles';
import { useAssignDoc } from './useAssignDoc.hook';

export const AssignDoc = (props: AssignDocProps) => {
	const { openAssign, row, pageNumber, closeAssignHandler, limit, term, clientName, sort, order } = props;
	const [existingActiveJobId, setExistingActiveJobId] = useState<number | null>(null);
	const classes = useAssignDocStyles();
	const [step, setStep] = useState(1);
	const [activeJobOptions, setActiveJobOptions] = useState<ActiveJobProps[]>([]);
	const [selectedCategory, setSelectedCategory] = useState('1');
	const [isMilestoneLimit, setIsMilestoneLimit] = useState(false);
	const [milestones, setMilestones] = useState<MilestoneProps[]>(INITIAL_STATE_MILESTONE);
	const [assignDocData, setAssignDocData] = useState<DocProps>(INITIAL_STATE_DOC);
	const [openLoading, setOpenLoading] = useState(false);

	const {
		// QUERIES
		clientNamesQuery,
		jobTypeQuery,
		teamsQuery,
		nextJobNumberQuery,
		nextQuoteNumberQuery,
		docTypesQuery,
		activeJobsQuery,
		activeJobDetailsQuery,
		// HANDLERS
		changeJobCategoryHandler,
		changeClientHandler,
		changeNewJobTypeHandler,
		changeTeamHandler,
		changeDocTypeHandler,
		changeActiveJobHandler,
		changeInvoiceFrequencyHandler,
		nextHandler,
		backHandler,
		splitHandler,
		milestoneLimitHandler,
		milestoneValueHandler,
		serviceChangeHandler,
		changeTextFieldHandler,
		closeModalHandler,
		uploadDocumentHandler,
		submitAssignDocHandler,
		// SUBMIT
		assignNewDoc,
		assignExistingDoc,
		uploadFileQuery,
		assignOtherDoc,
	} = useAssignDoc({
		existingActiveJobId,
		pageNumber,
		limit,
		term,
		setSelectedCategory,
		setAssignDocData,
		assignDocData,
		setExistingActiveJobId,
		setStep,
		step,
		setIsMilestoneLimit,
		setMilestones,
		selectedCategory,
		setOpenLoading,
		closeAssignHandler,
		clientName,
		sort,
		order,
	});

	// DATA
	const clientList = clientNamesQuery.isSuccess ? clientNamesQuery.data.result : [];
	const clientNameOptions = clientList.map((client: ClientProps) => client.name);

	const jobTypeList = jobTypeQuery.isSuccess ? jobTypeQuery.data.result : [];
	const jobtypeNameOptions = jobTypeList
		.map((jobType: JobTypeProps) => jobType.name)
		?.filter((jobType: string) => jobType !== 'New Job' && jobType);

	const teamList = teamsQuery.isSuccess ? teamsQuery.data.result : [];
	const teamNameOptions = teamList.map((team: TeamProps) => team.name);

	const docTypesList = docTypesQuery.isSuccess ? docTypesQuery.data.result : [];
	const docTypeOptions = docTypesList.map((type: DocTypeProps) => type.name);

	const activeJobList = activeJobsQuery.isSuccess ? activeJobsQuery.data.result : [];
	const activeJobNameOptions = activeJobOptions.map((activeJob: ActiveJobProps) => activeJob.job_name);

	const activeJobDetails = activeJobDetailsQuery.isSuccess ? activeJobDetailsQuery.data.result : undefined;

	const nextJobNumber = nextJobNumberQuery.isSuccess ? nextJobNumberQuery.data.result : null;
	const nextQuoteNumber = nextQuoteNumberQuery.isSuccess ? nextQuoteNumberQuery.data.result : null;
	const serviceTypeList = SERVICE_TYPES.map((service: ServiceTypeProps) => service.name);

	useEffect(() => {
		const client = clientList?.find((client: ClientProps) => client?.name === row?.client);
		setAssignDocData({
			...assignDocData,
			fees: row ? Number(row.fees.amount) : 0,
			id: row ? row.id : 0,
			job_cat: Number(selectedCategory),
			client_id: client ? client.id : assignDocData.client_id,
			job_client: client ? client.name : assignDocData.job_client,
			job_name: row ? row.name : '',
			new_job: selectedCategory === '1' ? Number(selectedCategory) : 0,
			panda_doc_id: row ? row.unassigned_job_id : '',
			job_number: selectedCategory !== '1' && activeJobDetails ? activeJobDetails?.job_number : nextJobNumber,
			qoute_number:
				selectedCategory !== '1' && activeJobDetails
					? activeJobDetails?.quotes_details[0].quote_number
					: row?.quote_number,
			asana_project_id: activeJobDetails ? activeJobDetails?.asana_project_id : '',
			cost_id: activeJobDetails ? activeJobDetails?.cost_id : '',
		});
	}, [row, clientNamesQuery.data, activeJobDetails]);

	useEffect(() => {
		setAssignDocData({
			...assignDocData,
			invoice_milestones: [...milestones],
		});
	}, [milestones]);

	useEffect(() => {
		let jobOptions = [];
		if (selectedCategory === '2')
			jobOptions = activeJobList.filter((job: ActiveJobProps) => job.job_type === 'Retainer Job');
		if (selectedCategory === '3')
			jobOptions = activeJobList.filter((job: ActiveJobProps) => job.job_type === 'Project Job');

		return setActiveJobOptions(jobOptions);
	}, [selectedCategory, activeJobList]);

	// RENDER LOADING & ERROR
	if (
		assignNewDoc.isLoading ||
		assignExistingDoc.isLoading ||
		clientNamesQuery.isLoading ||
		jobTypeQuery.isLoading ||
		teamsQuery.isLoading ||
		activeJobsQuery.isLoading
	) {
		return (
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openLoading}>
				<CircularProgress color='inherit' />
			</Backdrop>
		);
	}

	if (
		assignNewDoc.isError ||
		assignExistingDoc.isError ||
		clientNamesQuery.isError ||
		jobTypeQuery.isError ||
		teamsQuery.isError ||
		activeJobsQuery.isError
	) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	return (
		<div>
			<Dialog
				open={openAssign}
				onClose={(_, reason) => reason === 'backdropClick' && closeModalHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h6' style={{ fontWeight: 'bold' }}>
							{step} / 4
						</Typography>
						<ClearOutlinedIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
					</Box>
					{step === 1 ? (
						<Typography className={classes.title}>Document Category</Typography>
					) : (
						<Typography className={classes.title}>Job Category</Typography>
					)}
				</DialogTitle>
				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '750px' }}>
					<FormControl sx={{ width: '100%' }}>
						<TextField
							name='job_name'
							fullWidth
							variant='standard'
							placeholder='Job name here'
							size='small'
							sx={{ marginBottom: '24px' }}
							required
							value={assignDocData.job_name}
							onChange={(event) => changeTextFieldHandler(event)}
						/>
						{/* Step 1 ======================================= */}
						{step === 1 && (
							<Box>
								<FormControl sx={{ width: '100%' }}>
									<RadioGroup
										value={assignDocData.doc_type}
										name='doc-type'
										sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
										onChange={changeDocTypeHandler}
									>
										{docTypesList.map((type: DocTypeProps) => {
											return (
												<FormControlLabel
													key={type.id}
													value={type.id}
													className={classes.radioButton}
													control={<Radio />}
													label={type.name}
												/>
											);
										})}
									</RadioGroup>
								</FormControl>
							</Box>
						)}
						{/* Step 2 ======================================= */}
						{step === 2 && (
							<Box>
								<FormControl sx={{ width: '100%' }}>
									<RadioGroup
										value={selectedCategory}
										name='job-categories'
										sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
										onChange={changeJobCategoryHandler}
									>
										<FormControlLabel
											value='1'
											className={classes.radioButton}
											control={<Radio />}
											label='New job'
										/>

										<FormControlLabel
											value='2'
											className={classes.radioButton}
											control={<Radio />}
											label='Assign to existing retainer job'
											// disabled
										/>

										<FormControlLabel
											value='3'
											className={classes.radioButton}
											control={<Radio />}
											label='Assign to existing project job'
											// disabled
										/>
									</RadioGroup>
								</FormControl>
							</Box>
						)}

						{/* Step 3 ======================================= */}
						{step === 3 && (
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Client:</Typography>
									<Autocomplete
										options={clientNameOptions}
										defaultValue={assignDocData.job_client}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Select client' variant='standard' required />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeClientHandler(clientList, newValue)
										}
									/>
								</Box>

								{/* If selectedCategory === 1 (new job) */}
								{selectedCategory === '1' ? (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>New Job:</Typography>
										<Autocomplete
											options={jobtypeNameOptions}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select job category'
													variant='standard'
													required
												/>
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeNewJobTypeHandler(jobTypeList, newValue)
											}
										/>
									</Box>
								) : (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Active Job:</Typography>
										<Autocomplete
											options={activeJobNameOptions}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField {...params} label='Select job' variant='standard' required />
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeActiveJobHandler(activeJobList, newValue)
											}
										/>
									</Box>
								)}

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Team:</Typography>
									<Autocomplete
										options={teamNameOptions}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Select team' variant='standard' required />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeTeamHandler(teamList, newValue)
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='job_description'
										fullWidth
										id='task-name'
										variant='standard'
										placeholder='Please enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.job_description}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Number:</Typography>
									<TextField
										name='job_number'
										fullWidth
										variant='standard'
										placeholder='Job number here*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.job_number}
										onChange={(event) => changeTextFieldHandler(event)}
										disabled
										// disabled={!!existingActiveJobId}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Quote Number:</Typography>
									<TextField
										name='qoute_number'
										fullWidth
										variant='standard'
										placeholder='Quote number here*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.qoute_number}
										onChange={(event) => changeTextFieldHandler(event)}
										disabled={!!existingActiveJobId}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Fees:</Typography>
									<TextField
										name='fees'
										fullWidth
										variant='standard'
										placeholder='00000'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										}}
										value={assignDocData.fees}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated Time:</Typography>
									<TextField
										name='est_hours'
										type='number'
										fullWidth
										id='task-name'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											endAdornment: <InputAdornment position='end'>Hours</InputAdornment>,
										}}
										value={assignDocData.est_hours}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								{/* If selectedCategory === 2 (retainer) */}
								{selectedCategory === '2' && (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Invoice Frequency:</Typography>
										<Autocomplete
											options={['Monthly', 'Quarterly', 'Annually']}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select frequency'
													variant='standard'
													required
												/>
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeInvoiceFrequencyHandler(newValue)
											}
										/>
									</Box>
								)}

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Type:</Typography>
									<FormControl variant='standard' sx={{ width: '100%', marginTop: '16px' }}>
										<Select
											multiple
											value={assignDocData.services}
											onChange={serviceChangeHandler}
											renderValue={(selected) => selected.join(', ')}
											placeholder='Select job type'
										>
											{serviceTypeList.map((name: string) => (
												<MenuItem key={name} value={name}>
													<Checkbox checked={assignDocData.services.indexOf(name) > -1} />
													<ListItemText primary={name} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
								{(selectedCategory === '1' || selectedCategory === '3') && (
									<InvoiceMilestones
										setMilestoneLimitHandler={milestoneLimitHandler}
										setMilestoneValueHandler={milestoneValueHandler}
										milestones={milestones}
									/>
								)}
							</Box>
						)}

						{step === 4 && (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								{assignDocData.doc_type === 4 && (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Client:</Typography>
										<Autocomplete
											options={clientNameOptions}
											defaultValue={assignDocData.job_client}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField {...params} label='Select client' variant='standard' />
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeClientHandler(clientList, newValue)
											}
										/>
									</Box>
								)}

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Title:</Typography>
									<TextField
										name='title'
										fullWidth
										id='title'
										variant='standard'
										placeholder='Enter title'
										size='small'
										sx={{ marginTop: '16px' }}
										multiline
										maxRows={4}
										value={assignDocData.title}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography sx={{ width: '55%' }}>File:</Typography>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
										}}
									>
										<Button
											variant='outlined'
											component='label'
											size='small'
											color='error'
											style={{ whiteSpace: 'nowrap' }}
										>
											choose file
											<input
												id='file'
												hidden
												type='file'
												accept='.xlsx,.xls,.doc,.docx,.pdf'
												onChange={(event) => uploadDocumentHandler(event)}
											/>
										</Button>

										<Typography style={{ paddingTop: '6px' }}>
											{assignDocData.docs_file && assignDocData.docs_file.name}
										</Typography>
									</Box>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Notes:</Typography>
									<TextField
										name='notes'
										fullWidth
										id='notes'
										variant='standard'
										placeholder='Enter notes'
										sx={{ marginTop: '16px' }}
										multiline
										required
										maxRows={4}
										value={assignDocData.notes}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						{step === 1 && row?.parent_doc_id === '-' ? (
							<Button
								className={classes.submit}
								variant='contained'
								onClick={() => splitHandler(row?.id)}
								disableElevation
								// disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
							>
								Split
							</Button>
						) : (
							<Button
								className={classes.backBtn}
								style={{ opacity: step === 1 ? '0' : '1' }}
								variant='contained'
								onClick={backHandler}
								disableElevation
								disabled={step === 1}
							>
								Back
							</Button>
						)}

						{step === 4 ? (
							<Button
								className={classes.submit}
								variant='contained'
								onClick={() => submitAssignDocHandler(assignDocData, setOpenLoading)}
								disableElevation
								disabled={isMilestoneLimit || !assignDocData.notes}
							>
								Submit
							</Button>
						) : (
							<Button
								className={classes.submit}
								variant='contained'
								onClick={() => nextHandler(clientList)}
								disableElevation
								// disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
							>
								Next
							</Button>
						)}
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
};
