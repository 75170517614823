import { Typography, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Modal } from '../../../components';
import { handleCurrency } from '../../../utils';

export const CostRemove = ({ modalPropsRemoveCosts, toggleRemoveCosts, removeCosts, costRemoveLoading }: any) => {
	return (
		<Modal {...modalPropsRemoveCosts}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleRemoveCosts}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Remove Cost!
				{costRemoveLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{costRemoveLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Typography mb={3} className='gs__inline-field__title'>
					Are you sure you want to remove the following cost?
				</Typography>
				<Typography style={{ fontSize: '20px', overflow: 'auto' }} className='gs__inline-field__title'>
					{removeCosts?.description ? removeCosts.description : ''} :{' '}
					{removeCosts?.amount ? handleCurrency(removeCosts.amount) : ''}
				</Typography>{' '}
			</div>
		</Modal>
	);
};
