import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { TIME_ENTRIES_COLUMNS } from './TimeEntries.columns';
import { useGetPaginatedTimeEntries } from '../../../../services/api';
import { TimeEntriesProps } from './TimeEntries.props';
import { useTimeEntriesStyles } from './TimeEntries.styles';

export const TimeEntries = () => {
	const classes = useTimeEntriesStyles();
	const { id = 0 } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: timeEntriesData,
		isLoading: isLoadingTimeEntries,
		isError: isErrorTimeEntries,
	} = useGetPaginatedTimeEntries(pageNumber + 1, limit, Number(id));

	// RENDER LOADING & ERROR
	if (isLoadingTimeEntries) {
		return <p>Loading...</p>;
	}

	if (isErrorTimeEntries) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const timeEntries = timeEntriesData.message === 'Success' ? timeEntriesData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={TIME_ENTRIES_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{timeEntries.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{timeEntries?.map((entry: TimeEntriesProps) => (
						<TableRow key={entry.id}>
							<TableCell>{entry.date}</TableCell>
							<TableCell>{entry.task.name}</TableCell>
							<TableCell>{entry.user}</TableCell>
							<TableCell>{entry.time}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={timeEntriesData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
