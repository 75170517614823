import { useState } from 'react';
import {
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	Autocomplete,
	Divider,
	TextField,
	FormControl,
	InputLabel,
	Select,
	Collapse,
	MenuItem,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { SearchBox } from '../../components';
import { formatCurrency } from '../../utils/formatCurrency';
import { toArrayNames } from '../../utils/toArrayNames';
import { useGetPaginatedAsanaBoards, useGetTeams } from '../../services/api';
import { ASANA_BOARD_COLUMNS } from './AsanaBoard.columns';
import UpdateAsanaJob from './UpdateAsanaJob/UpdateAsanaJob';
import { useAsanaBoardsStyles } from './AsanaBoards.styles';

export const AsanaBoards = () => {
	const classes = useAsanaBoardsStyles();
	const [searchTerm, setSearchTerm] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');
	const [teamFilter, setTeamFilter] = useState('');
	const [collapseFilter, setCollapseFilter] = useState(false);

	// QUERIES
	const {
		data: asanaBoards,
		isLoading: isLoadingasanaBoards,
		isError: isErrorasanaBoards,
		isFetching: isFetchingAsanaBoards,
	} = useGetPaginatedAsanaBoards(searchTerm, limit, teamFilter, pageNumber + 1, sort, order);
	const { data: teams, isLoading: isLoadingTeams, isError: isErrorTeams } = useGetTeams();

	//RENDER LOADING & ERROR
	if (isLoadingasanaBoards || isLoadingTeams) {
		return <p>Loading...</p>;
	}

	if (isErrorasanaBoards || isErrorTeams) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = asanaBoards?.result.rows || [];
	const teamsData = teams?.result;

	const teamNamesArray = toArrayNames(teams?.result);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 1000);
	};

	const collapseFilterHandler = () => {
		setCollapseFilter((prev) => !prev);
	};

	const filterTeamHandler = (newValue: string | undefined | null, teams: any) => {
		const team = newValue ? teams.find((team: any) => team.name === newValue).gid : '';

		setTeamFilter(team);
		setPageNumber(0);
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	const findTeam = (teamId: string) => {
		const team = teamsData.find((team: any) => team.gid === teamId);

		return team?.name || '';
	};

	return (
		<Box className={classes.container}>
			<Typography mb={6} variant='h4'>
				Existing Asana Boards
			</Typography>

			<Box className={classes.filtersContainer}>
				<div style={{ display: 'flex' }}>
					<SearchBox onSearch={searchStringHandler} />
					<Box component='button' onClick={collapseFilterHandler} className={classes.filterButton}>
						<Typography className={classes.filterButtonText}>Filters</Typography>
						<ArrowDropDown />
					</Box>
				</div>
				<div className={classes.sortOrderContainer}>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
						<InputLabel id='sort-by'>Sort by</InputLabel>
						<Select labelId='sort-by' id='sort-by' value={sort} label='Sort by' onChange={handleSortChange}>
							<MenuItem value='createdAt'>Created Date</MenuItem>
							<MenuItem value='updatedAt'>Updated Date</MenuItem>
						</Select>
					</FormControl>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
						<InputLabel id='sort-by'>Order by</InputLabel>
						<Select
							labelId='order-by'
							id='order-by'
							value={order}
							label='Order by'
							onChange={handleOrderChange}
						>
							<MenuItem value='desc'>Descending</MenuItem>
							<MenuItem value='asc'>Ascending</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>
						<Autocomplete
							options={teamNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Select team'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterTeamHandler(newValue, teams?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={ASANA_BOARD_COLUMNS} />
						<TableBody sx={{ position: 'relative' }}>
							{searchTerm && isFetchingAsanaBoards && (
								<TableRow className={classes.loaderContainer}>
									<TableCell colSpan={7} align='center'>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{tableData.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={9} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{!isLoadingasanaBoards &&
								tableData?.map((row: any, index: number) => (
									<TableRow key={row.id}>
										<TableCell>{row.job_name}</TableCell>
										<TableCell>{findTeam(row.team_id)}</TableCell>
										<TableCell>{formatCurrency(row.fees)}</TableCell>
										<TableCell>{row.cost ? formatCurrency(row.cost) : '$0.00'}</TableCell>
										<TableCell>
											<UpdateAsanaJob {...row} teams={teams} />
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={asanaBoards.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>
		</Box>
	);
};
