import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/theme';
import { linearProgressClasses } from '@mui/material/LinearProgress';

export const useActiveJobDetailsStyles = makeStyles((theme) => ({
	backButton: {
		display: 'flex',
		alignItems: 'center',
		color: COLORS.neutral.shade400,
		fontSize: '14px',
		cursor: 'pointer',
	},

	status: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
	},

	dateContainer: {
		display: 'flex',
		gap: '2rem',
	},

	dateWrapper: {
		display: 'flex',
		gap: '10px',
	},

	titleWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},

	title: {
		display: 'flex',
		alignItems: 'center',
		gap: '1rem',
	},

	progressContainer: {
		width: '30%',
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},

	progressBarWrapper: {
		width: '100%',
	},

	progressBar: {
		backgroundColor: COLORS.neutral.shade100,
		height: '16px',

		[`& .${linearProgressClasses.bar}`]: {
			backgroundColor: COLORS.success.default,
		},
	},

	jobDetails: {
		display: 'flex',
		alignItems: 'flex-start',
		gap: '3rem',
	},

	jobDetailItem: {
		// border: '1px solid red',
	},

	jobDescription: {
		// border: '1px solid red',
		maxWidth: '30%',
	},

	jobDetailHeader: {
		fontWeight: 'bold',
		fontSize: '14px',
	},

	jobDetailValue: {
		fontSize: '14px',
		lineHeight: '120%',
		marginTop: '8px',
	},

	tab: {
		'&:hover': {
			color: COLORS.primary.shade600,
		},
		'&.Mui-selected': {
			color: COLORS.primary.shade600,
		},
	},
}));
