import React, { useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	Checkbox,
	FormControlLabel,
	FormGroup,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useGetActiveJobNameList, useAssignInvoice } from '../../../services/api';
import { AssignInvoiceProps, jobProps } from './AssignInvoice.props';
import { useAssignInvoiceStyles } from './AssignInvoice.styles';

export const AssignInvoice = (props: AssignInvoiceProps) => {
	const classes = useAssignInvoiceStyles();
	const { openAssign, closeAssignHandler, invoiceId, limit, pageNumber, term } = props;
	const [selectedJob, setSelectedJob] = useState({
		job_id: 0,
		job_name: '',
	});
	const [isSplit, setIsSplit] = useState(false);

	// QUERIES
	const {
		data: activeJobNames,
		isLoading: isLoadingActiveJobNames,
		isError: isErrorActiveJobNames,
	} = useGetActiveJobNameList();
	const {
		mutate: assignJob,
		isLoading: isLoadingAssignJob,
		isError: isErrorAssignJob,
	} = useAssignInvoice(term, limit, pageNumber);

	//RENDER LOADING & ERROR
	if (isLoadingActiveJobNames) {
		return <p>Loading...</p>;
	}

	if (isErrorActiveJobNames) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const activeJobs = activeJobNames?.result;
	const activeJobsNameArray = activeJobNames?.result.map((item: any) => item.job_name);

	// HANDLERS
	const getActiveJobDetailsHandler = (jobName: string | undefined | null) => {
		const job = activeJobs.find((job: jobProps) => job.job_name === jobName);
		setSelectedJob(job);
	};

	const assignJobHandler = () => {
		const data = {
			id: selectedJob.job_id,
			invoiceID: invoiceId,
			is_split_invoice: isSplit,
		};

		assignJob(data);
		closeAssignHandler();
	};

	return (
		<div>
			<Dialog
				open={openAssign}
				onClose={closeAssignHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Assign Invoice
						</Typography>
						<ClearOutlinedIcon onClick={closeAssignHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>
				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						<Autocomplete
							options={activeJobsNameArray}
							clearOnEscape
							fullWidth
							size='small'
							renderInput={(params) => (
								<TextField {...params} label='Select from jobs' variant='standard' required />
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								getActiveJobDetailsHandler(newValue)
							}
						/>
						<FormControlLabel
							color='error'
							sx={{ marginTop: '1rem' }}
							control={<Checkbox color='error' onChange={() => setIsSplit((prevState) => !prevState)} />}
							label='Retain this invoice in the unassigned list to allow multiple assignment.'
						/>
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={assignJobHandler}
						disableElevation
						// disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
					>
						Assign
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
