import React from 'react';
import { Typography, Box, FormGroup, FormControlLabel, Switch } from '@mui/material';

const NotificationSettings = () => {
	return (
		<Box>
			<Typography mb={2} variant='h5'>
				Notification
			</Typography>
			<Box>
				<FormGroup>
					<FormControlLabel
						control={<Switch defaultChecked color='error' />}
						label='Unassigned invoices requiring attention'
					/>
					<FormControlLabel
						control={<Switch color='error' />}
						label='Unassigned documents (currently quotes) requiring attention'
					/>
				</FormGroup>
			</Box>
		</Box>
	);
};

export default NotificationSettings;
