import { Route, Routes } from 'react-router-dom';
import {
	ActiveJobs,
	ActiveJobDetails,
	Clients,
	Contact,
	Help,
	Settings,
	Timesheets,
	UnassignedJobs,
	UnassignedPosCosts,
	JobPage,
	Dashboard,
	Login,
	UnassignedInvoices,
	Tasks,
	Attachments,
	TimeEntries,
	Costs,
	Quotes,
	Invoices,
	Archives,
	ArchivedJobs,
	UnassignedDocs,
	ArchivedInvoices,
	AsanaBoards,
	OtherDocuments,
	Milestones,
	ReportsPage,
} from '../../pages';
import { PrivateRoute } from '../../services/routes';

export const ProtectedRoutes = () => {
	return (
		<div style={{ marginBottom: '60px' }}>
			<Routes>
				{/* <Route path='/' element={<PrivateRoute />}>
					<Route path='/' element={<Dashboard />} />
				</Route> */}

				<Route path='/unassigned-docs' element={<PrivateRoute />}>
					<Route path='/unassigned-docs' element={<UnassignedDocs />} />
					{/* <Route path='/unassigned-docs' element={<UnassignedJobs />} /> */}
				</Route>

				<Route path='/active-jobs' element={<PrivateRoute />}>
					<Route path='/active-jobs' element={<ActiveJobs />} />
				</Route>

				<Route path='/active-jobs/:id' element={<PrivateRoute />}>
					<Route path='/active-jobs/:id' element={<ActiveJobDetails />}>
						<Route path='tasks/:asana_project_id' element={<Tasks />} />
						<Route path='time-entries' element={<TimeEntries />} />
						<Route path='attachments' element={<Attachments />} />
						<Route path='costs' element={<Costs />} />
						<Route path='quotes' element={<Quotes />} />
						<Route path='invoices' element={<Invoices />} />
						<Route path='milestones' element={<Milestones />} />
					</Route>
				</Route>

				<Route path='/other-documents' element={<PrivateRoute />}>
					<Route path='/other-documents' element={<OtherDocuments />} />
				</Route>

				<Route path='/asana-boards' element={<PrivateRoute />}>
					<Route path='/asana-boards' element={<AsanaBoards />} />
				</Route>

				<Route path='/unassigned-pos-costs' element={<PrivateRoute />}>
					<Route path='/unassigned-pos-costs' element={<UnassignedPosCosts />} />
				</Route>

				<Route path='/timesheets' element={<PrivateRoute />}>
					<Route path='/timesheets' element={<Timesheets />} />
				</Route>

				{/* <Route path='/reports' element={<PrivateRoute />}>
					<Route path='/reports' element={<Reports />} />
				</Route> */}

				<Route path='/clients' element={<PrivateRoute />}>
					<Route path='/clients' element={<Clients />} />
				</Route>

				<Route path='/reports' element={<PrivateRoute />}>
					<Route path='/reports' element={<ReportsPage />} />
				</Route>

				<Route path='/settings' element={<PrivateRoute />}>
					<Route path='/settings' element={<Settings />} />
				</Route>

				<Route path='/help' element={<PrivateRoute />}>
					<Route path='/help' element={<Help />} />
				</Route>

				<Route path='/archive-jobs' element={<PrivateRoute />}>
					<Route path='/archive-jobs' element={<Archives />}>
						<Route path='jobs' element={<ArchivedJobs />} />
						{/* <Route path='tasks/:asana_project_id' element={<Tasks />} /> */}

						<Route path='invoices' element={<ArchivedInvoices />} />
					</Route>
				</Route>

				<Route path='/job-page' element={<PrivateRoute />}>
					<Route path='/job-page' element={<JobPage />} />
				</Route>

				<Route path='/unassigned-invoices' element={<PrivateRoute />}>
					<Route path='/unassigned-invoices' element={<UnassignedInvoices />} />
				</Route>

				{/* <Route path='/login' element={<PrivateRoute />}> */}
				<Route path='/login' element={<Login />} />
				{/* </Route> */}

				<Route path='*' element={<PrivateRoute />}>
					<Route path='*' element={<UnassignedDocs />} />
				</Route>
			</Routes>
		</div>
	);
};
