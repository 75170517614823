export enum QueryKeys {
	// USER DATA
	GET_USER_DATA = 'get-user-data',

	// ACTIVE JOBS
	GET_ACTIVE_JOBS = 'get-active-jobs',
	GET_PAGINATED_ACTIVE_JOBS = 'get-paginated-active-jobs',
	GET_ACTIVE_JOB_NAME_LIST = 'get-active-job-name-list',
	GET_ACTIVE_JOB = 'get-active-job',
	GET_UNIQUE_ACTIVE_JOB = 'get-unique-active-job',
	GET_ARCHIVED_JOBS = 'get-archived-jobs',

	// CLIENTS
	GET_CLIENT_LIST = 'get-client-list',
	GET_UNIQUE_CLIENT_NAMES = 'get-unique-client-names',
	GET_UNIQUE_CUSTOMER_NAMES = 'get-unique-customer-names',

	// JOB TYPES
	GET_JOB_TYPES = 'get-job-types',

	// BILLING & BUDGET
	GET_BILLING_EMAILS = 'get-billing-emails',
	GET_BILLING_DETAILS = 'get-billing-details',
	GET_BILLING_TYPES = 'get-billing-types',
	GET_BUDGET_TYPES = 'get-budget-types',

	// TASKS
	GET_PAGINATED_TASKS = 'get-paginated-tasks',
	GET_JOB_TASKS = 'get-job-tasks',

	// INVOICES
	GET_PAGINATED_INVOICES = 'get-paginated-invoices',
	GET_ARCHIVED_INVOICES = 'get-archived-invoices',
	POST_ARCHIVED_INVOICES = 'get-archived-invoices',
	GET_PAGINATED_UNASSIGNED_INVOICES = 'get-paginated-unassigned-invoices',

	// DOCS
	GET_PAGINATED_UNASSIGNED_DOCS = 'get-paginated-unassigned-docs',
	GET_UNASSIGNED_DOC_SUBTASKS = 'get-paginated-unassigned-docs',
	GET_DOC_TYPES = 'get-doc-types',
	POST_OTHER_DOC = 'post-other-doc',

	// COSTS
	GET_PAGINATED_COSTS = 'get-paginated-costs',
	GET_SUPPIERS = 'get-suppliers',

	// QUOTES
	GET_PAGINATED_QUOTES = 'get-paginated-quotes',

	// TIME ENTRIES
	GET_PAGINATED_TIME_ENTRIES = 'get-paginated-time-entries',

	// ASSIGNEES
	GET_ASSIGNEES = 'get-assignees',

	// TEAMS
	GET_UNIQUE_TEAMS = 'get-unique-teams',

	// ATTACHMENTS
	GET_ATTACHMENTS = 'get-attachments',
	GET_PAGINATED_ATTACHMENTS = 'get-paginated-attachments',

	// ASANA BOARDS
	GET_PAGINATED_ASANA_BOARDS = 'get-paginated-asana-boards',

	// ASANA BOARDS
	GET_PAGINATED_MILESTONES = 'get-paginated-milestones',

	// NOTIFICATIONS
	GET_IN_APP_NOTIFICATIONS = 'get-in-app-notifications',
	CLEAR_IN_APP_NOTIFICATIONS = 'clear-in-app-notifications',
}
