import React, { useEffect, useState } from 'react';
import { DataTable, editAndRemove, DataTablePagination } from '../../../components';
import { useTaskRemove, useTaskUpdate, useTaskAddFromAsana, useTaskCreate } from '../../../hooks/pages';
import { TaskRemove } from './TaskRemove';
import { TaskUpdate } from './TaskUpdate';
import { TaskAddFromAsana } from './TaskAddFromAsana';
import { TaskCreate } from './TaskCreate';

export const Task = ({
	dataJobTask,
	asana_project_id,
	jobDetails,
	loadingJobTask,
	search,
	tbIndex,
	jobFilter,
}: any) => {
	// const useTaskAddFromAsanaProps = useTaskAddFromAsana(jobDetails);
	const useTaskRemoveProps = useTaskRemove();
	const useTaskUpdateProps = useTaskUpdate();
	const useTaskCreateProps = useTaskCreate({ createDetails: jobDetails });
	const [jobTaskList, setJobTaskList] = useState([]);

	const columns = [
		{ field: 'task', headerName: 'Tasks' },
		{ field: 'description', headerName: 'Description' },
		// { field: 'fee', headerName: 'Fees' },
		{ field: 'hours', headerName: 'Hours' },
		{ field: 'assignee', headerName: 'Assignees' },
		{ field: 'dueOn', headerName: 'Due On' },
		{
			field: 'actions',
			headerName: (
				<>
					<span
						style={{
							cursor: 'pointer',
							textDecoration: 'underline',
							marginRight: '5px',
							color: '#c5332f',
							textUnderlineOffset: '3px',
						}}
						onClick={() => useTaskCreateProps.toggleCreateTask()}
					>
						Add task
					</span>
				</>
			),
		},
	];

	const formatDataTaskJob = (data: any) => {
		return data && data.result && data.result.length
			? data.result.map((value: any) => {
					return {
						id: value.gid,
						task: value.name,
						description: value.notes,
						dueOn: value.due_on,
						// fee: '-',
						hours: String(value.time_estimate ? value.time_estimate : '-'),
						assignee: value.assignee,
					};
			  })
			: [];
	};

	useEffect(() => {
		const resultFormat = formatDataTaskJob(dataJobTask);
		const resultReformat = editAndRemove({
			data: resultFormat,
			handleEdit: useTaskUpdateProps.handleEditTask,
			handleRemove: useTaskRemoveProps.handleRemoveTask,
		});
		setJobTaskList(resultReformat);
	}, [dataJobTask]);

	const addZeroSingleNumber = (length: any, mainNumber: any) => {
		length = length || 2;
		return (new Array(length).join('0') + mainNumber).slice(length * -1);
	};

	useEffect(() => {
		if (jobFilter.taskDateDisable) {
			if (search && dataJobTask && dataJobTask.result && dataJobTask.result.length && tbIndex === 5) {
				let filtered = dataJobTask.result.filter((item: any) => {
					const assigneeNameCheck = item.assignee  ? item.assignee : String(Math.random());
					const dueonCheck = item.due_on ? item.due_on : String(Math.random());
					const nameCheck = item.name ? item.name : String(Math.random());
					const notesCheck = item.notes ? item.notes : String(Math.random());
					return (
						nameCheck.toLowerCase().includes(search.toLowerCase()) ||
						notesCheck.toLowerCase().includes(search.toLowerCase()) ||
						dueonCheck.toLowerCase().includes(search.toLowerCase()) ||
						assigneeNameCheck.toLowerCase().includes(search.toLowerCase())
					);
				});

				const getData = filtered
					? filtered.map((value: any) => {
							return {
								id: value.gid,
								task: value.name,
								description: value.notes,
								dueOn: value.due_on,
								fee: '',
								assignee: value.assignee ? value.assignee : '',
							};
					  })
					: [];
				const resultReformat = editAndRemove({
					data: getData,
					handleEdit: useTaskUpdateProps.handleEditTask,
					handleRemove: useTaskRemoveProps.handleRemoveTask,
				});

				setJobTaskList(resultReformat);
			} else {
				const resultFormat = formatDataTaskJob(dataJobTask);
				const resultReformat = editAndRemove({
					data: resultFormat,
					handleEdit: useTaskUpdateProps.handleEditTask,
					handleRemove: useTaskRemoveProps.handleRemoveTask,
				});
				setJobTaskList(resultReformat);
			}
		} else {
			if (
				tbIndex === 5 &&
				dataJobTask &&
				dataJobTask.result &&
				dataJobTask.result.length &&
				!jobFilter.taskDateDisable
			) {
				let filtered = dataJobTask.result.filter((item: any) => {
					const addZeroInMonth = addZeroSingleNumber(2, jobFilter.taskDate.getMonth() + 1);
					const formatDate = `${jobFilter.taskDate.getFullYear()}-${addZeroInMonth}`;
					const checkDueOn = item.due_on ? item.due_on : String(Math.random());

					return checkDueOn.toLowerCase().includes(formatDate.toLowerCase());
				});

				if (search && filtered.length && tbIndex === 5) {
					let filteredFormat = filtered.filter((item: any) => {
						const assigneeName = item.assignee ? item.assignee : String(Math.random());
						const dueonCheck = item.due_on ? item.due_on : String(Math.random());
						const nameCheck = item.name ? item.name : String(Math.random());
						const notesCheck = item.notes ? item.notes : String(Math.random());
						return (
							nameCheck.toLowerCase().includes(search.toLowerCase()) ||
							notesCheck.toLowerCase().includes(search.toLowerCase()) ||
							dueonCheck.toLowerCase().includes(search.toLowerCase()) ||
							assigneeName.toLowerCase().includes(search.toLowerCase())
						);
					});

					const getDataFormat = filteredFormat
						? filteredFormat.map((value: any) => {
								return {
									id: value.gid,
									task: value.name,
									description: value.notes,
									dueOn: value.due_on,
									fee: '',
									assignee: value.assignee && value.assignee ? value.assignee : '',
								};
						  })
						: [];
					const resultReformat = editAndRemove({
						data: getDataFormat,
						handleEdit: useTaskUpdateProps.handleEditTask,
						handleRemove: useTaskRemoveProps.handleRemoveTask,
					});
					setJobTaskList(resultReformat);
				} else {
					const getData = filtered
						? filtered.map((value: any) => {
								return {
									id: value.gid,
									task: value.name,
									description: value.notes,
									dueOn: value.due_on,
									fee: '',
									assignee: value.assignee && value.assignee ? value.assignee : '',
								};
						  })
						: [];
					const resultReformat = editAndRemove({
						data: getData,
						handleEdit: useTaskUpdateProps.handleEditTask,
						handleRemove: useTaskRemoveProps.handleRemoveTask,
					});

					setJobTaskList(resultReformat);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	const tableData = { columns, rows: jobTaskList, actions: true, isLoading: loadingJobTask };

	return (
		<div>
			{/* <DataTable {...tableData} /> */}
			<DataTablePagination {...tableData} />

			<TaskUpdate {...useTaskUpdateProps} />
			{/* <TaskAddFromAsana {...useTaskAddFromAsanaProps} /> */}
			<TaskRemove {...useTaskRemoveProps} />
			<TaskCreate {...useTaskCreateProps} />
		</div>
	);
};
