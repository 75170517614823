import { useContext, useEffect, useState } from 'react';
import { DataTable, DataTablePagination } from '../../components';
import { jobPageTimeEntriesDataTable } from '../../data/tableColumns';
import Context from '../../services/context';
import { useGetTimeEntries } from '../../services/api';

export const TimeEntries = ({ asanaProjectId, jobFilter, search, tbIndex }: any) => {
	const { data: timeEntriesData, isLoading: timeEntriesLoading } = useGetTimeEntries(asanaProjectId);
	const { dispatch, state } = useContext(Context);
	const [timeEntriesList, settimeEntriesList] = useState([]);

	const formatDataTaskJob = (data: any) => {
		return data && data.length
			? data.map((value: any, key: any) => {
					return {
						id: key,
						date: value.date,
						task: value.name,
						staff: value.user,
						time: String(
							typeof value.time === 'number'
								? new Date(value.time * 1000).toISOString().slice(11, -8)
								: value.time
						),
					};
			  })
			: [];
	};

	useEffect(() => {
		const resultFormat = formatDataTaskJob(timeEntriesData && timeEntriesData.result ? timeEntriesData.result : []);
		settimeEntriesList(resultFormat);
	}, [state.timeEntries]);

	useEffect(() => {
		const formattedJobs = formatDataTaskJob(
			timeEntriesData && timeEntriesData.result ? timeEntriesData.result : []
		);
		if (jobFilter.taskDateDisable) {
			if (search && formattedJobs && formattedJobs.length && tbIndex === 4) {
				let filtered = formattedJobs.filter((item: any) => {
					return (
						item.task.toLowerCase().includes(search.toLowerCase()) ||
						item.date.toLowerCase().includes(search.toLowerCase()) ||
						item.staff.toLowerCase().includes(search.toLowerCase()) ||
						item.time.toLowerCase().includes(search.toLowerCase())
					);
				});

				settimeEntriesList(filtered);
			} else {
				settimeEntriesList(formattedJobs);
			}
		} else {
			if (tbIndex === 4 && formattedJobs && formattedJobs.length && !jobFilter.taskDateDisable) {
				let filtered = formattedJobs.filter((item: any) => {
					const getStringMonth = jobFilter.taskDate.toLocaleString('default', { month: 'long' });
					const getFullYear = String(jobFilter.taskDate.getFullYear());

					return (
						item.date.toLowerCase().includes(getStringMonth.toLowerCase()) &&
						item.date.toLowerCase().includes(getFullYear.toLowerCase())
					);
				});

				if (search && filtered.length && tbIndex === 4) {
					let filteredFormat = filtered.filter((item: any) => {
						return (
							item.task.toLowerCase().includes(search.toLowerCase()) ||
							item.date.toLowerCase().includes(search.toLowerCase()) ||
							item.staff.toLowerCase().includes(search.toLowerCase()) ||
							item.time.toLowerCase().includes(search.toLowerCase())
						);
					});

					settimeEntriesList(filteredFormat);
				} else {
					const getData = filtered.length ? filtered : [];
					settimeEntriesList(getData);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	const tableData = {
		columns: jobPageTimeEntriesDataTable.columns,
		rows: timeEntriesList,
		isLoading: timeEntriesLoading,
	};

	return (
		<div>
			{/* <DataTable {...tableData} /> */}
			<DataTablePagination {...tableData} />
		</div>
	);
};
