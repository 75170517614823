import React, { useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ArchiveInvoiceProps } from './ArchiveInvoice.props';
import { useArchiveInvoice } from '../../../../services/api';
import { COLORS } from '../../../../constants/theme';
import { useArchiveInvoiceStyles } from './ArchiveInvoice.styles';

export const ArchiveInvoice = (props: ArchiveInvoiceProps) => {
	const { invoiceId, pageNumber, jobId, limit, number } = props;
	const classes = useArchiveInvoiceStyles();
	const [open, setOpen] = useState(false);
	const [archiveReason, setArchiveReason] = useState('Replace with new retainer/job');

	const {
		mutate: archiveInvoice,
		isLoading: isLoadingArchiveInvoice,
		isError: isErrorArchiveInvoice,
	} = useArchiveInvoice(limit, pageNumber + 1, jobId);

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const archiveInvoiceHandler = (id: string, reason: string) => {
		const archiveInvoiceData = { id: id, reason: reason };
		archiveInvoice(archiveInvoiceData);
		closeHandler();
	};

	const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setArchiveReason((event.target as HTMLInputElement).value);
	};

	return (
		<div>
			<Button
				sx={{ textDecoration: 'underline', color: COLORS.primary.default, textTransform: 'capitalize' }}
				variant='text'
				onClick={clickOpenHandler}
			>
				Archive
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Archive Invoice
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '0 2rem', minWidth: '500px' }}>
					<FormControl sx={{ width: '100%' }}>
						<Typography variant='body1' className={classes.subTitle}>
							Are you sure you want to archive this invoice?
						</Typography>

						<Typography variant='h6' sx={{ marginTop: '.5rem' }}>
							Invoice Number: <strong>INV-{number}</strong>
						</Typography>
					</FormControl>

					<FormControl sx={{ marginTop: '2rem' }}>
						<FormLabel id='archive-reason-label' color='error'>
							Reason for Archive
						</FormLabel>
						<RadioGroup
							aria-labelledby='demo-radio-buttons-group-label'
							defaultValue='Replace with new retainer/job'
							name='archive-reasons'
							onChange={handleReasonChange}
						>
							<FormControlLabel
								value='Replace with new retainer/job'
								control={<Radio color='error' />}
								label='Replace with new retainer/job'
							/>
							<FormControlLabel
								value='Job/retainer complete'
								control={<Radio color='error' />}
								label='Job/retainer complete'
							/>
							<FormControlLabel
								value='Client cancellation'
								control={<Radio color='error' />}
								label='Client cancellation'
							/>
							<FormControlLabel value='Other' control={<Radio color='error' />} label='Other' />
						</RadioGroup>
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.archiveBtn}
						variant='contained'
						onClick={() => archiveInvoiceHandler(invoiceId, archiveReason)}
						disableElevation
					>
						Archive
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
