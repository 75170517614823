import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	Skeleton,
	IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { AssigneeProps, AddJobScopeTaskProps, TaskProps } from './JobScopeTaskModal.props';
import { useAddTaskStyles } from './JobScopeTaskModal.styles';

const AddJobScopeTask = (props: AddJobScopeTaskProps) => {
	const {
		setAssignDocData,
		assignDocData,
		assigneeEmails,
		assignees,
		isLoadingAssignees,
		isErrorAssignees,
		rowData,
		taskAction,
	} = props;
	const classes = useAddTaskStyles();
	const [openAddTask, setOpenAddTask] = useState(false);
	const [openEditTask, setOpenEditTask] = useState(false);

	const [disableSubmit, setDisableSubmit] = useState(false);
	const [newTask, setNewTask] = useState({
		task_name: '',
		assign_user: '',
		assign_user_id: '',
		notes: '',
		due_on: '',
		time_estimate: 0,
		id: '',
	});

	useEffect(() => {
		if (rowData) {
			setNewTask({
				...newTask,
				task_name: rowData.task_name,
				assign_user: rowData.assign_user,
				assign_user_id: rowData.assign_user_id,
				notes: rowData.notes,
				due_on: rowData.due_on,
				time_estimate: rowData.time_estimate,
				id: rowData.id,
			});
		}
	}, [rowData]);

	useEffect(() => {
		newTask.task_name === '' || newTask.assign_user === '' || newTask.assign_user_id === ''
			? setDisableSubmit(true)
			: setDisableSubmit(false);
	}, [newTask.task_name, newTask.assign_user_id, newTask.notes]);

	const changeAssigneeHandler = (email: string | undefined | null) => {
		const assignee = assignees.find((item: AssigneeProps) => item.email === email);
		if (assignee) {
			return setNewTask({
				...newTask,
				assign_user: assignee.name,
				assign_user_id: assignee.gid,
			});
		}
	};

	const changeTextFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target.name === 'due_on') {
			return setNewTask({
				...newTask,
				[event.target.name]: new Date(event.target.value).toLocaleDateString('fr-CA'),
			});
		}
		return setNewTask({ ...newTask, [event.target.name]: event.target.value });
	};

	const submitHandler = () => {
		if (taskAction === 'add') {
			setAssignDocData({
				...assignDocData,
				asana_task: [...assignDocData.asana_task, { ...newTask, id: uuidv4() }],
			});
		}

		if (taskAction === 'edit') {
			if (rowData) {
				const updatedTasks = assignDocData.asana_task.map((task: TaskProps) =>
					task.id === rowData.id ? { ...newTask } : task
				);

				setAssignDocData({
					...assignDocData,
					asana_task: updatedTasks,
				});
			}
		}

		setOpenAddTask(false);
		setOpenEditTask(false);
		resetForm();
	};

	const resetForm = () => {
		setNewTask({
			...newTask,
			task_name: '',
			assign_user: '',
			assign_user_id: '',
			notes: '',
			due_on: '',
			time_estimate: 0,
			id: '',
		});
	};

	return (
		<div>
			{taskAction === 'add' ? (
				<Button className={classes.addTaskBtn} onClick={() => setOpenAddTask(true)}>
					Add Task
				</Button>
			) : (
				<IconButton
					aria-label='delete'
					size='small'
					onClick={() => (taskAction === 'add' ? setOpenAddTask(true) : setOpenEditTask(true))}
				>
					<EditIcon fontSize='small' />
				</IconButton>
			)}

			<Dialog
				open={taskAction === 'add' ? openAddTask : openEditTask}
				onClose={() => (taskAction === 'add' ? setOpenAddTask(false) : setOpenEditTask(false))}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							{taskAction === 'add' ? 'Create New Task' : 'Update Task'}
						</Typography>
						<ClearOutlinedIcon
							onClick={() => (taskAction === 'add' ? setOpenAddTask(false) : setOpenEditTask(false))}
							sx={{ cursor: 'pointer' }}
						/>
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{isLoadingAssignees ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Assignee:</Typography>
									<Autocomplete
										options={assigneeEmails}
										defaultValue={newTask.assign_user}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												label='Select assignee'
												variant='standard'
												required
											/>
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeAssigneeHandler(newValue)
										}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Task Name:</Typography>
									<TextField
										name='task_name'
										fullWidth
										id='task-name'
										variant='standard'
										placeholder='Enter task name*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={newTask.task_name}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='notes'
										fullWidth
										id='task-description'
										variant='standard'
										placeholder='Enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={newTask.notes}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Due on:</Typography>
									<TextField
										name='due_on'
										fullWidth
										id='due-on'
										type='date'
										variant='standard'
										placeholder='Due on'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newTask.due_on}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated time:</Typography>
									<TextField
										name='time_estimate'
										fullWidth
										type='number'
										id='estimated-time'
										variant='standard'
										placeholder='Hours*'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newTask.time_estimate}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={submitHandler}
						disableElevation
						disabled={disableSubmit || isErrorAssignees}
					>
						{taskAction === 'add' ? 'Add Task' : 'Update'}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddJobScopeTask;
