import { Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Modal } from '../../../components';

export const TaskRemove = ({modalPropsRemoveTask, toggleRemoveTask, removeTask }: any) => {
	return (
		<Modal {...modalPropsRemoveTask}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleRemoveTask}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Remove Task!
			</Typography>
			<div style={{ marginBottom: '40px'  }}>
				<Typography mb={3} className='gs__inline-field__title'>
					Are you sure you want remove the following task?
				</Typography>
				<Typography style={{ fontSize: '20px' }} className='gs__inline-field__title'>
					{removeTask?.task ? removeTask.task : ''}
				</Typography>{' '}
			</div>
		</Modal>
	);
};
