import { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	InputAdornment,
	Skeleton,
} from '@mui/material';
import { Edit, ClearOutlined } from '@mui/icons-material';
import { useGetAssigneeList, usePutAsanaTask } from '../../../../services/api';
import { AssigneeProps, UpdateTaskProps } from './UpdateTask.props';
import { useUpdateTaskStyles } from './UpdateTask.styles';

const UpdateTask = (props: UpdateTaskProps) => {
	const { asana_project_id, job_id, pageNumber, limit, row } = props;
	const classes = useUpdateTaskStyles();
	const [open, setOpen] = useState(false);
	const [taskAssignee, setTaskAssignee] = useState('');
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [taskData, setTaskData] = useState({
		task_name: '',
		assign_user: '',
		assign_user_id: '',
		assign_user_email: '',
		projects_id: '',
		notes: '',
		due_on: '',
		time_estimate: 0,
	});

	// QUERIES
	const { data: assignees, isError: isErrorAssignees, isLoading: isLoadingAssignees } = useGetAssigneeList();
	const {
		mutate: updateTask,
		isLoading: isLoadingUpdateTask,
		isError: isErrorUpdateTask,
	} = usePutAsanaTask(pageNumber + 1, limit, job_id);

	useEffect(() => {
		taskData.task_name === '' || taskData.assign_user === '' || taskData.assign_user_id === ''
			? setDisableSubmit(true)
			: setDisableSubmit(false);
	}, [taskData.task_name, taskData.assign_user_id, taskData.notes]);

	// Prefill fields
	useEffect(() => {
		setTaskData({
			...taskData,
			projects_id: asana_project_id,
			task_name: row.name,
			notes: row.notes,
			due_on: row.due_on,
			time_estimate: row.time_estimate,
			assign_user: row.assignee,
		});
	}, [row, open]);

	useEffect(() => {
		if (row) {
			setTaskAssignee(row.assignee);
		}
	}, [row]);

	// DATA
	const assigneesData = assignees?.result;
	const assigneesArray = assignees?.result.map((item: AssigneeProps) => item.name);

	const clickOpenHandler = () => {
		setOpen(true);
		getAssigneeHandler(taskAssignee);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const getAssigneeHandler = (name: string) => {
		const assignee = assigneesData.find((item: AssigneeProps) => item.name === name);

		if (assignee) {
			setTaskData({
				...taskData,
				assign_user: assignee.name,
				assign_user_id: assignee.gid,
				assign_user_email: assignee.email,
			});
		}
	};

	const changeAssigneeHandler = (name: string | undefined | null) => {
		const assignee = assigneesData.find((item: AssigneeProps) => item.name === name);

		return setTaskData({
			...taskData,
			assign_user: assignee ? assignee.name : '',
			assign_user_id: assignee ? assignee.gid : '',
			assign_user_email: assignee ? assignee.email : '',
		});
	};

	const changeTextFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target.name === 'due_on') {
			return setTaskData({
				...taskData,
				[event.target.name]: new Date(event.target.value).toLocaleDateString('fr-CA'),
			});
		}

		return setTaskData({ ...taskData, [event.target.name]: event.target.value });
	};

	const updateTaskHandler = () => {
		const updatedTask = {
			task_id: row.gid,
			task_name: taskData.task_name,
			assign_user: taskData.assign_user,
			assign_user_id: taskData.assign_user_id,
			projects_id: taskData.projects_id,
			notes: taskData.notes,
			due_on: taskData.due_on,
			time_estimate: taskData.time_estimate,
		};

		updateTask(updatedTask);
		setOpen(false);
		resetForm();
	};

	const resetForm = () => {
		setTaskData({
			task_name: '',
			assign_user: '',
			assign_user_id: '',
			assign_user_email: '',
			projects_id: '',
			notes: '',
			due_on: '',
			time_estimate: 0,
		});
	};

	return (
		<div>
			<Button className={classes.addTaskBtn} onClick={clickOpenHandler}>
				<Edit />
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Update Task
						</Typography>
						<ClearOutlined onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{isLoadingAssignees ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Assignee:</Typography>
									<Autocomplete
										options={assigneesArray}
										defaultValue={taskData.assign_user}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												label='Select assignee'
												variant='standard'
												required
											/>
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeAssigneeHandler(newValue)
										}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Task Name:</Typography>
									<TextField
										name='task_name'
										fullWidth
										id='task-name'
										variant='standard'
										placeholder='Enter task name*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={taskData.task_name}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='notes'
										fullWidth
										id='task-description'
										variant='standard'
										placeholder='Enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={taskData.notes}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Due on:</Typography>
									<TextField
										name='due_on'
										fullWidth
										id='due-on'
										type='date'
										variant='standard'
										placeholder='Due on'
										size='small'
										sx={{ marginTop: '16px' }}
										value={taskData.due_on}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated time:</Typography>
									<TextField
										name='time_estimate'
										fullWidth
										type='number'
										id='estimated-time'
										variant='standard'
										placeholder='Hours*'
										size='small'
										sx={{ marginTop: '16px' }}
										value={taskData.time_estimate}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={updateTaskHandler}
						disableElevation
						disabled={disableSubmit || isErrorAssignees || isErrorUpdateTask}
					>
						Update Task
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UpdateTask;
