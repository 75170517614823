export interface StringMap {
	[key: string]: string;
}

export const ACTIONS: StringMap = {
	ADD_ITEM: 'add_item',
	ADD_TOKEN: 'add_token',
	ADD_UNASSIGN_JOBS: 'add_unassign_jobs',
	ADD_TASKS: 'add_tasks',
	SET_LOADING_UNASSIGN_JOBS: 'set_loading_unassign_jobs',
	SET_ACTIVE_JOBS: 'set_active_jobs',
	SET_ACTIVE_JOB: 'set_active_job',
	SET_LOADING_ACTIVE_JOBS: 'set_loading_active_jobs',
	SET_UNASSIGNED_JOBS_TICKER: 'set_unassigned_job_ticker',
	SET_JOB_NUMBER_HELPER: 'set_job_number_helper',
	SET_USER_NAME: 'set_user_name',
	SET_POS: 'set_pos',
	SET_POS_TICKER: 'set_pos_ticker',
	SET_POS_LOADING: 'set_pos_loading',
	SET_POS_ASSIGN_LOADING: 'set_pos_assign_loading',
	SET_TIME_ENTRIES: 'set_time_entries',
	SET_TIME_ENTRIES_LOADING: 'set_time_entries_loading',
	SET_INVOICES: 'set_invoices',
	SET_INVOICES_LOADING: 'set_invoices_loading',
	SET_INVOICES_SELECTION: 'set_invoices_selection',
	SET_INVOICES_SELECTION_LOADING: 'set_invoices_selection_loading',
	SET_INVOICE_ASSIGN_LOADING: 'set_invoice_assign_loading',
	SET_INVOICE_JOB_SELECTION: 'set_invoice_job_selection',
	SET_CLIENTS: 'set_clients',
	SET_CLIENTS_LOADING: 'set_clients_loading',
	SET_ARCHIVE_JOBS: 'set_archive_jobs',
	SET_ARCHIVE_JOBS__LOADING: 'set_archive_jobs_loading',
	SET_NEXT_JOB_NUMBER: 'set_next_job_number',
	SET_NEXT_QUOTE_NUMBER: 'set_next_quote_number',
	SET_TEAMS: 'set_teams',
	SET_ACTIVE_JOB_ASSIGNING: 'set_active_job_assigning',
	SET_ASSIGNEES: 'set_assignees',
	SET_PROJECT_JOBS: 'set_project_jobs',
	SET_RETAINER_JOBS: 'set_retainer_jobs',

	REMOVE_ITEM: 'remove_item',

};
