import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';

import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetPaginatedInvoices } from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { InvoiceProps } from './invoices.props';
import { ArchiveInvoice } from '../ArchiveInvoice/ArchiveInvoice';
import { INVOICES_COLUMNS } from './Invoices.columns';
import { useInvoicesStyles } from './Invoices.styles';

export const Invoices = () => {
	const classes = useInvoicesStyles();
	const { id = 0 } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: invoiceData,
		isError: isErrorInvoice,
		isLoading: isLoadingInvoice,
		isFetching: isFetchingInvoice,
	} = useGetPaginatedInvoices(pageNumber + 1, limit, Number(id));

	// RENDER LOADING & ERROR
	if (isLoadingInvoice) {
		return <p>Loading...</p>;
	}

	if (isErrorInvoice) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const invoices = invoiceData.message === 'Success' ? invoiceData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={INVOICES_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{isFetchingInvoice && (
						<TableRow className={classes.loaderContainer}>
							<TableCell>
								<CircularProgress color='error' size={24} />
							</TableCell>
						</TableRow>
					)}

					{invoices.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{invoices?.map((invoice: InvoiceProps) => (
						<TableRow key={invoice.invoiceId}>
							<TableCell>{invoice.date}</TableCell>
							<TableCell>{invoice.number}</TableCell>
							<TableCell>{invoice.type}</TableCell>
							<TableCell>{formatCurrency(invoice.costs)}</TableCell>
							<TableCell>{formatCurrency(invoice.fees)}</TableCell>
							<TableCell>{invoice.dueDate}</TableCell>
							<TableCell style={{ textTransform: 'uppercase' }}>{invoice.status}</TableCell>
							<TableCell>
								<ArchiveInvoice
									invoiceId={invoice.invoiceId}
									number={invoice.number}
									pageNumber={pageNumber}
									limit={limit}
									jobId={Number(id)}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={invoiceData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
