import {
	Box,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	FormControl,
	TextField,
	Autocomplete,
} from '@mui/material';
import { BillingSettingsProps, EmailItemProps } from './BillingSettings.props';

import { useBillingSettingsStyles } from './BillingSettings.styles';

const BillingSettings = (props: BillingSettingsProps) => {
	const classes = useBillingSettingsStyles();
	const { onChangeBillingTextField, billing, billingEmails, onChangeBudgetDropdownHandler, budgetTypeId } = props;

	return (
		<Box sx={{ minWidth: '500px' }}>
			<Box>
				<FormGroup>
					{/* DISALLOW OVER BUDGET */}
					{[2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, null].includes(budgetTypeId) && (
						<FormControlLabel
							sx={{ width: 'fit-content' }}
							control={
								<Checkbox
									checked={billing.disallow_over_budget}
									className={classes.checkbox}
									onChange={(e: any) =>
										onChangeBillingTextField('disallow_over_budget', e.target.checked)
									}
								/>
							}
							label='Disallow over-budget'
						/>
					)}
					{/* EXCLUDE BILLABLE EXPENSES */}
					{[8, 9, 10, 13, null].includes(budgetTypeId) && (
						<FormControlLabel
							sx={{ width: 'fit-content' }}
							control={
								<Checkbox
									checked={billing.exclude_expenses}
									className={classes.checkbox}
									onChange={(e: any) =>
										onChangeBillingTextField('exclude_expenses', e.target.checked)
									}
								/>
							}
							label='Exclude billable expenses'
						/>
					)}
					{/* EXCLUDE NON-BILLABLE TIME */}
					{[7, 11, 12, null].includes(budgetTypeId) && (
						<FormControlLabel
							sx={{ width: 'fit-content' }}
							control={
								<Checkbox
									checked={billing.exclude_non_billable_time}
									className={classes.checkbox}
									onChange={(e: any) =>
										onChangeBillingTextField('exclude_non_billable_time', e.target.checked)
									}
								/>
							}
							label='Exclude non-billable time'
						/>
					)}
					{/* EXCLUDE COST EXPENSES */}
					{[3, 14, 15, 16, null].includes(budgetTypeId) && (
						<FormControlLabel
							sx={{ width: 'fit-content' }}
							control={
								<Checkbox
									checked={billing.exclude_expenses}
									className={classes.checkbox}
									onChange={(e: any) =>
										onChangeBillingTextField('exclude_expenses', e.target.checked)
									}
								/>
							}
							label='Exclude cost expenses'
						/>
					)}
					{/* RESET BUDGET EVERY (PERIOD) */}
					{[2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, null].includes(budgetTypeId) && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={billing.reset_budget}
										onChange={(e: any) =>
											onChangeBillingTextField('reset_budget', e.target.checked)
										}
										className={classes.checkbox}
									/>
								}
								label={<span>Reset budget every </span>}
							/>
							{/* RESET BUDGET PERIOD */}
							<FormControl variant='standard' sx={{ minWidth: 120 }}>
								<Select
									value={billing.period}
									onChange={(e: any) => onChangeBudgetDropdownHandler('period', e.target.value)}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
								>
									<MenuItem value='general'>general</MenuItem>
									<MenuItem value='daily'>daily</MenuItem>
									<MenuItem value='weekly'>weekly</MenuItem>
									<MenuItem value='monthly'>monthly</MenuItem>
								</Select>
							</FormControl>
						</Box>
					)}
					{/* START BUDGET FROM (DATE) */}
					{[2, 3, 7, 8, 9, 11, 12, 14, 15, null].includes(budgetTypeId) && (
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={billing.start_budget_from}
										onChange={(e: any) =>
											onChangeBillingTextField('start_budget_from', e.target.checked)
										}
										className={classes.checkbox}
									/>
								}
								label={<span>Start budget from </span>}
							/>
							{/* START BUDGET DATE */}
							<Box sx={{ minWidth: 120 }}>
								<TextField
									name='date'
									fullWidth
									id='date'
									type='date'
									variant='standard'
									size='small'
									sx={{ marginTop: '16px' }}
									value={billing.applied_from}
									onChange={(e: any) => onChangeBillingTextField('applied_from', e.target.value)}
								/>
							</Box>
						</Box>
					)}
					{/* SHOW BUDGET TO PROJECT MEMBERS */}
					{[2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, null].includes(budgetTypeId) && (
						<FormControlLabel
							sx={{ width: 'fit-content' }}
							control={
								<Checkbox
									className={classes.checkbox}
									checked={billing.show_budget}
									onChange={(e: any) => onChangeBillingTextField('show_budget', e.target.checked)}
								/>
							}
							label='Show budget to all project members'
						/>
					)}
					{/* EMAIL */}
					{[2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, null].includes(budgetTypeId) && (
						<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
							<FormControlLabel
								sx={{ display: 'flex', alignItems: 'flex-end' }}
								control={
									<Checkbox
										checked={!!billing.threshold}
										onChange={(e: any) =>
											onChangeBillingTextField('has_threshold', e.target.checked)
										}
										className={classes.checkbox}
										sx={{ paddingBottom: '2px' }}
									/>
								}
								label='Email'
							/>
							{/* EMAIL LIST */}
							<Autocomplete
								options={billingEmails
									.map((email: EmailItemProps) => email.name)
									.concat(['all admins'])}
								value={
									billing.threshold_emails && billing.threshold_emails?.length > 0
										? findValue(billingEmails, billing.threshold_emails[0])?.name
										: 'all admins'
								}
								disableClearable
								fullWidth
								size='small'
								sx={{ marginRight: '8px' }}
								renderInput={(params) => <TextField {...params} variant='standard' />}
								onChange={(event: any, newValue: string) =>
									onChangeBillingTextField('threshold_emails', newValue)
								}
							/>
							<span>about</span>
							<FormControl
								variant='standard'
								sx={{
									minWidth: '10%',
									display: 'flex',
									marginLeft: '16px',
									justifyContent: 'center',
									marginRight: '8px',
								}}
							>
								<Select
									value={billing.threshold}
									onChange={(e: any) => onChangeBillingTextField('threshold', Number(e.target.value))}
								>
									<MenuItem value='0'>0</MenuItem>
									<MenuItem value='75'>75</MenuItem>
									<MenuItem value='80'>80</MenuItem>
									<MenuItem value='85'>85</MenuItem>
								</Select>
							</FormControl>
							<span>threshold</span>
						</Box>
					)}
				</FormGroup>
			</Box>
		</Box>
	);
};

const findValue = (array: any, id: number) => {
	const item = array.find((item: any) => item.id === id);
	return item;
};

export default BillingSettings;
