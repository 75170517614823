export const ARCHIVED_JOBS_COLUMNS = [
	// { field: 'no', headerName: '#' },
	{ field: 'job_name', headerName: 'Job Name' },
	{ field: 'client_name', headerName: 'Client' },
	{ field: 'job_number', headerName: 'Job Number' },
	{ field: 'fees', headerName: 'Fees' },
	{ field: 'costs', headerName: 'Costs' },
	{ field: 'job_type', headerName: 'Job Type' },
	{ field: 'reason', headerName: 'Reason for Archive' },
];
