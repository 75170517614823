import { ActiveJobsColumnProps, TableHeaderComponentProps } from './TableHeaderComponent.props';
import { TableHead, TableRow, TableCell } from '@mui/material';

// TABLE HEADER
const TableHeaderComponent = (props: TableHeaderComponentProps) => {
	const { headerColumns, children } = props;

	return (
		<TableHead>
			<TableRow>
				{headerColumns.map((column: ActiveJobsColumnProps) => (
					<TableCell sx={{ fontWeight: 'bold' }} key={column.field}>
						{column.headerName}
					</TableCell>
				))}
				{children}
			</TableRow>
		</TableHead>
	);
};

export default TableHeaderComponent;
