import { useReducer, useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
// import { LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { initialState, reducer, ContextProvider } from '../../services/context';
import { useValidateToken } from '../../services/auth';
import { Layout } from '../';
import { login } from '../../services/api';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

export const App = () => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const { gsqToken } = useValidateToken();
	const ContextValues = {
		state,
		dispatch,
	};

	useEffect(() => {
		dispatch({ type: 'add_token', payload: gsqToken });
	}, []);

	if (!gsqToken) {
		login();
		return <></>;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ContextProvider value={ContextValues}>
					<ToastContainer hideProgressBar={true} theme='colored' />
					<div style={{ height: '100vh', width: '100%' }}>
						<Layout />
					</div>
					<ReactQueryDevtools initialIsOpen={true} />
				</ContextProvider>
			</LocalizationProvider>
		</QueryClientProvider>
	);
};
