import NumberFormat from 'react-number-format';
import { Typography, Box, TextField, Autocomplete, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
// import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Modal } from '../../../components';

export const TaskUpdate = ({
	modalPropsEditTask,
	toggleEditTask,
	handleEditTaskData,
	editTask,
	editTaskValidation,
	defaultPropsAssignees,
	selectedAssignee,
	editTaskLoading,
}: any) => {
	return (
		<Modal {...modalPropsEditTask}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleEditTask}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Update Task
				{editTaskLoading ? <span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span> : ''}
				{editTaskLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Box
					className='gs-pagetwo__row-box gs__inline-field'
					style={{ height: '32px', marginTop: '0px', marginBottom: '15px' }}
				>
					<Typography className='gs__inline-field__title'>Assignee:</Typography>
					<Box>
						<Autocomplete
							className='gs__inline-field__container'
							{...defaultPropsAssignees}
							id='clear-on-escape'
							clearOnEscape
							value={selectedAssignee}
							onChange={(event, newValue) => {
								handleEditTaskData(newValue, 'assignee');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select teams'
									error={!editTaskValidation.assignee ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Task:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleEditTaskData(event, 'task')}
							defaultValue={editTask.task}
							id='standard-basic'
							variant='standard'
							error={!editTaskValidation.task ? true : false}
							placeholder='*Please enter task'
						/>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Description:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleEditTaskData(event, 'description')}
							defaultValue={editTask.description}
							id='standard-basic'
							variant='standard'
							error={!editTaskValidation.description ? true : false}
							placeholder='*Please enter description'
						/>
					</Box>
				</Box>
				{/* <Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Fee:</Typography>
					<Box className='gs__inline-field__container'>
						<NumberFormat
							thousandsGroupStyle='thousand'
							onValueChange={(values) => handleEditTaskData(values, 'fee')}
							value={editTask.fee}
							prefix='$ '
							decimalSeparator='.'
							customInput={TextField}
							displayType='input'
							type='text'
							id='standard-basic'
							variant='standard'
							thousandSeparator={true}
							allowNegative={true}
							// error={!editTaskValidation.fee ? true : false}
							placeholder='*Please enter fee'
						/>
					</Box>
				</Box> */}
				{/* <Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Assignee:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleEditTaskData(event, 'assignee')}
							defaultValue={editTask.assignee}
							id='standard-basic'
							variant='standard'
							error={!editTaskValidation.assignee ? true : false}
							placeholder='*Please enter assignee'
						/>
					</Box>
				</Box> */}
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Due on:</Typography>
					<Box className='gs__inline-field__container'>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={editTask.dueOn}
								onChange={(newValue: any) => {
									handleEditTaskData(newValue, 'dueOn');
								}}
								renderInput={(params: any) => <TextField className={'gs__date-picker'} {...params} />}
							/>
						{/* </LocalizationProvider> */}
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '125px', marginRight: '0px' }}>
						Estimated time:
					</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							type='number'
							// className='gs-activeJobs__basic-input'
							style={{ width: '80px' }}
							id='standard-basic'
							variant='standard'
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							onChange={(event: any) => handleEditTaskData(event, 'estimatedTimeHours')}
							defaultValue={editTask.estimatedTimeHours}
							value={editTask.estimatedTimeHours}
							placeholder='*Hours'
							error={!editTask.estimatedTimeHours ? true : false}
						/>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};
