import { useContext, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'react-toastify';
import { DataTable, DataTablePagination } from '../../../components';
import { jobPageInvoicesDataTable } from '../../../data/tableColumns';
import { AddInvoices } from './AddInvoices';
import { InvoiceRemove } from './InvoiceRemove';
import Context from '../../../services/context';
import { usePutInvoice, useDeleteInvoice } from '../../../services/api';

export const Invoices = ({ jobDetails, jobFilter, search, tbIndex }: any) => {
	const { dispatch, state } = useContext(Context);
	const [showAddInvoice, setShowAddInvoice] = useState(false);
	const [jobId, setJobId] = useState<any>(null);
	const daw = usePutInvoice();
	const { mutate, isSuccess, isLoading } = daw;
	const [invoicesSelectionList, setInvoicesSelectionList] = useState<any>({
		id: 0,
		invoice: '000',
		invoiceId: '000',
	});
	const [invoiceSelected, setInvoiceSelected] = useState<any>({});
	const [invoiceList, setInvoiceList] = useState([]);

	function toggleAddInvoice() {
		setShowAddInvoice((showAddInvoice) => !showAddInvoice);
	}

	const handleAddTaskSubmit = (): void => {
		dispatch({ type: 'set_invoices_selection_loading', payload: true });
		if (invoiceSelected) {
			const format = {
				id: jobDetails.jobId,
				invoiceID: invoiceSelected.invoiceId,
			};
			mutate(format);
		} else {
			toast.info('Please select invoice!');
		}
	};

	const columns = [
		...jobPageInvoicesDataTable.columns,
		// {
		// 	field: 'actions',
		// 	headerName: (
		// 		<>
		// 			<span
		// 				style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '5px' }}
		// 				onClick={() => toggleAddInvoice()}
		// 			>
		// 				Add Invoice
		// 			</span>
		// 		</>
		// 	),
		// },
	];

	const formatDataInvoicesJob = (data: any) => {
		return data
			? data.map((value: any, index: any) => {
					return {
						id: index,
						invoiceId: value.invoice_id,
						date: value.date,
						number: value.invoiceNumber,
						type: value.type,
						amountDue: value.amountDue,
						amountPaid: value.amountPaid,
						total: value.total,
						due: value.duedate,
						status: value.status,
						client: value.client_name,
					};
			  })
			: [];
	};

	const handleFilterData = () => {
		return state.activeJobs
			? state.activeJobs.filter((value: any) => value.asana_project_id === jobDetails.asana_project_id)
			: [];
	};

	useEffect(() => {
		const filterJobs = handleFilterData();
		const filter =
			filterJobs && filterJobs[0] && filterJobs[0].invoice ? formatDataInvoicesJob(filterJobs[0].invoice) : [];
		setInvoiceList(filter);

		setJobId(filterJobs.length ? filterJobs[0].id : null);
	}, [state.activeJobs]);

	const modalPropsAddInvoice = {
		handleSubmit: handleAddTaskSubmit,
		isShowing: showAddInvoice,
		hide: toggleAddInvoice,
		currentPage: 1,
		pageLength: 1,
	};

	const addProps = {
		modalPropsAddInvoice,
		toggleAddInvoice,
		setInvoiceSelected,
		invoicesSelectionList,
		loading: state.invoicesSelectionLoading,
	};

	useEffect(() => {
		const filter = state.invoicesSelection.map((value: any, index: any) => ({
			id: index,
			invoice: `Invoice Number: ${value.invoiceNumber}`,
			invoiceId: value.invoiceID,
		}));
		setInvoicesSelectionList(filter);
	}, [state.invoicesSelection]);

	useEffect(() => {
		if (daw.data && daw.data.status === 200) {
			toggleAddInvoice();
		}
	}, [daw.data]);

	useEffect(() => {
		const filterJobs = handleFilterData();
		const formattedJobs =
			filterJobs && filterJobs[0] && filterJobs[0].invoice ? formatDataInvoicesJob(filterJobs[0].invoice) : [];
		if (jobFilter.taskDateDisable) {
			if (search && formattedJobs && formattedJobs.length && tbIndex === 1) {
				let filtered = formattedJobs.filter((item: any) => {
					return (
						item.date.toLowerCase().includes(search.toLowerCase()) ||
						item.number.toLowerCase().includes(search.toLowerCase()) ||
						item.type.toLowerCase().includes(search.toLowerCase()) ||
						String(item.amountDue).toLowerCase().includes(search.toLowerCase()) ||
						String(item.amountPaid).toLowerCase().includes(search.toLowerCase()) ||
						item.due.toLowerCase().includes(search.toLowerCase()) ||
						item.status.toLowerCase().includes(search.toLowerCase()) ||
						item.client.toLowerCase().includes(search.toLowerCase())
					);
				});
				setInvoiceList(filtered);
			} else {
				setInvoiceList(formattedJobs);
			}
		} else {
			if (tbIndex === 1 && formattedJobs && formattedJobs.length && !jobFilter.taskDateDisable) {
				let filtered = formattedJobs.filter((item: any) => {
					const getStringMonth = jobFilter.taskDate.toLocaleString('default', { month: 'short' });
					const getFullYear = String(jobFilter.taskDate.getFullYear());

					return (
						item.date.toLowerCase().includes(getStringMonth.toLowerCase()) &&
						item.date.toLowerCase().includes(getFullYear.toLowerCase())
					);
				});
				if (search && filtered.length && tbIndex === 1) {
					let filteredFormat = filtered.filter((item: any) => {
						return (
							item.date.toLowerCase().includes(search.toLowerCase()) ||
							item.number.toLowerCase().includes(search.toLowerCase()) ||
							item.type.toLowerCase().includes(search.toLowerCase()) ||
							String(item.amountDue).toLowerCase().includes(search.toLowerCase()) ||
							String(item.amountPaid).toLowerCase().includes(search.toLowerCase()) ||
							item.due.toLowerCase().includes(search.toLowerCase()) ||
							item.status.toLowerCase().includes(search.toLowerCase()) ||
							item.client.toLowerCase().includes(search.toLowerCase())
						);
					});

					setInvoiceList(filteredFormat);
				} else {
					const getData = filtered.length ? filtered : [];
					setInvoiceList(getData);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	// remove invoice =======================================
	const {
		mutate: mutateRemoveInvoice,
		data: costRemoveInvoice,
		isLoading: invoiceRemoveLoading,
	} = useDeleteInvoice();
	const [showRemoveInvoice, setShowRemoveInvoice] = useState(false);
	const [removeInvoice, setRemoveInvoice] = useState<any>({});

	function toggleRemoveInvoice() {
		setShowRemoveInvoice((showRemoveInvoice) => !showRemoveInvoice);
	}

	const handleRemoveInvoice = (value: any): void => {
		setRemoveInvoice(value);
		toggleRemoveInvoice();
	};

	const handleRemoveInvoiceSubmit = (): void => {
		const formatData = {
			id: jobId,
			invoice_id: removeInvoice.invoiceId,
		};
		mutateRemoveInvoice(formatData);
	};

	useEffect(() => {
		if (costRemoveInvoice && costRemoveInvoice.error === false) {
			toggleRemoveInvoice();
		}
	}, [costRemoveInvoice]);

	const modalPropsRemoveInvoice = {
		handleSubmit: handleRemoveInvoiceSubmit,
		isShowing: showRemoveInvoice,
		hide: toggleRemoveInvoice,
		currentPage: 1,
		pageLength: 1,
	};

	const invoiceRemoveProps = {
		modalPropsRemoveInvoice,
		toggleRemoveInvoice,
		removeInvoice,
		invoiceRemoveLoading,
	};

	// remove invoice =======================================

	const reformatData = invoiceList
		? invoiceList.map((list: any) => ({
				...list,
				actions: (
					<span
						className='gs__icon__remove'
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => handleRemoveInvoice({ ...list })}
					>
						<Tooltip placement='top' title='Remove' arrow>
							<DeleteForeverIcon />
						</Tooltip>
					</span>
				),
		  }))
		: [];

	const tableData = { columns, rows: reformatData };

	return (
		<div>
			{/* <DataTable {...tableData} actions={true} /> */}
			<DataTablePagination {...tableData} actions={true} />
			<AddInvoices {...addProps} />
			<InvoiceRemove {...invoiceRemoveProps} />
		</div>
	);
};
