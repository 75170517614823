import { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Skeleton,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { usePostDocument } from '../../../../services/api';
import { AddAttachmentProps } from './AddAttachment.props';
import { useAddAttachmentStyles } from './AddAttachment.styles';

const AddAttachment = (props: AddAttachmentProps) => {
	const { asana_project_id, job_id, pageNumber, limit } = props;
	const classes = useAddAttachmentStyles();
	const [open, setOpen] = useState(false);
	const [documentData, setdocumentData] = useState<any>({
		job_id: job_id,
		docs_file: null,
		title: '',
		notes: '',
	});

	// QUERIES
	const addAttachmentQuery = usePostDocument(job_id, pageNumber + 1, limit);

	useEffect(() => {
		setdocumentData({ ...documentData, job_id: job_id });
	}, []);

	// RENDER LOADING & ERROR
	if (addAttachmentQuery.isLoading) {
		return <Skeleton variant='rectangular' width={150} height={20} />;
	}

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeTextFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		return setdocumentData({ ...documentData, [event.target.name]: event.target.value });
	};

	const uploadDocumentHandler = (e: any) => {
		setdocumentData({ ...documentData, docs_file: e.target.files[0] });
	};

	const addAttachment = () => {
		let file = new FormData();
		file.append('docs_file', documentData.docs_file);
		file.append('job_id', documentData.job_id);
		file.append('title', documentData.title);
		file.append('notes', documentData.notes);

		addAttachmentQuery.mutate(file);
		resetForm();
		setOpen(false);
	};

	const resetForm = () => {
		setdocumentData({
			job_id: job_id,
			docs_file: null,
			title: '',
			notes: '',
		});
	};

	return (
		<div>
			<Button className={classes.addCostBtn} onClick={clickOpenHandler}>
				Add New
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Add Attachment
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{addAttachmentQuery.isLoading ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Title:</Typography>
									<TextField
										name='title'
										fullWidth
										id='title'
										variant='standard'
										placeholder='Enter title*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										multiline
										maxRows={4}
										value={documentData.title}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography sx={{ width: '55%' }}>File:</Typography>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											width: '100%',
											// marginTop: '16px',
										}}
									>
										<Button
											variant='outlined'
											component='label'
											size='small'
											color='error'
											style={{ whiteSpace: 'nowrap' }}
										>
											choose file
											<input
												id='file'
												hidden
												type='file'
												accept='.xlsx,.xls,.doc,.docx,.pdf'
												onChange={(event) => uploadDocumentHandler(event)}
											/>
										</Button>

										<Typography style={{ paddingTop: '6px' }}>
											{documentData.docs_file && documentData.docs_file.name}
										</Typography>
									</Box>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Notes:</Typography>
									<TextField
										name='notes'
										fullWidth
										id='notes'
										variant='standard'
										placeholder='Enter notes*'
										sx={{ marginTop: '16px' }}
										multiline
										required
										maxRows={4}
										value={documentData.notes}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={(e) => addAttachment()}
						disableElevation
						disabled={!documentData.notes || !documentData.title || !documentData.docs_file}
					>
						Add New
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddAttachment;
