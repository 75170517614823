export const INVOICES_COLUMNS = [
	{ field: 'date', headerName: 'Date' },
	{ field: 'number', headerName: 'Number' },
	{ field: 'type', headerName: 'Type' },
	{ field: 'costs', headerName: 'Costs' },
	{ field: 'fees', headerName: 'Fees' },
	{ field: 'dueDate', headerName: 'Due' },
	{ field: 'status', headerName: 'Status' },
	{ field: '', headerName: '' },
];
