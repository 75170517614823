import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Toolbar,
	Paper,
	Checkbox,
	IconButton,
	Tooltip,
	LinearProgress,
	TextField,
	Link,
	Chip,
	CircularProgress,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { DataTable, editAndRemove, Modal } from '../../components';
import Context from '../../services/context';
import { handleCurrency } from '../../utils';
import { usePutInvoice, useGetInvoices, useGetInvoiceJobSelection } from '../../services/api';

// import { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Link, Box, Chip, TableCell, TableRow, Paper, CircularProgress } from '@mui/material';
// import Context from '../../services/context';

interface Data {
	calories: number;
	carbs: number;
	fat: number;
	name: string;
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (event: any, property: keyof Data) => void;
	onSelectAllClick: (event: any) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
}

export const useDataTablePaginationLogic = ({
	columns,
	rows,
	noBorder = false,
	rowClickable = false,
	isLoading = false,
	pageDefault = 0,
}: any) => {
	const contextValue = useContext(Context);
	const navigate = useNavigate();

	const [orderBy, setOrderBy] = React.useState<keyof Data>('calories');
	const [order, setOrder] = React.useState<Order>('asc');
	const [page, setPage] = React.useState(pageDefault);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selected, setSelected] = React.useState<readonly string[]>([]);

	const attributesTableContainer = {
		className: `gs-components__data-table${noBorder ? ' gs-components__data-table--no-border' : ''}`,
		component: Paper,
	};
	const attributesTable = { sx: { minWidth: 650 }, 'aria-label': 'simple table' };

	function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}

	const getComparator = (order: any, orderBy: any) => {
		return order === 'desc'
			? (a: any, b: any) => descendingComparator(a, b, orderBy)
			: (a: any, b: any) => -descendingComparator(a, b, orderBy);
	};

	const EnhancedTableHead = (props: EnhancedTableProps) => {
		const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

		return (
			<TableHead>
				<TableRow>
					{columns.map((headCell: any) => (
						<TableCell
							style={{ fontWeight: 'bold' }}
							key={headCell.field}
							align={'left'}
							padding={headCell.disablePadding ? 'none' : 'normal'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							{headCell.headerName}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	};

	const stableSort = (array: any, comparator: any) => {
		const stabilizedThis = array.map((el: any, index: any) => [el, index]);
		stabilizedThis.sort((a: any, b: any) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el: any) => el[0]);
	};

	interface EnhancedTableToolbarProps {
		numSelected: number;
		handleSelectAllClick: Function;
	}

	const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
		const { numSelected, handleSelectAllClick } = props;

		return (
			<Toolbar
				style={{ minHeight: '45px' }}
				sx={{
					pl: { sm: 2 },
					pr: { xs: 1, sm: 1 },
					...(numSelected > 0 && {
						bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
					}),
				}}
			>
				{numSelected > 0 ? (
					<Typography sx={{ flex: '1 1 100%' }} color='inherit' variant='subtitle1' component='div'>
						{numSelected} selected
					</Typography>
				) : null}
				{numSelected > 0 ? (
					<Tooltip title='Delete'>
						<IconButton onClick={(event) => handleSelectAllClick(event)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				) : null}
			</Toolbar>
		);
	};

	const tableData: any = { columns, rows: [] };

	// const handleShowUnassignedInvoiceFilter = () => {
	// 	setShowUnassignedInvoiceFilter(!showUnassignedInvoiceFilter);
	// 	// setSelectedClientFilter({ id: 0, name: '' });
	// };

	const handleSelectAllClick = (event: any) => {};

	const handleRequestSort = (event: any, property: keyof Data) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: any) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (task_gid: string) => selected.indexOf(task_gid) !== -1;

	const handleClick = (event: any, row: any) => {};

	const handleNavigate = (row: any) => {
		if (rowClickable) {
			delete row['name'];
			delete row['archive'];
			navigate('/job-page', { state: row });
		}
	};

	const handleCellCurrency = (field: any, index: number) => {
		return (
			<TableCell key={index}>
				{Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				}).format(field)}
			</TableCell>
		);
	};

	useEffect(() => {
		setPage(pageDefault);
	}, [pageDefault]);

	const handleRowsData = (data: any) => {
		return data.map((row: any) => {
			return (
				<TableRow
					// className={`${rowClickable ? 'gs-components__row-cursor' : ''}`}
					// onClick={() => handleNavigate(row)}
					hover
					key={row.id}
					sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				>
					{columns.map((column: any, index: number) => {
						if (typeof row[column.field] === 'number') {
							return handleCellCurrency(row[column.field], index);
						}

						return (
							<TableCell
								// className={`gs-components__data-table--${column.field}`}
								key={index}
								onClick={() => (column.field !== 'archive' ? handleNavigate(row) : '')}
								// onClick={() => handleNavigate(row)}
								className={`gs-components__data-table--${column.field} ${
									rowClickable ? 'gs-components__row-cursor' : ''
								}`}
							>
								{row[column.field]}
							</TableCell>
						);
					})}
				</TableRow>
			);
		});
	};

	// const handleTableBodyRow = (data: any) => {
	// 	if (isLoading) {
	// 		console.log('loadinggggggggggg');

	// 		return (
	// 			<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
	// 				<TableCell
	// 					className={'gs-components__data-table'}
	// 					style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
	// 					align='center'
	// 					colSpan={columns.length}
	// 				>
	// 					<CircularProgress />
	// 				</TableCell>
	// 			</TableRow>
	// 		);
	// 	} else {
	// 		console.log('dattttttaaaaaaaaaaa');

	// 		if (data.length) {
	// 			return handleRowsData(data);
	// 		} else {
	// 			return (
	// 				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
	// 					<TableCell
	// 						className={'gs-components__data-table'}
	// 						style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
	// 						align='center'
	// 						colSpan={columns.length}
	// 					>
	// 						No data found.
	// 					</TableCell>
	// 				</TableRow>
	// 			);
	// 		}
	// 	}
	// };

	const tableHeader = (
		<EnhancedTableHead
			numSelected={selected.length}
			order={order}
			orderBy={orderBy}
			onSelectAllClick={handleSelectAllClick}
			onRequestSort={handleRequestSort}
			rowCount={rows.length}
		/>
	);

	const tableBody = (
		<TableBody>
			{rows.length ? (
				stableSort(rows, getComparator(order, orderBy))
					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
					.map((row: any, index: any) => {
						const isItemSelected = isSelected(row.task_gid);
						const labelId = `enhanced-table-checkbox-${index}`;

						return (
							<TableRow
								hover
								onClick={(event) => handleClick(event, row)}
								role='checkbox'
								aria-checked={isItemSelected}
								tabIndex={-1}
								key={row.task_gid}
								selected={isItemSelected}
							>
								{columns.map((column: any, index: number) => {
									if (typeof row[column.field] === 'number') {
										return handleCellCurrency(row[column.field], index);
									}

									return (
										<TableCell
											// className={`gs-components__data-table--${column.field}`}
											key={index}
											onClick={() => (column.field !== 'archive' ? handleNavigate(row) : '')}
											// onClick={() => handleNavigate(row)}
											className={`gs-components__data-table--${column.field} ${
												rowClickable ? 'gs-components__row-cursor' : ''
											}`}
										>
											{row[column.field]}
										</TableCell>
									);
								})}
							</TableRow>
						);
					})
			) : (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell
						className={'gs-components__data-table'}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
						align='center'
						colSpan={columns.length + 1}
					>
						No data found.
					</TableCell>
				</TableRow>
			)}
		</TableBody>
	);

	const loadingTableBody = () => {
		if (isLoading) {
			return (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell
						className={'gs-components__data-table'}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
						align='center'
						colSpan={columns.length}
					>
						<CircularProgress />
					</TableCell>
				</TableRow>
			);
		} else {
			return tableBody;
		}
	};

	const tablePagination = rows.length ? (
		<Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
			<TablePagination
				rowsPerPageOptions={[10, 20, 30]}
				component='div'
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Box>
	) : (
		''
	);

	return {
		tableHeader,
		loadingTableBody,
		tablePagination,
		setPage,
		...contextValue,
	};
};
