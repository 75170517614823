import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants/theme';

export const useMilestonesStyles = makeStyles((theme) => ({
	taskNameWrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
		// border: '1px solid red',
	},

	taskName: {
		fontWeight: 'bold',
	},

	taskChip: {
		width: 'fit-content',
		fontSize: '12px',
	},

	actionButton: {
		textDecoration: 'underline',
		color: COLORS.primary.default,
	},

	loaderContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'rgb(255 255 255 / 58%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	// PAGINATION
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}));
