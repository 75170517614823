import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Box, Chip, TableCell, TableRow, Paper, CircularProgress } from '@mui/material';
import Context from '../../services/context';

export const useDataTableLogic = ({
	columns,
	rows,
	noBorder = false,
	rowClickable = false,
	isLoading = false,
}: any) => {
	const contextValue = useContext(Context);
	const navigate = useNavigate();

	const attributesTableContainer = {
		className: `gs-components__data-table${noBorder ? ' gs-components__data-table--no-border' : ''}`,
		component: Paper,
	};
	const attributesTable = { sx: { minWidth: 650 }, 'aria-label': 'simple table' };

	const handleTableHeadRow = (
		<TableRow>
			{columns.map((column: any, index: number) => (
				<TableCell key={index}>{column.headerName}</TableCell>
			))}
		</TableRow>
	);

	const handleCellCurrency = (field: any, index: number) => {
		return (
			<TableCell key={index}>
				{Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
				}).format(field)}
			</TableCell>
		);
	};

	const handleCellSubHeader = (field: any, subHeader: any, index: number) => {
		return (
			<TableCell key={index}>
				{field}
				<Box>
					<Chip
						label={subHeader.content}
						size='small'
						sx={{
							backgroundColor: subHeader.bgColor,
							color: subHeader.color,
						}}
					/>
					{subHeader.content === 'Assana' ? (
						<Link href='/#' color='error' sx={{ marginLeft: 1, fontSize: 10 }}>
							Please assign
						</Link>
					) : (
						''
					)}
				</Box>
			</TableCell>
		);
	};

	const handleNavigate = (row: any) => {
		if (rowClickable) {
			delete row['name'];
			delete row['archive'];
			navigate('/job-page', { state: row });
		}
	};

	const handleRowsData = (rows: any) => {
		return rows.map((row: any) => {
			return (
				<TableRow
					// className={`${rowClickable ? 'gs-components__row-cursor' : ''}`}
					// onClick={() => handleNavigate(row)}
					hover
					key={row.id}
					sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				>
					{columns.map((column: any, index: number) => {
						if (typeof row[column.field] === 'number') {
							return handleCellCurrency(row[column.field], index);
						}

						if (row.hasSubHeader === true && column.field === 'taskJobNumber') {
							return handleCellSubHeader(row[column.field], row.subHeader, index);
						}

						return (
							<TableCell
								// className={`${rowClickable ? 'gs-components__row-cursor' : ''}`}
								onClick={() => column.field !== 'archive' ? handleNavigate(row) : ''}
								// onClick={() => handleNavigate(row)}
								className={`gs-components__data-table--${column.field} ${rowClickable ? 'gs-components__row-cursor' : ''}`}
								key={index}
							>
								{row[column.field]}
							</TableCell>
						);
					})}
				</TableRow>
			);
		});
	};

	const handleTableBodyRow = () => {
		if (isLoading) {
			return (
				<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
					<TableCell
						className={'gs-components__data-table'}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
						align='center'
						colSpan={columns.length}
					>
						<CircularProgress />
					</TableCell>
				</TableRow>
			);
		} else {
			if (rows.length) {
				return handleRowsData(rows);
			} else {
				return (
					<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						<TableCell
							className={'gs-components__data-table'}
							style={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
							align='center'
							colSpan={columns.length}
						>
							No data found.
						</TableCell>
					</TableRow>
				);
			}
		}
	};

	return {
		rows,
		attributesTableContainer,
		attributesTable,
		handleTableHeadRow,
		handleCellCurrency,
		handleCellSubHeader,
		handleTableBodyRow,
		...contextValue,
	};
};
