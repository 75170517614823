import { formatCurrency } from '../../../utils/formatCurrency';
import { JobRawDataProps } from './ActiveJobDetails.props';

export const toActiveJobsDetailsData = (item: JobRawDataProps) => {
	const activeJobsDetailsData = {
		id: item?.id ? item.id : 0,
		job_name: item?.job_name ? item.job_name : '-',
		client: item?.client ? item.client : '-',
		job_number: item?.job_number ? item.job_number : '-',
		description: item?.description ? item.description : '-',
		quote_fees: item?.quote_fees ? formatCurrency(item.quote_fees) : '0',
		total_cost: item?.totalCost ? formatCurrency(item.totalCost) : '0',
		job_type: item?.job_category ? item.job_category : '-',
		rate: item?.billing?.rate ? item.billing.rate : '-',
		budget: item?.budget?.total ? item.budget.total : '-',
		start_date: item?.start_date ? item.start_date : '-',
		end_date: item?.end_date ? item.end_date : '-',
		invoice_fees: item?.invoice_fees ? formatCurrency(item.invoice_fees) : '0',
		total_time_entries: item?.total_time_entries ? item.total_time_entries : '-',
		services_list: item?.services_list ? item.services_list : [],
		invoice_milestones: item?.invoice_milestones ? item.invoice_milestones : [],
	};

	return activeJobsDetailsData;
};
