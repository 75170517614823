import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Typography,
	Box,
	TextField,
	CircularProgress,
	Divider,
	Tab,
	Tabs,
	Chip,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@mui/material';

import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetPaginatedCosts } from '../../../../services/api';
import AddCost from '../AddCost/AddCost';
import UpdateCost from '../UpdateCost/UpdateCost';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { COSTS_COLUMNS } from './Costs.columns';
import { CostsProps } from './Costs.props';
import { COLORS } from '../../../../constants/theme';
import { useCostsStyles } from './Costs.styles';

export const Costs = () => {
	const classes = useCostsStyles();
	const { id = 0, asana_project_id = '' } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: costsData,
		isError: isErrorCosts,
		isLoading: isLoadingCosts,
		isFetching: isFetchingCosts,
	} = useGetPaginatedCosts(pageNumber + 1, limit, Number(id));

	// RENDER LOADING & ERROR
	if (isLoadingCosts) {
		return <p>Loading...</p>;
	}

	if (isErrorCosts) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const costs = costsData.message === 'Success' ? costsData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={COSTS_COLUMNS}>
					<TableCell key='add-cost'>
						<AddCost
							asana_project_id={asana_project_id}
							job_id={Number(id)}
							limit={limit}
							pageNumber={pageNumber}
						/>
					</TableCell>
				</TableHeaderComponent>
				<TableBody sx={{ position: 'relative' }}>
					{/* {(searchTerm || teamFilter || clientFilter) && isFetchingActiveJobs && (
				<TableRow className={classes.loaderContainer}>
					<TableCell>
						<CircularProgress color='error' size={24} />
					</TableCell>
				</TableRow>
			)} */}

					{costs.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{costs.map((cost: CostsProps) => (
						<TableRow key={cost.id}>
							<TableCell>{cost.date}</TableCell>
							<TableCell width='50%'>{cost.description}</TableCell>
							<TableCell>{cost.supplier}</TableCell>
							<TableCell>{formatCurrency(cost.amount)}</TableCell>
							<TableCell>
								<UpdateCost
									asana_project_id={asana_project_id}
									job_id={Number(id)}
									limit={limit}
									pageNumber={pageNumber}
									row={cost}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={costsData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
