export const columnsUnassignedJobs = [
	{ field: 'name', headerName: 'Job Name' },
	{ field: 'client', headerName: 'Client' },
	{ field: 'quote_number', headerName: 'Quote Number' },
	{ field: 'fees', headerName: 'Fees' },
	{ field: 'costs', headerName: 'Costs' },
	{ field: 'jobType', headerName: 'Job Type' },
];

export const columnsUnassignedPosCosts = [
	{ field: 'customer', headerName: 'Customer' },
	{ field: 'purchaseOrderNumber', headerName: 'PO Number' },
	{ field: 'total', headerName: 'Total' },
	{ field: 'subTotal', headerName: 'Subtotal' },
	{ field: 'status', headerName: 'Status' },
	{ field: 'actions', headerName: '' },
];

export const columnsActiveJobs = [
	{ field: 'name', headerName: 'Job Name' },
	{ field: 'client', headerName: 'Client' },
	{ field: 'job_number', headerName: 'Job Number' },
	{ field: 'fees', headerName: 'Fees' },
	{ field: 'costs', headerName: 'Costs' },
	{ field: 'job_type', headerName: 'Job Type' },
	// { field: 'archive', headerName: '' },
];

export const columnsAddJobScopeTask = [
	{ field: 'task', headerName: 'Task' },
	{ field: 'description', headerName: 'Description' },
	{ field: 'hours', headerName: 'Hours' },
	{ field: 'assignee', headerName: 'Assignees' },
	// { field: 'fee', headerName: 'Fee' },
	{ field: 'due_on', headerName: 'Due on' },
	{ field: 'actions', headerName: '' },
];

export const addJobScopeFour = [
	{ field: 'name', headerName: 'Name' },
	{ field: 'description', headerName: 'Description' },
	{ field: 'total', headerName: 'Total' },
	{ field: 'actions', headerName: '' },
];

export const jobPageCostDataTable = {
	columns: [
		{ field: 'date', headerName: 'Date' },
		{ field: 'description', headerName: 'Description' },
		{ field: 'poNumber', headerName: 'PO Number' },
		{ field: 'supplier', headerName: 'Supplier' },
		{ field: 'quantity', headerName: 'Quantity' },
		{ field: 'amount', headerName: 'Amount' },
		{ field: 'actions', headerName: '' },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			description: 'Sub-contractor',
			quantity: '1',
			supplier: 'Alley Productions',
			amount: 10000.0,
		},
		{
			id: 2,
			date: '22 August 2021',
			description: 'Media fees',
			quantity: '1',
			supplier: 'Facebook',
			amount: 1500.0,
		},
	],
};

export const jobPageDocumentDataTable = {
	columns: [
		{ field: 'title', headerName: 'Title' },
		{ field: 'notes', headerName: 'Notes' },
		{ field: 'date', headerName: 'Date' },
		{ field: 'document', headerName: 'Document' },
		{ field: 'download', headerName: 'Download' },
		// { field: 'action', headerName: <a href='/#'>Add new</a> },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			document: 'CH_25112019_CONTRACT.docx',
			download: <a href='/#'>Click here</a>,
		},
	],
};

export const jobPageInvoicesDataTable = {
	columns: [
		{ field: 'client', headerName: 'Client' },
		{ field: 'date', headerName: 'Date' },
		{ field: 'number', headerName: 'Number' },
		{ field: 'type', headerName: 'Type' },
		{ field: 'amountDue', headerName: 'Amount Due' },
		{ field: 'amountPaid', headerName: 'Amount Paid' },
		{ field: 'due', headerName: 'Due' },
		{ field: 'status', headerName: 'Status' },
		{ field: 'actions', headerName: '' },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			number: <a href='/#'>INV-001</a>,
			type: 'Project',
			costs: 0.0,
			fees: 10000.0,
			due: '22 October 2021',
			status: 'Paid',
		},
		{
			id: 2,
			date: '27 August 2021',
			number: <a href='/#'>INV-002</a>,
			type: 'Project',
			costs: 1500.0,
			fees: 18000.0,
			due: '27 September 2021',
			status: 'Overdue',
		},
	],
};

export const jobPagePurchaseOrdersDataTable = {
	columns: [
		{ field: 'date', headerName: 'Date' },
		{ field: 'description', headerName: 'Description' },
		{ field: 'po', headerName: 'PO' },
		{ field: 'status', headerName: 'Status' },
		{ field: 'amount', headerName: 'Amount' },
		{ field: 'action', headerName: <a href='/#'>Add new</a> },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			description: 'Sub-contractor',
			po: 'PO-1234',
			status: 'Draft',
			amount: 10000.0,
		},
		{
			id: 2,
			date: '27 September 2021',
			description: 'Media fees',
			po: 'PO-1235',
			status: 'Proceed',
			amount: 1500.0,
		},
	],
};

export const jobPageQuotesDataTable = {
	columns: [
		{ field: 'dateCreated', headerName: 'Date Created' },
		{ field: 'date', headerName: 'Quote Date' },
		{ field: 'number', headerName: 'Number' },
		{ field: 'hours', headerName: 'Hours' },
		{ field: 'type', headerName: 'Type' },
		{ field: 'costs', headerName: 'Costs' },
		{ field: 'fees', headerName: 'Fees' },
		{ field: 'status', headerName: 'Status' },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			number: <a href='/#'>Q-001</a>,
			type: 'Project',
			costs: 0.0,
			fees: 10000.0,
			status: 'Approved',
		},
		{
			id: 2,
			date: '27 August 2021',
			number: <a href='/#'>Q-002</a>,
			type: 'Project',
			costs: 1500.0,
			fees: 18000.0,
			status: 'Approved',
		},
	],
};

export const jobPageTimeEntriesDataTable = {
	columns: [
		{ field: 'date', headerName: 'Date' },
		{ field: 'task', headerName: 'Task' },
		{ field: 'staff', headerName: 'Staff' },
		{ field: 'time', headerName: 'Time' },
	],
	rows: [
		{
			id: 1,
			date: '22 September 2021',
			task: 'UX/UI Design',
			staff: 'Aaron Paul Barnes',
			time: '1:00',
		},
		{
			id: 2,
			date: '27 August 2021',
			task: 'UX/UI Design',
			staff: 'Ruby New',
			time: '0:30',
		},
		{
			id: 3,
			date: '25 August 2021',
			task: 'Project Management',
			staff: 'Maribel Lawlor',
			time: '0:30',
		},
	],
};

export const jobPageTaskAddDataTable = {
	columns: [
		{
			id: 'task',
			numeric: false,
			disablePadding: true,
			label: 'Task',
		},
		{
			id: 'description',
			numeric: true,
			disablePadding: false,
			label: 'Description',
		},
		{
			id: 'fees',
			numeric: true,
			disablePadding: false,
			label: 'Fees',
		},
		{
			id: 'assignee',
			numeric: true,
			disablePadding: false,
			label: 'Assignee',
		},
	],
};
