import { useContext, useEffect, useState } from 'react';
import { Typography, TextField, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import { DataTable, DataTablePagination } from '../../components';
import Context from '../../services/context';
import { usePostAddCost, useGetPOs } from '../../services/api';
import { columnsUnassignedPosCosts } from '../../data/tableColumns';
import { AssignJob } from './AssigningPOs/AssignJob';
import { handleCurrency } from '../../utils';

export const UnassignedPosCosts = () => {
	const { state } = useContext(Context);
	const [posData, setPosData] = useState([]);
	const [showAddInvoice, setShowAddInvoice] = useState(false);
	const [selectedPo, setSelectedPo] = useState('');
	const [invoiceSelected, setInvoiceSelected] = useState<any>({});
	const [invoicesSelectionList, setInvoicesSelectionList] = useState({ id: 0, invoice: '000', invoiceId: '000' });
	const { mutate, data: posCostsData, isLoading: assignCostLoading } = usePostAddCost();
	const [search, setSearch] = useState<any>('');
	const [showUnassignedPOsFilter, setShowUnassignedPOsFilter] = useState(false);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');

	const { data: posCostData, isLoading: posLoading, isError: posError } = useGetPOs(sort, order);

	//RENDER LOADING & ERROR
	const handleShowUnassignedPOsFilter = () => {
		setShowUnassignedPOsFilter(!showUnassignedPOsFilter);
		// setSelectedClientFilter({ id: 0, name: '' });
	};

	function toggleAddInvoice() {
		setShowAddInvoice((showAddInvoice) => !showAddInvoice);
	}

	const searchHandle = (event: any) => {
		event.preventDefault();
		setSearch(event.target.value);
		setPage(0);
	};

	const handleSelectPos = (id: any) => {
		toggleAddInvoice();
		setSelectedPo(id);
	};

	const handleAddTaskSubmit = (): void => {
		if (invoiceSelected) {
			const format = {
				id: invoiceSelected.jobId,
				cost_id: invoiceSelected.cost_id,
			};

			mutate(format);
		} else {
			toast.info('Please select Jobs!');
		}
	};

	const modalPropsAddInvoice = {
		handleSubmit: handleAddTaskSubmit,
		isShowing: showAddInvoice,
		hide: toggleAddInvoice,
		currentPage: 1,
		pageLength: 1,
	};

	const addProps = {
		modalPropsAddInvoice,
		toggleAddInvoice,
		setInvoiceSelected,
		selectedPo,
		invoicesSelectionList,
		loading: assignCostLoading,
	};

	useEffect(() => {
		const filteredData = posCostData?.result ? formatData(posCostData?.result) : [];
		if (showUnassignedPOsFilter) {
			// let formattedJobs = [];
			// if (selectedClientFilter && selectedClientFilter.name) {
			// 	formattedJobs = filteredData.filter((item: any) => {
			// 		return item.client.toLowerCase().includes(selectedClientFilter.name.toLowerCase());
			// 	});
			// } else {
			// 	formattedJobs = filteredData;
			// }
			// if (search) {
			// 	let filtered = formattedJobs.filter((item: any) => {
			// 		return (
			// 			item.job_name.toLowerCase().includes(search.toLowerCase()) ||
			// 			item.client.toLowerCase().includes(search.toLowerCase()) ||
			// 			item.job_number.toLowerCase().includes(search.toLowerCase()) ||
			// 			item.fees.toLowerCase().includes(search.toLowerCase()) ||
			// 			item.job_type.toLowerCase().includes(search.toLowerCase()) ||
			// 			String(item.costs).toLowerCase().includes(search.toLowerCase())
			// 		);
			// 	});
			// 	setUnassignedJobs(filtered);
			// } else {
			// 	setUnassignedJobs(formattedJobs);
			// }
		} else {
			if (search) {
				let filtered = filteredData.filter((item: any) => {
					return (
						item.customer.toLowerCase().includes(search.toLowerCase()) ||
						item.purchaseOrderNumber.toLowerCase().includes(search.toLowerCase()) ||
						item.total.toLowerCase().includes(search.toLowerCase()) ||
						item.subTotal.toLowerCase().includes(search.toLowerCase()) ||
						item.status.toLowerCase().includes(search.toLowerCase())
					);
				});

				setPosData(filtered);
			} else {
				setPosData(filteredData);
			}
		}

		// setPosData(filteredData);
	}, [state.posLoading, state.pos, search, showUnassignedPOsFilter, sort, order, posCostsData]);

	useEffect(() => {
		const filter = state.setActiveJobAssigning
			? state.setActiveJobAssigning.map((value: any, index: any) => ({
					id: index,
					invoice: value.job_name,
					jobId: value.job_id,
					// jobNumber: value.job_number,
			  }))
			: [];
		setInvoicesSelectionList(filter);
	}, [state.setActiveJobAssigning, sort, order]);

	useEffect(() => {
		if (posCostsData && posCostsData.status === 200) {
			toggleAddInvoice();
		} else {
			// toast.info('Server error!')
		}
	}, [posCostsData, sort, order]);

	if (posLoading) {
		return <p>Loading...</p>;
	}

	if (posError) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	const tableData: any = {
		columns: columnsUnassignedPosCosts,
		rows: posData,
		isLoading: state.posLoading,
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	const formatData = (data: any) => {
		return data
			? data.map((value: any) => {
					const details = {
						id: value.purchaseOrderID,
						customer: value.customer,
						purchaseOrderNumber: value.purchaseOrderNumber,
						status: value.status,
						total: value.total ? handleCurrency(value.total) : handleCurrency(0),
						subTotal: value.subTotal ? handleCurrency(value.subTotal) : handleCurrency(0),
						actions: (
							<span
								style={{
									cursor: 'pointer',
									textDecoration: 'underline',
									marginRight: '20px',
									color: '#c5332f',
									textUnderlineOffset: '3px',
								}}
								onClick={() => handleSelectPos(value.purchaseOrderID)}
							>
								Please assign
							</span>
						),
					};

					return details;
			  })
			: [];
	};

	return (
		<Box sx={{ position: 'relative', height: 'calc(100vh - 100px)' }}>
			<Typography mb={5} variant='h4'>
				Unassigned POs/Costs
			</Typography>
			<Box
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}
			>
				<div className='gs-unassignedJobs__header-div'>
					<SearchIcon />
					<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
						Search :
					</Typography>
					<TextField
						value={search}
						onChange={(event) => searchHandle(event)}
						className='gs-unassignedJobs__basic-input'
						id='standard-basic'
						variant='standard'
					/>
				</div>
				<div style={{ display: 'flex', gap: '1rem' }}>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
						<InputLabel id='sort-by'>Sort by</InputLabel>
						<Select labelId='sort-by' id='sort-by' value={sort} label='Sort by' onChange={handleSortChange}>
							<MenuItem value='createdAt'>Created Date</MenuItem>
							<MenuItem value='updatedAt'>Updated Date</MenuItem>
						</Select>
					</FormControl>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
						<InputLabel id='sort-by'>Order by</InputLabel>
						<Select
							labelId='order-by'
							id='order-by'
							value={order}
							label='Order by'
							onChange={handleOrderChange}
						>
							<MenuItem value='desc'>Descending</MenuItem>
							<MenuItem value='asc'>Ascending</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Box>
			{/* <DataTable {...tableData} /> */}
			<DataTablePagination {...tableData} pageDefault={page} />
			<AssignJob {...addProps} />
		</Box>
	);
};
