import { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	Skeleton,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { useAddAsanaTask, useGetAssigneeList } from '../../../../services/api';
import { AssigneeProps, AddTaskProps } from './AddTask.props';
import { useAddTaskStyles } from './AddTask.styles';

const AddTask = (props: AddTaskProps) => {
	const { asana_project_id, job_id, pageNumber, limit } = props;
	const classes = useAddTaskStyles();
	const [open, setOpen] = useState(false);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [newTask, setNewTask] = useState({
		task_name: '',
		assign_user: '',
		assign_user_id: '',
		projects_id: asana_project_id,
		notes: '',
		due_on: '',
		time_estimate: 0,
	});

	// QUERIES
	const { data: assignees, isError: isErrorAssignees, isLoading: isLoadingAssignees } = useGetAssigneeList();
	const {
		mutate: addTask,
		isLoading: isLoadingAddTask,
		isError: isErrorAddTask,
	} = useAddAsanaTask(pageNumber + 1, limit, job_id);

	useEffect(() => {
		newTask.task_name === '' || newTask.assign_user === '' || newTask.assign_user_id === ''
			? setDisableSubmit(true)
			: setDisableSubmit(false);
	}, [newTask.task_name, newTask.assign_user_id, newTask.notes]);

	useEffect(() => {
		setNewTask({ ...newTask, projects_id: asana_project_id });
	}, []);

	// RENDER LOADING & ERROR
	if (isLoadingAddTask) {
		return <Skeleton variant='rectangular' width={150} height={20} />;
	}

	// DATA
	const assigneesData = assignees?.result;
	const assigneesArray = assignees?.result.map((item: AssigneeProps) => item.email);

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeAssigneeHandler = (email: string | undefined | null) => {
		const assignee = assigneesData.find((item: AssigneeProps) => item.email === email);
		return setNewTask({ ...newTask, assign_user: assignee.name, assign_user_id: assignee.gid });
	};

	const changeTextFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target.name === 'due_on') {
			return setNewTask({
				...newTask,
				[event.target.name]: new Date(event.target.value).toLocaleDateString('fr-CA'),
			});
		}
		return setNewTask({ ...newTask, [event.target.name]: event.target.value });
	};

	const addTaskHandler = () => {
		addTask(newTask);
		setOpen(false);
		resetForm();
	};

	const resetForm = () => {
		setNewTask({
			...newTask,
			task_name: '',
			assign_user: '',
			assign_user_id: '',
			notes: '',
			due_on: '',
			time_estimate: 0,
		});
	};

	return (
		<div>
			<Button className={classes.addTaskBtn} onClick={clickOpenHandler}>
				Add Task
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Create New Task
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{isLoadingAssignees ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Assignee:</Typography>
									<Autocomplete
										options={assigneesArray}
										// defaultValue={client_name}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												label='Select assignee'
												variant='standard'
												required
											/>
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeAssigneeHandler(newValue)
										}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Task Name:</Typography>
									<TextField
										name='task_name'
										fullWidth
										id='task-name'
										variant='standard'
										placeholder='Enter task name*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={newTask.task_name}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='notes'
										fullWidth
										id='task-description'
										variant='standard'
										placeholder='Enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={newTask.notes}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Due on:</Typography>
									<TextField
										name='due_on'
										fullWidth
										id='due-on'
										type='date'
										variant='standard'
										placeholder='Due on'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newTask.due_on}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated time:</Typography>
									<TextField
										name='time_estimate'
										fullWidth
										type='number'
										id='estimated-time'
										variant='standard'
										placeholder='Hours*'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newTask.time_estimate}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={(e) => addTaskHandler()}
						disableElevation
						disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
					>
						Add Task
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AddTask;
