import { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import { toast } from 'react-toastify';
import { columnsActiveJobs } from '../../data/tableColumns';
import { handleCurrency } from '../../utils';
import Context from '../../services/context';
import { usePostArchiveJob, useGetActiveJobs, useGetActiveJobsByTeam } from '../../services/api';

export const useActiveJobs = () => {
	// useGetActiveJobs();
	const resultState: any = useLocation();
	const { mutate, data: documentAddData, isLoading: archiveLoading } = usePostArchiveJob();
	const { state, dispatch } = useContext(Context);
	const [jobData, setJobData] = useState([]);
	const [search, setSearch] = useState<any>('');
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [showAddInvoice, setShowAddInvoice] = useState(false);
	const [invoiceSelected, setInvoiceSelected] = useState<any>({});
	const [showSearch, setShowSearch] = useState<any>(true);
	const [selectedPo, setSelectedPo] = useState<any>('');
	const [reasonArchive, setReasonArchive] = useState<any>(1);
	const [reason, setReason] = useState<any>('');
	const [teamList, setTeamList] = useState<any>([]);
	const [selectedTeam, setSelectedTeam] = useState<any>({ id: 0, name: '' });
	const [page, setPage] = useState(0);

	const { mutate: mutateActiveJob, data: dataActiveJob, isLoading: activeJobLoading } = useGetActiveJobsByTeam();

	const defaultPropsTeams: any = {
		options: teamList,
		getOptionLabel: (option: any) => option.name,
	};

	const [jobFilter, setJobFilter] = useState<any>({
		taskDate: new Date(),
		taskDateDisable: true,
		filteredData: [],
	});

	// active job filter ============================================
	const [showActivejobFilter, setShowActivejobFilter] = useState(false);
	const [clientList, setClientList] = useState<any>([]);
	const [newJobCat, setNewJobCat] = useState<any>([
		// { id: 1, name: 'G-Dennis' },
		{ id: 2, name: 'Retainer Job' },
		{ id: 3, name: 'Project Job' },
	]);
	const [selectedNewJobType, setSelectedNewJobType] = useState<any>({ id: 0, name: '' });
	const [selectedClientFilter, setSelectedClientFilter] = useState<any>({ id: 0, name: '' });

	const handleShowActivejobFilter = () => {
		setShowActivejobFilter(!showActivejobFilter);
		setSelectedNewJobType({ id: 0, name: '' });
		setSelectedClientFilter({ id: 0, name: '' });
		// handleResetSearchFilter();
	};

	const defaultPropsNewJobType: any = {
		options: newJobCat,
		getOptionLabel: (option: any) => option.name,
	};

	const formatClientsData = (data: any) => {
		return data
			? data.map((list: any, index: number) => ({
					id: list.id,
					name: list.name,
			  }))
			: [];
	};

	const defaultPropsClients: any = {
		options: clientList,
		getOptionLabel: (option: any) => option.name,
	};

	useEffect(() => {
		const filter = state.clients && state.clients.length ? formatClientsData(state.clients) : [];
		setClientList(filter);
	}, [state.clients]);
	// active job filter ============================================

	const handleArchive = (data: any) => {
		setSelectedPo(data);
		toggleAddInvoice();
	};

	const handleArchiveSubmit = () => {
		let archiveData = new FormData();
		if (reasonArchive === 1) {
			archiveData.append('reason', 'Replace w/ new retainer/job');
			mutate({ id: selectedPo.asana_project_id, body: archiveData });
		} else if (reasonArchive === 2) {
			archiveData.append('reason', 'Job/retainer complete');
			mutate({ id: selectedPo.asana_project_id, body: archiveData });
		} else if (reasonArchive === 3) {
			archiveData.append('reason', 'Client cancellation');
			mutate({ id: selectedPo.asana_project_id, body: archiveData });
		} else if (reasonArchive === 4) {
			if (reason) {
				archiveData.append('reason', reason);
				mutate({ id: selectedPo.asana_project_id, body: archiveData });
			} else {
				toast.info('Please fill for other reason');
			}
		}
	};

	const formatDataInvoicesJob = (data: any) => {
		return data
			? data.map((list: any, index: number) => {
					const fees = list.fees && list.fees ? list.fees : 0;
					const formatBilling = list.billing && list.billing.rate ? list.billing.rate : 0;
					const formatBudget =
						list.budget && list.budget.type
							? list.budget.type === 'money'
								? Number(list.budget.total)
								: list.budget.total
							: '0';

					const jobName = (
						<>
							{/* <CircleIcon fontSize='inherit' sx={{ color: '#3acf41' }} style={{ marginRight: '10px' }} /> */}
							{list.job_name}
						</>
					);

					const dateToday = new Date()

					return {
						jobId: list.id,
						id: list.asana_project_id,
						invoice_milestones: list.invoice_milestones ? list.invoice_milestones : [],
						job_number: list.job_number ? list.job_number : '',
						name: jobName ? jobName : '',
						job_type: list.job_category,
						job_type_id: list.job_category_id ? list.job_category_id : 0,
						client: list.client ? list.client : '-',
						fees: list.period ? `${handleCurrency(fees)}/${list.period}` : handleCurrency(fees),
						feesInt: fees,
						teamId: list.team_id ? list.team_id : '',
						reason: list.reason ? list.reason : '',
						// costs:
						// 	list.period && list.costs !== '-'
						// 		? `${handleCurrency(list.costs)}/${list.period}`
						// 		: list.costs,
						job_name: list.job_name ? list.job_name : '',
						start_date: list.start_date ? list.start_date : dateToday!.toLocaleDateString('fr-CA'),
						end_date: list.end_date ?  list.end_date : dateToday!.toLocaleDateString('fr-CA'),
						qoute_number: list.qoute_number ? list.qoute_number : '',
						asana_project_id: list.asana_project_id,
						workspace_id: list.workspace_id,
						description: list.description ? list.description : '',
						isArchive: true,
						totalInvoice: list.totalInvoice ? list.totalInvoice : 0,
						billing: list.billing ? list.billing : 0,
						costs: list.totalCost ? list.totalCost : 0,
						services_list: list.services_list ? list.services_list : [],
						budget: list.budget ? list.budget : 0,
						invoice_frequency: list.invoice_frequency ? list.invoice_frequency : [],
						archive: (
							<span
								style={{
									cursor: 'pointer',
									textDecoration: 'underline',
									marginRight: '20px',
									color: '#c5332f',
									textUnderlineOffset: '3px',
								}}
								onClick={() => handleArchive(list)}
							>
								Archive
							</span>
						),
					};
			  })
			: [];
	};

	const searchHandle = (event: any) => {
		event.preventDefault();
		setSearch(event.target.value);
		setPage(0);
	};

	useEffect(() => {
		if (resultState && resultState.state && resultState.state.forClient) {
			const filteredJob = resultState.state.data ? formatDataInvoicesJob(resultState.state.data) : [];
			setShowSearch(false);
			setJobData(filteredJob);
		} else {
			setShowSearch(true);
			if (!state.loadingActiveJobs) {
				const filteredJob = state.activeJobs ? formatDataInvoicesJob(state.activeJobs) : [];

				setJobData(filteredJob);
			}
		}
	}, [state.loadingActiveJobs, state.activeJobs, resultState]);

	useEffect(() => {
		setSelectedClient(resultState.state && resultState.state.client ? resultState.state.client : null);
	}, [resultState]);

	useEffect(() => {
		let formattedJobs: any = [];
		if (resultState && resultState.state && resultState.state.forClient) {
			formattedJobs = resultState.state.data ? formatDataInvoicesJob(resultState.state.data) : [];
		} else {
			formattedJobs = state.activeJobs && state.activeJobs.length ? formatDataInvoicesJob(state.activeJobs) : [];
		}

		if (showActivejobFilter) {
			let filteredJobType = [];
			let filteredClients = [];
			let filteredData = [];

			if (selectedNewJobType && selectedNewJobType.name) {
				filteredData = formattedJobs.filter((item: any) => {
					return item.job_type.toLowerCase().includes(selectedNewJobType.name.toLowerCase());
				});
			} else {
				filteredData = formattedJobs;
			}

			if (selectedClientFilter && selectedClientFilter.name) {
				if (filteredData.length) {
					filteredData = filteredData.filter((item: any) => {
						return item.client.toLowerCase().includes(selectedClientFilter.name.toLowerCase());
					});
				} else {
					filteredData = formattedJobs.filter((item: any) => {
						return item.client.toLowerCase().includes(selectedClientFilter.name.toLowerCase());
					});
				}
			}

			if (search) {
				let filtered = filteredData.filter((item: any) => {
					return (
						item.job_name.toLowerCase().includes(search.toLowerCase()) ||
						item.client.toLowerCase().includes(search.toLowerCase()) ||
						item.job_number.toLowerCase().includes(search.toLowerCase()) ||
						item.fees.toLowerCase().includes(search.toLowerCase()) ||
						item.job_type.toLowerCase().includes(search.toLowerCase()) ||
						String(item.costs).toLowerCase().includes(search.toLowerCase())
					);
				});

				setJobData(filtered);
			} else {
				setJobData(filteredData);
			}
		} else {
			if (search) {
				let filtered = formattedJobs.filter((item: any) => {
					return (
						item.job_name.toLowerCase().includes(search.toLowerCase()) ||
						item.client.toLowerCase().includes(search.toLowerCase()) ||
						item.job_number.toLowerCase().includes(search.toLowerCase()) ||
						item.fees.toLowerCase().includes(search.toLowerCase()) ||
						item.job_type.toLowerCase().includes(search.toLowerCase()) ||
						String(item.costs).toLowerCase().includes(search.toLowerCase())
					);
				});

				setJobData(filtered);
			} else {
				setJobData(formattedJobs);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		jobFilter.taskDate,
		jobFilter.taskDateDisable,
		search,
		resultState,
		showActivejobFilter,
		selectedNewJobType,
		selectedClientFilter,
	]);

	useEffect(() => {
		// const validateField = Object.keys(documentValidation).filter((key) => !documentValidation[key]);

		if (documentAddData && documentAddData.status) {
			// toggleAddDocument();
			if (documentAddData.status === 200) {
				toggleAddInvoice();
				toast.success('Successfully archived job');
			} else {
				toast.error('Server error!');
			}
		}
	}, [documentAddData]);

	useEffect(() => {
		const filterTeams = state.teams.map((value: any) => ({ name: value.name, id: value.gid }));
		setTeamList(filterTeams);
		// if (filterTeams.length) {
		// 	setSelectedTeam(filterTeams[0]);
		// 	if (filterTeams[0].id) {
		// 		mutateActiveJob(filterTeams[0].id);
		// 	}
		// }
	}, [state.teams]);

	useEffect(() => {
		if (selectedTeam.id) {
			mutateActiveJob(selectedTeam.id);
		}
	}, [selectedTeam]);

	function toggleAddInvoice() {
		setShowAddInvoice((showAddInvoice) => !showAddInvoice);
	}

	const modalPropsAddInvoice = {
		handleSubmit: handleArchiveSubmit,
		isShowing: showAddInvoice,
		hide: toggleAddInvoice,
		currentPage: 1,
		pageLength: 1,
	};

	const addProps = {
		modalPropsAddInvoice,
		toggleAddInvoice,
		setInvoiceSelected,
		selectedPo,
		loading: archiveLoading,
		reasonArchive,
		setReasonArchive,
		setReason,
		reason,
		// selectedInvoiceId,
	};

	const reformatHeader = [...columnsActiveJobs, { field: 'archive', headerName: '' }];

	return {
		jobData,
		loading: activeJobLoading,
		columnsActiveJobs: reformatHeader,
		search,
		searchHandle,
		selectedClient,
		addProps,
		showSearch,

		showActivejobFilter,
		handleShowActivejobFilter,
		defaultPropsNewJobType,
		selectedNewJobType,
		setSelectedNewJobType,
		defaultPropsClients,
		selectedClientFilter,
		setSelectedClientFilter,

		defaultPropsTeams,
		selectedTeam,
		setSelectedTeam,

		page,
	};
};
