import { makeStyles } from '@material-ui/core/styles';

export const useProfileSettingsStyles = makeStyles((theme) => ({
	profileSettingsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',

		// medium screen and above
		'@media (min-width: 900px)': {
			flexDirection: 'row',
		},
	},

	settingsBtn: {
		display: 'flex',
		marginBottom: '40px',

		'& button': {
			width: '100%',
			borderRadius: '50px',
		},

		'& label': {
			width: '100%',
			borderRadius: '50px',
		},

		// medium screen and above
		'@media (min-width: 900px)': {
			justifyContent: 'flex-end',
			marginBottom: '0',

			'& button': {
				width: 'unset',
			},

			'& label': {
				width: 'unset',
			},
		},
	},

	profileSettings: {
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		width: '100%',
	},

	profileSettingImageContainer: {
		width: '100%',
	},

	profileSettingImageWrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '16px',
	},

	profileSettingImage: {
		height: '150px',
		width: '150px',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		borderRadius: '4px',
	},
}));
