import { UnassignedDocsProps } from './UnassignedDocs.props';

export const toUnassignedDocsTableData = (rows: UnassignedDocsProps[]) => {
	const unassignedDocsTableData = rows.map((row: UnassignedDocsProps) => {
		return {
			name: row.name ? row.name : '-',
			id: row.id ? row.id : '-',
			fees: row.fees.amount
				? {
						amount: row.fees.amount,
						currency: row.fees.currency,
				  }
				: {
						amount: '-',
						currency: '-',
				  },
			quote_number: row.quote_number ? row.quote_number : '-',
			client: row.client ? row.client : '-',
			cost: row.cost ? row.cost : 0,
			unassigned_job_id: row.unassigned_job_id ? row.unassigned_job_id : '-',
			status: row.status ? row.status : '-',
			is_active: row.is_active ? row.is_active : false,
			createdAt: row.createdAt ? row.createdAt : '-',
			updatedAt: row.updatedAt ? row.updatedAt : '-',
			doc_type: row.doc_type ? row.doc_type : '-',
			parent_doc_id: row.parent_doc_id ? row.parent_doc_id : '-',
			is_split_quote: row.is_split_quote ? row.is_split_quote : false,
			total_sub_items: row.total_sub_items ? row.total_sub_items : 0,
			total_assigned_sub_items: row.total_assigned_sub_items ? row.total_assigned_sub_items : 0,
		};
	});

	return unassignedDocsTableData;
};
