import React, { useState, useEffect } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	Skeleton,
	InputAdornment,
	CircularProgress,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Edit, ClearOutlined } from '@mui/icons-material';
import { useUpdateCost, useGetSuppliers, useAddNewSupplier } from '../../../../services/api';
import { SupplierProps, UpdateCostProps, NewCostProps, NewSupplierProps } from './UpdateCost.props';
import { useUpdateCostStyles } from './UpdateCost.styles';

const filter = createFilterOptions();

const UpdateCost = (props: UpdateCostProps) => {
	const { asana_project_id, job_id, pageNumber, limit, row } = props;
	const classes = useUpdateCostStyles();
	const [open, setOpen] = useState(false);
	const [openSupplier, setOpenSupplier] = useState(false);
	const [searchSupplier, setSearchSupplier] = useState('');
	const [newSupplier, setNewSupplier] = useState<NewSupplierProps>({
		name: '',
		email: '',
		phone_number: '',
	});

	const [newCost, setNewCost] = useState<NewCostProps>({
		id: null,
		date: '',
		description: '',
		supplier: '',
		amount: '',
	});

	useEffect(() => {
		setNewCost({
			...newCost,
			id: row.id,
			date: new Date(row.date).toLocaleDateString('fr-CA'),
			description: row.description,
			supplier: row.supplier,
			amount: row.amount,
		});
	}, [row]);

	// QUERIES
	const supplierQuery = useGetSuppliers(searchSupplier);
	const updateCostQuery = useUpdateCost(pageNumber + 1, limit, job_id);
	const addSupplierQuery = useAddNewSupplier(searchSupplier);

	// RENDER LOADING
	if (supplierQuery.isLoading) {
		return <Skeleton variant='rectangular' width={150} height={20} />;
	}

	// DATA
	const supplierData = supplierQuery.isSuccess ? supplierQuery.data.result : [];
	const supplierArray = supplierData?.map((item: SupplierProps) => item.name);

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const handleCloseSupplier = () => {
		setNewSupplier({
			name: '',
			email: '',
			phone_number: '',
		});
		setOpenSupplier(false);
		setNewCost({ ...newCost, supplier: '' });
	};

	const changeSupplierHandler = (event: any, name: any) => {
		const supplier = supplierData.find((item: SupplierProps) => item.name === name);

		if (supplier) {
			return setNewCost({ ...newCost, supplier: supplier.name });
		}

		setSearchSupplier(name.inputValue);
	};

	const changeTextFieldHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (event.target.name === 'date') {
			return setNewCost({
				...newCost,
				[event.target.name]: new Date(event.target.value).toLocaleDateString('fr-CA'),
			});
		}
		return setNewCost({ ...newCost, [event.target.name]: event.target.value });
	};

	const updateTaskHandler = () => {
		updateCostQuery.mutate(newCost);
		setOpen(false);
		resetForm();
	};

	const addSupplierHandler = (event: any) => {
		event.preventDefault();
		const data = {
			name: newSupplier.name,
			email: newSupplier.email,
			phone_number: newSupplier.phone_number,
		};

		addSupplierQuery.mutate(data);
		setNewCost({
			...newCost,
			supplier: newSupplier.name,
		});
		handleCloseSupplier();
	};

	const resetForm = () => {
		setNewCost({
			...newCost,
			id: row.id,
			date: '',
			description: '',
			supplier: '',
			amount: '',
		});
	};

	return (
		<div>
			<Button className={classes.addCostBtn} onClick={clickOpenHandler}>
				<Edit />
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Update Cost
						</Typography>
						<ClearOutlined onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{supplierQuery.isLoading ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='description'
										fullWidth
										id='description'
										variant='standard'
										placeholder='Enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										multiline
										maxRows={4}
										value={newCost.description}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Date:</Typography>
									<TextField
										name='date'
										fullWidth
										id='date'
										type='date'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newCost.date}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Supplier:</Typography>

									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<Autocomplete
											options={supplierArray}
											filterOptions={(options, params) => {
												const filtered = filter(options, params);
												setSearchSupplier(params.inputValue);
												return filtered;
											}}
											getOptionLabel={(option: any) => {
												// e.g value selected with enter, right from the input
												if (typeof option === 'string') {
													return option;
												}
												return 'loading...';
											}}
											disableClearable
											fullWidth
											size='small'
											renderInput={(params) => {
												return (
													<TextField
														{...params}
														label='Select supplier'
														variant='standard'
														inputProps={{
															...params.inputProps,
															value: params.inputProps.value
																? params.inputProps.value
																: newCost.supplier,
														}}
														required
													/>
												);
											}}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeSupplierHandler(event, newValue)
											}
										/>
										<Button
											color='error'
											onClick={() => setOpenSupplier((prevState) => !prevState)}
										>
											Add
										</Button>
										<Dialog open={openSupplier} onClose={handleCloseSupplier}>
											<form
												onSubmit={addSupplierHandler}
												style={{
													padding: '1rem 2rem',
													minWidth: '500px',
												}}
											>
												<DialogTitle>
													<Typography variant='h4'>Add new supplier</Typography>
												</DialogTitle>
												<DialogContent>
													<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
														<Typography sx={{ width: '55%' }}>Name:</Typography>
														<TextField
															autoFocus
															name='name'
															fullWidth
															id='supplier-name'
															variant='standard'
															placeholder='Enter name*'
															size='small'
															sx={{ marginTop: '16px' }}
															required
															multiline
															maxRows={4}
															value={newSupplier.name}
															onChange={(event) =>
																setNewSupplier({
																	...newSupplier,
																	name: event.target.value,
																})
															}
														/>
													</Box>
													<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
														<Typography sx={{ width: '55%' }}>Email:</Typography>
														<TextField
															autoFocus
															name='email'
															fullWidth
															id='supplier-email'
															variant='standard'
															placeholder='Enter email*'
															size='small'
															sx={{ marginTop: '16px' }}
															required
															multiline
															maxRows={4}
															value={newSupplier.email}
															onChange={(event) =>
																setNewSupplier({
																	...newSupplier,
																	email: event.target.value,
																})
															}
														/>
													</Box>
													<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
														<Typography sx={{ width: '55%' }}>Phone:</Typography>
														<TextField
															autoFocus
															name='phone_number'
															fullWidth
															id='supplier-phone'
															variant='standard'
															placeholder='Enter phone*'
															size='small'
															sx={{ marginTop: '16px' }}
															required
															multiline
															maxRows={4}
															value={newSupplier.phone_number}
															onChange={(event) =>
																setNewSupplier({
																	...newSupplier,
																	phone_number: event.target.value,
																})
															}
														/>
													</Box>
												</DialogContent>
												<DialogActions>
													<Button onClick={handleCloseSupplier} color='error'>
														Cancel
													</Button>
													<Button type='submit' color='error'>
														Add
													</Button>
												</DialogActions>
											</form>
										</Dialog>
									</div>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Amount:</Typography>
									<TextField
										name='amount'
										fullWidth
										type='number'
										id='amount'
										variant='standard'
										placeholder='Amount'
										size='small'
										sx={{ marginTop: '16px' }}
										value={newCost.amount}
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										}}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={(e) => updateTaskHandler()}
						disableElevation
						disabled={supplierQuery.isError}
					>
						Update Cost
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UpdateCost;
