import { UnassignedInvoicesRowProps } from './UnassignedInvoices.props';

export const toUnassignedInvoicesTableData = (rows: UnassignedInvoicesRowProps[]) => {
	const activeJobsTableData = rows.map((row: UnassignedInvoicesRowProps) => {
		return {
			invoice_id: row.invoice_id ? row.invoice_id : '',
			date: row.date ? row.date : '',
			duedate: row.duedate ? row.duedate : '-',
			type: row.type ? row.type : '-',
			client_name: row.client_name ? row.client_name : '-',
			invoiceNumber: row.invoiceNumber ? row.invoiceNumber : '-',
			amountDue: row.amountDue ? row.amountDue : 0,
			amountPaid: row.amountPaid ? row.amountPaid : 0,
			total: row.total ? row.total : 0,
			totalTax: row.totalTax ? row.totalTax : 0,
			subTotal: row.subTotal ? row.subTotal : 0,
			status: row.status ? row.status : '-',
		};
	});

	return activeJobsTableData;
};
