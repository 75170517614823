import { Typography, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Modal } from '../../../components';
import { handleCurrency } from '../../../utils';

export const InvoiceRemove = ({
	modalPropsRemoveInvoice,
	toggleRemoveInvoice,
	removeInvoice,
	invoiceRemoveLoading,
}: any) => {
	return (
		<Modal {...modalPropsRemoveInvoice}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleRemoveInvoice}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Remove Invoice!
				{invoiceRemoveLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{invoiceRemoveLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Typography mb={3} className='gs__inline-field__title'>
					Are you sure you want to remove the following invoice?
				</Typography>
				<Typography style={{ fontSize: '20px', overflow: 'auto' }} className='gs__inline-field__title'>
					{removeInvoice?.client ? removeInvoice.client : ''} :{' '}
					{removeInvoice?.number ? removeInvoice.number : ''}
				</Typography>{' '}
			</div>
		</Modal>
	);
};
