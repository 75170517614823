import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {
	Box,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import NumberFormat from 'react-number-format';

import { DataTable } from '../../components';
import { weeksToDays } from 'date-fns';

const budgets = [
	{
		value: 'hours',
		label: 'Total hours',
	},
];

const budgetsOptions: any = {
	'1': [
		{
			value: '1',
			label: 'No budget',
		},
		{
			value: '2',
			label: 'Total hours',
		},
		{
			value: '3',
			label: 'Total costs',
		},
	],
	'2': [
		{
			value: '1',
			label: 'No budget',
		},
		{
			value: '2',
			label: 'Total billable amount',
		},
		{
			value: '3',
			label: 'Total hours',
		},
		{
			value: '4',
			label: 'Total costs',
		},
	],
	'3': [
		{
			value: '1',
			label: 'No budget',
		},
		{
			value: '2',
			label: 'Total billable amount',
		},
		{
			value: '3',
			label: 'Total hours',
		},
		{
			value: '4',
			label: 'Total costs',
		},
	],
	'4': [
		{
			value: '1',
			label: "Don't track",
		},
		{
			value: '2',
			label: 'By total hours',
		},
		{
			value: '3',
			label: 'By project rate',
		},
		{
			value: '4',
			label: 'By members rate',
		},
		{
			value: '5',
			label: 'By members costs',
		},
	],
};

const billings = [
	{
		value: '1',
		label: 'Non-billable',
	},
	{
		value: '2',
		label: 'T&M (project rate)',
	},
	{
		value: '3',
		label: 'T&M (members rate)',
	},
	{
		value: '4',
		label: 'Fixed-fee',
	},
];

export const PageFive = () => {
	const [budget, setBudget] = useState<any>('1');
	const [billing, setBilling] = useState<any>('1');
	const [values, setValues] = useState<any>('');
	const [budgetvalues, setbudgetValues] = useState<any>('');

	const handleChange = (event: any) => {
		setValues(event.target.value);
		console.log({ naunsa: event.target.value });
	};

	const handleBudgetChange = (event: any) => {
		setbudgetValues(event.target.value);
	};

	const handleBudgetChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBudget(event.target.value);
	};

	const handleBillingChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
		setBilling(event.target.value);
	};

	const onlyNumbers = (str: any) => {
		return /^[0-9]+$/.test(str);
	};

	return (
		<div>
			<Box>
				<Typography mb={2} id='modal-modal-title'>
					Bill/Fees
				</Typography>
				<div>
					<Box
						component='form'
						sx={{
							'& .MuiTextField-root': { m: 1, width: '25ch' },
						}}
						style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
						noValidate
						autoComplete='off'
					>
						<div
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '66px', width: '430px' }}
						>
							<TextField
								style={{ width: `${billing === '1' ? '100%' : '60%'}` }}
								id='standard-select-currency'
								select
								label='Billing'
								value={billing}
								onChange={handleBillingChangeOption}
								helperText='Please select your billing'
								variant='standard'
							>
								{billings.map((option) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							<FormControl
								style={{ width: '40%', display: `${billing === '1' ? 'none' : 'block'}` }}
								sx={{ m: 1 }}
								variant='standard'
							>
								<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
								<Input
									id='standard-adornment-amount'
									value={values}
									onChange={(event) => handleChange(event)}
									startAdornment={<InputAdornment position='start'>$</InputAdornment>}
									endAdornment={
										billing !== '4' ? <InputAdornment position='end'>/hour</InputAdornment> : ''
									}
								/>
								
							</FormControl>
						</div>
						<div style={{ display: 'flex', justifyContent: 'center', width: '430px' }}>
							<TextField
								style={{ width: `${budget === '1' ? '100%' : '60%'}` }}
								id='standard-select-currency'
								select
								label='Budget'
								value={budget}
								onChange={handleBudgetChangeOption}
								helperText='Please select your budget'
								variant='standard'
							>
								{budgetsOptions[billing].map((option: any) => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</TextField>
							<FormControl
								style={{
									width: '40%',
									display: `${budget === '1' ? 'none' : 'flex'}`,
								}}
								sx={{ m: 1 }}
								variant='standard'
							>
								<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
								<Input
									id='standard-adornment-amount'
									value={budgetvalues}
									onChange={(event) => handleBudgetChange(event)}
									startAdornment={
										(billing === '1' && budget === '3') ||
										(billing === '2' && budget === '2') ||
										(billing === '2' && budget === '4') ||
										(billing === '3' && budget === '2') ||
										(billing === '3' && budget === '4') ||
										(billing === '4' && budget === '3') ||
										(billing === '4' && budget === '4') ||
										(billing === '4' && budget === '5') ? (
											<InputAdornment position='start'>$</InputAdornment>
										) : (
											''
										)
									}
									endAdornment={
										(billing === '1' && budget === '2') ||
										(billing === '2' && budget === '3') ||
										(billing === '3' && budget === '3') ||
										(billing === '4' && budget === '2') ||
										(billing === '4' && budget === '3') ||
										(billing === '4' && budget === '4') ||
										(billing === '4' && budget === '5') ? (
											<InputAdornment position='end'>
												{/* <IconButton
											aria-label='toggle password visibility'
											// onClick={handleClickShowPassword}
											// onMouseDown={handleMouseDownPassword}
										> */}
												{(billing === '4' && budget === '3') ||
												(billing === '4' && budget === '4') ||
												(billing === '4' && budget === '5') ? (
													'/hour'
												) : (
													<DeleteSweepIcon />
												)}
												{/* </IconButton> */}
											</InputAdornment>
										) : (
											''
										)
									}
								/>
							</FormControl>
						</div>
						<FormGroup
							style={{
								padding: '8px',
								width: '430px',
								display: `${budget === '1' ? 'none' : 'flex'}`,
							}}
						>
							<FormControlLabel
								control={<Checkbox color='default' defaultChecked />}
								label='Disallow over-budget'
							/>
							<FormControlLabel
								style={{
									display: `${
										(billing === '1' && budget === '3') ||
										(billing === '2' && budget === '4') ||
										(billing === '3' && budget === '4') ||
										(billing === '4' && budget === '5')
											? 'block'
											: 'none'
									}`,
								}}
								control={<Checkbox color='default' defaultChecked />}
								label='Exclude cost expenses'
							/>
							<FormControlLabel
								style={{
									display: `${
										(billing === '2' && budget === '2') ||
										(billing === '3' && budget === '2') ||
										(billing === '4' && budget === '3') ||
										(billing === '4' && budget === '4')
											? 'block'
											: 'none'
									}`,
								}}
								control={<Checkbox color='default' defaultChecked />}
								label='Exclude billable expenses'
							/>
							<FormControlLabel
								style={{
									display: `${
										(billing === '2' && budget === '3') ||
										(billing === '3' && budget === '3') ||
										(billing === '4' && budget === '2')
											? 'block'
											: 'none'
									}`,
								}}
								control={<Checkbox color='default' defaultChecked />}
								label='Exclude non-billable time'
							/>
							<FormControlLabel
								control={<Checkbox color='default' defaultChecked />}
								label='Reset budget every *select*'
							/>
							<FormControlLabel
								style={{
									display: `${
										billing === '4' && budget !== '3' && budget !== '4' && budget !== '5'
											? 'block'
											: 'none'
									}`,
								}}
								control={<Checkbox color='default' defaultChecked />}
								label='Start budget from *select*'
							/>
							<FormControlLabel
								control={<Checkbox color='default' defaultChecked />}
								label='Show budget to all project members'
							/>
							<FormControlLabel
								control={<Checkbox color='default' defaultChecked />}
								label='Email *just me* about *75* threshold'
							/>
						</FormGroup>
					</Box>
				</div>
			</Box>
		</div>
	);
};

// Reset budget every
// day
// week
// smonth

// Start budget from
// March, 1

// Email
// email selection
// about
// 0 to 100%
