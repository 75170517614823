import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { usePutAsanaTask } from '../../../../services/api';
import Context from '../../../../services/context';

export const useTaskUpdate = () => {
	const { state } = useContext(Context);
	const [editTask, setEditTask] = useState({
		id: '',
		task: '',
		description: '',
		fee: '0',
		assignee: '',
		// assignee: '',
		dueOn: new Date(),
		estimatedTimeHours: '',
	});
	const [editTaskValidation, setEditTaskValidation] = useState<any>({
		task: false,
		description: false,
		fee: true,
		assignee: true,
		dueOn: false,
		estimatedTimeHours: false,
	});
	const [showEditTask, setShowEditTask] = useState(false);
	const { mutate, data: editTaskData, isLoading: editTaskLoading } = usePutAsanaTask();
	const [assignees, setAssignees] = useState<any>([]);
	const [selectedAssignee, setSelectedAssignee] = useState({ id: 0, name: '' });

	function toggleEditTask() {
		setShowEditTask((showEditTask) => !showEditTask);
	}

	const handleEditTask = (value: any): void => {
		const dateNow = new Date();
		const dateChecking = dateNow!.toLocaleDateString('fr-CA') <= value.dueOn;

		setEditTask({
			id: value.id,
			task: value.task,
			description: value.description,
			fee: String(value.fee),
			assignee: value.assignee,
			dueOn: value.dueOn ? new Date(value.dueOn) : new Date(),
			estimatedTimeHours: value.hours ? String(value.hours) : '',
		});
		setEditTaskValidation({
			task: value.task.trim() ? true : false,
			description: value.description.trim() ? true : false,
			dueOn: value.dueOn && dateChecking ? true : false,
			// fee: String(value.fee).replace(/0/g, '').trim() ? true : false,
			assignee: value.assignee && value.assignee.trim() ? true : false,
			estimatedTimeHours: value.hours ? true : false,
		});
		toggleEditTask();
	};

	const capitalize = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleEditTaskSubmit = (): void => {
		const validateField = Object.keys(editTaskValidation).filter((key) => !editTaskValidation[key]);

		if (validateField.length) {
			if (!editTaskValidation.dueOn) {
				toast.info('Date must not be less than today');
			} else {
				toast.info(`${capitalize(validateField[0])} must not be empty`);
			}
		} else {
			if (!editTask.estimatedTimeHours) {
				toast.info('Estimated time must not be empty');
			} else {
				const formatData = {
					task_id: editTask.id,
					name: editTask.task,
					notes: editTask.description,
					assign_user: selectedAssignee.name,
					assign_user_id: selectedAssignee.id,
					due_on: editTask.dueOn!.toLocaleDateString('fr-CA'),
					time_estimate: Number(editTask.estimatedTimeHours),
				};

				mutate(formatData);
			}
		}
	};

	const handleEditTaskData = (event: any, field: any) => {
		switch (field) {
			case 'task':
				const valueTask = (event.target as HTMLInputElement).value;
				setEditTask({ ...editTask, task: valueTask });
				setEditTaskValidation({
					...editTaskValidation,
					task: valueTask.trim() ? true : false,
				});
				break;
			case 'estimatedTimeHours':
				const valueEstimatedTimeHours = (event.target as HTMLInputElement).value;
				setEditTask({
					...editTask,
					estimatedTimeHours:
						Number(valueEstimatedTimeHours) < 0 || !Number(valueEstimatedTimeHours)
							? ''
							: valueEstimatedTimeHours,
				});
				setEditTaskValidation({
					...editTaskValidation,
					estimatedTimeHours:
						Number(valueEstimatedTimeHours) !== 0 ||
						!Number(valueEstimatedTimeHours) ||
						valueEstimatedTimeHours
							? true
							: false,
				});
				break;
			case 'description':
				const valueDescription = (event.target as HTMLInputElement).value;
				setEditTask({ ...editTask, description: valueDescription });
				setEditTaskValidation({
					...editTaskValidation,
					description: valueDescription.trim() ? true : false,
				});
				break;
			case 'fee':
				setEditTask({ ...editTask, fee: event.formattedValue });
				setEditTaskValidation({
					...editTaskValidation,
					fee: event.formattedValue.replace(/0/g, '').trim() && event.formattedValue !== '$ 0' ? true : false,
				});
				break;
			case 'assignee':
				// const valueAssignee = (event.target as HTMLInputElement).value;
				setSelectedAssignee(event);
				setEditTask({ ...editTask, assignee: event ? event.name : '' });
				setEditTaskValidation({
					...editTaskValidation,
					assignee: event && event.name ? true : false,
				});
				break;
			case 'dueOn':
				const dateNow = new Date();
				const dateChecking = dateNow!.toLocaleDateString('fr-CA') <= event!.toLocaleDateString('fr-CA');

				setEditTask({ ...editTask, dueOn: event });
				setEditTaskValidation({
					...editTaskValidation,
					dueOn: dateChecking,
				});
				break;
		}
	};

	const defaultPropsAssignees: any = {
		options: assignees,
		getOptionLabel: (option: any) => option.name,
	};

	const formatDataUsers = (data: any) => {
		return data
			? data.map((list: any, index: number) => ({
					id: list.gid,
					name: `${list.name} - ${list.email}`,
			  }))
			: [];
	};

	useEffect(() => {
		const filter = state.assignees && state.assignees.length ? formatDataUsers(state.assignees) : [];
		setAssignees(filter);
	}, [state.assignees]);

	useEffect(() => {
		const filter = state.assignees && state.assignees.length ? formatDataUsers(state.assignees) : [];
		if (editTask.assignee && editTask.assignee !== '-' && filter.length) {
			const getDefaultClient = filter.filter((value: any) =>
				value.name.toLowerCase().includes(editTask.assignee.toLowerCase())
			);
			if (getDefaultClient.length) {
				setSelectedAssignee(getDefaultClient[0]);
			}
		}
	}, [showEditTask, state.assignees]);

	useEffect(() => {
		if (editTaskData && editTaskData.error === false) {
			toggleEditTask();
		}
	}, [editTaskData]);

	const modalPropsEditTask = {
		handleSubmit: handleEditTaskSubmit,
		isShowing: showEditTask,
		hide: toggleEditTask,
		currentPage: 1,
		pageLength: 1,
	};

	return {
		modalPropsEditTask,
		toggleEditTask,
		handleEditTaskData,
		editTask,
		editTaskValidation,
		handleEditTask,
		capitalize,
		defaultPropsAssignees,
		selectedAssignee,
		editTaskLoading,
	};
};
