import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	Autocomplete,
	TextField,
	Divider,
	Collapse,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { SearchBox } from '../../components';
import { formatCurrency } from '../../utils/formatCurrency';
import { useGetPaginatedUnassignedInvoices, useGetUniqueClientNames } from '../../services/api';
import { UNASSIGNED_INVOICES_COLUMNS } from './UnassignedInvoices.columns';
import { UnassignedInvoicesRowProps } from './UnassignedInvoices.props';
import { toUnassignedInvoicesTableData } from './UnassignedInvoices.transform';
import { toArrayNames } from '../../utils/toArrayNames';
import { AssignInvoice } from './AssignInvoice/AssignInvoice';
import { useUnassignedInvoicesStyles } from './UnassignedInvoices.styles';

export const UnassignedInvoices = () => {
	const classes = useUnassignedInvoicesStyles();
	const { state }: any = useLocation();
	const client = state ? state?.client : '';
	const [openAssign, setOpenAssign] = useState(false);
	const [selectedInvoiceId, setSelectedInvoiceId] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');
	const CLIENT_DATA_SOURCE = 'XERO';

	// FILTERS
	const [searchTerm, setSearchTerm] = useState('');
	const [clientFilter, setClientFilter] = useState('');
	const [collapseFilter, setCollapseFilter] = useState(false);

	useEffect(() => {
		if (state) {
			state && setClientFilter(client);
			setCollapseFilter(true);
		}
	}, [client]);

	// QUERIES
	const {
		data: unassignedInvoices,
		isLoading: isLoadingUnassignedInvoices,
		isError: isErrorUnassignedInvoices,
		isFetching: isFetchingUnassignedInvoices,
	} = useGetPaginatedUnassignedInvoices(searchTerm, limit, clientFilter, pageNumber + 1, sort, order);
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);

	//RENDER LOADING & ERROR
	if (isLoadingUnassignedInvoices || isLoadingClients) {
		return <p>Loading...</p>;
	}

	if (isErrorUnassignedInvoices || isErrorClients) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = toUnassignedInvoicesTableData(unassignedInvoices?.result.rows);
	const clientNamesArray = toArrayNames(clients?.result);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 1000);
	};

	const openAssignHandler = (invoice_id: string) => {
		setOpenAssign(true);
		setSelectedInvoiceId(invoice_id);
	};

	const closeAssignHandler = () => {
		setOpenAssign(false);
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const filterClientHandler = (newValue: string | undefined | null, clients: any) => {
		const client = newValue ? clients.find((client: any) => client.name === newValue).name : '';

		setClientFilter(client);
		setPageNumber(0);
	};

	const collapseFilterHandler = () => {
		setCollapseFilter((prev) => !prev);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	return (
		<Box className={classes.container}>
			<Typography mb={6} variant='h4'>
				Unassigned Invoices
			</Typography>

			<Box className={classes.filtersContainer}>
				<div style={{ display: 'flex' }}>
					<SearchBox onSearch={searchStringHandler} />
					<Box component='button' onClick={collapseFilterHandler} className={classes.filterButton}>
						<Typography className={classes.filterButtonText}>Filters</Typography>
						<ArrowDropDown />
					</Box>
				</div>
				<div className={classes.sortOrderContainer}>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
						<InputLabel id='sort-by'>Sort by</InputLabel>
						<Select labelId='sort-by' id='sort-by' value={sort} label='Sort by' onChange={handleSortChange}>
							<MenuItem value='createdAt'>Created Date</MenuItem>
							<MenuItem value='updatedAt'>Updated Date</MenuItem>
						</Select>
					</FormControl>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
						<InputLabel id='sort-by'>Order by</InputLabel>
						<Select
							labelId='order-by'
							id='order-by'
							value={order}
							label='Order by'
							onChange={handleOrderChange}
						>
							<MenuItem value='desc'>Descending</MenuItem>
							<MenuItem value='asc'>Ascending</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>

						<Autocomplete
							options={clientNamesArray}
							clearOnEscape
							defaultValue={client}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Client name'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterClientHandler(newValue, clients?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={UNASSIGNED_INVOICES_COLUMNS} />
						<TableBody sx={{ position: 'relative' }}>
							{searchTerm && isFetchingUnassignedInvoices && (
								<TableRow className={classes.loaderContainer}>
									<TableCell colSpan={7} align='center'>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{tableData.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={9} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{!isLoadingUnassignedInvoices &&
								tableData?.map((row: UnassignedInvoicesRowProps, index: number) => (
									<TableRow key={row.invoice_id}>
										<TableCell>{row.client_name}</TableCell>
										<TableCell>{row.invoiceNumber}</TableCell>
										<TableCell>{formatCurrency(row.amountDue)}</TableCell>
										<TableCell>{formatCurrency(row.totalTax)}</TableCell>
										<TableCell>{formatCurrency(row.amountPaid)}</TableCell>
										<TableCell>{formatCurrency(row.total)}</TableCell>
										{/* <TableCell>{formatCurrency(row.subTotal)}</TableCell> */}
										<TableCell>{row.client_name}</TableCell>
										<TableCell>{row.status}</TableCell>
										<TableCell>
											<Button
												variant='text'
												className={classes.assignButton}
												onClick={() => openAssignHandler(row.invoice_id)}
											>
												Please assign
											</Button>
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={unassignedInvoices.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>

			<AssignInvoice
				invoiceId={selectedInvoiceId}
				closeAssignHandler={closeAssignHandler}
				openAssign={openAssign}
				pageNumber={pageNumber}
				limit={limit}
				term={searchTerm}
			/>
		</Box>
	);
};

// const SplitJobRow = ({ row, openAssignHandler, pageNumber, limit, searchTerm }: any) => {
// 	const classes = useUnassignedInvoicesStyles();
// 	const [expanded, setExpanded] = useState(false);

// 	const UnassignedDocSubtasksQuery = useGetUnassignedDocSubtasks(row.id, pageNumber + 1, limit, searchTerm);

// 	//RENDER LOADING & ERROR
// 	if (UnassignedDocSubtasksQuery.isLoading) {
// 		return <p>Loading...</p>;
// 	}

// 	if (UnassignedDocSubtasksQuery.isError) {
// 		return <p>Opps! Something went wrong. Please try again.</p>;
// 	}

// 	const docSubTask =
// 		UnassignedDocSubtasksQuery.data.message === 'Success.'
// 			? toUnassignedInvoicesTableData(UnassignedDocSubtasksQuery.data?.result.rows)
// 			: [];

// 	return (
// 		<>
// 			<TableRow>
// 				<TableCell>{row.name}</TableCell>
// 				<TableCell>{row.client}</TableCell>
// 				<TableCell>{row.quote_number}</TableCell>
// 				<TableCell>{row.fees.amount}</TableCell>
// 				<TableCell>{row.cost && formatCurrency(row.cost)}</TableCell>
// 				<TableCell>
// 					<IconButton aria-label='expand row' size='small' onClick={() => setExpanded((prev: any) => !prev)}>
// 						{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
// 					</IconButton>
// 				</TableCell>
// 			</TableRow>
// 			<TableRow style={{ backgroundColor: '#f6f6f6' }}>
// 				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
// 					<Collapse in={expanded} timeout='auto' unmountOnExit>
// 						<Box>
// 							<Table>
// 								<TableHeaderComponent headerColumns={UNASSIGNED_INVOICES_COLUMNS} />
// 								<TableBody>
// 									{docSubTask?.map((job: any) => (
// 										<TableRow key={job.id}>
// 											<TableCell component='th' scope='row'>
// 												{job.name}
// 											</TableCell>
// 											<TableCell>{job.client}</TableCell>
// 											<TableCell>{job.quote_number}</TableCell>
// 											<TableCell>{formatCurrency(job.fees.amount)}</TableCell>
// 											<TableCell>{job.cost && formatCurrency(job.cost)}</TableCell>
// 											<TableCell>
// 												<Button
// 													variant='text'
// 													className={classes.assignButton}
// 													onClick={() => openAssignHandler(job)}
// 													disabled={job.is_active}
// 												>
// 													{job.is_active ? <em>Assigned</em> : 'Please assign'}
// 												</Button>
// 											</TableCell>
// 										</TableRow>
// 									))}
// 								</TableBody>
// 							</Table>
// 						</Box>
// 					</Collapse>
// 				</TableCell>
// 			</TableRow>
// 		</>
// 	);
// };
