import { createTheme } from '@material-ui/core/styles';

export const COLORS = {
	neutral: {
		default: '#64748B',
		shade50: '#F7F8F9',
		shade100: '#E7EAEE',
		shade200: '#D0D5DD',
		shade300: '#B8C0CC',
		shade400: '#A0ABBB',
		shade500: '#64748B',
		shade600: '#4B5768',
		shade700: '#323A46',
		shade800: '#191D23',
		shade900: '#0D0F11',
	},
	primary: {
		default: '#C5332F',
		shade50: '#FCF4F4',
		shade100: '#F7DFDF',
		shade200: '#F0C0BF',
		shade300: '#E0817E',
		shade400: '#D9615E',
		shade500: '#C5332F',
		shade600: '#A72B28',
		shade700: '#8A2421',
		shade800: '#631A18',
		shade900: '#3B0F0E',
	},
	secondary: {
		default: '#FCD718',
		shade50: '#FFFDF3',
		shade100: '#FFF9DC',
		shade200: '#FEF3BA',
		shade300: '#FEED97',
		shade400: '#FCE465',
		shade500: '#FCD718',
		shade600: '#F5CE03',
		shade700: '#BFA002',
		shade800: '#6D5C01',
		shade900: '#362E01',
	},
	ternary: {
		default: '#5A96E1',
		shade50: '#F6FAFE',
		shade100: '#E4EFFD',
		shade200: '#C9DFFA',
		shade300: '#AECEF8',
		shade400: '#92BEF5',
		shade500: '#5A96E1',
		shade600: '#1471E6',
		shade700: '#0F55AD',
		shade800: '#0A3973',
		shade900: '#051C3A',
	},
	success: {
		default: '#4DCA5D',
		shade50: '#F6FCF7',
		shade100: '#E4F7E7',
		shade200: '#D3F2D7',
		shade300: '#B8EABE',
		shade400: '#8BDD96',
		shade500: '#4DCA5D',
		shade600: '#179F29',
		shade700: '#138322',
		shade800: '#0F671A',
		shade900: '#094111',
	},
	warning: {
		default: '#F59E0B',
		shade50: '#FFF3EB',
		shade100: '#FBEADF',
		shade200: '#FDDDC8',
		shade300: '#FDC788',
		shade400: '#FFB74B',
		shade500: '#FA9E03',
		shade600: '#D97706',
		shade700: '#B45309',
		shade800: '#92400E',
		shade900: '#78350F',
	},
	error: {
		default: '#EF4444',
		shade50: '#FEF2F2',
		shade100: '#FEE2E2',
		shade200: '#FECACA',
		shade300: '#FCA5A5',
		shade400: '#F87171',
		shade500: '#EF4444',
		shade600: '#DC2626',
		shade700: '#B91C1C',
		shade800: '#991B1B',
		shade900: '#7F1D1D',
	},
	white: '#FFFFFF',
	black: '#141414',
};

export const theme = createTheme({
	palette: {
		primary: {
			main: '#C5332F',
			light: '#F0C0BF',
			dark: '#8A2421',
		},
		error: {
			main: '#EF4444',
			light: '#FECACA',
			dark: '#B91C1C',
		},
		success: {
			main: '#4DCA5D',
			light: '#D3F2D7',
			dark: '#138322',
		},
	},
});
