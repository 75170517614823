import { Typography, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Modal } from '../../../components';

export const DocumentRemove = ({
	modalPropsRemoveDocument,
	toggleRemoveDocument,
	removeDocument,
	documentRemoveLoading,
}: any) => {
	return (
		<Modal {...modalPropsRemoveDocument}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleRemoveDocument}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Remove Document!
				{documentRemoveLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{documentRemoveLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Typography mb={3} className='gs__inline-field__title'>
					Are you sure you want to remove the following document?
				</Typography>
				<Typography style={{ fontSize: '20px', overflow: 'auto' }} className='gs__inline-field__title'>
					{removeDocument?.title ? removeDocument.title : ''} :{' '}
					{removeDocument?.document ? removeDocument.document : ''}
				</Typography>{' '}
			</div>
		</Modal>
	);
};
