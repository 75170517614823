import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	Typography,
	Box,
	TextField,
	Autocomplete,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	Divider,
	Collapse,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { useGetPaginatedActiveJobs, useGetTeams, useGetJobTypes, useGetUniqueClientNames } from '../../services/api';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { ActiveJobsRowProps } from './ActiveJobs.props';
import { activeJobsColumns } from './ActiveJobs.columns';
import { toActiveJobsTableData } from './ActiveJobs.transform';
import { toArrayNames } from '../../utils/toArrayNames';
import { SearchBox } from '../../components';
import Archive from './Archive/Archive';
import { useActiveJobsStyles } from './ActiveJobs.styles';

export const ActiveJobs = () => {
	const classes = useActiveJobsStyles();
	const navigate = useNavigate();
	const { state }: any = useLocation();
	const client = state ? state?.client : '';
	const [collapseFilter, setCollapseFilter] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');
	const CLIENT_DATA_SOURCE = 'XERO';

	// FILTERS
	const [searchTerm, setSearchTerm] = useState('');
	const [teamFilter, setTeamFilter] = useState('');
	const [clientFilter, setClientFilter] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('');

	useEffect(() => {
		if (state) {
			state && setClientFilter(client);
			setCollapseFilter(true);
		}
	}, [client]);

	// QUERIES
	const { data: teamList, isError: isErrorTeamList, isLoading: isLoadingTeamList } = useGetTeams();
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const { data: jobTypes, isError: isErrorJobTypes, isLoading: isLoadingJobTypes } = useGetJobTypes();
	const {
		data: activeJobs,
		isError: isErrorActiveJobs,
		isLoading: isLoadingActiveJobs,
		isFetching: isFetchingActiveJobs,
	} = useGetPaginatedActiveJobs(
		pageNumber + 1,
		limit,
		teamFilter,
		clientFilter,
		jobTypeFilter,
		searchTerm,
		sort,
		order
	);

	// RENDER LOADING & ERROR
	if (isLoadingActiveJobs || isLoadingTeamList || isLoadingClients || isLoadingJobTypes) {
		return <p>Loading...</p>;
	}

	if (isErrorActiveJobs || isErrorTeamList || isErrorClients || isErrorJobTypes) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = toActiveJobsTableData(activeJobs?.result.rows);
	const teamNamesArray = toArrayNames(teamList?.result);
	const clientNamesArray = toArrayNames(clients?.result);
	const jobTypeNamesArray = toArrayNames(jobTypes?.result);
	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	const filterTeamHandler = (newValue: string | undefined | null, teams: any) => {
		const team = newValue ? teams.find((team: any) => team.name === newValue).gid : '';

		setTeamFilter(team);
		setPageNumber(0);
	};

	const filterClientHandler = (newValue: string | undefined | null, clients: any) => {
		const client = newValue ? clients.find((client: any) => client.name === newValue).name : '';

		setClientFilter(client);
		setPageNumber(0);
	};

	const filterJobTypeHandler = (newValue: string | undefined | null, jobTypes: any) => {
		const jobType = newValue ? jobTypes.find((jobType: any) => jobType.name === newValue).name : '';

		setJobTypeFilter(jobType);
		setPageNumber(0);
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 1000);
	};

	const collapseFilterHandler = () => {
		setCollapseFilter((prev) => !prev);
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	// COMPONENT
	return (
		<Box className={classes.container}>
			<Typography mb={6} variant='h4'>
				Active Jobs
			</Typography>

			<Box className={classes.filtersContainer}>
				<div style={{ display: 'flex' }}>
					<SearchBox onSearch={searchStringHandler} />
					<Box component='button' onClick={collapseFilterHandler} className={classes.filterButton}>
						<Typography className={classes.filterButtonText}>Filters</Typography>
						<ArrowDropDown />
					</Box>
				</div>
				<div className={classes.sortOrderContainer}>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
						<InputLabel id='sort-by'>Sort by</InputLabel>
						<Select labelId='sort-by' id='sort-by' value={sort} label='Sort by' onChange={handleSortChange}>
							<MenuItem value='createdAt'>Created Date</MenuItem>
							<MenuItem value='updatedAt'>Updated Date</MenuItem>
						</Select>
					</FormControl>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
						<InputLabel id='sort-by'>Order by</InputLabel>
						<Select
							labelId='order-by'
							id='order-by'
							value={order}
							label='Order by'
							onChange={handleOrderChange}
						>
							<MenuItem value='desc'>Descending</MenuItem>
							<MenuItem value='asc'>Ascending</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>
						<Autocomplete
							options={teamNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Select team'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterTeamHandler(newValue, teamList?.result)
							}
						/>
						<Autocomplete
							options={jobTypeNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Job type'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterJobTypeHandler(newValue, jobTypes?.result)
							}
						/>
						<Autocomplete
							options={clientNamesArray}
							clearOnEscape
							defaultValue={client}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Client name'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterClientHandler(newValue, clients?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={activeJobsColumns} />
						<TableBody sx={{ position: 'relative' }}>
							{(searchTerm || teamFilter || clientFilter) && isFetchingActiveJobs && (
								<TableRow className={classes.loaderContainer}>
									<TableCell>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{tableData.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={7} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{!isLoadingActiveJobs &&
								tableData?.map((row: ActiveJobsRowProps, index: number) => (
									<TableRow key={row.id} sx={{ cursor: 'pointer' }}>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											<Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
												{row.job_name}
											</Box>
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.client}
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.job_number}
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.team_name}
										</TableCell>
										{/* <TableCell
											width='25%'
											onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}
										>
											<Box className={classes.truncatedData}>{row.description}</Box>
										</TableCell> */}
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.fees}
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.total_cost}
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.job_type}
										</TableCell>
										<TableCell onClick={() => navigate(`${row.id}/tasks/${row.asana_project_id}`)}>
											{row.services.join(', ')}
										</TableCell>
										<TableCell>
											<Archive job={row} />
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={activeJobs.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>
		</Box>
	);
};
