import React from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ArchiveProps } from './Archive.props';
import { useArchiveJob, usePostArchiveJob } from '../../../services/api';
import { COLORS } from '../../../constants/theme';
import { ARCHIVE_REASONS } from './Archive.consts';
import { useArchiveStyles } from './Archive.styles';

const Archive = (props: ArchiveProps) => {
	const { job } = props;

	const classes = useArchiveStyles();
	const [open, setOpen] = React.useState(false);
	const [reason, setReason] = React.useState('');
	const [otherReason, setOtherReason] = React.useState('');

	const { mutate: archiveJob, isLoading: isLoadingArchive, isError: isErrorArchive } = useArchiveJob();

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeReasonHandler = (value: string) => {
		setReason(value);
	};

	const changeOtherReasonHandler = (value: string) => {
		setOtherReason(value);
	};

	const archiveJobHandler = (id: string, reason: string) => {
		let data = new FormData();

		if (reason === 'other' || otherReason !== '') {
			data.append('reason', otherReason);
		} else {
			data.append('reason', reason);
		}

		const archiveJobData = { id: id, body: data };
		archiveJob(archiveJobData);
		closeHandler();
	};

	return (
		<div>
			<Button
				sx={{ textDecoration: 'underline', color: COLORS.primary.default, textTransform: 'capitalize' }}
				variant='text'
				onClick={clickOpenHandler}
			>
				Archive
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Archive Job
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>

					<Typography variant='body1' className={classes.subTitle}>
						Are you sure you want to archive this job?
					</Typography>
					<Typography variant='h5'>{job.job_name}</Typography>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '500px' }}>
					<FormControl sx={{ width: '100%' }}>
						<FormLabel>
							<Typography variant='body1' className={classes.subTitle}>
								Reason for Archive:
							</Typography>
						</FormLabel>

						<RadioGroup
							aria-labelledby='demo-radio-buttons-group-label'
							defaultValue='female'
							name='radio-buttons-group'
							onChange={(e) => changeReasonHandler(e.target.value)}
						>
							{ARCHIVE_REASONS.map((item, index) => (
								<FormControlLabel
									value={item.value}
									control={<Radio color='error' />}
									label={item.label}
									key={index}
								/>
							))}
						</RadioGroup>

						{reason === 'other' && (
							<Box>
								<TextField
									fullWidth
									id='standard-basic'
									variant='standard'
									placeholder='*Reason'
									color='error'
									onChange={(e) => changeOtherReasonHandler(e.target.value)}
								/>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.archiveBtn}
						variant='contained'
						onClick={() => archiveJobHandler(job.asana_project_id, reason)}
						disableElevation
						disabled={reason === '' || (reason === 'other' && otherReason === '')}
					>
						Archive
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Archive;
