import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	FormControl,
	InputLabel,
	Collapse,
	TextField,
	Autocomplete,
	Divider,
	Select,
	MenuItem,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { toArrayNames } from '../../utils/toArrayNames';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { SearchBox } from '../../components';
import { useGetOtherDocuments, useDownloadDocument, useGetUniqueClientNames } from '../../services/api';
import { OTHER_DOCUMENTS_COLUMNS } from './OtherDocuments.columns';
import { useOtherDocumentsStyles } from './OtherDocuments.styles';

export const OtherDocuments = () => {
	const classes = useOtherDocumentsStyles();
	const { state }: any = useLocation();
	const client = state ? state?.client : '';
	const [collapseFilter, setCollapseFilter] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');
	const CLIENT_DATA_SOURCE = 'XERO';

	// FILTERS
	const [searchTerm, setSearchTerm] = useState('');
	const [clientFilter, setClientFilter] = useState('');

	useEffect(() => {
		if (state) {
			state && setClientFilter(client);
			setCollapseFilter(true);
		}
	}, [client]);

	// QUERIES
	const {
		data: otherDocuments,
		isLoading: isLoadingOtherDocuments,
		isError: isErrorOtherDocuments,
		isFetching: isFetchingOtherDocuments,
	} = useGetOtherDocuments(searchTerm, limit, clientFilter, pageNumber + 1, sort, order);
	const downloadAttachmentQuery = useDownloadDocument();
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);

	//RENDER LOADING & ERROR
	if (isLoadingOtherDocuments || isLoadingClients) {
		return <p>Loading...</p>;
	}

	if (isErrorOtherDocuments || isErrorClients) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = otherDocuments?.result.rows || [];
	const clientNamesArray = toArrayNames(clients?.result);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 1000);
	};

	const filterClientHandler = (newValue: string | undefined | null, clients: any) => {
		const client = newValue ? clients.find((client: any) => client.name === newValue).name : '';

		setClientFilter(client);
		setPageNumber(0);
	};

	const collapseFilterHandler = () => {
		setCollapseFilter((prev) => !prev);
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	const downloadAttachmentHandler = (id: any, fileName: any) => {
		if (id) {
			downloadAttachmentQuery.mutate({ id: id, file: fileName });
		}
	};

	return (
		<Box className={classes.container}>
			<Typography mb={6} variant='h4'>
				Other Documents
			</Typography>

			<Box className={classes.filtersContainer}>
				<div style={{ display: 'flex' }}>
					<SearchBox onSearch={searchStringHandler} />
					<Box component='button' onClick={collapseFilterHandler} className={classes.filterButton}>
						<Typography className={classes.filterButtonText}>Filters</Typography>
						<ArrowDropDown />
					</Box>
				</div>
				<div className={classes.sortOrderContainer}>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
						<InputLabel id='sort-by'>Sort by</InputLabel>
						<Select labelId='sort-by' id='sort-by' value={sort} label='Sort by' onChange={handleSortChange}>
							<MenuItem value='createdAt'>Created Date</MenuItem>
							<MenuItem value='updatedAt'>Updated Date</MenuItem>
						</Select>
					</FormControl>
					<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
						<InputLabel id='sort-by'>Order by</InputLabel>
						<Select
							labelId='order-by'
							id='order-by'
							value={order}
							label='Order by'
							onChange={handleOrderChange}
						>
							<MenuItem value='desc'>Descending</MenuItem>
							<MenuItem value='asc'>Ascending</MenuItem>
						</Select>
					</FormControl>
				</div>
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>
						<Autocomplete
							options={clientNamesArray}
							clearOnEscape
							defaultValue={client}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Client name'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterClientHandler(newValue, clients?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={OTHER_DOCUMENTS_COLUMNS} />
						<TableBody sx={{ position: 'relative' }}>
							{searchTerm && isFetchingOtherDocuments && (
								<TableRow className={classes.loaderContainer}>
									<TableCell colSpan={7} align='center'>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{tableData.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={9} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{!isLoadingOtherDocuments &&
								tableData?.map((row: any, index: number) => (
									<TableRow key={row.id}>
										<TableCell>{row.title}</TableCell>
										<TableCell>{row.notes}</TableCell>
										<TableCell>{row.file_name}</TableCell>
										<TableCell>{row.client_name}</TableCell>
										<TableCell
											style={{
												cursor: 'pointer',
												textDecoration: 'underline',
												textUnderlineOffset: '3px',
											}}
											onClick={() => downloadAttachmentHandler(row.id, row.file_name)}
										>
											Download file
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={otherDocuments.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>
		</Box>
	);
};
