import { Table, TableBody, TableContainer, TableHead} from '@mui/material';
import { useDataTableLogic } from '../../hooks/components';

export const DataTable = (props: any) => {
	const { attributesTableContainer, attributesTable, handleTableHeadRow, handleTableBodyRow } =
		useDataTableLogic(props);


	return (
		<TableContainer {...attributesTableContainer}>
			<Table {...attributesTable}>
				<TableHead>{handleTableHeadRow}</TableHead>

				<TableBody>{handleTableBodyRow()}</TableBody>
			</Table>
		</TableContainer>
	);
};
