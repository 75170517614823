import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import TableHeaderComponent from '../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../components/TablePaginationComponent/TablePaginationComponent';
import { ARCHIVED_JOBS_COLUMNS } from './ArchivedJobs.columns';
import { useActiveJobsStyles } from './ActiveJobs.styles';
import { useGetArchivedJobs } from '../../../services/api';
import { toArchivedJobsTableData } from './ArchivedJobs.transform';
import { ArchivedJobsRowProps } from './ArchivedJobs.props';

export const ArchivedJobs = () => {
	const navigate = useNavigate();
	const classes = useActiveJobsStyles();
	const { searchTerm }: any = useOutletContext();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: archivedJobs,
		isError: isErrorArchivedJobs,
		isLoading: isLoadingArchivedJobs,
		isFetching: isfetchingArchivedJobs,
	} = useGetArchivedJobs(pageNumber + 1, limit, searchTerm);

	// RENDER LOADING & ERROR
	if (isLoadingArchivedJobs) {
		return <p>Loading...</p>;
	}

	if (isErrorArchivedJobs) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = toArchivedJobsTableData(archivedJobs?.result.rows);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	const goToActiveJobsPage = (id: number, asanaId: string) => {
		navigate(`/active-jobs/${id}/tasks/${asanaId}`);
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={ARCHIVED_JOBS_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{isfetchingArchivedJobs && (
						<TableRow className={classes.loaderContainer}>
							<TableCell>
								<CircularProgress color='error' size={24} />
							</TableCell>
						</TableRow>
					)}

					{tableData.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{!isLoadingArchivedJobs &&
						tableData?.map((row: ArchivedJobsRowProps, index: number) => (
							<TableRow
								key={`${row.id}-${index}`}
								sx={{ cursor: 'pointer' }}
								onClick={() => goToActiveJobsPage(row.id, row.asana_project_id)}
							>
								<TableCell>{row.job_name}</TableCell>
								<TableCell>{row.client}</TableCell>
								<TableCell>{row.job_number}</TableCell>
								<TableCell>{row.fees}</TableCell>
								<TableCell>{row.total_cost}</TableCell>
								<TableCell>{row.job_type}</TableCell>
								<TableCell width='25%'>
									<Box className={classes.truncatedData}>{row.reason}</Box>
								</TableCell>
							</TableRow>
						))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={archivedJobs.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
