import { useState } from 'react';
import { useDeleteAsanaTask } from '../../../../services/api';

export const useTaskRemove = () => {
	const { mutate } = useDeleteAsanaTask();
	const [showRemoveTask, setShowRemoveTask] = useState(false);
	const [removeTask, setRemoveTask] = useState<any>({});

	function toggleRemoveTask() {
		setShowRemoveTask((showRemoveTask) => !showRemoveTask);
	}

	const handleRemoveTaskSubmit = (): void => {
		mutate({ task_id: removeTask.id });
		toggleRemoveTask();
	};

	const handleRemoveTask = (value: any): void => {
		setRemoveTask(value);
		toggleRemoveTask();
	};

	const modalPropsRemoveTask = {
		handleSubmit: handleRemoveTaskSubmit,
		isShowing: showRemoveTask,
		hide: toggleRemoveTask,
		currentPage: 1,
		pageLength: 1,
	};

	return { modalPropsRemoveTask, handleRemoveTask, toggleRemoveTask, removeTask };
};
