import { useEffect, useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	InputAdornment,
	FormControl,
	RadioGroup,
	FormControlLabel,
	Checkbox,
	Select,
	MenuItem,
	ListItemText,
	Radio,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	IconButton,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import InvoiceMilestones from '../../components/InvoiceMilestones/InvoiceMilestones';
import { MilestoneProps } from '../../components/InvoiceMilestones/InvoiceMilestones.props';
import { TASKS__COLUMNS } from './AddjobScopes.columns';
import { INITIAL_STATE_JOB, INITIAL_STATE_MILESTONE, SERVICE_TYPES, JOB_SCOPE_OPTIONS } from './AddJobScopes.const';
import {
	AddJobScopesProps,
	ClientProps,
	JobTypeProps,
	TeamProps,
	ActiveJobProps,
	ServiceTypeProps,
	JobProps,
	DocTypeProps,
	JobScopeOptionsProps,
	AsanaTaskProps,
	TaskDataProps,
} from './AddJobScopes.props';
import { AssigneeProps } from './AddJobScopeTask/JobScopeTaskModal.props';
import { useAddJobScopesStyles } from './AddJobScopes.styles';
import { useAddJobScopes } from './useAddJobScopes.hook';
import JobScopeTaskModal from './AddJobScopeTask/JobScopeTaskModal';

export const AddJobScopes = (props: AddJobScopesProps) => {
	const { isOpenAddJobScope, setIsOpenAddJobScope, pageNumber, limit, term } = props;
	const [existingActiveJobId, setExistingActiveJobId] = useState<number | null>(null);
	const classes = useAddJobScopesStyles();
	const [step, setStep] = useState(1);

	const [activeJobOptions, setActiveJobOptions] = useState<ActiveJobProps[]>([]);
	const [selectedCategory, setSelectedCategory] = useState('1');
	const [isMilestoneLimit, setIsMilestoneLimit] = useState(false);
	const [milestones, setMilestones] = useState<MilestoneProps[]>(INITIAL_STATE_MILESTONE);
	const [assignDocData, setAssignDocData] = useState<JobProps>(INITIAL_STATE_JOB);

	const {
		// QUERIES
		clientNamesQuery,
		jobTypeQuery,
		teamsQuery,
		nextJobNumberQuery,
		nextQuoteNumberQuery,
		docTypesQuery,
		activeJobsQuery,
		unassignedJobSQuery,
		activeJobDetailsQuery,
		assigneeQuery,
		// HANDLERS
		changeJobCategoryHandler,
		changeClientHandler,
		changeNewJobTypeHandler,
		changeTeamHandler,
		changeDocTypeHandler,
		changeActiveJobHandler,
		changeUnassignedJobHandler,
		changeInvoiceFrequencyHandler,
		nextHandler,
		backHandler,
		milestoneLimitHandler,
		milestoneValueHandler,
		serviceChangeHandler,
		changeTextFieldHandler,
		closeModalHandler,
		submitAssignDocHandler,
		removeTaskHandler,
		// SUBMIT
		assignNewDoc,
		assignExistingDoc,
		resetModal,
	} = useAddJobScopes({
		existingActiveJobId,
		pageNumber,
		limit,
		term,
		setSelectedCategory,
		setAssignDocData,
		assignDocData,
		setExistingActiveJobId,
		setStep,
		step,
		setIsMilestoneLimit,
		setMilestones,
		setIsOpenAddJobScope,
		selectedCategory,
	});

	// DATA
	const clientList = clientNamesQuery.isSuccess ? clientNamesQuery.data.result : [];
	const clientNameOptions = clientList.map((client: ClientProps) => client.name);

	const jobTypeList = jobTypeQuery.isSuccess ? jobTypeQuery.data.result : [];
	const jobtypeNameOptions = jobTypeList
		.map((jobType: JobTypeProps) => jobType.name)
		?.filter((jobType: string) => jobType !== 'New Job' && jobType);

	const teamList = teamsQuery.isSuccess ? teamsQuery.data.result : [];
	const teamNameOptions = teamList.map((team: TeamProps) => team.name);

	const assigneeList = assigneeQuery.isSuccess ? assigneeQuery.data.result : [];
	const assigneeEmails = assigneeList.map((item: AssigneeProps) => item.email);

	const docTypesList = docTypesQuery.isSuccess ? docTypesQuery.data.result : [];
	const docTypeOptions = docTypesList.map((type: DocTypeProps) => type.name);

	const activeJobList = activeJobsQuery.isSuccess ? activeJobsQuery.data.result : [];
	const activeJobNameOptions = activeJobOptions.map((activeJob: ActiveJobProps) => activeJob.job_name);

	const unassignedJobList = unassignedJobSQuery.isSuccess ? unassignedJobSQuery.data.result : [];
	const unassignedJobNameOptions = unassignedJobList.map((job: any) => job.name);

	const activeJobDetails = activeJobDetailsQuery.isSuccess ? activeJobDetailsQuery.data.result : undefined;

	const nextJobNumber = nextJobNumberQuery.isSuccess ? nextJobNumberQuery.data.result : null;
	const nextQuoteNumber = nextQuoteNumberQuery.isSuccess ? nextQuoteNumberQuery.data.result : null;
	const serviceTypeList = SERVICE_TYPES.map((service: ServiceTypeProps) => service.name);

	useEffect(() => {
		setAssignDocData({
			...assignDocData,
			job_cat: Number(selectedCategory),
			new_job: selectedCategory === '1' ? Number(selectedCategory) : 0,
			job_number: selectedCategory !== '1' && activeJobDetails ? activeJobDetails?.job_number : nextJobNumber,
			qoute_number:
				selectedCategory !== '1' && activeJobDetails
					? activeJobDetails?.quotes_details[0].quote_number
					: nextQuoteNumber,
			asana_project_id: selectedCategory !== '1' ? activeJobDetails?.asana_project_id : '',
			cost_id: activeJobDetails ? activeJobDetails?.cost_id : '',
			job_name: activeJobDetails ? activeJobDetails?.job_name : '',
			job_description: activeJobDetails ? activeJobDetails?.description : '',
			job_client: activeJobDetails ? activeJobDetails?.client : '',
			client_id: activeJobDetails ? activeJobDetails?.client_id : '',
			team_id: activeJobDetails ? activeJobDetails?.team_id : '',
			fees: activeJobDetails ? activeJobDetails?.fees : 0,
			start_date: activeJobDetails ? activeJobDetails?.start_date : '',
			end_date: activeJobDetails ? activeJobDetails?.end_date : '',
			est_hours: activeJobDetails ? activeJobDetails?.estimate : '',
			invoice_frequency: activeJobDetails ? activeJobDetails?.invoice_frequency : [],
			invoice_milestones: activeJobDetails ? activeJobDetails?.invoice_milestones : [],
			asana_task: activeJobDetails ? activeJobDetails?.invoice_milestones : [],
		});
	}, [clientNamesQuery.data, activeJobDetails, selectedCategory, nextJobNumber, isOpenAddJobScope]);

	useEffect(() => {
		setAssignDocData({
			...assignDocData,
			client_id: activeJobDetails ? activeJobDetails.client_id : null,
		});
	}, [clientNamesQuery.data]);

	useEffect(() => {
		setAssignDocData({
			...assignDocData,
			invoice_milestones: [...milestones],
		});
	}, [milestones]);

	useEffect(() => {
		let jobOptions = [];

		if (selectedCategory === '2')
			jobOptions = activeJobList.filter((job: ActiveJobProps) => job.job_type === 'Retainer Job');
		if (selectedCategory === '3')
			jobOptions = activeJobList.filter((job: ActiveJobProps) => job.job_type === 'Project Job');

		return setActiveJobOptions(jobOptions);
	}, [selectedCategory, activeJobList]);

	// RENDER LOADING & ERROR
	if (
		assignNewDoc.isLoading ||
		assignExistingDoc.isLoading ||
		clientNamesQuery.isLoading ||
		jobTypeQuery.isLoading ||
		teamsQuery.isLoading ||
		activeJobsQuery.isLoading
	) {
		return <p>Loading...</p>;
	}

	if (
		assignNewDoc.isError ||
		assignExistingDoc.isError ||
		clientNamesQuery.isError ||
		jobTypeQuery.isError ||
		teamsQuery.isError ||
		activeJobsQuery.isError
	) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	return (
		<div>
			<Button
				className='gs-global__btn'
				color='error'
				style={{ position: 'fixed', bottom: '40px', right: '24px' }}
				onClick={() => setIsOpenAddJobScope(true)}
				variant='contained'
			>
				<AddOutlinedIcon /> Job/Scope
			</Button>

			<Dialog
				open={isOpenAddJobScope}
				onClose={() => {
					setIsOpenAddJobScope(false);
					setStep(1);
					resetModal();
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h6' style={{ fontWeight: 'bold' }}>
							{step} / 3
						</Typography>
						<ClearOutlinedIcon
							onClick={() => {
								setIsOpenAddJobScope(false);
								setStep(1);
								resetModal();
							}}
							sx={{ cursor: 'pointer' }}
						/>
					</Box>
					<Typography className={classes.title}>Add Job/Scope</Typography>
				</DialogTitle>
				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '750px' }}>
					<FormControl sx={{ width: '100%' }}>
						{/* STEP 1 ======================================= */}
						{step === 1 && (
							<Box>
								<Typography variant='inherit' style={{ color: 'grey', marginBottom: '1rem' }}>
									Please select an option:
								</Typography>
								<FormControl sx={{ width: '100%' }}>
									<RadioGroup
										value={assignDocData.doc_type}
										name='doc-type'
										sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
										onChange={changeDocTypeHandler}
									>
										{JOB_SCOPE_OPTIONS.map((option: JobScopeOptionsProps) => {
											return (
												<FormControlLabel
													key={option.id}
													value={option.id}
													className={classes.radioButton}
													control={<Radio />}
													label={option.label}
												/>
											);
										})}
									</RadioGroup>
								</FormControl>
							</Box>
						)}

						{/* STEP 2 ======================================= */}
						{step === 2 && (
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								{/* If selectedCategory === 1 (new job) */}
								{assignDocData.doc_type === 1 ? (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Job Category:</Typography>
										<Autocomplete
											options={jobtypeNameOptions}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select job category'
													variant='standard'
													required
												/>
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeNewJobTypeHandler(jobTypeList, newValue)
											}
										/>
									</Box>
								) : (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Active Job:</Typography>
										<Autocomplete
											options={activeJobNameOptions}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField {...params} label='Select job' variant='standard' required />
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeActiveJobHandler(activeJobList, newValue)
											}
										/>
									</Box>
								)}

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Name:</Typography>
									<TextField
										name='job_name'
										fullWidth
										id='job-name'
										variant='standard'
										placeholder='Please enter job name*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.job_name}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Client:</Typography>
									<Autocomplete
										options={clientNameOptions}
										defaultValue={assignDocData.job_client}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Select client' variant='standard' required />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeClientHandler(clientList, newValue)
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Team:</Typography>
									<Autocomplete
										options={teamNameOptions}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Select team' variant='standard' required />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeTeamHandler(teamList, newValue)
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='job_description'
										fullWidth
										id='task-name'
										variant='standard'
										placeholder='Please enter description*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.job_description}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Number:</Typography>
									<TextField
										name='job_number'
										fullWidth
										variant='standard'
										placeholder='Job number here*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={assignDocData.job_number}
										onChange={(event) => changeTextFieldHandler(event)}
										disabled={!!existingActiveJobId}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Fees:</Typography>
									<TextField
										name='fees'
										fullWidth
										variant='standard'
										placeholder='00000'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										}}
										value={assignDocData.fees}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated Time:</Typography>
									<TextField
										name='est_hours'
										type='number'
										fullWidth
										id='task-name'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											endAdornment: <InputAdornment position='end'>Hours</InputAdornment>,
										}}
										value={assignDocData.est_hours}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Date:</Typography>
									<Box sx={{ display: 'flex', width: '100%', gap: '2rem' }}>
										<TextField
											name='start_date'
											type='date'
											fullWidth
											id='start_date'
											variant='standard'
											size='small'
											sx={{ marginTop: '16px' }}
											required
											value={assignDocData.start_date}
											onChange={(event) => changeTextFieldHandler(event)}
										/>
										<TextField
											name='end_date'
											type='date'
											fullWidth
											id='end_date'
											variant='standard'
											size='small'
											sx={{ marginTop: '16px' }}
											required
											value={assignDocData.end_date}
											onChange={(event) => changeTextFieldHandler(event)}
										/>
									</Box>
								</Box>

								{/* If selectedCategory === 2 (retainer) */}
								{selectedCategory === '2' && (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Invoice Frequency:</Typography>
										<Autocomplete
											options={['Monthly', 'Quarterly', 'Annually']}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select frequency'
													variant='standard'
													required
												/>
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeInvoiceFrequencyHandler(newValue)
											}
										/>
									</Box>
								)}

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Type:</Typography>
									<FormControl variant='standard' sx={{ width: '100%', marginTop: '16px' }}>
										<Select
											multiple
											value={assignDocData.services}
											onChange={serviceChangeHandler}
											renderValue={(selected) => selected.join(', ')}
											placeholder='Select job type'
										>
											{serviceTypeList.map((name: string) => (
												<MenuItem key={name} value={name}>
													<Checkbox checked={assignDocData.services.indexOf(name) > -1} />
													<ListItemText primary={name} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
								{(selectedCategory === '1' || selectedCategory === '3') && (
									<InvoiceMilestones
										setMilestoneLimitHandler={milestoneLimitHandler}
										setMilestoneValueHandler={milestoneValueHandler}
										milestones={milestones}
									/>
								)}
							</Box>
						)}

						{/* STEP 3 ======================================= */}
						{step === 3 && (
							<Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										marginBottom: '1rem',
									}}
								>
									<Typography variant='h6'>Tasks:</Typography>
									<JobScopeTaskModal
										assignDocData={assignDocData}
										setAssignDocData={setAssignDocData}
										assigneeEmails={assigneeEmails}
										assignees={assigneeList}
										isLoadingAssignees={assigneeQuery.isLoading}
										isErrorAssignees={assigneeQuery.isError}
										rowData={null}
										taskAction='add'
									/>
								</Box>
								<TableContainer>
									<Table>
										<TableHeaderComponent headerColumns={TASKS__COLUMNS} />
										<TableBody sx={{ position: 'relative' }}>
											{assignDocData.asana_task.length < 1 && (
												<TableRow sx={{ textAlign: 'center' }}>
													<TableCell colSpan={9} align='center'>
														No data found
													</TableCell>
												</TableRow>
											)}

											{assignDocData.asana_task?.map((row: AsanaTaskProps, i: number) => (
												<>
													<TableRow key={`${i}-${row.task_name}`}>
														<TableCell>{row.task_name}</TableCell>
														<TableCell>{row.notes}</TableCell>
														<TableCell>{row.time_estimate}</TableCell>
														<TableCell>
															{
																assigneeList.find(
																	(assignee: AssigneeProps) =>
																		assignee.gid === row.assign_user_id
																)?.name
															}
														</TableCell>
														<TableCell>{row.due_on}</TableCell>
														<TableCell>
															<Box sx={{ display: 'flex', gap: '12px' }}>
																<JobScopeTaskModal
																	assignDocData={assignDocData}
																	setAssignDocData={setAssignDocData}
																	assigneeEmails={assigneeEmails}
																	assignees={assigneeList}
																	isLoadingAssignees={assigneeQuery.isLoading}
																	isErrorAssignees={assigneeQuery.isError}
																	rowData={row}
																	taskAction='edit'
																/>

																<IconButton
																	aria-label='delete'
																	size='small'
																	onClick={() => {
																		removeTaskHandler(row.id);
																	}}
																>
																	<DeleteIcon fontSize='small' />
																</IconButton>
															</Box>
														</TableCell>
													</TableRow>
												</>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<Button
							className={classes.backBtn}
							style={{ opacity: step === 1 ? '0' : '1' }}
							variant='contained'
							onClick={backHandler}
							disableElevation
							disabled={step === 1}
						>
							Back
						</Button>

						{step === 3 ? (
							<Button
								className={classes.submit}
								variant='contained'
								onClick={() => submitAssignDocHandler(assignDocData)}
								disableElevation
								disabled={isMilestoneLimit}
							>
								Submit
							</Button>
						) : (
							<Button
								className={classes.submit}
								variant='contained'
								onClick={() => nextHandler(clientList)}
								disableElevation
								// disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
							>
								Next
							</Button>
						)}
					</Box>
				</DialogActions>
			</Dialog>
		</div>
	);
};
