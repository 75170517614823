import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/theme';

export const useInvoiceMilestonesStyles = makeStyles((theme) => ({
	archiveBtn: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},
	percent: {
		'&:not(:last-child)::after ': {
			content: '"/"',
		},
	},
}));
