import { Box, TextField, Typography, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { Modal } from '../../../components';
import { usePostDocument } from '../../../services/api';

export const DocumentAdd = ({
	modalPropsDocumentAdd,
	toggleAddDocument,
	jobDetails,
	fileSelectHandler,
	documentName,
	documentAddLoading,
	handleDocumentData,
	documentData,
}: any) => {
	return (
		<Modal {...modalPropsDocumentAdd}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleAddDocument}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Add Document
				{documentAddLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{documentAddLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						Title:
					</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleDocumentData(event, 'title')}
							defaultValue={documentData.title}
							id='standard-basic'
							variant='standard'
							error={!documentData.title ? true : false}
							placeholder='*Please enter document title'
						/>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						File:
					</Typography>
					<Box className='gs__inline-field__container' style={{ display: 'flex' }}>
						<TextField
							id='standard-basic'
							variant='standard'
							error={!documentData.docs_file ? true : false}
							value={documentData.docs_file}
							placeholder='*Please enter file name'
						/>
						<Typography
							htmlFor='contained-button-file'
							component='label'
							className='gs__inline-field__title gs__inline-field__file'
							style={{ minWidth: 'max-content', margin: '0px' }}
						>
							<input
								style={{ display: 'none' }}
								id='contained-button-file'
								type='file'
								accept='.xlsx,.xls,.doc,.docx,.pdf'
								onChange={(event) => handleDocumentData(event, 'docs_file')}
							/>
							choose file
						</Typography>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '60px' }}>
						Notes:
					</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleDocumentData(event, 'notes')}
							defaultValue={documentData.notes}
							id='standard-basic'
							variant='standard'
							error={!documentData.notes ? true : false}
							placeholder='*Please enter document notes'
						/>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};
