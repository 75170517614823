import { makeStyles } from '@material-ui/core/styles';

export const useTimeEntriesStyles = makeStyles((theme) => ({
	// PAGINATION
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}));
