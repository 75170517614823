import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';

import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetPaginatedInvoices, useGetPaginatedMilestones } from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { MilestoneProps } from './Milestones.props';
import { ArchiveInvoice } from '../ArchiveInvoice/ArchiveInvoice';
import { MILESTONES_COLUMNS } from './Milestones.columns';
import { useMilestonesStyles } from './Milestones.styles';

export const Milestones = () => {
	const classes = useMilestonesStyles();
	const { id = '' } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: milestonesData,
		isError: isErrorMilestones,
		isLoading: isLoadingMilestones,
		isFetching: isFetchingMilestones,
	} = useGetPaginatedMilestones(id, limit, pageNumber + 1);

	// RENDER LOADING & ERROR
	if (isLoadingMilestones) {
		return <p>Loading...</p>;
	}

	if (isErrorMilestones) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const milestones = milestonesData.message === 'Success' ? milestonesData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={MILESTONES_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{isFetchingMilestones && (
						<TableRow className={classes.loaderContainer}>
							<TableCell>
								<CircularProgress color='error' size={24} />
							</TableCell>
						</TableRow>
					)}

					{milestones.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{milestones?.map((milestone: MilestoneProps, i: number) => (
						<TableRow key={i}>
							<TableCell>{milestone.percent}</TableCell>
							<TableCell>{milestone.end_date}</TableCell>
							<TableCell>{milestone.note}</TableCell>
							<TableCell>{milestone.status}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={milestonesData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
