export const useModalogic = ({
	isShowing,
	hide,
	currentPage,
	handleBackPage,
	pageLength,
	handleNextPage,
	handleSubmit,
	width = 0,
}: any) => {
	const style = {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: width ? width : 512,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 4,
	};

	const attributes = {
		uiModal: {
			open: isShowing,
			// onClose: hide,
			'aria-labelledby': 'modal-modal-title',
			'aria-describedby': 'modal-modal-description',
		},
		buttonBack: {
			className: 'gs-global__btn',
			style: {
				display: `${currentPage === 1 ? 'none' : 'block'}`,
			},
			onClick: () => handleBackPage(),
		},
		buttonNext: {
			className: 'gs-global__btn',
			style: {
				display: `${currentPage === pageLength ? 'none' : 'block'}`,
			},
			onClick: () => handleNextPage(),
		},
		buttonSubmit: {
			className: 'gs-global__btn',
			style: {
				display: `${currentPage === pageLength ? 'block' : 'none'}`,
			},
			onClick: handleSubmit,
		},
	};

	return { attributes, style };
};
