import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../../constants/theme';

export const useBillingSettingsStyles = makeStyles((theme) => ({
	submit: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	checkbox: {
		color: COLORS.primary.default,
		'&.Mui-checked': {
			color: COLORS.primary.default,
		},
	},
}));
