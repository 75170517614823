import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Button, Box, Chip, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetPaginatedTasks } from '../../../../services/api';
import { TASKS_COLUMNS } from './Tasks.columns';
import { formatCurrency } from '../../../../utils/formatCurrency';
import AddTask from '../AddTask/AddTask';
import UpdateTask from '../UpdateTask/UpdateTask';
import { TaskProps } from './Tasks.props';
import { COLORS } from '../../../../constants/theme';
import { useTasksStyles } from './Tasks.styles';

export const Tasks = () => {
	const classes = useTasksStyles();
	const { id = 0, asana_project_id = '' } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: tasksData,
		isError: isErrorTasks,
		isLoading: isLoadingTasks,
		isFetching: isFetchingTasks,
	} = useGetPaginatedTasks(pageNumber + 1, limit, Number(id));

	// RENDER LOADING & ERROR
	if (isLoadingTasks) {
		return <p>Loading...</p>;
	}

	if (isErrorTasks) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tasks = tasksData.message === 'Success' ? tasksData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={TASKS_COLUMNS}>
					<TableCell key='add-task'>
						<AddTask
							asana_project_id={asana_project_id}
							job_id={Number(id)}
							limit={limit}
							pageNumber={pageNumber}
						/>
					</TableCell>
				</TableHeaderComponent>
				<TableBody sx={{ position: 'relative' }}>
					{/* {(searchTerm || teamFilter || clientFilter) && isFetchingActiveJobs && (
				<TableRow className={classes.loaderContainer}>
					<TableCell>
						<CircularProgress color='error' size={24} />
					</TableCell>
				</TableRow>
			)} */}

					{tasks.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{tasks.map((task: TaskProps) => (
						<TableRow key={task.gid}>
							<TableCell>
								<Box className={classes.taskNameWrapper}>
									<span className={classes.taskName}>{task.name ? task.name : '-'}</span>
									<Chip
										label={task.scope_type}
										size='small'
										className={classes.taskChip}
										sx={{ backgroundColor: COLORS.primary.shade100 }}
									/>
								</Box>
							</TableCell>
							<TableCell>{task.notes ? task.notes : '-'}</TableCell>
							<TableCell>{formatCurrency(task.fees)}</TableCell>
							<TableCell>{task.assignee ? task.assignee : '-'}</TableCell>
							<TableCell className={classes.actionButton}>
								<UpdateTask
									asana_project_id={asana_project_id}
									job_id={Number(id)}
									limit={limit}
									pageNumber={pageNumber}
									row={task}
								/>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={tasksData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
