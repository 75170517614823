import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { capitalize } from '../../../../utils';
import { usePostAsanaTask } from '../../../../services/api';
import Context from '../../../../services/context';

export const useTaskCreate = ({ createDetails }: any) => {
	const { state } = useContext(Context);
	const [addTask, setAddTask] = useState({
		task: '',
		description: '',
		fee: '0',
		assignee: '',
		assigneeId: '',
		estimatedTimeHours: '',
		dueOn: new Date(),
	});
	const [addTaskValidation, setAddTaskValidation] = useState<any>({
		task: false,
		description: false,
		fee: false,
		assignee: false,
		assigneeId: false,
		estimatedTimeHours: false,
		dueOn: false,
	});
	const [showAddTask, setShowAddTask] = useState(false);
	const { mutate, data: addTaskData, isLoading: addTaskLoading } = usePostAsanaTask();
	const [assignees, setAssignees] = useState<any>([]);
	const [selectedAssignee, setSelectedAssignee] = useState({ id: 0, name: '' });

	function toggleCreateTask() {
		setShowAddTask((showAddTask) => !showAddTask);
		setAddTask({
			task: '',
			description: '',
			fee: '0',
			assignee: '',
			assigneeId: '',
			estimatedTimeHours: '',
			dueOn: new Date(),
		});
		setAddTaskValidation({
			task: false,
			description: false,
			fee: false,
			assignee: false,
			assigneeId: false,
			estimatedTimeHours: false,
			dueOn: false,
		});
		setSelectedAssignee({ id: 0, name: '' });
	}

	const handleAddTaskSubmit = (): void => {
		const dateNow = new Date();
		const doubleCheckValidation: any = {
			task: addTask.task ? true : false,
			description: addTask.description ? true : false,
			estimatedTimeHours: addTask.estimatedTimeHours ? true : false,
			// fee: String(addTask.fee).replace(/0/g, '').trim() ? true : false,
			assignee: addTask.assignee.trim() ? true : false,
			dueOn: dateNow!.toLocaleDateString('fr-CA') <= addTask.dueOn!.toLocaleDateString('fr-CA'),
		};
		const validateField = Object.keys(addTaskValidation).filter((key) => !addTaskValidation[key]);
		const validateFieldTwo = Object.keys(doubleCheckValidation).filter((key) => !doubleCheckValidation[key]);

		// if (validateField.length || validateFieldTwo.length) {
		if (validateFieldTwo.length) {
			if (!doubleCheckValidation.dueOn) {
				toast.info('Date must not be less than today');
			} else {
				toast.info(`${capitalize(validateFieldTwo[0])} must not be empty`);
			}
		} else {
			const formatData = {
				task_name: addTask.task,
				workspaces_id: createDetails.workspace_id,
				projects_id: createDetails.asana_project_id,
				notes: addTask.description,
				due_on: addTask.dueOn!.toLocaleDateString('fr-CA'),
				assign_user: addTask.assignee,
				assign_user_id: addTask.assigneeId,
				time_estimate: Number(addTask.estimatedTimeHours),
			};

			mutate(formatData);
		}
	};

	const handleAddTaskData = (event: any, field: any) => {
		switch (field) {
			case 'task':
				const valueTask = (event.target as HTMLInputElement).value;
				setAddTask({ ...addTask, task: valueTask });
				setAddTaskValidation({
					...addTaskValidation,
					task: valueTask.trim() ? true : false,
				});
				break;
			case 'estimatedTimeHours':
				const valueEstimatedTimeHours = (event.target as HTMLInputElement).value;
				setAddTask({
					...addTask,
					estimatedTimeHours:
						Number(valueEstimatedTimeHours) < 0 || !Number(valueEstimatedTimeHours)
							? ''
							: valueEstimatedTimeHours,
				});
				setAddTaskValidation({
					...addTaskValidation,
					estimatedTimeHours:
						Number(valueEstimatedTimeHours) !== 0 || !Number(valueEstimatedTimeHours) ? true : false,
				});
				break;
			case 'description':
				const valueDescription = (event.target as HTMLInputElement).value;
				setAddTask({ ...addTask, description: valueDescription });
				setAddTaskValidation({
					...addTaskValidation,
					description: valueDescription.trim() ? true : false,
				});
				break;
			case 'fee':
				setAddTask({ ...addTask, fee: event.formattedValue });
				setAddTaskValidation({
					...addTaskValidation,
					fee: event.formattedValue.replace(/0/g, '').trim() && event.formattedValue !== '$ 0' ? true : false,
				});
				break;
			case 'assignee':
				// const valueAssignee = (event.target as HTMLInputElement).value;
				setSelectedAssignee(event);
				setAddTask({ ...addTask, assignee: event ? event.name : '', assigneeId: event ? event.id : 0 });
				setAddTaskValidation({
					...addTaskValidation,
					assignee: event && event.name ? true : false,
					assigneeId: event && event.id ? true : false,
				});
				break;
			case 'dueOn':
				setAddTask({ ...addTask, dueOn: event });
				setAddTaskValidation({
					...addTaskValidation,
					dueOn: event,
				});
				break;
		}
	};

	const defaultPropsAssignees: any = {
		options: assignees,
		getOptionLabel: (option: any) => option.name,
	};

	const formatDataUsers = (data: any) => {
		return data
			? data.map((list: any, index: number) => ({
					id: list.gid,
					name:  `${list.name} - ${list.email}`,
			  }))
			: [];
	};

	useEffect(() => {
		const filter = state.assignees && state.assignees.length ? formatDataUsers(state.assignees) : [];
		setAssignees(filter);
	}, [state.assignees]);

	useEffect(() => {
		if (addTaskData && addTaskData.error === false) {
			toggleCreateTask();
		}
	}, [addTaskData]);

	const modalPropsAddTask = {
		handleSubmit: handleAddTaskSubmit,
		isShowing: showAddTask,
		hide: toggleCreateTask,
		currentPage: 1,
		pageLength: 1,
	};

	return {
		modalPropsAddTask,
		toggleCreateTask,
		handleAddTaskData,
		addTaskValidation,
		addTask,
		defaultPropsAssignees,
		addTaskLoading,
		selectedAssignee,
	};
};
