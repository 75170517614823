export interface InitialState {
	items: { [key: string]: any };
	token: string;
	unassignJobs: any;
	activeJobs: any;
	activeJob: any;
	loadingActiveJobs: boolean;
	loadingUnassignJobs: boolean;
	tasks: any;
	jobType: any;
	unassignedJobTicker: number;
	jobNumberHelper: any;
	userName: string;
	pos: any;
	posTicker: number;
	posLoading: boolean;
	timeEntries: any;
	timeEntriesLoading: boolean;
	invoices: any;
	invoicesLoading: boolean;
	invoicesSelection: any;
	invoicesSelectionLoading: boolean;
	posAssignLoading: boolean;
	invoiceAssignLoading: boolean;
	invoiceJobSelection: any;
	clients: any;
	clientsLoading: boolean;
	archiveJobs: any;
	archiveJobsLoading: boolean;
	nextJobNumber: string;
	nextQuoteNumber: string;
	teams: any;
	setActiveJobAssigning: any;
	assignees: any;
	projectJobs: any;
	retainerJobs: any;
}

export const initialState: InitialState = {
	items: {},
	unassignJobs: [],
	loadingUnassignJobs: false,
	activeJobs: [],
	activeJob: [],
	loadingActiveJobs: false,
	token: '',
	jobType: [
		{ id: '1', name: 'New job' },
		{ id: '2', name: 'Add to retainer job' },
		{ id: '3', name: 'Add to project job' },
	],
	unassignedJobTicker: 0,
	tasks: [],
	jobNumberHelper: {},
	userName: '',
	pos: [],
	posTicker: 0,
	posLoading: false,
	posAssignLoading: false,
	timeEntries: [],
	timeEntriesLoading: false,
	invoices: [],
	invoicesLoading: false,
	invoicesSelection: [],
	invoicesSelectionLoading: false,
	invoiceAssignLoading: false,
	invoiceJobSelection: [],
	clients: [],
	clientsLoading: false,
	archiveJobs: [],
	archiveJobsLoading: false,
	nextJobNumber: '',
	nextQuoteNumber: '',
	teams: [],
	setActiveJobAssigning: [],
	assignees: [],
	projectJobs: [],
	retainerJobs: [],
};
