import { useContext } from 'react';
import MenuIcon from '@material-ui/icons/Menu';

import { AppBar, Avatar, Button, Grid, Typography, Toolbar, IconButton } from '@mui/material';
import { login, getLogout, useGetUserData, useGetLogout } from '../../services/api';
import Context from '../../services/context';
import { ReactComponent as GerpLogo } from '../../assets/images/gsq_logo.svg';
import { Notifications } from '../../components/Notifications/Notifications';

export const Header = ({ handleDrawerToggle }: any) => {
	useGetUserData();
	const { state } = useContext(Context);

	const handleLogout = async () => {
		const data = await getLogout();

		localStorage.setItem('gsqToken', '');
		localStorage.clear();
		window.location.href = data.result.redirect_uri;
	};

	return (
		<AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
			<Toolbar className='gs-main__nav-bar'>
				<IconButton
					color='inherit'
					aria-label='open drawer'
					edge='start'
					onClick={handleDrawerToggle}
					sx={{ mr: 2, display: { sm: 'none' } }}
				>
					<MenuIcon />
				</IconButton>
				<GerpLogo className='gerp-logo' />
				<Grid
					className='gs-main__user-info-grid'
					container
					item
					xs={6}
					spacing={1}
					textAlign='right'
					sx={{ display: 'flex', justifyContent: 'end' }}
				>
					{/* <Grid>
						<Avatar>M</Avatar>
					</Grid> */}
					<Grid sx={{ marginLeft: '1em' }}>
						<Typography variant='body1'>Welcome, {state.userName}</Typography>
						<span onClick={() => handleLogout()} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
							Logout
						</span>
					</Grid>
					<Grid sx={{ marginLeft: '1em' }}>
						<Notifications />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};
