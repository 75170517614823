import React, { useEffect, useState } from 'react';
import { Typography, Box, TextField, InputAdornment, Button } from '@mui/material';
import { AccountCircle, Mail } from '@mui/icons-material';
import { userProps } from '../Settings.props';
import { usePutUserData, useGetUserData } from '../../../services/api';
import { useProfileSettingsStyles } from './ProfileSettings.styles';

const ProfileSettings = () => {
	const classes = useProfileSettingsStyles();
	const [user, setUser] = useState<userProps>({
		email: '',
		full_name: '',
		img_url: '',
		image_file: new FormData(),
		photo_metadata: {
			'@odata.context': '',
			'@odata.mediaContentType': '',
			'@odata.mediaEtag': '',
			id: '',
			height: 0,
			width: 0,
		},
	});
	const [file, setFile] = useState<any>();

	// QUERY USER DATA
	const { data: userData, isLoading: isLoadingUserData, isError: isErrorUserData } = useGetUserData();

	// MUTATE UPDATE USER DATA
	const { mutate: updateUserData } = usePutUserData();

	useEffect(() => {
		userData.result.id &&
			setUser({
				...user,
				email: userData?.result.email,
				full_name: userData?.result.full_name,
				img_url: userData?.result.img_url,
				image_file: userData?.result.image_file,
			});
	}, [userData.result]);

	// RENDER LOADING & ERROR
	if (isLoadingUserData) {
		return <p>Loading...</p>;
	}

	if (isErrorUserData) {
		return <p>Opps! Something went wrong. Please try again. </p>;
	}

	// HANDLERS
	const changeTextFieldValueHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setUser({ ...user, [name]: value });
	};

	const uploadImageHandler = (event: any) => {
		event.preventDefault();
		const objectUrl = URL.createObjectURL(event.target.files[0]);
		console.log({ objectUrl });
		setFile(event.target.files[0]);
		setUser({ ...user, img_url: objectUrl, image_file: event.target.files[0] });
	};

	const saveProfleHandler = (event: any) => {
		event.preventDefault();
		let formData;
		formData = new FormData();
		formData.append('fileName', file?.name);
		formData.append('file', file);

		const updatedUser = {
			full_name: user.full_name,
			image_file: formData,
		};

		updateUserData(updatedUser);
		setUser({ ...user, ...updatedUser });
	};

	// COMPONENT
	return (
		<form action='#'>
			<Typography mb={2} variant='h5'>
				Profile
			</Typography>

			<Box className={classes.profileSettingsContainer}>
				<Box className={classes.profileSettings}>
					<TextField
						fullWidth
						id='full_name'
						name='full_name'
						label='Username'
						value={user.full_name}
						onChange={(e) => changeTextFieldValueHandler(e)}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AccountCircle />
								</InputAdornment>
							),
						}}
						variant='standard'
					/>
					<TextField
						fullWidth
						id='email'
						name='email'
						label='Email'
						type='email'
						value={user.email}
						onChange={(e) => changeTextFieldValueHandler(e)}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<Mail />
								</InputAdornment>
							),
						}}
						variant='standard'
						disabled
					/>

					<Box className={classes.settingsBtn}>
						<Button color='error' variant='contained' onClick={saveProfleHandler} disableElevation>
							Save
						</Button>
					</Box>
				</Box>

				<Box className={classes.profileSettingImageContainer}>
					<Box className={classes.profileSettingImageWrapper}>
						<Box
							component='div'
							sx={{
								backgroundImage: `url(${user.img_url ? user.img_url : 'https://tinyurl.com/53anvth3'})`,
							}}
							className={classes.profileSettingImage}
						/>
						<Box className={classes.settingsBtn}>
							<Button variant='contained' component='label' color='error' disableElevation>
								Upload
								<input
									hidden
									accept='image/*'
									type='file'
									onChange={(e: any) => uploadImageHandler(e)}
								/>
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
		</form>
	);
};

export default ProfileSettings;
