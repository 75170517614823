import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants/theme';

export const useTasksStyles = makeStyles((theme) => ({
	taskNameWrapper: {
		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
		// border: '1px solid red',
	},

	taskName: {
		fontWeight: 'bold',
	},

	taskChip: {
		width: 'fit-content',
		fontSize: '12px',
	},

	actionButton: {
		textDecoration: 'underline',
		color: COLORS.primary.default,
	},

	addTaskButton: {
		minWidth: '40%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		color: COLORS.primary.default,
		textDecoration: 'underline',
		paddingLeft: '0px',
		fontWeight: 'bold',

		'&:hover': {
			backgroundColor: COLORS.primary.shade100,
		},
	},

	addTaskBtn: {
		minWidth: '40%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		color: COLORS.primary.default,
		textDecoration: 'underline',
		fontWeight: 'bold',
		padding: '0 8px',
		marginLeft: '-8px',

		'&:hover': {
			backgroundColor: COLORS.primary.shade100,
		},
	},

	// PAGINATION
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
}));
