import { Table, TableBody, TableContainer, TableHead, Paper, Box } from '@mui/material';
import { useDataTablePaginationLogic } from '../../hooks/components';

export const DataTablePagination = (props: any) => {
	const { tableHeader, loadingTableBody, tablePagination } = useDataTablePaginationLogic(props);

	return (
		<>
			<TableContainer sx={{ height: '65vh' }}>
				<Table aria-labelledby='tableTitle' size='medium' stickyHeader>
					{tableHeader}
					{loadingTableBody()}
				</Table>
			</TableContainer>
			{tablePagination}
		</>
	);
};
