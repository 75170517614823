import { useContext, useEffect, useState } from 'react';
import { DataTable, DataTablePagination } from '../../components';
import { jobPageQuotesDataTable } from '../../data/tableColumns';
import Context from '../../services/context';

export const Quotes = ({ asana_project_id, jobFilter, search, tbIndex }: any) => {
	const { dispatch, state } = useContext(Context);
	const [qoutesList, setQoutesList] = useState([]);
	const [loading, setLoading] = useState(state.loadingActiveJobs);

	const formatDataQuotesJob = (data: any) => {
		return data[0] && data[0].quotes_details
			? data[0].quotes_details.map((value: any, index: any) => {
					return {
						id: index,
						date: value.quote_date ? value.quote_date : '-',
						number: value.quote_number ? value.quote_number : '-',
						type: value.project_type ? value.project_type : '-',
						costs: value.cost && value.cost !== '-' ? Number(value.cost) : 0,
						fees: value.fees && value.fees !== '-' ? Number(value.fees) : 0,
						status: value.status ? value.status : '-',
						dateCreated: value.date_created
							? new Date(value.date_created)!.toLocaleDateString('fr-CA')
							: '-',
						hours: value.est_hours ? String(value.est_hours) : '0',
					};
			  })
			: [];
	};

	const handleFilterData = () => {
		return state.activeJobs
			? state.activeJobs.filter((value: any) => value.asana_project_id == asana_project_id)
			: [];
	};

	useEffect(() => {
		const filterJobs = handleFilterData();

		if (filterJobs && filterJobs.length) {
			setQoutesList(formatDataQuotesJob(filterJobs));
		} else {
			setQoutesList(formatDataQuotesJob([]));
		}
	}, [state.activeJobs]);

	useEffect(() => {
		setLoading(state.loadingActiveJobs);
	}, [state.loadingActiveJobs]);

	const addZeroSingleNumber = (length: any, mainNumber: any) => {
		length = length || 2;
		return (new Array(length).join('0') + mainNumber).slice(length * -1);
	};

	useEffect(() => {
		const filterJobs = handleFilterData();
		const formattedJobs = filterJobs && filterJobs.length ? formatDataQuotesJob(filterJobs) : [];
		if (jobFilter.taskDateDisable) {
			if (search && formattedJobs && formattedJobs.length && tbIndex === 0) {
				let filtered = formattedJobs.filter((item: any) => {
					return (
						item.date.toLowerCase().includes(search.toLowerCase()) ||
						item.number.toLowerCase().includes(search.toLowerCase()) ||
						item.type.toLowerCase().includes(search.toLowerCase()) ||
						String(item.costs).toLowerCase().includes(search.toLowerCase()) ||
						String(item.fees).toLowerCase().includes(search.toLowerCase()) ||
						item.status.toLowerCase().includes(search.toLowerCase())
					);
				});
				setQoutesList(filtered);
			} else {
				setQoutesList(formattedJobs);
			}
		} else {
			if (tbIndex === 0 && formattedJobs && formattedJobs.length && !jobFilter.taskDateDisable) {
				let filtered = formattedJobs.filter((item: any) => {
					const addZeroInMonth = addZeroSingleNumber(2, jobFilter.taskDate.getMonth() + 1);
					const formatDate = `${jobFilter.taskDate.getFullYear()}-${addZeroInMonth}`;
					return item.date.toLowerCase().includes(formatDate.toLowerCase());
				});
				if (search && filtered.length && tbIndex === 0) {
					let filteredFormat = filtered.filter((item: any) => {
						return (
							item.date.toLowerCase().includes(search.toLowerCase()) ||
							item.number.toLowerCase().includes(search.toLowerCase()) ||
							item.type.toLowerCase().includes(search.toLowerCase()) ||
							String(item.costs).toLowerCase().includes(search.toLowerCase()) ||
							String(item.fees).toLowerCase().includes(search.toLowerCase()) ||
							item.status.toLowerCase().includes(search.toLowerCase())
						);
					});

					setQoutesList(filteredFormat);
				} else {
					const getData = filtered.length ? filtered : [];
					setQoutesList(getData);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	const tableData = {
		columns: jobPageQuotesDataTable.columns,
		rows: qoutesList,
		isLoading: loading,
	};

	return (
		<div>
			{/* <DataTable {...tableData} /> */}
			<DataTablePagination {...tableData} />
		</div>
	);
};
