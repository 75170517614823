import { makeStyles } from '@material-ui/core/styles';

export const useSearchBoxStyles = makeStyles((theme) => ({
	searchContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: '.7rem',
	},

	searchText: {
		fontSize: '14px',
		paddingTop: '3px',
	},

	searchField: {
		marginLeft: '10px',
		width: '300px',

		'& .MuiInputBase-input': {
			padding: '.5px',
		},
	},
}));
