import React from 'react';

export const INITIAL_STATE_DOC = {
	client_id: null,
	end_date: '',
	est_hours: '',
	fees: 0,
	invoice_frequency: [
		{
			range: 'Monthly',
			notes: '',
		},
	],
	invoice_milestones: [],
	job_cat: null,
	job_client: '',
	job_description: '',
	job_name: '',
	job_number: '',
	new_job: 0,
	panda_doc_id: '',
	qoute_number: '',
	services: [],
	start_date: '',
	team_id: '',
	id: 0, // Active job ID
	asana_project_id: '',
	cost_id: '',
	doc_type: 1,
	docs_file: null,
	title: '',
	notes: '',
};

export const INITIAL_STATE_MILESTONE = [
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
];
