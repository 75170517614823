import { useState, useEffect, useContext } from 'react';
import {
	Button,
	Box,
	Typography,
	TextField,
	Autocomplete,
	Input,
	InputAdornment,
	Checkbox,
	FormControl,
	InputLabel,
} from '@mui/material';
import NumberFormat from 'react-number-format';
// import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ClearIcon from '@mui/icons-material/Clear';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Context from '../../services/context';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { toast } from 'react-toastify';

const top100Films = [
	{ title: 'Web', id: 1, indexN: 0 },
	{ title: 'SEO', id: 2, indexN: 1 },
	{ title: 'Content', id: 3, indexN: 2 },
	{ title: 'Paid Media', id: 4, indexN: 3 },
	{ title: 'Email Marketing', id: 5, indexN: 4 },
	{ title: 'Community Management', id: 6, indexN: 5 },
];

const teamsList = [{ title: '', id: 1 }];

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const PageTwo = ({
	addJob,
	handleAddJob,
	addJobValidation,
	setClientSelected,
	defaultProps,
	handleTotalInvoicePercent,
	invoiceMilestonePercentage,
	handleAddMilestone,
	handleUpdatePercent,
	handleRemoveMilestone,
	teamList,
	defaultPropsTeams,
	newJobCat,
	setnewJobCat,
	defaultPropsProjectJobs,
	selectedProject,
	setSelectedProject,
	defaultPropsRetainerJobs,
	selectedRetainer,
	setSelectedRetainer,
	defaultPropsInvFrequency,
	selectedInvFreq,
	setSelectedInvFreq,
	setSelectedJob,
	selectedJob,
}: any) => {
	const checkList =
		addJob.retainerWork && addJob.retainerWork.length
			? addJob.retainerWork.map((value: any) => {
					return top100Films[value.indexN];
			  })
			: [];

	return (
		<div className='gs-pagetwo__container'>
			<Box className='gs-pagetwo__header-div' style={{ display: addJob.jobCat === 1 ? 'block' : 'none' }}>
				<Button
					onClick={() => setnewJobCat(3)}
					className={`gs-global__btn${newJobCat === 3 ? ' gs-pagetwo__btn-category-show' : ''}`}
					color={newJobCat === 3 ? 'error' : 'secondary'}
					variant='outlined'
					disableElevation
				>
					Project
				</Button>
				<Button
					onClick={() => setnewJobCat(2)}
					className={`gs-global__btn${newJobCat === 2 ? ' gs-pagetwo__btn-category-show' : ''}`}
					color={newJobCat === 2 ? 'error' : 'secondary'}
					variant='outlined'
					disableElevation
				>
					Retainer
				</Button>
			</Box>

			<Box className='gs-pagetwo__content-box'>
				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography>Client:</Typography>
					<Box className='gs__text-field'>
						<Autocomplete
							className='gs-activeJobs__basic-input--description'
							{...defaultProps}
							id='clear-on-escape'
							disableClearable
							// disabled={selectedJob.id ? true : false}
							value={addJob.clientName}
							onChange={(value: any, newValue: any) => {
								// const setVal = newValue ? newValue : { id: 0, name: '' };
								handleAddJob(newValue, 'clientName');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select client'
									error={!addJobValidation.clientName ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{ height: '32px', display: addJob.jobCat === 3 ? 'flex' : 'none' }}
				>
					<Typography>Active Jobs:</Typography>
					<Box>
						<Autocomplete
							className='gs-activeJobs__basic-input--description'
							{...defaultPropsProjectJobs}
							id='clear-on-escape'
							disableClearable
							disabled={addJob.clientName.id ? false : true}
							value={selectedProject}
							onChange={(event, newValue) => {
								setSelectedProject(newValue);
								setSelectedJob(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select job'
									error={
										(selectedProject && selectedProject.name) || !addJob.clientName.id
											? false
											: true
									}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{ height: '32px', display: addJob.jobCat === 2 ? 'flex' : 'none' }}
				>
					<Typography>Active Jobs:</Typography>
					<Box>
						<Autocomplete
							className='gs-activeJobs__basic-input--description'
							{...defaultPropsRetainerJobs}
							id='clear-on-escape'
							disableClearable
							disabled={addJob.clientName.id ? false : true}
							value={selectedRetainer}
							onChange={(event, newValue) => {
								setSelectedRetainer(newValue);
								setSelectedJob(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select job'
									error={
										(selectedRetainer && selectedRetainer.name) || !addJob.clientName.id
											? false
											: true
									}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography>Job name:</Typography>
					<Box>
						<TextField
							className='gs-activeJobs__basic-input--description'
							id='standard-basic'
							variant='standard'
							onChange={(event) => handleAddJob(event, 'jobName')}
							defaultValue={addJob.jobName}
							value={addJob.jobName}
							placeholder='*Please enter job name'
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							error={
								!selectedJob.id && addJob.jobCat !== 1
									? false
									: !addJobValidation.jobName
									? true
									: false
							}
						/>
					</Box>
				</Box>
				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography>Teams:</Typography>
					<Box>
						<Autocomplete
							className='gs-activeJobs__basic-input--description'
							{...defaultPropsTeams}
							id='clear-on-escape'
							disableClearable
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							value={addJob.teams}
							onChange={(event, newValue) => {
								handleAddJob(newValue, 'teams');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select teams'
									error={
										!selectedJob.id && addJob.jobCat !== 1
											? false
											: !addJobValidation.teams
											? true
											: false
									}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography>Description:</Typography>
					<Box>
						<TextField
							className='gs-activeJobs__basic-input--description'
							id='standard-basic'
							variant='standard'
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							onChange={(event) => handleAddJob(event, 'description')}
							defaultValue={addJob.description}
							value={addJob.description}
							placeholder='*Please enter description'
							// error={!addJobValidation.description ? true : false}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography>Job number:</Typography>
					<Box>
						<TextField
							onChange={(event) => handleAddJob(event, 'jobNumber')}
							value={addJob.jobNumber}
							className='gs-activeJobs__basic-input'
							id='standard-basic'
							disabled={
								addJob.jobCat !== 1
									? addJob.clientName.id && selectedJob.id
										? selectedJob.id
											? true
											: false
										: true
									: false
							}
							variant='standard'
							placeholder='*Please enter job number'
							error={!addJobValidation.jobNumber ? true : false}
						/>
					</Box>
				</Box>
				{/* <Box className='gs-pagetwo__row-box'>
					<Typography>Quote number:</Typography>
					<Box>
						<TextField
							onChange={(event) => handleAddJob(event, 'quoteNumber')}
							value={addJob.quoteNumber}
							className='gs-activeJobs__basic-input'
							id='standard-basic'
							variant='standard'
							placeholder='*Please enter quote number'
							// error={!addJobValidation.quoteNumber ? true : false}
						/>
					</Box>
				</Box> */}

				<Box className='gs-pagetwo__row-box'>
					<Typography>Fees:</Typography>
					<Box>
						<NumberFormat
							className='gs-activeJobs__basic-input'
							thousandsGroupStyle='thousand'
							onValueChange={(values) => {
								handleAddJob(values, 'fees');
							}}
							value={addJob.feesValue}
							// prefix='none'
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							error={!selectedJob.id && addJob.jobCat !== 1 ? false : !addJob.feesValue ? true : false}
							decimalSeparator='.'
							customInput={Input}
							displayType='input'
							type='text'
							id='standard-basic'
							startAdornment={<InputAdornment position='start'>$</InputAdornment>}
							thousandSeparator={true}
							allowNegative={false}
						/>
					</Box>
				</Box>

				<Box className='gs-pagetwo__row-box'>
					<Typography>Estimated time:</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							type='number'
							className='gs-activeJobs__basic-input'
							style={{ width: '80px' }}
							id='standard-basic'
							variant='standard'
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							onChange={(event) => handleAddJob(event, 'estimatedTimeHours')}
							defaultValue={addJob.estimatedTimeHours}
							value={addJob.estimatedTimeHours}
							placeholder='*Hours'
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							error={
								!selectedJob.id && addJob.jobCat !== 1
									? false
									: !addJobValidation.estimatedTimeHours
									? true
									: false
							}
						/>
					</Box>
				</Box>
				<Box className='gs-pagetwo__row-box' style={{ height: '32px' }}>
					<Typography>
						{addJob.jobCat === 2 || (addJob.jobCat === 1 && newJobCat === 2) ? 'Contract ' : ''}Dates:
					</Typography>
					<Box>
						<Typography className='gs-pagetwo__text-mr'>Start</Typography>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={addJob.dateStart}
								disabled={
									addJob.jobCat !== 1
										? addJob.clientName.id && selectedJob.id
											? false
											: true
										: false
								}
								onChange={(newValue: any) => {
									handleAddJob(newValue, 'dateStart');
								}}
								renderInput={(params: any) => <TextField className='gs__date-picker' {...params} />}
							/>
						{/* </LocalizationProvider> */}
						<Typography className='gs-pagetwo__text-ml-mr'>End</Typography>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={addJob.dateEnd}
								disabled={
									addJob.jobCat !== 1
										? addJob.clientName.id && selectedJob.id
											? false
											: true
										: false
								}
								onChange={(newValue: any) => {
									handleAddJob(newValue, 'dateEnd');
								}}
								renderInput={(params: any) => <TextField className='gs__date-picker' {...params} />}
							/>
						{/* </LocalizationProvider> */}
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{
						height: '32px',
						display: addJob.jobCat === 2 || (addJob.jobCat === 1 && newJobCat === 2) ? 'flex' : 'none',
					}}
				>
					<Typography>Invoice Frequency:</Typography>
					<Box className='gs__text-field'>
						<Autocomplete
							className='gs-activeJobs__basic-input--description'
							{...defaultPropsInvFrequency}
							id='clear-on-escape'
							disableClearable
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							value={selectedInvFreq}
							onChange={(value: any, newValue: any) => {
								setSelectedInvFreq(newValue);
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select invoice frequency'
									disabled={
										addJob.jobCat !== 1
											? addJob.clientName.id && selectedJob.id
												? false
												: true
											: false
									}
									error={selectedInvFreq && selectedInvFreq.name ? false : true}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box style={{ minHeight: '32px' }} className='gs-pagetwo__row-box'>
					<Typography>Job type:</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Autocomplete
							multiple
							id='size-small-standard-multi'
							size='small'
							options={top100Films}
							disableCloseOnSelect
							getOptionLabel={(option) => option.title}
							defaultValue={checkList}
							disabled={
								addJob.jobCat !== 1 ? (addJob.clientName.id && selectedJob.id ? false : true) : false
							}
							value={checkList}
							onChange={(event, newValue) => {
								handleAddJob(newValue, 'retainerWork');
							}}
							renderOption={(props, option, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.title}
								</li>
							)}
							style={{ width: '92%' }}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='standard'
									disabled={
										addJob.jobCat !== 1
											? addJob.clientName.id && selectedJob.id
												? false
												: true
											: false
									}
									error={
										!selectedJob.id && addJob.jobCat !== 1
											? false
											: !addJobValidation.retainerWork
											? true
											: false
									}
									placeholder='Select job type'
								/>
							)}
						/>
					</Box>
				</Box>

				<Box
					className='gs-pagetwo__row-box'
					style={{
						height: '32px',
						display: addJob.jobCat === 3 || (addJob.jobCat === 1 && newJobCat === 3) ? 'flex' : 'none',
					}}
				>
					<Typography>Invoice Milestones:</Typography>
					<Box>
						<Typography
							style={{
								display: 'flex',
								alignItems: 'flex-end',
								justifyContent: 'space-between',
								width: '92%',
								color: handleTotalInvoicePercent > 100 ? 'red' : 'black',
							}}
						>
							{invoiceMilestonePercentage.length
								? invoiceMilestonePercentage.map((value: any, index: any) => {
										if (index === invoiceMilestonePercentage.length - 1) {
											return `${value.percent ? value.percent : 0}`;
										} else {
											return `${value.percent ? value.percent : 0}/`;
										}
								  })
								: '0'}{' '}
							= {handleTotalInvoicePercent}%
							<span style={{ display: 'flex' }}>
								<AddOutlinedIcon
									className='gs__icon__edit'
									style={{ cursor: 'pointer' }}
									onClick={() => {
										if (
											addJob.jobCat !== 1
												? addJob.clientName.id && selectedJob.id
													? false
													: true
												: false
										) {
											toast.info('Select clieent and active job first');
										} else {
											handleAddMilestone();
										}
									}}
								/>
							</span>
						</Typography>
					</Box>
				</Box>

				{addJob.jobCat === 3 || (addJob.jobCat === 1 && newJobCat === 3)
					? invoiceMilestonePercentage.map((value: any, index: any) => {
							return (
								<Box className='gs-pagetwo__row-box'>
									<Typography></Typography>
									<Box>
										<div style={{ width: '92%', display: 'flex', justifyContent: 'space-between' }}>
											<div style={{ width: '11%' }}>
												<Input
													onChange={(event) => handleUpdatePercent(event, index, 'percent')}
													value={value.percent}
													id='standard-adornment-amount'
													disabled={
														addJob.jobCat !== 1
															? addJob.clientName.id && selectedJob.id
																? false
																: true
															: false
													}
													endAdornment={<InputAdornment position='start'>%</InputAdornment>}
												/>
											</div>
											<Input
												style={{ width: '86%' }}
												onChange={(event) => handleUpdatePercent(event, index, 'notes')}
												value={value.notes}
												id='standard-adornment-amount'
												placeholder='Notes'
												multiline
												disabled={
													addJob.jobCat !== 1
														? addJob.clientName.id && selectedJob.id
															? false
															: true
														: false
												}
												endAdornment={
													<InputAdornment position='end'>
														<ClearIcon
															style={{ cursor: 'pointer' }}
															// onClick={() => handleRemoveMilestone(index)}
															onClick={() => {
																if (
																	addJob.jobCat !== 1
																		? addJob.clientName.id && selectedJob.id
																			? false
																			: true
																		: false
																) {
																	toast.info('Select clieent and active job first');
																} else {
																	handleRemoveMilestone(index);
																}
															}}
															className='gs__icon__edit'
														/>
													</InputAdornment>
												}
											/>
										</div>
									</Box>
								</Box>
							);
					  })
					: ''}

				{/* <Box
					style={{ minHeight: '32px', display: addJob.jobCat === 2 ? 'flex' : 'none' }}
					className='gs-pagetwo__row-box'
				> */}
			</Box>
		</div>
	);
};
