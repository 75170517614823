export const ARCHIVED_INVOICES_COLUMNS = [
	// { field: 'no', headerName: '#' },
	{ field: 'client', headerName: 'Client' },
	{ field: 'invoice_number', headerName: 'Invoice Number' },
	{ field: 'job_number', headerName: 'Amount Due' },
	{ field: 'fees', headerName: 'Total tax' },
	{ field: 'costs', headerName: 'Amount Paid' },
	{ field: 'job_type', headerName: 'Total' },
	{ field: 'status', headerName: 'Subtotal Status' },
];
