import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { COLORS } from '../../../constants/theme';
import { Typography, Box, Tab, Tabs, Skeleton } from '@mui/material';

import { ChevronLeft } from '@mui/icons-material';
import { toActiveJobsDetailsData } from './ActiveJobDetails.transform';
import AddBilling from './AddBilling/AddBilling';
import { useGetActiveJobDetails, useUpdateJobDetails } from '../../../services/api';
import { jobDataProps } from './ActiveJobDetails.props';
import { useActiveJobDetailsStyles } from './ActiveJobDetails.styles';

import UpdateActiveJobDetails from './UpdateActiveJobDetails/UpdateActiveJobDetails';

export const ActiveJobDetails = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const state = useLocation();
	const classes = useActiveJobDetailsStyles();
	const [tab, setTab] = useState(0);
	const [jobId, setJobId] = useState(0);
	const [jobData, setJobData] = useState<jobDataProps>({
		id: null,
		job: {
			job_name: '',
			client_id: null,
			job_number: '',
			job_description: '',
			job_cat: null,
			start_date: '',
			end_date: '',
			invoice_milestones: [],
			team_id: '',
			asana_project_id: '',
			invoice_frequency: [],
			services: [],
		},
	});

	// QUERIES
	const { data: activeJob, isError: isErrorActiveJob, isLoading: isLoadingActiveJob } = useGetActiveJobDetails(jobId);
	const { mutate: updateJobDetails, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate } = useUpdateJobDetails();

	useEffect(() => {
		if (id) {
			setJobId(parseInt(id));
		}
	}, [id]);

	useEffect(() => {
		const jobInfo = activeJob?.result;
		setJobData({
			id: jobInfo?.id,
			job: {
				job_name: jobInfo?.job_name,
				client_id: jobInfo?.client_id,
				job_number: jobInfo?.job_number,
				job_description: jobInfo?.description,
				job_cat: jobInfo?.job_category_id,
				start_date: jobInfo?.start_date,
				end_date: jobInfo?.end_date,
				invoice_milestones: jobInfo?.invoice_milestones,
				team_id: jobInfo?.team_id,
				asana_project_id: jobInfo?.asana_project_id,
				invoice_frequency: jobInfo?.invoice_frequency,
				services: jobInfo?.services_list,
			},
		});
	}, [activeJob]);

	// RENDER LOADING & ERROR
	if (isLoadingActiveJob) {
		return <p>Loading...</p>;
	}

	if (isErrorActiveJob) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const jobDetails = activeJob?.result;
	const job = toActiveJobsDetailsData(activeJob?.result);

	// HANDLER
	if (isErrorActiveJob) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// HANDLERS
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		if (newValue === 0) navigate(`tasks/${jobDetails.asana_project_id}`);
		if (newValue === 1) navigate('time-entries');
		if (newValue === 2) navigate('attachments');
		if (newValue === 3) navigate('costs');
		if (newValue === 4) navigate('quotes');
		if (newValue === 5) navigate('invoices');
		if (newValue === 6) navigate('milestones');

		setTab(newValue);
	};

	return (
		<Box>
			<Box mb={4} className={classes.backButton} onClick={() => navigate('/active-jobs')}>
				<ChevronLeft fontSize='small' />
				back to Active Jobs
			</Box>

			<Box mb={1}>
				<Box mb={2} className={classes.titleWrapper}>
					<Box className={classes.title}>
						<Typography variant='h4'>
							{job.job_name === '-' ? <Skeleton width={250} /> : job.job_name}
						</Typography>
						{jobDetails?.id && (
							<UpdateActiveJobDetails {...jobDetails} client_name={job.client} job_type={job.job_type} />
						)}
					</Box>
				</Box>

				<Box mb={3} className={classes.dateContainer}>
					<Box className={classes.dateWrapper}>
						<Typography className={classes.jobDetailHeader}>Start date</Typography>
						<Typography>{job.start_date}</Typography>
					</Box>
					<Box className={classes.dateWrapper}>
						<Typography className={classes.jobDetailHeader}>End date</Typography>
						<Typography>{job.end_date}</Typography>
					</Box>

					<AddBilling id={jobDetails?.id} asana_id={jobDetails?.asana_project_id} />
				</Box>

				<Box mb={4} className={classes.jobDetails}>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Job Number</Typography>
						<Typography className={classes.jobDetailValue}>{job.job_number}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Client</Typography>
						<Typography className={classes.jobDetailValue}>{job.client}</Typography>
					</Box>
					<Box className={classes.jobDescription}>
						<Typography className={classes.jobDetailHeader}>Description</Typography>
						<Typography className={classes.jobDetailValue}>{job.description}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Job Type</Typography>
						<Typography className={classes.jobDetailValue}>{job.job_type}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Tags</Typography>
						<Typography className={classes.jobDetailValue}>
							{job.services_list.map((service) => service.service).join(', ')}
						</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Quote Fees</Typography>
						<Typography className={classes.jobDetailValue}>{job.quote_fees}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Costs</Typography>
						<Typography className={classes.jobDetailValue}>{job.total_cost}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Total Time Entries</Typography>
						<Typography className={classes.jobDetailValue}>{job.total_time_entries}</Typography>
					</Box>
					<Box className={classes.jobDetailItem}>
						<Typography className={classes.jobDetailHeader}>Invoice Fees</Typography>
						<Typography className={classes.jobDetailValue}>{job.invoice_fees}</Typography>
					</Box>
				</Box>

				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={tab}
							onChange={handleChange}
							aria-label='active jobs tabs'
							TabIndicatorProps={{
								style: {
									backgroundColor: COLORS.primary.default,
									color: COLORS.primary.default,
								},
							}}
						>
							<Tab
								label='Tasks'
								{...a11yProps(0)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
							<Tab
								label='Time Entries'
								{...a11yProps(1)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
							<Tab
								label='Attachments'
								{...a11yProps(2)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>

							<Tab
								label='Costs'
								{...a11yProps(3)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
							<Tab
								label='Quotes'
								{...a11yProps(4)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
							<Tab
								label='Invoices'
								{...a11yProps(5)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
							<Tab
								label='Milestones'
								{...a11yProps(6)}
								className={classes.tab}
								sx={{
									padding: '0 2.2rem',
									textTransform: 'capitalize',
									fontWeight: 'bold',
									color: COLORS.neutral.shade900,
								}}
							/>
						</Tabs>
					</Box>
					<TabPanel value={tab} index={0}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={1}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={2}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={3}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={4}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={5}>
						<Outlet />
					</TabPanel>
					<TabPanel value={tab} index={6}>
						<Outlet />
					</TabPanel>
				</Box>
			</Box>
		</Box>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`active-job-tabpanel-${index}`}
			aria-labelledby={`active-job-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ padding: '1rem 0' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

function a11yProps(index: number) {
	return {
		id: `active-job-tab-${index}`,
		'aria-controls': `active-job-tabpanel-${index}`,
	};
}
