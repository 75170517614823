import { NavLink, Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Avatar, Toolbar, ListItemText, List, Divider, ListItem, ListItemIcon } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export const Sidebar = ({ ticker, posTicker, unassignedIvoiceTicker }: any) => {
	return (
		<>
			<Toolbar />
			<Divider />
			<List sx={{ marginTop: '2em' }}>
				{[
					// { id: 1, name: 'Dashboard', path: '/', icon: <DashboardIcon /> },
					{
						id: 2,
						name: (
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								Unassigned Documents
								{ticker ? (
									<Avatar
										style={{
											height: '22px',
											width: '22px',
											fontSize: '10px',
											padding: '9px',
											marginLeft: '8px',
											background: '#c5332f',
										}}
									>
										{ticker}
									</Avatar>
								) : (
									''
								)}
							</span>
						),
						path: '/unassigned-docs',
						icon: <ContentPasteIcon />,
					},
					{
						id: 3,
						name: (
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								Unassigned POs/Costs
								{posTicker ? (
									<Avatar
										style={{
											height: '22px',
											width: '22px',
											fontSize: '10px',
											padding: '9px',
											marginLeft: '8px',
											background: '#c5332f',
										}}
									>
										{posTicker}
									</Avatar>
								) : (
									''
								)}
							</span>
						),
						path: '/unassigned-pos-costs',
						icon: <RequestQuoteIcon />,
					},
					{
						id: 4,
						name: (
							<span
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								Unassigned Invoices
								{unassignedIvoiceTicker ? (
									<Avatar
										style={{
											height: '22px',
											width: '22px',
											fontSize: '10px',
											padding: '9px',
											marginLeft: '8px',
											background: '#c5332f',
										}}
									>
										{unassignedIvoiceTicker}
									</Avatar>
								) : (
									''
								)}
							</span>
						),
						path: '/unassigned-invoices',
						icon: <ReceiptIcon />,
					},
					{ id: 5, name: 'Active Jobs', path: '/active-jobs', icon: <ContentPasteGoIcon /> },
					{ id: 6, name: 'Existing Asana Boards', path: '/asana-boards', icon: <GridViewOutlinedIcon /> },
					{ id: 7, name: 'Other Documents', path: '/other-documents', icon: <FilePresentIcon /> },
					{
						id: 8,
						name: (
							<span style={{ display: 'flex', alignItems: 'center' }}>
								Timesheets{' '}
								<OpenInNewIcon style={{ fontSize: '17px', color: '#757575', marginLeft: '6px' }} />
							</span>
						),
						path: '/timesheets',
						icon: <HistoryToggleOffIcon />,
					},
					// { id: 7, name: 'Reports', path: '/reports', icon: <ReceiptLongIcon /> },
					{ id: 9, name: 'Reports', path: '/reports', icon: <EqualizerIcon /> },
					{ id: 10, name: 'Clients', path: '/clients', icon: <PeopleIcon /> },
				].map(({ id, name, path, icon }) => {
					return (
						<ListItem className='gs-main__list-active' button key={id} component={NavLink} to={path}>
							<ListItemIcon>{icon}</ListItemIcon>
							<ListItemText primary={name} />
						</ListItem>
					);
				})}
			</List>
			<List className='gs-main__drawer-bottom'>
				{/* {[
					{ id: 8, name: 'Settings', path: '/settings', icon: <SettingsIcon /> },
					{ id: 9, name: 'Help', path: '/help', icon: <ContactSupportIcon /> },
					{ id: 10, name: 'Archives', path: '/archive-jobs', icon: <ArchiveIcon /> },
				].map(({ name, path, icon }) => (
					<ListItem className='gs-main__list-active' button key={name} component={NavLink} to={path}>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={name} />
					</ListItem>
				))} */}
				<ListItem className='gs-main__list-active' button key='Settings' component={NavLink} to='/settings'>
					<ListItemIcon>
						<SettingsIcon />
					</ListItemIcon>
					<ListItemText primary='Settings' />
				</ListItem>
				<a
					className='gs-main__list-active'
					key='Help'
					href='https://gsquared.atlassian.net/wiki/spaces/GT/pages/687276036/GERP'
					target='_blank'
					rel='noopener noreferrer'
					style={{ textDecoration: 'none', color: '#000' }}
				>
					<ListItem button>
						<ListItemIcon>
							<ContactSupportIcon />
						</ListItemIcon>
						<ListItemText primary='Help' />
					</ListItem>
				</a>
				<ListItem className='gs-main__list-active' button key='Archives' component={NavLink} to='/archive-jobs'>
					<ListItemIcon>
						<ArchiveIcon />
					</ListItemIcon>
					<ListItemText primary='Archives' />
				</ListItem>
			</List>
		</>
	);
};
