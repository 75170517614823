import { serverDomain, API } from './method';
import { userDataProps } from './props';

// Get APIs ==================================================================

export const getJobType = async () => {
	return await API('/api/admin/jobtype', 'getJobType', 'get', {});
};

export const getUnassignedJobs = async () => {
	return await API('/api/admin/pandaDoc/unassigned-jobs', 'getUnassignedJobs', 'get', {});
};

export const getTasks = async () => {
	return await API('/api/admin/asana/allTasks', 'getTasks', 'get', {});
};

export const getJobTask = async (id: any) => {
	return await API(`/api/admin/asana/task-in-project/${id}`, 'getJobTask', 'get', {});
};

export const getLogout = async () => {
	return await API('/api/admin/logout', 'getLogout', 'get', {});
};

export const getPOs = async (sort: string, order: string) => {
	return await API(`/api/admin/xero/pos-list?sort_by=${sort}&order_by=${order}`, 'getPOs', 'get', {});
};

export const getTimeEntries = async (taskId: any) => {
	return await API(`/api/admin/everhour/projects/${taskId}/tasks`, 'getTimeEntries', 'get', {});
};

// OLD
export const getInvoices = async () => {
	return await API('/api/admin/xero/invoices-list/drafts', 'getInvoices', 'get', {});
};

export const getDocument = async (jobId: any) => {
	return await API(`/api/admin/document/${jobId}`, 'getDocument', 'get', {});
};

export const getInvoiceJobSelection = async () => {
	return await API('/api/admin/cost/active-job-list', 'getInvoiceJobSelection', 'get', {});
};

export const getClients = async () => {
	return await API('/api/admin/client', 'getClients', 'get', {});
};

export const getArchiveJobs = async () => {
	return await API('/api/admin/archived-job', 'getArchiveJobs', 'get', {});
};

export const getNextJobNumber = async () => {
	return await API('/api/admin/job-last-number', 'getNextJobNumber', 'get', {});
};

export const getNextQuoteNumber = async () => {
	return await API('/api/admin/next-quote-number', 'getNextQuoteNumber', 'get', {});
};

export const getTeams = async () => {
	return await API('/api/admin/asana/teams', 'getTeams', 'get', {});
};

export const getProjectJobs = async () => {
	return await API('/api/admin/job-project', 'getProjectJobs', 'get', {});
};

export const getRetainerJobs = async () => {
	return await API('/api/admin/job-retainer', 'getRetainerJobs', 'get', {});
};

export const getDataCounter = async (id: any) => {
	return await API('/api/admin/counter ', 'getDataCounter', 'get', {});
};

// Post APIs ==================================================================
export const postAssignJob = async (data: any) => {
	return await API('/api/admin/pandaDoc/assigned-job', 'postAssignJob', 'post', data);
};

export const postTask = async (data: any) => {
	return await API('/api/admin/jobs/addTask', 'postTask', 'post', data);
};

export const postAsanaTask = async (data: any) => {
	return await API('/api/admin/asana/task', 'postAsanaTask', 'post', data);
};

export const postDocument = async (data: any) => {
	return await API('/api/admin/document', 'postDocument', 'post', data);
};

export const postDocumentDownload = async (data: any) => {
	return await API(`/api/admin/document/${data.id}/download`, 'postDocumentDownload', 'get', data);
};

export const postArchiveJob = async (data: any) => {
	return await API(`/api/admin/job-archived/${data.id}`, 'postArchiveJob', 'put', data.body);
};

export const postJob = async (data: any) => {
	return await API(`/api/admin/client/jobs/${data.client_id}`, 'postJob', 'post', data.job);
};

export const splitJob = async (data: any) => {
	return await API('/api/admin/pandaDoc/split-quote', 'postJob', 'post', data);
};

export const postBill = async (data: any) => {
	return await API(`/api/admin/job/project-billing/${data.id}/${data.ev_proj_id}`, 'postBill', 'post', data.data);
};

// Delete APIs ==================================================================
export const deleteTask = async (data: any) => {
	return await API('/api/admin/everhour/task-delete', 'deleteTask', 'delete', data);
};

export const deleteAsanaTask = async (data: any) => {
	return await API(`/api/admin/asana/task-delete/${data.task_id}`, 'deleteTask', 'delete', {});
};

export const deleteDocument = async (data: any) => {
	return await API(`/api/admin/document/${data.fileId}/delete`, 'deleteDocument', 'delete', data);
};

export const deleteCost = async (data: any) => {
	return await API(`/api/admin/destroy-cost/${data.id}/${data.cost_id}`, 'deleteCost', 'delete', data);
};

export const deleteInvoice = async (data: any) => {
	return await API(`/api/admin/destroy-invoice/${data.id}/${data.invoice_id}`, 'deleteInvoice', 'delete', data);
};

// Put APIs ==================================================================
export const putTask: any = async (data: any) => {
	return await API(`/api/admin/jobs/addTask/${data.job_id}`, 'putTask', 'put', data.formatPutTaskData);
};

export const putAsanaTask: any = async (data: any) => {
	return await API(`/api/admin/asana/task-update/${data.task_id}`, 'putAsanaTask', 'put', data);
};

export const putInvoice: any = async (data: any) => {
	return await API(`/api/admin/jobs/addInvoice/${data.id}`, 'putInvoice', 'put', { invoiceID: data.invoiceID });
};

export const putAssignPo: any = async (data: any) => {
	return await API(`/api/admin/jobs/addCost/${data.id}`, 'putAssignPo', 'put', data);
};

export const putRetainerWork: any = async (data: any) => {
	return await API(`/api/admin/jobs/update-retainer-type/${data.id}`, 'putRetainerWork', 'put', data?.job);
};

export const putJobDetails: any = async (data: any) => {
	return await API(`/api/admin/jobs/update-details/${data.id}`, 'putJobDetails', 'put', data.job);
};

// ====== AUTH / USER DATA ==================================================
export const login = async () => {
	window.location.href = `${serverDomain}/api/sso/login`;
	return;
};

export const getUserData = async () => {
	return await API('/api/admin/user-data', 'getUserData', 'get', {});
};

export const putUserData = async (data: userDataProps) => {
	return await API('/api/admin/update-user-data', 'putUserData', 'put', data);
};

// =================================================================
// Docs
// =================================================================
// OLD
export const putJobExisting = async (data: any) => {
	return await API(`/api/admin/jobs/${data.id}`, 'putJobExisting', 'put', data.job);
};

// NEW
export const putExistingJobProject = async (data: any) => {
	return await API(`/api/admin/jobs/assign-doc/${data.id}`, 'putExistingJobProject', 'put', data.job);
};

// NEW
export const postAssignDoc = async (data: any) => {
	return await API('/api/admin/pandaDoc/assigned-job', 'postAssignJob', 'post', data.job);
};
// NEW
export const getDocTypes = async () => {
	return await API('/api/admin/document-type', 'getDocTypes', 'get', {});
};

// =================================================================
// ACTIVE JOBS
// =================================================================
export const getActiveJobs = async () => {
	return await API('/api/admin/job', 'getActiveJobs', 'get', {});
};

export const getUniqueActiveJobs = async () => {
	return await API('/api/admin/job/active-list', 'getUniqueActiveJobs', 'get', {});
};

// NEW
export const getPaginatedActiveJobs = async (
	pageNumber: number,
	limit: number,
	teamId: string,
	clientName: string,
	jobTypeName: string,
	term: string,
	sort: string,
	order: string
) => {
	return await API(
		`/api/admin/job?page=${pageNumber}&limit=${limit}&teamId=${teamId}&clientName=${clientName}&jobTypeName=${jobTypeName}&term=${term}&sort_by=${sort}&order_by=${order}`,
		'getPaginatedActiveJobs',
		'get',
		{}
	);
};

export const getActiveJobNameList = async () => {
	return await API('/api/admin/active-job-list', 'getActiveJobNameList', 'get', {});
};

export const getActiveJob = async (id: number | null) => {
	return await API(`/api/admin/job/${id}`, 'getActiveJob', 'get', {});
};

export const getClientActiveJobs = async (jobId: any) => {
	return await API(`/api/admin/jobs/client/${jobId}`, 'getClientActiveJobs', 'get', { id: jobId });
};

export const getActiveJobAssigning = async () => {
	return await API('/api/admin/active-job-list', 'getActiveJobAssigning', 'get', {});
};

export const getActiveJobsByTeam = async (id: any) => {
	// return await API(`/api/admin/asana/teams/${id}/projects`, 'getActiveJobsByTeam', 'get', {});
	return await API(`/api/admin/job/${id}/teams`, 'getActiveJobsByTeam', 'get', {});
};

// NEW
export const putArchiveJob = async (data: any) => {
	return await API(`/api/admin/job-archived/${data.id}`, 'putArchivedJob', 'put', data);
};

// =================================================================
// CLIENTS
// =================================================================
export const getClientUniqueNamesList = async (data_source: string) => {
	return await API(
		`/api/admin/client/unique-name-list?data_source=${data_source}`,
		'getClientUniqueNameList',
		'get',
		{}
	);
};

export const getClientList = async (pageNumber: number, limit: number, term: string) => {
	return await API(`/api/admin/client?page=${pageNumber}&limit=${limit}&term=${term}`, 'getClientList', 'get', {});
};

export const postClient = async (data: any) => {
	return await API('/api/admin/clients', 'postClient', 'post', data);
};

// =================================================================
// JOB TYPES
// =================================================================
// NEW
export const getJobTypes = async () => {
	return await API('/api/admin/jobtype', 'getJobTypes', 'get', {});
};

// =================================================================
// BILLING
// =================================================================
// NEW
export const getBillingDetails = async (jobId: number) => {
	return await API(`/api/admin/job/project-billing/details/${jobId}`, 'getBillingDetails', 'get', {});
};

export const getBillingEmails = async (asana_id: string) => {
	return await API(`/api/admin/job/project-billing/users/${asana_id}`, 'getBillingEmails', 'get', {});
};

export const postUpdateBill = async (data: any) => {
	return await API(`/api/admin/job/project-billing/${data.id}/${data.ev_proj_id}`, 'postUpdateBill', 'post', data);
};

// =================================================================
// TASKS
// =================================================================

// NEW
export const getPaginatedTasks = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(`/api/admin/job-tasks/${jobId}?limit=${limit}&page=${pageNumber}`, 'getPaginatedTasks', 'get', {});
};

export const postNewAsanaTask = async (data: any) => {
	return await API('/api/admin/asana/task', 'postAsanaTask', 'post', data);
};

export const updateAsanaTask = async (data: any) => {
	return await API(`/api/admin/asana/task-update/${data.task_id}`, 'putAsanaTask', 'put', data);
};

// =================================================================
// ARCHIVES
// =================================================================
export const getArchivedJobs = async (pageNumber: number, limit: number, term: string) => {
	return await API(
		`/api/admin/archived-job?page=${pageNumber}&limit=${limit}&term=${term}`,
		'getArchiveJobs',
		'get',
		{}
	);
};
export const getArchivedInvoices = async (pageNumber: number, limit: number, term: string) => {
	return await API(
		`/api/admin/xero/invoices-list/archived?term=${term}&limit=${limit}&page=${pageNumber}`,
		'getArchiveInvoices',
		'get',
		{}
	);
};

// =================================================================
// ASSIGNEES
// =================================================================
// NEW
export const getAssignees = async () => {
	return await API('/api/admin/asana/org/users', 'getAssignees', 'get', {});
};

// =================================================================
// INVOICES
// =================================================================
// NEW
export const getPaginatedInvoices = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(`/api/admin/job-invoices/${jobId}?limit=${limit}&page=${pageNumber}`, 'getInvoices', 'get', {});
};
// NEW
export const getPaginatedUnassignedInvoices = async (
	term: string,
	limit: number,
	clientFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	return await API(
		`/api/admin/xero/invoices-list/drafts?term=${term}&limit=${limit}&page=${pageNumber}&sort_by=${sort}&order_by=${order}&clientName=${clientFilter}`,
		'getUnassignedInvoices',
		'get',
		{}
	);
};

// NEW
export const putAssignInvoice = async (data: any) => {
	return await API(`/api/admin/jobs/addInvoice/${data.id}`, 'putAssignInvoice', 'put', data);
};
// NEW
export const postArchiveInvoice = async (data: any) => {
	return await API(`/api/admin/xero/archive-invoice/${data.id}`, 'postArchiveInvoice', 'post', data);
};

// =================================================================
// COSTS
// =================================================================
// NEW
export const getPaginatedCosts = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(`/api/admin/job-costs/${jobId}?limit=${limit}&page=${pageNumber}`, 'getCosts', 'get', {});
};

// NEW
export const postAddCost = async (data: any) => {
	return await API(`/api/admin/jobs/addCost/${data.id}`, 'postNewCost', 'post', data);
};

// NEW
export const putUpdateCost = async (data: any) => {
	return await API(`/api/admin/jobs/updateCost/${data.id}`, 'putUpdateCost', 'put', data);
};

// NEW
export const getSuppliers = async (term: string) => {
	return await API(`/api/admin/xero/contacts?term=${term}`, 'getSuppliers', 'get', {});
};

// NEW
export const postNewSupplier = async (data: any) => {
	return await API('/api/admin/xero/create-contact', 'postNewSupplier', 'post', data);
};

// =================================================================
// QUOTES
// =================================================================
// NEW
export const getPaginatedQuotes = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(`/api/admin/job-quotes/${jobId}?limit=${limit}&page=${pageNumber}`, 'getQuotes', 'get', {});
};

// =================================================================
// TIME ENTRIES
// =================================================================
// NEW
export const getPaginatedTimeEntries = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(
		`/api/admin/job-time-entries/${jobId}?limit=${limit}&page=${pageNumber}`,
		'getTimeEntries',
		'get',
		{}
	);
};

// =================================================================
// UNASSIGNED DOCS
// =================================================================
// NEW
export const getPaginatedUnassignedDocs = async (
	pageNumber: number,
	limit: number,
	clientName: string,
	term: string,
	sort: string,
	order: string
) => {
	return await API(
		`/api/admin/pandaDoc/unassigned-jobs?page=${pageNumber}&limit=${limit}&term=${term}&sort_by=${sort}&order_by=${order}&clientName=${clientName}`,
		'getPaginatedUnassignedDocs',
		'get',
		{}
	);
};

// NEW
export const getUnassignedDocSubtasks = async (
	parent_doc_id: string,
	pageNumber: number,
	limit: number,
	term: string
) => {
	return await API(
		`/api/admin/pandaDoc/job-sub-tasks?parent_doc_id=${parent_doc_id}&page=1&limit=30&term=${term}`,
		'getUnassignedDocSubtasks',
		'get',
		{}
	);
};

// =================================================================
// ATTACHMENTS
// =================================================================
// NEW
export const getPaginatedAttachments = async (pageNumber: number, limit: number, jobId: number) => {
	return await API(
		`/api/admin/job-attachments/${jobId}?limit=${limit}&page=${pageNumber}`,
		'getTimeEntries',
		'get',
		{}
	);
};

// =================================================================
// EVERHOUR
// =================================================================
// Billing Types
export const getBillingTypes = async () => {
	return await API('/api/admin/everhour/billing-types', 'getBillingTypes', 'get', {});
};
// Budget Types
export const getBudgetTypes = async ({ queryKey }: any) => {
	const [budget_types, billing_type_id] = queryKey;
	return await API(
		`/api/admin/everhour/billing-budget-types?billing_type_id=${billing_type_id}`,
		'getBudgetTypes',
		'get',
		{}
	);
};

// =================================================================
// ASANA BOARDS
// =================================================================
// NEW
export const getPaginatedAsanaBoards = async (
	term: string,
	limit: number,
	teamFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	const teamId = teamFilter ? parseInt(teamFilter) : '';

	return await API(
		`/api/admin/asana/unassigned-jobs?page=${pageNumber}&limit=${limit}&teamId=${teamId}&term=${term}&sort_by=${sort}&order_by=${order}
		)}`,
		'getPaginatedAsanaBoards',
		'get',
		{}
	);
};

// =================================================================
// NOTIFICATIONS
// =================================================================
// NEW
export const getInAppNotifications = async () => {
	return await API('/api/admin/user-inapp-notifications', 'getInAppNotifications', 'get', {});
};
// NEW
export const clearInAppNotifications = async () => {
	return await API('/api/admin/clear-inapp-notifications', 'clearInAppNotifications', 'put', {});
};

// =================================================================
// OTHER DOCS
// =================================================================
// NEW
export const postAssignAsOthers = async (data: any) => {
	return await API('/api/admin/pandaDoc/assign-as-others', 'postAssignAsOthers', 'post', data);
};

export const getPaginatedOtherDocuments = async (
	term: string,
	limit: number,
	clientFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	return await API(
		`/api/admin/pandaDoc/other-documents?page=${pageNumber}&limit=${limit}&clientName=${clientFilter}&term=${term}&sort_by=${sort}&order_by=${order}`,
		'postAssignAsOthers',
		'get',
		{}
	);
};

// =================================================================
// MILESTONES
// =================================================================
// NEW
export const getPaginatedMilestones = async (id: string, limit: number, pageNumber: number) => {
	return await API(
		`/api/admin/job-invoice-milestones/${id}?limit=${limit}&page=${pageNumber}`,
		'getPaginatedMilestones',
		'get',
		{}
	);
};

// =================================================================
// CUSTOMERS
// =================================================================
// NEW

export const getCustomerUniqueNamesList = async () => {
	return await API('/api/admin/xero/po-unique-customers', 'getCustomerUniqueNamesList', 'get', {});
};

// =================================================================
// EXPORT REPORT
// =================================================================
// NEW
export const exportExcelReport = async (data: any) => {
	const jsonData = JSON.stringify(data);
	return await API(`/api/admin/reports/export-excel?report_filters=${jsonData}`, 'exportReportExcel', 'get', data);
};

export const exportCsvReport = async (data: any) => {
	const jsonData = JSON.stringify(data);
	return await API(`/api/admin/reports/export-csv?report_filters=${jsonData}`, 'exportReportCSV', 'get', data);
};

export const downloadCsvReport = async (data: any) => {
	return await API(`/api/admin/reports/download?filename=${data.filename}`, 'downloadReportCSV', 'get', data);
};
