import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Box,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	Button,
} from '@mui/material';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { SearchBox } from '../../components';
import { useGetClientList } from '../../services/api';
import { AddClient } from './AddClient/AddClient';
import { ClientRowProps } from './Clients.props';
import { toClientsTableData } from './Clients.transform';
import { CLIENTS_COLUMNS } from './Clients.columns';
import { useClientStyles } from './Clients.styles';

export const Clients = () => {
	const classes = useClientStyles();
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [openAddClient, setOpenAddClient] = useState(false);

	// QUERIES
	const {
		data: clientList,
		isLoading: isLoadingClientList,
		isError: isErrorClientList,
		isFetching: isFetchingClientList,
	} = useGetClientList(pageNumber + 1, limit, searchTerm);

	// RENDER LOADING & ERROR
	if (isLoadingClientList) {
		return <p>Loading...</p>;
	}

	if (isErrorClientList) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = toClientsTableData(clientList?.result.rows);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 0);
	};

	const goToActiveJobsPage = (clientName: string) => {
		navigate('/active-jobs', { state: { client: clientName } });
	};

	const openAddClientHandler = () => {
		setOpenAddClient(true);
	};

	const closeAddClientHandler = () => {
		setOpenAddClient(false);
	};

	return (
		<Box className={classes.container}>
			<Typography mb={6} variant='h4'>
				Clients
			</Typography>

			<Box className={classes.filtersContainer}>
				<SearchBox onSearch={searchStringHandler} />
			</Box>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={CLIENTS_COLUMNS}>
							{/* <TableCell>
								<Button className={classes.addClientBtn} onClick={openAddClientHandler}>
									Add Client
								</Button>
							</TableCell> */}
						</TableHeaderComponent>
						<TableBody sx={{ position: 'relative' }}>
							{searchTerm && isFetchingClientList && (
								<TableRow className={classes.loaderContainer}>
									<TableCell colSpan={6} align='center'>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{tableData.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={9} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{!isLoadingClientList &&
								tableData?.map((row: ClientRowProps, index: number) => (
									<TableRow
										key={row.id}
										onClick={() => goToActiveJobsPage(row.name)}
										style={{ cursor: 'pointer' }}
									>
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.contact_name}</TableCell>
										<TableCell>{row.email}</TableCell>
										<TableCell>{row.mobile_num}</TableCell>
										<TableCell colSpan={2}>{row.address}</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={clientList.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>

			<AddClient
				pageNumber={pageNumber}
				limit={limit}
				closeAddClientHandler={closeAddClientHandler}
				openAddClient={openAddClient}
			/>
		</Box>
	);
};
