import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';

import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetPaginatedQuotes } from '../../../../services/api';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { formatDateYearMonthDay } from '../../../../utils/formatDate';
import { InvoiceProps } from './Quotes.props';
import { QUOTES_COLUMNS } from './Quotes.columns';
import { useQuotesStyles } from './Quotes.styles';

export const Quotes = () => {
	const classes = useQuotesStyles();
	const { id = 0 } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: quotesData,
		isError: isErrorQuotes,
		isLoading: isLoadingQuotes,
		isFetching: isFetchingQuotes,
	} = useGetPaginatedQuotes(pageNumber + 1, limit, Number(id));

	// RENDER LOADING & ERROR
	if (isLoadingQuotes) {
		return <p>Loading...</p>;
	}

	if (isErrorQuotes) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const quotes = quotesData.message === 'Success' ? quotesData?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={QUOTES_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{quotes.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{quotes.map((quote: InvoiceProps) => (
						<TableRow key={quote.id}>
							<TableCell>{formatDateYearMonthDay(quote.date)}</TableCell>
							<TableCell>{quote.number}</TableCell>
							<TableCell>{quote.type}</TableCell>
							<TableCell>{formatCurrency(quote.costs)}</TableCell>
							<TableCell>{formatCurrency(quote.fees)}</TableCell>
							<TableCell>{quote.status}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={quotesData.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
