import { useState } from 'react';
import NumberFormat from 'react-number-format';
import {
	Typography,
	Box,
	TextField,
	MenuItem,
	FormControl,
	InputLabel,
	Input,
	InputAdornment,
	IconButton,
	FormGroup,
	FormControlLabel,
	Checkbox,
	LinearProgress,
	Popover,
	Select,
} from '@mui/material';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
// import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Modal } from '../../components';
import { handleCurrency } from '../../utils';

export const Billing = ({
	modalPropsBilling,
	toggleBillingTask,
	handleBillData,
	addBill,
	billings,
	budgetsOptions,
	billAddLoading,
}: any) => {
	const numberWithCommas = (x: any) => {
		return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [billingSettings, setBillingSettings] = useState({
		overBudget: false,
		resetBudget: false,
		resetBudgetCycle: 'month',
		showBudget: false,
		sendEmail: false,
		email: 'stationfive@gmail.com',
		threshold: '75',
	});

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<Modal {...modalPropsBilling}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleBillingTask}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			{/* MODAL TITLE ===== */}
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Add billing
				{billAddLoading ? <span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span> : ''}
				{billAddLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>

			{/* MODAL BODY ===== */}
			<Box sx={{ marginBottom: '40px' }}>
				{/* Form Wrapper */}
				<Box
					component='form'
					sx={{
						'& .MuiTextField-root': { m: 1, width: '25ch' },
					}}
					style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
					noValidate
					autoComplete='off'
				>
					{/* Billing Field */}
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginBottom: '24px',
							width: '100%',
						}}
					>
						<TextField
							style={{
								marginLeft: '0px',
								width: `${addBill.billingOption === '1' ? '100%' : '60%'}`,
							}}
							id='standard-select-currency'
							select
							label='Billing'
							value={addBill.billingOption}
							onChange={(event) => handleBillData(event, 'billingOption')}
							helperText='Please select your billing'
							variant='standard'
						>
							{billings.map((option: any) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<FormControl
							style={{
								width: '40%',
								display: `${addBill.billingOption === '1' ? 'none' : 'block'}`,
							}}
							sx={{ m: 1 }}
							variant='standard'
						>
							<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
							<NumberFormat
								thousandsGroupStyle='thousand'
								onValueChange={(values) => {
									handleBillData(values, 'bill');
								}}
								value={addBill.billValue}
								// prefix='none'
								error={addBill.billingOption !== '1' && !addBill.billValueDisplay ? true : false}
								decimalSeparator='.'
								customInput={Input}
								displayType='input'
								type='text'
								id='standard-basic'
								startAdornment={<InputAdornment position='start'>$</InputAdornment>}
								endAdornment={
									addBill.billingOption !== '4' ? (
										<InputAdornment position='end'>/hour</InputAdornment>
									) : (
										''
									)
								}
								thousandSeparator={true}
								allowNegative={false}
							/>
						</FormControl>
					</div>

					{/* Billing Field */}
					<div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<TextField
							style={{
								marginLeft: '0px',
								width: `${addBill.budgetOption === '1' ? '100%' : '60%'}`,
							}}
							id='standard-select-currency'
							select
							label='Budget'
							value={addBill.budgetOption}
							onChange={(event) => handleBillData(event, 'budgetOption')}
							helperText='Please select your budget'
							variant='standard'
						>
							{budgetsOptions[addBill.billingOption].map((option: any) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>
						<FormControl
							style={{
								width: '40%',
								display: `${addBill.budgetOption === '1' ? 'none' : 'flex'}`,
							}}
							sx={{ m: 1 }}
							variant='standard'
						>
							<InputLabel htmlFor='standard-adornment-amount'></InputLabel>
							<NumberFormat
								thousandsGroupStyle='thousand'
								onValueChange={(values) => {
									handleBillData(values, 'budget');
								}}
								value={addBill.budgetValue}
								// prefix=''
								error={addBill.budgetOption !== '1' && !addBill.budgetValueDisplay ? true : false}
								decimalSeparator='.'
								customInput={Input}
								displayType='input'
								type='text'
								id='standard-basic'
								startAdornment={
									(addBill.billingOption === '1' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '2' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '2' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '5') ? (
										<InputAdornment position='start'>$</InputAdornment>
									) : (
										''
									)
								}
								endAdornment={
									(addBill.billingOption === '1' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '2' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '5') ? (
										<InputAdornment position='end'>
											{/* <IconButton
											aria-label='toggle password visibility'
											// onClick={handleClickShowPassword}
											// onMouseDown={handleMouseDownPassword}
										> */}
											{(addBill.billingOption === '4' && addBill.budgetOption === '3') ||
											(addBill.billingOption === '4' && addBill.budgetOption === '4') ||
											(addBill.billingOption === '4' && addBill.budgetOption === '5')
												? '/hour'
												: // <DeleteSweepIcon />
												  ''}
											{/* </IconButton> */}
										</InputAdornment>
									) : (
										''
									)
								}
								thousandSeparator={
									(addBill.billingOption === '1' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '2' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '2')
										? false
										: true
								}
								allowNegative={false}
							/>
						</FormControl>
					</div>

					{/* Please Login note... */}
					<div
						style={{
							fontStyle: 'italic',
							color: 'red',
							width: '100%',
							marginTop: '5px',
							display: `${addBill.budgetOption === '1' ? 'none' : 'block'}`,
						}}
					>
						Please login to Everhour to adjust these settings.
					</div>

					{/* Checkboxes */}
					<FormGroup
						style={{
							marginTop: '15px',
							width: '100%',
							display: `${addBill.budgetOption === '1' ? 'none' : 'flex'}`,
						}}
					>
						<FormControlLabel
							control={
								<Checkbox
									checked={addBill.disallowOverBudget}
									onChange={(event) => handleBillData(event, 'disallowOverBudget')}
									color='default'
								/>
							}
							label='Disallow over-budget'
						/>
						<FormControlLabel
							style={{
								display: `${
									(addBill.billingOption === '1' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '2' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '4') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '5')
										? 'block'
										: 'none'
								}`,
							}}
							control={
								<Checkbox
									checked={addBill.excludeCostExpenses}
									onChange={(event) => handleBillData(event, 'excludeCostExpenses')}
									color='default'
								/>
							}
							label='Exclude cost expenses'
						/>
						<FormControlLabel
							style={{
								display: `${
									(addBill.billingOption === '2' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '2') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '4')
										? 'block'
										: 'none'
								}`,
							}}
							control={
								<Checkbox
									checked={addBill.excludeBillableExpenses}
									onChange={(event) => handleBillData(event, 'excludeBillableExpenses')}
									color='default'
								/>
							}
							label='Exclude billable expenses'
						/>
						<FormControlLabel
							style={{
								display: `${
									(addBill.billingOption === '2' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '3' && addBill.budgetOption === '3') ||
									(addBill.billingOption === '4' && addBill.budgetOption === '2')
										? 'block'
										: 'none'
								}`,
							}}
							control={
								<Checkbox
									checked={addBill.excludeNonBillableTime}
									onChange={(event) => handleBillData(event, 'excludeNonBillableTime')}
									color='default'
								/>
							}
							label='Exclude non-billable time'
						/>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={addBill.resetBudget}
										onChange={(event) => handleBillData(event, 'resetBudget')}
										color='default'
									/>
								}
								// label='Reset budget every *select*'
								label={<span>Reset budget every </span>}
							/>

							<FormControl variant='standard' sx={{ minWidth: 120 }}>
								<Select
									value={billingSettings.resetBudgetCycle}
									onChange={(e) =>
										setBillingSettings({ ...billingSettings, resetBudgetCycle: e.target.value })
									}
									displayEmpty
									inputProps={{ 'aria-label': 'Without label' }}
								>
									<MenuItem value='day'>day</MenuItem>
									<MenuItem value='week'>week</MenuItem>
									<MenuItem value='month'>month</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<FormControlLabel
							style={{
								display: `${
									addBill.billingOption === '4' &&
									addBill.budgetOption !== '3' &&
									addBill.budgetOption !== '4' &&
									addBill.budgetOption !== '5'
										? 'block'
										: 'none'
								}`,
							}}
							control={
								<Checkbox
									checked={addBill.startBudget}
									onChange={(event) => handleBillData(event, 'startBudget')}
									color='default'
								/>
							}
							// label='Start budget from *select*'
							label={
								<span>
									Start budget from{' '}
									<span style={{ borderBottom: '1px dashed grey', color: 'grey' }}>select</span>
								</span>
							}
						/>
						{/* <div style={{ display: 'flex' }}>
									<Checkbox
										style={{ paddingLeft: '0px', marginLeft: '-2px' }}
										checked={addBill.startBudget}
										onChange={(event) => handleBillData(event, 'startBudget')}
										color='default'
									/>
									<span style={{ display: 'flex', alignItems: 'center' }}>
										Start budget from{' '}
										<span
											style={{
												display: 'flex',
												cursor: 'pointer',
												borderBottom: '1px dashed grey',
												color: 'grey',
												marginLeft: '7px',
											}}
											aria-describedby={id}
											onClick={handleClick}
										>
											select
											<KeyboardArrowDownIcon />
											<KeyboardArrowUpIcon />
										</span>
									</span>
									<Popover
										id={id}
										open={open}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',	
										}}
									>
										<Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
									</Popover>
								</div> */}

						<FormControlLabel
							control={
								<Checkbox
									checked={addBill.showBudget}
									onChange={(event) => handleBillData(event, 'showBudget')}
									color='default'
								/>
							}
							label='Show budget to all project members'
						/>

						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<FormControlLabel
								control={
									<Checkbox
										checked={addBill.emailThreshold}
										onChange={(event) => handleBillData(event, 'emailThreshold')}
										color='default'
									/>
								}
								// label={
								// 	<span>
								// 		Email{' '}
								// 		<span style={{ borderBottom: '1px dashed grey', color: 'grey' }}>just me</span>{' '}
								// 		about <span style={{ borderBottom: '1px dashed grey', color: 'grey' }}>75</span>{' '}
								// 		threshold
								// 	</span>
								// }
								label='Email'
							/>
							<TextField
								id='email'
								defaultValue={billingSettings.email}
								size='small'
								variant='standard'
								style={{ width: '120px', marginLeft: '-4px', marginRight: '16px' }}
							/>
							<span>about</span>
							<FormControl
								variant='standard'
								sx={{ minWidth: 50, display: 'flex', marginLeft: '16px', justifyContent: 'center' }}
							>
								<Select
									value={billingSettings.threshold}
									onChange={(e) =>
										setBillingSettings({ ...billingSettings, threshold: e.target.value })
									}
								>
									<MenuItem value='75'>75</MenuItem>
									<MenuItem value='80'>80</MenuItem>
									<MenuItem value='85'>85</MenuItem>
								</Select>
							</FormControl>
							<span>threshold</span>
						</Box>
					</FormGroup>
				</Box>
			</Box>
		</Modal>
	);
};
