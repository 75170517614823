import { useEffect, useState } from 'react';
import { Typography, Box, TextField, InputAdornment, FormControl, Select, MenuItem } from '@mui/material';
import { Close, Add } from '@mui/icons-material';
import { MilestoneProps, InvoiceMilestonesProps } from './InvoiceMilestones.props';
import { useInvoiceMilestonesStyles } from './InvoiceMilestones.styles';
import { toast } from 'react-toastify';
import { COLORS } from '../../constants/theme';

const InvoiceMilestones = (props: InvoiceMilestonesProps) => {
	const classes = useInvoiceMilestonesStyles();
	const { setMilestoneLimitHandler, setMilestoneValueHandler, milestones } = props;
	const [total, setTotal] = useState(0);

	useEffect(() => {
		totalMilestonePercent(milestones);
	}, [milestones]);

	// TOTAL PERCENT
	const totalMilestonePercent = (milestonesArr: MilestoneProps[]) => {
		const totalPercent = milestonesArr.reduce(
			(previousValue: number, currentValue: MilestoneProps) => previousValue + parseInt(currentValue.percent),
			0
		);

		if (totalPercent > 100) {
			setMilestoneLimitHandler(true);
		} else {
			setMilestoneLimitHandler(false);
		}

		setTotal(totalPercent);
		return totalPercent;
	};

	// UPDATE
	const changeMilestoneHandler = (percent: string, date: string, note: string, status: string, index: number) => {
		const formattedPercent = percent ? parseInt(percent).toFixed(0) : '0';
		const updatedValue = { percent: formattedPercent, date: date, note: note, status: status };
		const updatedMilestones = milestones.map((milestone: MilestoneProps, i: number) =>
			i === index ? updatedValue : milestone
		);

		// Compute total milestone
		const totalMileStones = totalMilestonePercent(updatedMilestones);
		if (totalMileStones > 100) {
			toast.info('Milestone should not be greater than 100 percent');
		}

		// setMilestones(updatedMilestones);
		setMilestoneValueHandler(updatedMilestones);
		setTotal(totalMileStones);
	};

	// REMOVE
	const removeMilestoneHandler = (index: number) => {
		const updatedMilestones = milestones.filter((milestone, i) => i !== index);
		const totalMileStones = totalMilestonePercent(updatedMilestones);

		// setMilestones(updatedMilestones);
		setMilestoneValueHandler(updatedMilestones);
		setTotal(totalMileStones);
	};

	// ADD
	const addMilestoneHandler = () => {
		const newMilestone = { percent: '0', date: '', note: '', status: 'PENDING' };
		const updatedMilestones = [...milestones, newMilestone];

		// setMilestones(updatedMilestones);
		setMilestoneValueHandler(updatedMilestones);
	};

	// PERCENT LIST
	const renderPercent = (milestonesArr: MilestoneProps[]) => {
		return milestonesArr.length > 0
			? milestonesArr.map((milestone, index) => (
					<Box component='span' className={classes.percent} key={index}>
						{milestone.percent}
					</Box>
			  ))
			: 0;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				minWidth: '672px',
				justifyContent: 'space-between',
				marginTop: '1rem',
			}}
		>
			<Box sx={{ width: '55%' }}>
				<Typography>Invoice Milestones:</Typography>
				<Box sx={{ backgroundColor: '#fbfbcf', padding: '16px', marginTop: '1rem', marginRight: '2rem' }}>
					<Typography sx={{ fontSize: '14px' }}>
						Kindly add the end date to each milestone to get the reminders working.
					</Typography>
				</Box>
			</Box>

			<Box
				sx={{
					width: '100%',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						justifyContent: 'space-between',
					}}
				>
					<Typography sx={{ color: total > 100 ? COLORS.error.default : 'unset' }}>
						{renderPercent(milestones)} = {total}%
					</Typography>
					<Add onClick={addMilestoneHandler} sx={{ cursor: 'pointer' }} />
				</Box>
				<Box component='ul' sx={{ listStyle: 'none', paddingLeft: '0' }}>
					{milestones.map((milestone: MilestoneProps, index: number) => (
						<Box key={index} component='li' sx={{ display: 'flex', gap: '2rem', alignItems: 'flex-end' }}>
							<TextField
								id='standard-basic'
								variant='standard'
								type='number'
								value={milestone.percent}
								size='small'
								sx={{ marginTop: '16px', minWidth: '10%' }}
								onChange={(e) =>
									changeMilestoneHandler(
										e.target.value,
										milestone.date,
										milestone.note,
										milestone.status,
										index
									)
								}
								InputProps={{
									endAdornment: <InputAdornment position='end'>%</InputAdornment>,
								}}
							/>
							<TextField
								id='standard-basic'
								variant='standard'
								type='date'
								value={milestone.date}
								size='small'
								sx={{ marginTop: '16px', minWidth: '25%' }}
								onChange={(e) =>
									changeMilestoneHandler(
										milestone.percent,
										e.target.value,
										milestone.note,
										milestone.status,
										index
									)
								}
							/>

							<TextField
								fullWidth
								id='standard-basic'
								variant='standard'
								placeholder='Note'
								value={milestone.note}
								size='small'
								sx={{ marginTop: '16px' }}
								onChange={(e) =>
									changeMilestoneHandler(
										milestone.percent,
										milestone.date,
										e.target.value,
										milestone.status,
										index
									)
								}
							/>

							<FormControl variant='standard' sx={{ minWidth: 100 }}>
								<Select
									id='status'
									value={milestone.status}
									label='Age'
									onChange={(e) =>
										changeMilestoneHandler(
											milestone.percent,
											milestone.date,
											milestone.note,
											e.target.value,
											index
										)
									}
								>
									<MenuItem value='PENDING'>Pending</MenuItem>
									<MenuItem value='COMPLETED'>Completed</MenuItem>
								</Select>
							</FormControl>
							<Close onClick={() => removeMilestoneHandler(index)} sx={{ cursor: 'pointer' }} />
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
};

export default InvoiceMilestones;
