import NumberFormat from 'react-number-format';
import { Typography, Box, TextField, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Autocomplete } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Modal } from '../../../components';

export const TaskCreate = ({
	modalPropsAddTask,
	toggleCreateTask,
	handleAddTaskData,
	addTaskValidation,
	addTask,
	defaultPropsAssignees,
	selectedAssignee,
	addTaskLoading,
}: any) => {
	return (
		<Modal {...modalPropsAddTask}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleCreateTask}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Create New Task
				{addTaskLoading ? <span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span> : ''}
				{addTaskLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Box
					className='gs-pagetwo__row-box gs__inline-field'
					style={{ height: '32px', marginTop: '0px', marginBottom: '15px' }}
				>
					<Typography className='gs__inline-field__title'>Assignee:</Typography>
					<Box>
						<Autocomplete
							className='gs__inline-field__container'
							{...defaultPropsAssignees}
							id='clear-on-escape'
							clearOnEscape
							value={selectedAssignee}
							onChange={(event, newValue) => {
								handleAddTaskData(newValue, 'assignee');
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label='Select teams'
									error={!addTaskValidation.assignee ? true : false}
									variant='standard'
								/>
							)}
						/>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Task:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleAddTaskData(event, 'task')}
							defaultValue={addTask.task}
							id='standard-basic'
							variant='standard'
							error={!addTaskValidation.task ? true : false}
							placeholder='*Please enter task name'
						/>
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Description:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleAddTaskData(event, 'description')}
							defaultValue={addTask.description}
							id='standard-basic'
							variant='standard'
							error={!addTaskValidation.description ? true : false}
							placeholder='*Please enter description'
						/>
					</Box>
				</Box>
				{/* <Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Fee:</Typography>
					<Box className='gs__inline-field__container'>
						<NumberFormat
							thousandsGroupStyle='thousand'
							onValueChange={(values) => handleAddTaskData(values, 'fee')}
							value={addTask.fee}
							prefix='$ '
							decimalSeparator='.'
							customInput={TextField}
							displayType='input'
							type='text'
							id='standard-basic'
							variant='standard'
							thousandSeparator={true}
							allowNegative={true}
							error={!addTaskValidation.fee ? true : false}
							placeholder='*Please enter fee'
						/>
					</Box>
				</Box> */}
				{/* <Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Assignee:</Typography>
					<Box className='gs__inline-field__container'>
						<TextField
							onChange={(event) => handleAddTaskData(event, 'assignee')}
							defaultValue={addTask.assignee}
							id='standard-basic'
							variant='standard'
							error={!addTaskValidation.assignee ? true : false}
							placeholder='*Please enter assignee'
						/>
					</Box>
				</Box> */}
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title'>Due on:</Typography>
					<Box className='gs__inline-field__container'>
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<MobileDatePicker
								className='gs__date-picker-container'
								value={addTask.dueOn}
								onChange={(newValue: any) => {
									handleAddTaskData(newValue, 'dueOn');
								}}
								renderInput={(params: any) => <TextField className={'gs__date-picker'} {...params} />}
							/>
						{/* </LocalizationProvider> */}
					</Box>
				</Box>
				<Box style={{ display: 'flex' }} className='gs__inline-field'>
					<Typography className='gs__inline-field__title' style={{ minWidth: '125px', marginRight: '0px' }}>
						Estimated time:
					</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<TextField
							type='number'
							// className='gs-activeJobs__basic-input'
							style={{ width: '80px' }}
							id='standard-basic'
							variant='standard'
							inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
							onChange={(event: any) =>
								handleAddTaskData(event, 'estimatedTimeHours')
							}
							defaultValue={addTask.estimatedTimeHours}
							value={addTask.estimatedTimeHours}
							placeholder='*Hours'
							error={!addTask.estimatedTimeHours ? true : false}
						/>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};
