import { ACTIONS } from './constants';
import { InitialState } from './states';

export interface Actions {
	type: string;
	payload: any;
}

export const reducer = (state: InitialState, action: Actions) => {
	let item = action.payload;
	let items = { ...state.items };

	switch (action.type) {
		case ACTIONS.ADD_ITEM:
			if (items[item.name]) {
				items[item.name].push(item);
			} else {
				items[item.name] = [item];
			}

			return { ...state, items };

		case ACTIONS.ADD_UNASSIGN_JOBS:
			return { ...state, unassignJobs: item };

		case ACTIONS.ADD_TOKEN:
			return { ...state, token: item };

		case ACTIONS.ADD_TASKS:
			return { ...state, tasks: item };

		case ACTIONS.SET_ACTIVE_JOBS:
			return { ...state, activeJobs: item };

		case ACTIONS.SET_UNASSIGNED_JOBS_TICKER:
			return { ...state, unassignedJobTicker: item };

		case ACTIONS.SET_LOADING_ACTIVE_JOBS:
			return { ...state, loadingActiveJobs: item };

		case ACTIONS.SET_LOADING_UNASSIGN_JOBS:
			return { ...state, loadingUnassignJobs: item };

		case ACTIONS.SET_JOB_NUMBER_HELPER:
			return { ...state, jobNumberHelper: item };

		case ACTIONS.SET_USER_NAME:
			return { ...state, userName: item };

		case ACTIONS.SET_POS:
			return { ...state, pos: item };

		case ACTIONS.SET_POS_TICKER:
			return { ...state, posTicker: item };

		case ACTIONS.SET_POS_LOADING:
			return { ...state, posLoading: item };

		case ACTIONS.SET_POS_ASSIGN_LOADING:
			return { ...state, posAssignLoading: item };

		case ACTIONS.SET_TIME_ENTRIES:
			return { ...state, timeEntries: item };

		case ACTIONS.SET_TIME_ENTRIES_LOADING:
			return { ...state, timeEntriesLoading: item };

		case ACTIONS.SET_INVOICES:
			return { ...state, invoices: item };

		case ACTIONS.SET_INVOICES_LOADING:
			return { ...state, invoicesLoading: item };

		case ACTIONS.SET_INVOICES_SELECTION:
			return { ...state, invoicesSelection: item };

		case ACTIONS.SET_INVOICES_SELECTION_LOADING:
			return { ...state, invoicesSelectionLoading: item };

		case ACTIONS.SET_INVOICE_ASSIGN_LOADING:
			return { ...state, invoiceAssignLoading: item };

		case ACTIONS.SET_INVOICE_JOB_SELECTION:
			return { ...state, invoiceJobSelection: item };

		case ACTIONS.SET_CLIENTS:
			return { ...state, clients: item };

		case ACTIONS.SET_CLIENTS_LOADING:
			return { ...state, clientsLoading: item };

		case ACTIONS.SET_ARCHIVE_JOBS:
			return { ...state, archiveJobs: item };

		case ACTIONS.SET_ARCHIVE_JOBS__LOADING:
			return { ...state, archiveJobsLoading: item };

		case ACTIONS.SET_NEXT_JOB_NUMBER:
			return { ...state, nextJobNumber: item };

		case ACTIONS.SET_NEXT_QUOTE_NUMBER:
			return { ...state, nextQuoteNumber: item };

		case ACTIONS.SET_TEAMS:
			return { ...state, teams: item };

		case ACTIONS.SET_ACTIVE_JOB_ASSIGNING:
			return { ...state, setActiveJobAssigning: item };

		case ACTIONS.SET_ASSIGNEES:
			return { ...state, assignees: item };

		case ACTIONS.SET_PROJECT_JOBS:
			return { ...state, projectJobs: item };

		case ACTIONS.SET_RETAINER_JOBS:
			return { ...state, retainerJobs: item };

		case ACTIONS.SET_ACTIVE_JOB:
			return { ...state, activeJob: item };

		case ACTIONS.REMOVE_ITEM:
			items[item.name].pop();

			if (items[item.name].length === 0) delete items[item.name];

			return { ...state, items };

		default:
			return state;
	}
};
