import { Typography, LinearProgress, Box, Autocomplete, Checkbox, TextField } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Modal } from '../../../components';

const top100Films = [
	{ title: 'Web', id: 1, indexN: 0 },
	{ title: 'SEO', id: 2, indexN: 1 },
	{ title: 'Content', id: 3, indexN: 2 },
	{ title: 'Paid Media', id: 4, indexN: 3 },
	{ title: 'Email Marketing', id: 5, indexN: 4 },
	{ title: 'Community Management', id: 6, indexN: 5 },
];

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const UpdateWork = ({
	modalPropsUpdateWork,
	toggleUpdateWork,
	retainerWork,
	setRetainerWork,
	retainerUpdateLoading,
}: any) => {
	const checkList = retainerWork.map((value: any) => {
		return top100Films[value.indexN];
	});

	return (
		<Modal {...modalPropsUpdateWork}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					style={{ display: retainerUpdateLoading ? 'none' : 'block' }}
					className='add-job-scope__close-btn'
					onClick={toggleUpdateWork}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Update retainer work
				{retainerUpdateLoading ? (
					<span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span>
				) : (
					''
				)}
				{retainerUpdateLoading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Box style={{ minHeight: '32px', display: 'flex' }} className='gs-pagetwo__row-box'>
					<Typography style={{ width: '38%' }}>Retainer job type:</Typography>
					<Box style={{ display: 'flex', alignItems: 'center' }}>
						<Autocomplete
							multiple
							id='size-small-standard-multi'
							size='small'
							options={top100Films}
							disableCloseOnSelect
							getOptionLabel={(option: any) => option.title}
							// defaultValue={addJob.retainerWork}
							defaultValue={checkList}
							onChange={(event, newValue) => {
								setRetainerWork(newValue);
							}}
							// retainerType
							renderOption={(props, option: any, { selected }) => (
								<li {...props}>
									<Checkbox
										icon={icon}
										checkedIcon={checkedIcon}
										style={{ marginRight: 8 }}
										checked={selected}
									/>
									{option.title}
								</li>
							)}
							style={{ width: '100%' }}
							renderInput={(params) => (
								<TextField
									{...params}
									variant='standard'
									// label='*Select retainer job type'
									placeholder='Select retainer job type'
								/>
							)}
						/>
					</Box>
				</Box>
			</div>
		</Modal>
	);
};
