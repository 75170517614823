export const UNASSIGNED_INVOICES_COLUMNS = [
	{ field: 'client', headerName: 'Client', width: 160 },
	{ field: 'number', headerName: 'Invoice Number', width: 160 },
	{ field: 'amountDue', headerName: 'Amount Due', width: 160 },
	{ field: 'totalTax', headerName: 'Total Tax', width: 160 },
	{ field: 'amountPaid', headerName: 'Amount Paid', width: 160 },
	{ field: 'total', headerName: 'Total', width: 160 },
	{ field: 'client_name', headerName: 'Client', width: 160 },
	{ field: 'status', headerName: 'Status', width: 160 },
	{ field: 'action', headerName: 'Action' },
];
