import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../../constants/theme';

export const useAddBillingStyles = makeStyles((theme) => ({
	submit: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	addBillingBtn: {
		display: 'flex',
		alignItems: 'center',
		backgroundColor: COLORS.primary.default,
		color: '#FFF',
		padding: '2px 16px',
		fontSize: '14px',
		borderRadius: '50px',
		cursor: 'pointer',
		textTransform: 'capitalize',
		boxShadow: '0 2px 2px rgb(0 0 0 / 16%), 0 2px 2px rgb(0 0 0 / 16%)',

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	dialogTitle: {
		padding: '2rem',
	},

	closeBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'unset',
		border: 'none',
		width: '100%',
	},

	title: {
		fontWeight: 'bold',
	},
}));
