import { useContext, useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DataTable, DataTablePagination } from '../../../components';
import { jobPageCostDataTable } from '../../../data/tableColumns';
import Context from '../../../services/context';
import { CostRemove } from './CostRemove';
import { useDeleteCost } from '../../../services/api';
import { handleCurrency } from '../../../utils';

export const Costs = ({ asana_project_id, jobFilter, search, tbIndex }: any) => {
	const { dispatch, state } = useContext(Context);
	const [costList, setCostList] = useState([]);
	const [loading, setLoading] = useState(state.loadingActiveJobs);

	// const formatDataTaskJob = (data: any) => {
	// 	const resultCost: any = [];
	// 	for (let jobs = 0; jobs < data.length; jobs++) {
	// 		if (data[jobs].costLineItem) {
	// 			for (let cost = 0; cost < data[jobs].costLineItem.length; cost++) {
	// 				const element = data[jobs].costLineItem[cost];
	// 				resultCost.push({
	// 					id: cost,
	// 					date: data[jobs].costDate,
	// 					description: element.description,
	// 					quantity: String(element.quantity) ? String(element.quantity) : '',
	// 					amount: element.lineAmount,
	// 					cost_id: data[jobs].cost_id ? data[jobs].cost_id : '0',
	// 					job_id: data[jobs].id,
	// 				});
	// 			}
	// 		}
	// 	}

	// 	return resultCost;
	// };

	const formatDataTaskJob = (data: any) => {
		const resultCost: any = [];
		for (let jobs = 0; jobs < data.length; jobs++) {
			if (data[jobs].costDetails) {
				for (let cost = 0; cost < data[jobs].costDetails.length; cost++) {
					const element = data[jobs].costDetails[cost];
					resultCost.push({
						id: cost,
						date: element.costDate,
						description: element.costDesc,
						quantity: element.quantity ? element.quantity : '-',
						supplier: element.supplier,
						poNumber: element.po_num,
						amount: element.costs_total ? handleCurrency(element.costs_total) : 0,
						cost_id: element.cost_id,
						job_id: data[jobs].id,
					});
				}
			}
		}

		return resultCost;
	};

	const handleFilterData = () => {
		return state.activeJobs
			? state.activeJobs.filter((value: any) => value.asana_project_id === asana_project_id)
			: [];
	};

	// useEffect(() => {
	// 	const filterJobs = handleFilterData();
	// 	if (filterJobs && filterJobs[0] && filterJobs[0].costLineItem) {
	// 		setCostList(formatDataTaskJob(filterJobs));
	// 	} else {
	// 		setCostList(formatDataTaskJob([]));
	// 	}
	// }, [state.activeJobs]);

	useEffect(() => {
		const filterJobs = handleFilterData();
		if (filterJobs && filterJobs[0] && filterJobs[0].costDetails) {
			setCostList(formatDataTaskJob(filterJobs));
		} else {
			setCostList(formatDataTaskJob([]));
		}
	}, [state.activeJobs]);

	useEffect(() => {
		setLoading(state.loadingActiveJobs);
	}, [state.loadingActiveJobs]);

	useEffect(() => {
		const filterJobs = handleFilterData();
		const formattedJobs =
			filterJobs && filterJobs[0] && filterJobs[0].costDetails ? formatDataTaskJob(filterJobs) : [];
		if (jobFilter.taskDateDisable) {
			if (search && formattedJobs && formattedJobs.length && tbIndex === 2) {
				let filtered = formattedJobs.filter((item: any) => {
					return (
						item.date.toLowerCase().includes(search.toLowerCase()) ||
						item.description.toLowerCase().includes(search.toLowerCase()) ||
						// item.quantity.toLowerCase().includes(search.toLowerCase()) ||
						item.supplier.toLowerCase().includes(search.toLowerCase()) ||
						item.poNumber.toLowerCase().includes(search.toLowerCase()) ||
						String(item.amount).toLowerCase().includes(search.toLowerCase())
					);
				});

				setCostList(filtered);
			} else {
				setCostList(formattedJobs);
			}
		} else {
			if (tbIndex === 2 && formattedJobs && formattedJobs.length && !jobFilter.taskDateDisable) {
				let filtered = formattedJobs.filter((item: any) => {
					const getStringMonth = jobFilter.taskDate.toLocaleString('default', { month: 'short' });
					const getFullYear = String(jobFilter.taskDate.getFullYear());

					return (
						item.date.toLowerCase().includes(getStringMonth.toLowerCase()) &&
						item.date.toLowerCase().includes(getFullYear.toLowerCase())
					);
				});

				if (search && filtered.length && tbIndex === 2) {
					let filteredFormat = filtered.filter((item: any) => {
						return (
							item.date.toLowerCase().includes(search.toLowerCase()) ||
							item.description.toLowerCase().includes(search.toLowerCase()) ||
							// item.quantity.toLowerCase().includes(search.toLowerCase()) ||
							item.supplier.toLowerCase().includes(search.toLowerCase()) ||
							item.poNumber.toLowerCase().includes(search.toLowerCase()) ||
							String(item.amount).toLowerCase().includes(search.toLowerCase())
						);
					});

					setCostList(filteredFormat);
				} else {
					const getData = filtered.length ? filtered : [];

					setCostList(getData);
				}
			}
		}
	}, [jobFilter.taskDate, jobFilter.taskDateDisable, search]);

	// remove cost =======================================
	const { mutate, data: costRemoveData, isLoading: costRemoveLoading } = useDeleteCost();
	const [showRemoveCost, setShowRemoveCost] = useState(false);
	const [removeCosts, setRemoveCosts] = useState<any>({});

	function toggleRemoveCosts() {
		setShowRemoveCost((showRemoveCost) => !showRemoveCost);
	}

	const handleRemoveCost = (value: any): void => {
		setRemoveCosts(value);
		toggleRemoveCosts();
	};

	const handleRemoveCostsSubmit = (): void => {
		const formatData = {
			id: removeCosts.job_id,
			cost_id: removeCosts.cost_id,
		};
		mutate(formatData);
	};

	useEffect(() => {
		if (costRemoveData && costRemoveData.error === false) {
			toggleRemoveCosts();
		}
	}, [costRemoveData]);

	const modalPropsRemoveCosts = {
		handleSubmit: handleRemoveCostsSubmit,
		isShowing: showRemoveCost,
		hide: toggleRemoveCosts,
		currentPage: 1,
		pageLength: 1,
	};

	const costRemoveProps = {
		modalPropsRemoveCosts,
		toggleRemoveCosts,
		removeCosts,
		costRemoveLoading,
	};

	// remove cost =======================================

	const reformatData = costList
		? costList.map((list: any) => ({
				...list,
				actions: (
					<span
						className='gs__icon__remove'
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() => handleRemoveCost({ ...list })}
					>
						<Tooltip placement='top' title='Remove' arrow>
							<DeleteForeverIcon />
						</Tooltip>
					</span>
				),
		  }))
		: [];

	const tableData = {
		columns: jobPageCostDataTable.columns,
		rows: reformatData,
		isLoading: loading,
	};

	return (
		<div>
			{/* <DataTable {...tableData} /> */}
			<DataTablePagination {...tableData} />
			<CostRemove {...costRemoveProps} />
		</div>
	);
};
