import { Box, Drawer } from '@mui/material';
import { Sidebar } from './Sidebar';

export const SidebarContainer = ({drawerWidth, container, mobileOpen, handleDrawerToggle, ticker, posTicker, unassignedIvoiceTicker}: any) => {
	return (
		<Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
			<Drawer
				container={container}
				variant='temporary'
				open={mobileOpen}
				onClose={handleDrawerToggle}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
			>
				<Sidebar ticker={ticker} posTicker={posTicker} unassignedIvoiceTicker={unassignedIvoiceTicker} />
			</Drawer>
			<Drawer
				variant='permanent'
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
				open
			>
				<Sidebar ticker={ticker} posTicker={posTicker} unassignedIvoiceTicker={unassignedIvoiceTicker} />
			</Drawer>
		</Box>
	);
};
