import { ClientRowProps } from './Clients.props';

export const toClientsTableData = (rows: ClientRowProps[]) => {
	const clientsTableData = rows.map((row: ClientRowProps) => {
		return {
			address: row.address ? row.address : '-',
			asana_id: row.asana_id ? row.asana_id : '-',
			contact_name: row.contact_name ? row.contact_name : '-',
			createdAt: row.createdAt ? row.createdAt : '-',
			email: row.email ? row.email : '-',
			id: row.id ? row.id : 0,
			mobile_num: row.mobile_num ? row.mobile_num : '-',
			name: row.name ? row.name : '-',
			updatedAt: row.updatedAt ? row.updatedAt : '-',
			xero_id: row.xero_id ? row.xero_id : '-',
		};
	});

	return clientsTableData;
};
