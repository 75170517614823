import axios from 'axios';
import fileDownload from 'js-file-download';

export const serverDomain: string | undefined = process.env.REACT_APP_SERVER_IP;

export const apiHeader = (file = false) => {
	const auth = localStorage.getItem('gsqToken');
	const AuthStr = `Bearer ${auth}`;
	return {
		headers: {
			'Content-Type': file ? 'multipart/form-data' : 'application/json',
			accept: 'application/json',
			Authorization: AuthStr,
		},
	};
};

export const API = async (path: string, func: string, method: string, data: any) => {
	let result: any = {};

	switch (method) {
		case 'get':
			if (func === 'postDocumentDownload') {
				result = await axios
					.get(`${serverDomain}${path}`, {
						responseType: 'blob',
					})
					.then((response) => fileDownload(response.data, data.file));
			}
			if (func === 'downloadReportCSV') {
				result = await axios
					.get(`${serverDomain}${path}`, {
						responseType: 'blob',
					})
					.then((response) => fileDownload(response.data, data.filename));
			}
			if (func === 'exportReportExcel') {
				result = await axios
					.get(`${serverDomain}${path}`, {
						responseType: 'blob',
					})
					.then((response) => fileDownload(response.data, 'report.xlsx'));
			} else {
				result = await axios.get(`${serverDomain}${path}`, apiHeader()).then((response) => response.data);
			}
			break;
		case 'post':
			result = await axios
				.post(
					`${serverDomain}${path}`,
					data,
					apiHeader(func === 'postDocument' || func === 'postClient' ? true : false)
				)
				.then((response) => response.data);

			break;
		case 'put':
			if (func === 'putUserDat') {
				result = await axios
					.put(`${serverDomain}${path}`, data, apiHeader(true))
					.then((response) => response.data);
			} else {
				result = await axios.put(`${serverDomain}${path}`, data, apiHeader()).then((response) => response.data);
			}

			break;
		case 'delete':
			result = await axios.delete(`${serverDomain}${path}`, apiHeader()).then((response) => response.data);
			break;
	}

	if (result && result.error) {
		return {
			error: true,
			result: [],
			message: result.message,
		};
	}
	return result;
};
