import { Typography, Box, TextField, Autocomplete, LinearProgress } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Modal } from '../../../components';


export const AddInvoices = ({
	loading,
	invoicesSelectionList,
	setInvoiceSelected,
	modalPropsAddInvoice,
	toggleAddInvoice,
}: any) => {
	const defaultProps = {
		options: invoicesSelectionList,
		getOptionLabel: (option: any) => option.invoice,
	};

	return (
		<Modal {...modalPropsAddInvoice}>
			<Typography className='add-job-scope__top-typography' sx={{ fontSize: 18, mb: 1.5 }}>
				<ClearOutlinedIcon
					className='add-job-scope__close-btn'
					onClick={toggleAddInvoice}
					sx={{ cursor: 'pointer' }}
				/>
			</Typography>
			<Typography mb={3} id='modal-modal-title' variant='h4' component='div' sx={{ fontWeight: 700 }}>
				Add invoice
				{loading ? <span style={{ fontWeight: 'normal', fontSize: '12px' }}> processing...</span> : ''}
				{loading ? <LinearProgress color='inherit' /> : ''}
			</Typography>
			<div style={{ marginBottom: '40px' }}>
				<Autocomplete
					{...defaultProps}
					id='clear-on-escape'
					clearOnEscape
					onChange={(value: any, newValue: any) => {
						setInvoiceSelected(newValue);
					}}
					renderInput={(params) => <TextField {...params} label='Select invoice' variant='standard' />}
				/>
			</div>
		</Modal>
	);
};
