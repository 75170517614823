import { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Context from '../../services/context';
import { login } from '../../services/api';

export const Login = () => {
	const { state, dispatch } = useContext(Context);
	// console.log({ state });

	const loginHandle = () => {
		const serverDomain: string | undefined = process.env.REACT_APP_SERVER_IP;
		// window.location.href = `${serverDomain}/api/login`;
	};

	if (state.token) {
		return <Navigate to='/' />
	}

	return (
		<div>
			<Button variant='contained' onClick={() => login()}>
				Login to MS SSO
			</Button>
			<p>Note: this page is for backend testing purposes only to implement the SSO logout</p>
		</div>
	);
};
