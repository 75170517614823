import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { usePostDocument } from '../../../../services/api';

export const useDocumentAdd = ({ jobDetails }: any) => {
	const { mutate, data: documentAddData, isLoading: documentAddLoading } = usePostDocument();
	const [showAddDocument, setshowAddDocument] = useState(false);
	const [fileUpload, setFileUpload] = useState<any>({});
	const [documentName, setDocumentName] = useState<any>('');
	const [documentData, setdocumentData] = useState<any>({
		job_id: jobDetails.jobId,
		docs_file: '',
		title: '',
		notes: '',
	});

	const [documentValidation, setdocumentValidation] = useState<any>({
		job_id: jobDetails.jobId ? true : false,
		docs_file: false,
		title: false,
		notes: false,
	});

	const fileSelectHandler = (event: any) => {
		if (event.target && event.target && event.target.files[0]) {
			setFileUpload(event.target.files[0]);
		} else {
			toast.info('Please select file!');
		}
	};

	const toggleAddDocument = () => {
		setshowAddDocument(!showAddDocument);
	};

	const handleDocumentData = (event: any, field: any) => {
		switch (field) {
			case 'docs_file':
				const valueFile: any = (event.target as HTMLInputElement).files;
				setFileUpload(valueFile[0]);
				setdocumentData({ ...documentData, docs_file: valueFile[0].name });
				setdocumentValidation({
					...documentValidation,
					docs_file: valueFile[0].name ? true : false,
				});
				break;
			case 'title':
				const valueTitle: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, title: valueTitle });
				setdocumentValidation({
					...documentValidation,
					title: valueTitle ? true : false,
				});
				break;
			case 'notes':
				const valueNotes: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, notes: valueNotes });
				setdocumentValidation({
					...documentValidation,
					notes: valueNotes ? true : false,
				});
				break;
		}
	};

	const capitalize = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleSubmitDocumentAdd = async () => {
		const validateField = Object.keys(documentValidation).filter((key) => !documentValidation[key]);
		let docs_file = new FormData();
		if (validateField.length) {
			if (!documentValidation.docs_file) {
				toast.info('Please select file!');
			} else {
				toast.info(`${capitalize(validateField[0])} must not be empty`);
			}
		} else {
			const fileName = fileUpload.name;
			var allowedExtensions = ['docx', 'pdf', 'xlsx', 'doc', 'xls'];
			var fileExtension = fileName.split('.').pop().toLowerCase();

			if (allowedExtensions.includes(fileExtension)) {
				docs_file.append('docs_file', fileUpload);
				docs_file.append('job_id', documentData.job_id);
				docs_file.append('title', documentData.title);
				docs_file.append('notes', documentData.notes);

				mutate(docs_file);
			} else {
				toast.info('File is not supported!');
			}
		}
	};

	useEffect(() => {
		setDocumentName(fileUpload.name);
	}, [fileUpload]);

	useEffect(() => {
		const validateField = Object.keys(documentValidation).filter((key) => !documentValidation[key]);
		
		if (documentAddData && documentAddData.status === 200 && !validateField.length) {
			toggleAddDocument();
		}
	}, [documentAddData]);

	const modalPropsDocumentAdd = {
		handleSubmit: handleSubmitDocumentAdd,
		isShowing: showAddDocument,
		hide: toggleAddDocument,
		currentPage: 1,
		pageLength: 1,
	};

	return {
		modalPropsDocumentAdd,
		toggleAddDocument,
		setFileUpload,
		fileSelectHandler,
		documentName,
		documentAddLoading,
		handleDocumentData,
		documentData,
	};
};
