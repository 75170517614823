import React from 'react';
import {
	useGetUniqueClientNames,
	useGetJobTypes,
	useGetUniqueTeams,
	useGetActiveJobNameList,
	useGetActiveJobDetails,
	usePostAssignDoc,
	useGetNextJobNumber,
	useGetNextQuoteNumber,
	useGetDocTypes,
	usePutExistingJobProject,
	useGetAssigneeList,
	useGetJobTasks,
	useGetUnassignedJobs,
} from '../../services/api';

import { INITIAL_STATE_JOB } from './AddJobScopes.const';
import { ClientProps, JobTypeProps, TeamProps, ActiveJobProps, JobProps, AsanaTaskProps } from './AddJobScopes.props';
import { MilestoneProps } from '../../components/InvoiceMilestones/InvoiceMilestones.props';

interface AddJobScopeProps {
	existingActiveJobId: number | null;
	pageNumber: number;
	limit: number;
	term: string;
	selectedCategory: string;
	setSelectedCategory: (jobCategory: string) => void;
	setAssignDocData: (docData: JobProps) => void;
	setStep: (next: number) => void;
	step: number;
	setExistingActiveJobId: (id: number | null) => void;
	assignDocData: JobProps;
	setIsMilestoneLimit: (limit: boolean) => void;
	setMilestones: (milestonesArr: MilestoneProps[]) => void;
	setIsOpenAddJobScope: (value: boolean) => void;
}

export const useAddJobScopes = (props: AddJobScopeProps) => {
	const {
		existingActiveJobId,
		pageNumber,
		limit,
		term,
		selectedCategory,
		setSelectedCategory,
		setAssignDocData,
		assignDocData,
		setExistingActiveJobId,
		setStep,
		step,
		setIsMilestoneLimit,
		setMilestones,
		setIsOpenAddJobScope,
	} = props;
	const CLIENT_DATA_SOURCE = 'XERO';

	// QUERIES
	const clientNamesQuery = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const jobTypeQuery = useGetJobTypes();
	const teamsQuery = useGetUniqueTeams();
	const nextJobNumberQuery = useGetNextJobNumber();
	const nextQuoteNumberQuery = useGetNextQuoteNumber();
	const docTypesQuery = useGetDocTypes();
	const activeJobsQuery = useGetActiveJobNameList();
	const activeJobDetailsQuery = useGetActiveJobDetails(existingActiveJobId);
	const assignNewDoc = usePostAssignDoc(pageNumber, limit, term);
	const assignExistingDoc = usePutExistingJobProject(pageNumber, limit, term);
	const assigneeQuery = useGetAssigneeList();
	const unassignedJobSQuery = useGetUnassignedJobs();
	// const jobTasksQuery = useGetJobTasks(assignDocData?.asana_project_id);

	// HANDLERS
	const findClientId = (clients: ClientProps[], name: string | undefined) => {
		const client = clients.find((client: ClientProps) => (client.name === name ? client.id : null));
		return client?.id;
	};

	const changeJobCategoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const jobCategory = (event.target as HTMLInputElement).value;
		setSelectedCategory(jobCategory);
		setAssignDocData({
			...assignDocData,
			job_cat: Number(jobCategory),
			new_job: jobCategory === '1' ? Number(jobCategory) : 0,
		});
	};

	const changeClientHandler = (clientList: ClientProps[], name: string | undefined | null) => {
		const client = clientList?.find((client: ClientProps) => client?.name === name);
		setAssignDocData({
			...assignDocData,
			client_id: client ? client.id : '',
			job_client: client ? client.name : '',
		});
	};

	const changeNewJobTypeHandler = (jobTypeList: JobTypeProps[], name: string | undefined | null) => {
		const newJobType = jobTypeList?.find((type: JobTypeProps) => type?.name === name);
		const jobType = newJobType?.name === 'Retainer Job' ? 2 : 3;
		setSelectedCategory(jobType.toString());
		setAssignDocData({
			...assignDocData,
			job_cat: newJobType ? jobType : null,
		});
	};

	const changeTeamHandler = (teamList: TeamProps[], name: string | undefined | null) => {
		const team = teamList?.find((team: TeamProps) => team?.name === name);
		setAssignDocData({
			...assignDocData,
			team_id: team ? team.gid : '',
		});
	};

	const changeDocTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedCategory(event.target.value);
		const id = Number(event.target.value);
		setAssignDocData({
			...assignDocData,
			doc_type: id ? id : null,
			new_job: id === 1 ? id : 0,
		});
	};

	const changeActiveJobHandler = (activeJobList: ActiveJobProps[], name: string | undefined | null) => {
		const activeJob = activeJobList?.find((job: ActiveJobProps) => job.job_name === name);
		setExistingActiveJobId(activeJob ? Number(activeJob.job_id) : null);

		setAssignDocData({
			...assignDocData,
			id: activeJob ? Number(activeJob.job_id) : null,
		});
	};

	const changeUnassignedJobHandler = (activeJobList: any[], name: string | undefined | null) => {
		const activeJob = activeJobList?.find((job: any) => job.name === name);

		setAssignDocData({
			...assignDocData,
			id: activeJob ? Number(activeJob.job_id) : null,
		});
	};

	const changeInvoiceFrequencyHandler = (name: string | undefined | null) => {
		const invoice_freq = [{ range: name ? name : '', notes: '' }];

		setAssignDocData({
			...assignDocData,
			invoice_frequency: invoice_freq,
		});
	};

	const nextHandler = (clientList: ClientProps[]) => {
		const clientId = findClientId(clientList, assignDocData.job_client);
		setStep(step + 1);
		setAssignDocData({
			...assignDocData,
			client_id: clientId ? clientId : null,
		});
	};

	const backHandler = () => {
		setStep(step - 1);
	};

	const milestoneLimitHandler = (value: boolean) => {
		// console.log('Milestone limit ', value);
		setIsMilestoneLimit(value);
	};

	const milestoneValueHandler = (milestonesArr: MilestoneProps[]) => {
		// console.log('milestonesArr ', milestonesArr);
		setMilestones(milestonesArr);
	};

	const serviceChangeHandler = (event: any) => {
		const {
			target: { value },
		} = event;
		setAssignDocData({
			...assignDocData,
			services: typeof value === 'string' ? value.split(',') : value,
		});
	};

	const changeTextFieldHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setAssignDocData({
			...assignDocData,
			[e.target.name]: e.target.value,
		});
	};

	const closeModalHandler = () => {
		setIsOpenAddJobScope(false);
		setStep(1);
		resetModal();
	};

	const resetModal = () => {
		setAssignDocData(INITIAL_STATE_JOB);
	};

	const removeTaskHandler = (id: string) => {
		const newTaskList = assignDocData.asana_task.filter((task: AsanaTaskProps) => task.id !== id);
		setAssignDocData({
			...assignDocData,
			asana_task: newTaskList,
		});
	};

	const submitAssignDocHandler = async (assignDocData: JobProps) => {
		const services = assignDocData.services.map((item: string) => {
			return { service: item };
		});

		const newJobData = {
			client_id: assignDocData.client_id,
			job: {
				panda_doc_id: assignDocData.panda_doc_id,
				doc_type: assignDocData.doc_type, // MSA, MBA, Job
				asana_project_id: assignDocData.asana_project_id,
				client_id: assignDocData.client_id,
				cost_id: assignDocData.cost_id,
				end_date: assignDocData.end_date,
				est_hours: `${assignDocData.est_hours}h`,
				fees: assignDocData.fees,
				invoice_frequency: [...assignDocData.invoice_frequency],
				invoice_milestones: [...assignDocData.invoice_milestones],
				job_cat: assignDocData.job_cat,
				job_client: assignDocData.job_client,
				job_description: assignDocData.job_description,
				job_name: assignDocData.job_name,
				job_number: assignDocData.job_number,
				new_job: assignDocData.new_job,
				qoute_number: assignDocData.qoute_number,
				services: [...services],
				start_date: assignDocData.start_date,
				team_id: assignDocData.team_id,
				asana_task: assignDocData.asana_task,
			},
		};
		const existingJobData = {
			id: assignDocData.id,
			job: {
				panda_doc_id: assignDocData.panda_doc_id,
				doc_type: assignDocData.doc_type, // MSA, MBA, Job
				asana_project_id: assignDocData.asana_project_id,
				client_id: assignDocData.client_id,
				cost_id: assignDocData.cost_id,
				end_date: assignDocData.end_date,
				est_hours: `${assignDocData.est_hours}h`,
				fees: assignDocData.fees,
				invoice_frequency: [...assignDocData.invoice_frequency],
				invoice_milestones: [...assignDocData.invoice_milestones],
				job_cat: assignDocData.job_cat,
				job_client: assignDocData.job_client,
				job_description: assignDocData.job_description,
				job_name: assignDocData.job_name,
				job_number: assignDocData.job_number,
				new_job: assignDocData.new_job,
				qoute_number: assignDocData.qoute_number,
				services: [...services],
				start_date: assignDocData.start_date,
				team_id: assignDocData.team_id,
				asana_task: [assignDocData.asana_task],
			},
		};

		if (assignDocData.doc_type === 1) {
			console.log({ newJobData });
			assignNewDoc.mutate(newJobData);
			console.log('Submitted  newJobData');
		} else {
			console.log({ existingJobData });
			assignExistingDoc.mutate(existingJobData);
			console.log('Submitted  existingJobData');
		}

		closeModalHandler();
	};

	return {
		// QUERIES
		clientNamesQuery,
		jobTypeQuery,
		teamsQuery,
		nextJobNumberQuery,
		nextQuoteNumberQuery,
		docTypesQuery,
		activeJobsQuery,
		unassignedJobSQuery,
		activeJobDetailsQuery,
		assigneeQuery,
		// HANDLERS
		changeJobCategoryHandler,
		changeClientHandler,
		changeNewJobTypeHandler,
		changeTeamHandler,
		changeDocTypeHandler,
		changeActiveJobHandler,
		changeUnassignedJobHandler,
		changeInvoiceFrequencyHandler,
		nextHandler,
		backHandler,
		milestoneLimitHandler,
		milestoneValueHandler,
		serviceChangeHandler,
		changeTextFieldHandler,
		closeModalHandler,
		submitAssignDocHandler,
		removeTaskHandler,
		resetModal,
		// SUBMIT
		assignNewDoc,
		assignExistingDoc,
	};
};
