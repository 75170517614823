import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../constants/theme';

export const useArchivesStyles = makeStyles((theme) => ({
	// SEARCH
	searchContainer: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: '.7rem',
	},

	searchText: {
		fontSize: '14px',
		paddingTop: '3px',
	},

	searchField: {
		marginLeft: '10px',
		width: '300px',

		'& .MuiInputBase-input': {
			padding: '.5px',
		},
	},

	// TEAM FILTER
	filterTeamContainer: {
		display: 'flex',
		alignItems: 'flex-end',
		marginBottom: '1rem',
	},

	filterTeamText: {
		fontSize: '14px',
		paddingTop: '3px',
		marginLeft: '2px',
	},

	filterTextField: {
		marginLeft: '10px',
		width: '324px',

		'& .MuiInputBase-input': {
			padding: '.5px',
		},
	},

	tab: {
		'&:hover': {
			color: COLORS.primary.shade600,
		},
		'&.Mui-selected': {
			color: COLORS.primary.shade600,
		},
	},

	// TABLE

	loaderContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		background: 'rgb(255 255 255 / 58%)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	truncatedData: {
		whiteSpace: 'normal',
		wordBreak: 'break-word',
		textOverflow: 'ellipsis',
		'-webkit-line-clamp': '2',
		'-webkit-box-orient': 'vertical',
		overflow: 'hidden',
		display: '-webkit-box',
	},

	// PAGINATION
	paginationContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},

	fetchingText: {
		color: '#cbcbcb',
		fontSize: '15px',
	},

	// FILTERS
	filtersContainer: {
		display: 'flex',
		gap: '1rem',
		marginBottom: '1rem',
	},

	filterButton: {
		border: 'none',
		backgroundColor: 'unset',
		cursor: 'pointer',
		display: 'flex',
	},

	filterButtonText: {
		textDecoration: 'underline',
		marginBottom: '6px',
	},

	filterGroup: {
		padding: '1rem 1rem 2rem 1rem',
		backgroundColor: '#f9f9f9',
	},

	fieldset: {
		width: '100%',
		border: '1px solid #dfdfdf',
		borderRadius: '4px',
		display: 'flex',
	},
}));
