import React from 'react';

export const INITIAL_STATE_JOB = {
	client_id: null,
	end_date: '',
	est_hours: '',
	fees: 0,
	invoice_frequency: [
		{
			range: 'Monthly',
			notes: '',
		},
	],
	invoice_milestones: [],
	job_cat: null,
	job_client: '',
	job_description: '',
	job_name: '',
	job_number: '',
	new_job: 0,
	panda_doc_id: '',
	qoute_number: '',
	services: [],
	start_date: '',
	team_id: '',
	id: null, // Active job ID
	asana_project_id: '',
	cost_id: '',
	doc_type: 1,
	asana_task: [],
};

export const INITIAL_STATE_MILESTONE = [
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
];

export const SERVICE_TYPES = [
	{ name: 'Web', id: 1, indexN: 0 },
	{ name: 'SEO', id: 2, indexN: 1 },
	{ name: 'Content', id: 3, indexN: 2 },
	{ name: 'Paid Media', id: 4, indexN: 3 },
	{ name: 'Email Marketing', id: 5, indexN: 4 },
	{ name: 'Community Management', id: 6, indexN: 5 },
];

export const JOB_SCOPE_OPTIONS = [
	{ id: 1, label: 'Add NEW project/retainer', value: 'job' },
	{ id: 2, label: 'Edit existing retainer', value: 'retainer' },
	{ id: 3, label: 'Add scope to existing project', value: 'project' },
];
