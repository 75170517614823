import { useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PhoneInput from 'react-phone-number-input';
import { useAddClient } from '../../../services/api';
import { AddClientProps } from './AddClient.props';
import { useAddClientStyles } from './AddClient.styles';

export const AddClient = (props: AddClientProps) => {
	const { pageNumber, limit, openAddClient, closeAddClientHandler } = props;
	const classes = useAddClientStyles();
	const [newClient, setNewClient] = useState({
		name: '',
		contact_name: '',
		email: '',
		phone: '',
		address: '',
	});

	// QUERIES
	const { mutate: addClient, isLoading: isLoadingClient } = useAddClient(pageNumber, limit);

	// RENDER LOADING & ERROR
	if (isLoadingClient) {
		return <p>...Loading</p>;
	}

	// DATA

	// HANDLERS
	const changeTextFieldHandler = (event: any, name: string) => {
		if (name === 'phone') {
			return setNewClient({ ...newClient, phone: event });
		}
		return setNewClient({ ...newClient, [name]: event.target.value });
	};

	const addClienthandler = () => {
		let clientData = new FormData();
		clientData.append('name', newClient.name);
		clientData.append('contact_name', newClient.contact_name);
		clientData.append('email', newClient.email);
		clientData.append('address', newClient.address);
		clientData.append('phone_num', newClient.phone);

		addClient(clientData);
		closeAddClientHandler();
		resetForm();
	};

	const resetForm = () => {
		setNewClient({
			name: '',
			contact_name: '',
			email: '',
			phone: '',
			address: '',
		});
	};

	return (
		<Box>
			<Dialog
				open={openAddClient}
				onClose={closeAddClientHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Add Client
						</Typography>
						<ClearOutlinedIcon onClick={closeAddClientHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography sx={{ width: '55%' }}>Name:</Typography>
								<TextField
									name='name'
									fullWidth
									id='name'
									variant='standard'
									placeholder='Please enter client name*'
									size='small'
									sx={{ marginTop: '16px' }}
									required
									value={newClient.name}
									onChange={(event) => changeTextFieldHandler(event, 'name')}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography sx={{ width: '55%' }}>Contact Name:</Typography>
								<TextField
									name='contact_name'
									fullWidth
									id='contact_name'
									variant='standard'
									placeholder='Please enter contact name*'
									size='small'
									sx={{ marginTop: '16px' }}
									required
									value={newClient.contact_name}
									onChange={(event) => changeTextFieldHandler(event, 'contact_name')}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography sx={{ width: '55%' }}>Email:</Typography>
								<TextField
									name='email'
									fullWidth
									id='email'
									type='email'
									variant='standard'
									placeholder='Please enter email*'
									size='small'
									sx={{ marginTop: '16px' }}
									required
									value={newClient.email}
									onChange={(event) => changeTextFieldHandler(event, 'email')}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography sx={{ width: '55%' }}>Phone number:</Typography>
								<PhoneInput
									name='phone'
									className={classes.phone}
									international
									countryCallingCodeEditable={false}
									defaultCountry='AU'
									value={newClient.phone}
									onChange={(event) => changeTextFieldHandler(event, 'phone')}
								/>
							</Box>
							<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
								<Typography sx={{ width: '55%' }}>Address:</Typography>
								<TextField
									name='address'
									fullWidth
									id='address'
									variant='standard'
									placeholder='Please enter address*'
									size='small'
									sx={{ marginTop: '16px' }}
									value={newClient.address}
									onChange={(event) => changeTextFieldHandler(event, 'address')}
								/>
							</Box>
						</Box>
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={addClienthandler}
						disableElevation
						// disabled={disableSubmit || isErrorAssignees || isErrorAddTask}
					>
						Add Client
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
