import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Stack, Modal, Typography, Button, Box } from '@mui/material';

export const Timesheets = () => {

	const goToEverhourLogin = () => {
		window.open('https://everhour.com/login', '_blank');
	};

	return (
		<>
			<Typography mb={5} variant='h4'>
				Timesheets
			</Typography>
			<Button className='gs-global__btn' color='error' onClick={() => goToEverhourLogin()} variant='contained'>
				Login to Everhour <ExitToAppIcon style={{ marginLeft: '10px' }} />
			</Button>
		</>
	);
};
