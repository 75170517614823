import { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Typography, Box, TextField, Autocomplete, Divider, Collapse, Tab, Tabs } from '@mui/material';

import { useGetTeams, useGetJobTypes, useGetUniqueClientNames } from '../../services/api';
import { toArrayNames } from '../../utils/toArrayNames';
import { SearchBox } from '../../components';
import { useArchivesStyles } from './Archives.styles';
import { COLORS } from '../../constants/theme';

export const Archives = () => {
	const classes = useArchivesStyles();
	const [collapseFilter, setCollapseFilter] = useState(false);
	const navigate = useNavigate();
	const [tab, setTab] = useState(0);
	const CLIENT_DATA_SOURCE = 'XERO';

	// FILTERS
	const [searchTerm, setSearchTerm] = useState('');
	const [teamFilter, setTeamFilter] = useState('');
	const [clientFilter, setClientFilter] = useState('');
	const [jobTypeFilter, setJobTypeFilter] = useState('');

	// QUERIES
	const { data: teamList, isError: isErrorTeamList, isLoading: isLoadingTeamList } = useGetTeams();
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const { data: jobTypes, isError: isErrorJobTypes, isLoading: isLoadingJobTypes } = useGetJobTypes();

	useEffect(() => {
		if (tab === 0) navigate('jobs');
	}, []);

	// RENDER LOADING & ERROR
	if (isLoadingTeamList || isLoadingClients || isLoadingJobTypes) {
		return <p>Loading...</p>;
	}

	if (isErrorTeamList || isErrorClients || isErrorJobTypes) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const teamNamesArray = toArrayNames(teamList?.result);
	const clientNamesArray = toArrayNames(clients?.result);
	const jobTypeNamesArray = toArrayNames(jobTypes?.result);

	// HANDLERS

	const filterTeamHandler = (newValue: string | undefined | null, teams: any) => {
		const team = newValue ? teams.find((team: any) => team.name === newValue).gid : '';

		setTeamFilter(team);
	};

	const filterClientHandler = (newValue: string | undefined | null, clients: any) => {
		const client = newValue ? clients.find((client: any) => client.name === newValue).name : '';

		setClientFilter(client);
	};

	const filterJobTypeHandler = (newValue: string | undefined | null, jobTypes: any) => {
		const jobType = newValue ? jobTypes.find((jobType: any) => jobType.name === newValue).name : '';

		setJobTypeFilter(jobType);
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
		}, 1000);
	};

	const changeTabHandler = (event: React.SyntheticEvent, newValue: number) => {
		if (newValue === 0) navigate('jobs');
		if (newValue === 1) navigate('invoices');

		setTab(newValue);
	};

	// COMPONENT
	return (
		<Box>
			<Typography mb={6} variant='h4'>
				Archives
			</Typography>

			<Box className={classes.filtersContainer}>
				<SearchBox onSearch={searchStringHandler} />
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>
						<Autocomplete
							options={teamNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Select team'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterTeamHandler(newValue, teamList?.result)
							}
						/>
						<Autocomplete
							options={jobTypeNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Job type'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterJobTypeHandler(newValue, jobTypes?.result)
							}
						/>
						<Autocomplete
							options={clientNamesArray}
							clearOnEscape
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Client name'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterClientHandler(newValue, clients?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box sx={{ width: '100%' }}>
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={tab}
						onChange={changeTabHandler}
						aria-label='basic tabs example'
						TabIndicatorProps={{
							style: {
								backgroundColor: COLORS.primary.default,
								color: COLORS.primary.default,
							},
						}}
					>
						<Tab
							label='Jobs'
							{...a11yProps(0)}
							className={classes.tab}
							sx={{
								padding: '0 2.2rem',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								color: COLORS.neutral.shade900,
							}}
						/>
						<Tab
							label='Invoices'
							{...a11yProps(1)}
							className={classes.tab}
							sx={{
								padding: '0 2.2rem',
								textTransform: 'capitalize',
								fontWeight: 'bold',
								color: COLORS.neutral.shade900,
							}}
						/>
					</Tabs>
				</Box>
				<TabPanel value={tab} index={0}>
					<Outlet context={{ searchTerm: searchTerm }} />
				</TabPanel>
				<TabPanel value={tab} index={1}>
					<Outlet context={{ searchTerm: searchTerm }} />
				</TabPanel>
			</Box>
		</Box>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`active-job-tabpanel-${index}`}
			aria-labelledby={`active-job-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ padding: '1rem 0' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
};

function a11yProps(index: number) {
	return {
		id: `active-job-tab-${index}`,
		'aria-controls': `active-job-tabpanel-${index}`,
	};
}
