import { Typography, Box, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SearchBoxProps } from './SearchBox.props';
import { useSearchBoxStyles } from './SearchBox.styles';

export const SearchBox = (props: SearchBoxProps) => {
	const classes = useSearchBoxStyles();
	const { searchTerm, onSearch } = props;

	return (
		<Box className={classes.searchContainer}>
			<SearchIcon />
			<Typography className={classes.searchText} color='text.secondary' gutterBottom>
				Search :
			</Typography>
			<TextField
				className={classes.searchField}
				onChange={(event) => onSearch(event.target.value)}
				id='search'
				variant='standard'
			/>
		</Box>
	);
};
