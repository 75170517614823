import { useEffect, useState, useContext } from 'react';
import { Box, Stack, Toolbar, Drawer, CssBaseline, Button } from '@mui/material';
import { AddJobScope } from '../';
import { AddJobScopes } from '../AddJobScopes/AddJobScopes';
import { ProtectedRoutes } from '../../components';
import Context from '../../services/context';
import { Sidebar } from './Sidebar';
import { SidebarContainer } from './SidebarContainer';
import { Header } from './Header';
import {
	useGetActiveJobs,
	useGetUnassignedJobs,
	useGetPOs,
	useGetInvoices,
	useGetInvoiceJobSelection,
	useGetClients,
	useGetArchiveJobs,
	useGetNextJobNumber,
	useGetNextQuoteNumber,
	useGetTeams,
	useGetActiveJobAssigning,
	useGetAssignees,
	useGetProjectJobs,
	useGetRetainerJobs,
} from '../../services/api';

const drawerWidth = 290;

export const Layout = (props: any) => {
	// useGetActiveJobs();
	useGetUnassignedJobs();
	useGetPOs();
	useGetInvoices();
	// useGetInvoiceJobSelection();
	useGetClients();
	// useGetArchiveJobs();
	useGetTeams();
	useGetNextJobNumber();
	useGetNextQuoteNumber();
	useGetActiveJobAssigning();
	useGetAssignees();
	useGetProjectJobs();
	useGetRetainerJobs();

	const { window } = props;
	const { state, dispatch } = useContext(Context);
	const [mobileOpen, setMobileOpen] = useState(false);
	const [ticker, setTicker] = useState(state.unassignedJobTicker);
	const [posTicker, setPosTicker] = useState(state.posTicker);
	const [unassignedIvoiceTicker, setUnassignedIvoiceTicker] = useState(0);

	const [activePage, setActivePage] = useState(localStorage.getItem('activePage') || '/');
	const [activeRoute, setActiveRoute] = useState(localStorage.getItem('activePage'));

	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);
	// const initPath = window.location.pathname.search('/login');
	// console.log({initPath});
	const [userInfo, setUserInfo] = useState<any>('');

	// const handleUserInfo = async () => {
	// 	const data = await getUserData();
	// 	if (data && data.result) {setUserInfo(data.result)}
	// 	return data;
	// };

	useEffect(() => {
		// handleUserInfo()
		setTicker(state.unassignedJobTicker);
	}, [state.unassignedJobTicker]);

	useEffect(() => {
		setPosTicker(state.posTicker);
	}, [state.posTicker]);

	useEffect(() => {
		setUnassignedIvoiceTicker(state.invoicesSelection.length);
	}, [state.invoicesSelection]);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		localStorage.setItem('activePage', activePage);
		setActiveRoute(() => localStorage.getItem('activePage'));
	}, [activePage]);

	const container = window !== undefined ? () => window().document.body : undefined;
	const sidebarProps = {
		drawerWidth,
		container,
		mobileOpen,
		handleDrawerToggle,
		ticker,
		posTicker,
		unassignedIvoiceTicker,
	};
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			{state.token ? <Header handleDrawerToggle={handleDrawerToggle} /> : ''}
			{state.token ? <SidebarContainer {...sidebarProps} /> : ''}

			<Box
				component='main'
				sx={{
					flexGrow: 1,
					maxHeight: '100vh',
					p: 3,
					width: { sm: `calc(100% - ${drawerWidth}px)` },
				}}
			>
				<Toolbar />
				<ProtectedRoutes />
			</Box>
			{/* {state.token ? <AddJobScope /> : ''} */}
			{state.token ? (
				<AddJobScopes
					setIsOpenAddJobScope={setOpen}
					isOpenAddJobScope={open}
					pageNumber={page}
					limit={limit}
					term=''
				/>
			) : (
				''
			)}
			{/* <AddJobScopes
				setIsOpenAddJobScope={setOpen}
				isOpenAddJobScope={open}
				pageNumber={page}
				limit={limit}
				term=''
			/> */}
		</Box>
	);
};
