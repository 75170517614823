import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Typography,
	Box,
	TableContainer,
	Autocomplete,
	Table,
	TableBody,
	TableRow,
	TableCell,
	CircularProgress,
	Button,
	Collapse,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	Divider,
	TextField,
	MenuItem,
} from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableHeaderComponent from '../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../components/TablePaginationComponent/TablePaginationComponent';
import { SearchBox } from '../../components';
import { AssignDoc } from './AssignDoc/AssignDoc';
import { UnassignedDocsProps } from './UnassignedDocs.props';
import {
	useGetPaginatedUnassignedDocs,
	useGetUniqueClientNames,
	useGetUnassignedDocSubtasks,
} from '../../services/api';
import { formatCurrency } from '../../utils/formatCurrency';
import { toArrayNames } from '../../utils/toArrayNames';
import { UNASSIGNED_DOCS__COLUMNS } from './UnassignedDocs.columns';
import { toUnassignedDocsTableData } from './UnassignedDocs.transform';
import { useUnassignedDocsStyles } from './UnassignedDocs.styles';

export const UnassignedDocs = () => {
	const classes = useUnassignedDocsStyles();
	const { state }: any = useLocation();
	const client = state ? state?.client : '';
	const [collapseFilter, setCollapseFilter] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);
	const [order, setOrder] = useState('desc');
	const [sort, setSort] = useState('createdAt');
	const [openAssign, setOpenAssign] = useState(false);
	const [selectedrow, setSelectedRow] = useState<UnassignedDocsProps>();
	const CLIENT_DATA_SOURCE = 'XERO';

	// FILTERS
	const [searchTerm, setSearchTerm] = useState('');
	const [clientFilter, setClientFilter] = useState('');

	useEffect(() => {
		if (state) {
			state && setClientFilter(client);
			setCollapseFilter(true);
		}
	}, [client]);

	// QUERIES
	const {
		data: unassignedDocsData,
		isLoading: isLoadingUnassignedDocsData,
		isError: isErrorUnassignedDocsData,
		isFetching: isFetchingUnassignedDocsData,
	} = useGetPaginatedUnassignedDocs(pageNumber + 1, limit, clientFilter, searchTerm, sort, order);
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);

	//RENDER LOADING & ERROR
	if (isLoadingUnassignedDocsData || isLoadingClients) {
		return <p>Loading...</p>;
	}

	if (isErrorUnassignedDocsData || isErrorClients) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const unassignedDocs =
		unassignedDocsData.message === 'Success.' ? toUnassignedDocsTableData(unassignedDocsData?.result.rows) : [];
	const clientNamesArray = toArrayNames(clients?.result);

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	let timer: NodeJS.Timeout;
	const searchStringHandler = (search: string) => {
		clearTimeout(timer);
		if (!search) setSearchTerm('');

		timer = setTimeout(() => {
			setSearchTerm(search);
			setPageNumber(0);
		}, 1000);
	};

	const openAssignHandler = (data: UnassignedDocsProps) => {
		setOpenAssign(true);
		setSelectedRow(data);
	};

	const closeAssignHandler = () => {
		setOpenAssign(false);
	};

	const collapseFilterHandler = () => {
		setCollapseFilter((prev) => !prev);
	};

	const filterClientHandler = (newValue: string | undefined | null, clients: any) => {
		const client = newValue ? clients.find((client: any) => client.name === newValue).name : '';

		setClientFilter(client);
		setPageNumber(0);
	};

	const handleSortChange = (e: any) => {
		e.preventDefault();
		setSort(e.target.value);
	};

	const handleOrderChange = (e: any) => {
		e.preventDefault();
		setOrder(e.target.value);
	};

	return (
		<Box className={classes.container}>
			<Box>
				<Typography mb={6} variant='h4'>
					Unassigned Documents
				</Typography>

				<Box className={classes.filtersContainer}>
					<div style={{ display: 'flex' }}>
						<SearchBox onSearch={searchStringHandler} />
						<Box component='button' onClick={collapseFilterHandler} className={classes.filterButton}>
							<Typography className={classes.filterButtonText}>Filters</Typography>
							<ArrowDropDown />
						</Box>
					</div>
					<div className={classes.sortOrderContainer}>
						<FormControl sx={{ marginBottom: '1.5rem', minWidth: 150 }} size='small'>
							<InputLabel id='sort-by'>Sort by</InputLabel>
							<Select
								labelId='sort-by'
								id='sort-by'
								value={sort}
								label='Sort by'
								onChange={handleSortChange}
							>
								<MenuItem value='createdAt'>Created Date</MenuItem>
								<MenuItem value='updatedAt'>Updated Date</MenuItem>
							</Select>
						</FormControl>
						<FormControl sx={{ marginBottom: '1.5rem', minWidth: 160 }} size='small'>
							<InputLabel id='sort-by'>Order by</InputLabel>
							<Select
								labelId='order-by'
								id='order-by'
								value={order}
								label='Order by'
								onChange={handleOrderChange}
							>
								<MenuItem value='desc'>Descending</MenuItem>
								<MenuItem value='asc'>Ascending</MenuItem>
							</Select>
						</FormControl>
					</div>
				</Box>
			</Box>

			<Collapse in={collapseFilter}>
				<Divider />
				<Box className={classes.filterGroup}>
					<fieldset className={classes.fieldset}>
						<legend>Filters:</legend>

						<Autocomplete
							options={clientNamesArray}
							clearOnEscape
							defaultValue={client}
							renderInput={(params) => (
								<TextField
									{...params}
									className={classes.filterTextField}
									label='Client name'
									variant='standard'
								/>
							)}
							onChange={(event: any, newValue: string | undefined | null) =>
								filterClientHandler(newValue, clients?.result)
							}
						/>
					</fieldset>
				</Box>
				<Divider />
			</Collapse>

			<Box className={classes.tableContent}>
				<TableContainer className={classes.tableContainer}>
					<Table stickyHeader>
						<TableHeaderComponent headerColumns={UNASSIGNED_DOCS__COLUMNS} />
						<TableBody sx={{ position: 'relative' }}>
							{searchTerm && isFetchingUnassignedDocsData && (
								<TableRow className={classes.loaderContainer}>
									<TableCell colSpan={7} align='center'>
										<CircularProgress color='error' size={24} />
									</TableCell>
								</TableRow>
							)}

							{unassignedDocs.length < 1 && (
								<TableRow sx={{ textAlign: 'center' }}>
									<TableCell colSpan={9} align='center'>
										No data found
									</TableCell>
								</TableRow>
							)}

							{unassignedDocs?.map((row: any) =>
								row?.total_sub_items > 0 ? (
									<SplitJobRow
										row={row}
										key={row.id}
										openAssignHandler={openAssignHandler}
										pageNumber={pageNumber}
										limit={limit}
										searchTerm={searchTerm}
									/>
								) : (
									<TableRow key={row.id}>
										<TableCell>{row.name}</TableCell>
										<TableCell>{row.client}</TableCell>
										<TableCell>{row.quote_number.replace(/\s+/g, '')}</TableCell>
										<TableCell>{formatCurrency(row.fees.amount)}</TableCell>
										<TableCell>{row.cost && formatCurrency(row.cost)}</TableCell>
										<TableCell>
											<Button
												variant='text'
												className={classes.assignButton}
												onClick={() => openAssignHandler(row)}
											>
												Please assign
											</Button>
										</TableCell>
									</TableRow>
								)
							)}
						</TableBody>
					</Table>

					<Box className={classes.paginationWrapper}>
						<TablePaginationComponent
							count={unassignedDocsData.result.count}
							rowsPerPage={limit}
							pageNumber={pageNumber}
							onChangePage={changePaginationPageHandler}
							onChangeRowsPerPage={changeRowsPerPageHandler}
						/>
					</Box>
				</TableContainer>
			</Box>

			<AssignDoc
				closeAssignHandler={closeAssignHandler}
				openAssign={openAssign}
				row={selectedrow}
				pageNumber={pageNumber}
				limit={pageNumber}
				term={searchTerm}
				clientName={clientFilter}
				sort={sort}
				order={order}
			/>
		</Box>
	);
};

const SplitJobRow = ({ row, openAssignHandler, pageNumber, limit, searchTerm }: any) => {
	const classes = useUnassignedDocsStyles();
	const [expanded, setExpanded] = useState(false);

	const UnassignedDocSubtasksQuery = useGetUnassignedDocSubtasks(row.id, pageNumber + 1, limit, searchTerm);

	//RENDER LOADING & ERROR
	if (UnassignedDocSubtasksQuery.isLoading) {
		return <p>Loading...</p>;
	}

	if (UnassignedDocSubtasksQuery.isError) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	const docSubTask =
		UnassignedDocSubtasksQuery.data.message === 'Success.'
			? toUnassignedDocsTableData(UnassignedDocSubtasksQuery.data?.result.rows)
			: [];

	return (
		<>
			<TableRow>
				<TableCell>{row.name}</TableCell>
				<TableCell>{row.client}</TableCell>
				<TableCell>{row.quote_number.replace(/\s+/g, '')}</TableCell>
				<TableCell>{row.fees.amount}</TableCell>
				<TableCell>{row.cost && formatCurrency(row.cost)}</TableCell>
				<TableCell>
					<IconButton aria-label='expand row' size='small' onClick={() => setExpanded((prev: any) => !prev)}>
						{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
			</TableRow>
			<TableRow style={{ backgroundColor: '#f6f6f6' }}>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={expanded} timeout='auto' unmountOnExit>
						<Box>
							<Table>
								<TableHeaderComponent headerColumns={UNASSIGNED_DOCS__COLUMNS} />
								<TableBody>
									{docSubTask?.map((job: any) => (
										<TableRow key={job.id}>
											<TableCell component='th' scope='row'>
												{job.name}
											</TableCell>
											<TableCell>{job.client}</TableCell>
											<TableCell>{job.quote_number}</TableCell>
											<TableCell>{formatCurrency(job.fees.amount)}</TableCell>
											<TableCell>{job.cost && formatCurrency(job.cost)}</TableCell>
											<TableCell>
												<Button
													variant='text'
													className={classes.assignButton}
													onClick={() => openAssignHandler(job)}
													disabled={job.is_active}
												>
													{job.is_active ? <em>Assigned</em> : 'Please assign'}
												</Button>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};
