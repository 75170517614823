import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
	useGetUniqueClientNames,
	useGetJobTypes,
	useGetUniqueTeams,
	useGetActiveJobNameList,
	useGetActiveJobDetails,
	usePostUnAssignDoc,
	useGetNextJobNumber,
	useGetNextQuoteNumber,
	useGetDocTypes,
	usePutExistingUnassignedJob,
	usePostSplit,
	usePostDocument,
	useAssignAsOthers,
} from '../../../services/api';

import { ClientProps, JobTypeProps, TeamProps, ActiveJobProps, DocProps } from './AssignDoc.props';
import { MilestoneProps } from '../../../components/InvoiceMilestones/InvoiceMilestones.props';

interface AssignDocHook {
	existingActiveJobId: number | null;
	pageNumber: number;
	limit: number;
	term: string;
	selectedCategory: string;
	setSelectedCategory: (jobCategory: string) => void;
	setAssignDocData: (docData: DocProps) => void;
	setStep: (next: number) => void;
	step: number;
	setExistingActiveJobId: (id: number | null) => void;
	assignDocData: DocProps;
	setIsMilestoneLimit: (limit: boolean) => void;
	setMilestones: (milestonesArr: MilestoneProps[]) => void;
	setOpenLoading: (value: boolean) => void;
	closeAssignHandler: () => void;
	clientName: string;
	sort: string;
	order: string;
}
export const useAssignDoc = (props: AssignDocHook) => {
	const {
		existingActiveJobId,
		pageNumber,
		limit,
		term,
		clientName,
		sort,
		order,
		selectedCategory,
		setSelectedCategory,
		setAssignDocData,
		assignDocData,
		setExistingActiveJobId,
		setStep,
		step,
		setIsMilestoneLimit,
		setMilestones,
		setOpenLoading,
		closeAssignHandler,
	} = props;
	const CLIENT_DATA_SOURCE = 'XERO';

	// QUERIES
	const clientNamesQuery = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const jobTypeQuery = useGetJobTypes();
	const teamsQuery = useGetUniqueTeams();
	const nextJobNumberQuery = useGetNextJobNumber();
	const nextQuoteNumberQuery = useGetNextQuoteNumber();
	const docTypesQuery = useGetDocTypes();
	const activeJobsQuery = useGetActiveJobNameList();
	const activeJobDetailsQuery = useGetActiveJobDetails(existingActiveJobId);
	const splitDocQuery = usePostSplit(pageNumber + 1, limit, term, clientName, sort, order);
	const assignNewDoc = usePostUnAssignDoc(pageNumber, limit, term);
	const assignExistingDoc = usePutExistingUnassignedJob(pageNumber, limit, term);
	const uploadFileQuery = usePostDocument();
	const assignOtherDoc = useAssignAsOthers();

	// HANDLERS
	const findClientId = (clients: ClientProps[], name: string | undefined) => {
		const client = clients.find((client: ClientProps) => (client.name === name ? client.id : null));
		return client?.id;
	};

	const changeJobCategoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const jobCategory = (event.target as HTMLInputElement).value;

		setSelectedCategory(jobCategory);
		setAssignDocData({
			...assignDocData,
			job_cat: Number(jobCategory),
			new_job: jobCategory === '1' ? Number(jobCategory) : 0,
		});
	};

	const changeClientHandler = (clientList: ClientProps[], name: string | undefined | null) => {
		const client = clientList?.find((client: ClientProps) => client?.name === name);
		setAssignDocData({
			...assignDocData,
			client_id: client ? client.id : '',
			job_client: client ? client.name : '',
		});
	};

	const changeNewJobTypeHandler = (jobTypeList: JobTypeProps[], name: string | undefined | null) => {
		const newJobType = jobTypeList?.find((type: JobTypeProps) => type?.name === name);
		const jobType = newJobType?.name === 'Retainer Job' ? 2 : 3;
		setSelectedCategory(jobType.toString());
		setAssignDocData({
			...assignDocData,
			job_cat: newJobType ? jobType : null,
		});
	};

	const changeTeamHandler = (teamList: TeamProps[], name: string | undefined | null) => {
		const team = teamList?.find((team: TeamProps) => team?.name === name);
		setAssignDocData({
			...assignDocData,
			team_id: team ? team.gid : '',
		});
	};

	const changeDocTypeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const id = Number(event.target.value);

		setAssignDocData({
			...assignDocData,
			doc_type: id ? id : null,
		});
	};

	const changeActiveJobHandler = (activeJobList: ActiveJobProps[], name: string | undefined | null) => {
		const activeJob = activeJobList?.find((job: ActiveJobProps) => job.job_name === name);
		setExistingActiveJobId(activeJob ? Number(activeJob.job_id) : null);
		setAssignDocData({
			...assignDocData,
			id: activeJob ? Number(activeJob.job_id) : null,
		});
	};

	const changeInvoiceFrequencyHandler = (name: string | undefined | null) => {
		const invoice_freq = [{ range: name ? name : '', notes: '' }];

		setAssignDocData({
			...assignDocData,
			invoice_frequency: invoice_freq,
		});
	};

	const nextHandler = (clientList: ClientProps[]) => {
		const clientId = findClientId(clientList, assignDocData.job_client);
		if (step === 1 && assignDocData.doc_type === 4) {
			setStep(step + 3);
		} else {
			setStep(step + 1);
		}

		setAssignDocData({
			...assignDocData,
			client_id: clientId ? clientId : null,
		});
	};

	const backHandler = () => {
		if (step === 4 && assignDocData.doc_type === 4) {
			setStep(step - 3);
		} else {
			setStep(step - 1);
		}
	};

	const milestoneLimitHandler = (value: boolean) => {
		setIsMilestoneLimit(value);
	};

	const milestoneValueHandler = (milestonesArr: MilestoneProps[]) => {
		setMilestones(milestonesArr);
	};

	const serviceChangeHandler = (event: any) => {
		const {
			target: { value },
		} = event;
		setAssignDocData({
			...assignDocData,
			services: typeof value === 'string' ? value.split(',') : value,
		});
	};

	const changeTextFieldHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setAssignDocData({
			...assignDocData,
			[e.target.name]: e.target.value,
		});
	};

	const closeModalHandler = () => {
		closeAssignHandler();
		setStep(1);
	};

	const splitHandler = (docId: string | undefined) => {
		if (docId) {
			const data = { parent_doc_id: docId, is_split_quote: true };
			splitDocQuery.mutate(data);
			closeModalHandler();
		}
	};

	const uploadDocumentHandler = (e: any) => {
		setAssignDocData({
			...assignDocData,
			docs_file: e.target.files[0],
		});
	};

	const submitAssignDocHandler = async (assignDocData: DocProps, setOpenLoading: any) => {
		setOpenLoading(true);

		const services = assignDocData.services.map((item: string) => {
			return { service: item };
		});

		const newJobData = {
			client_id: assignDocData.client_id,
			job: {
				panda_doc_id: assignDocData.panda_doc_id,
				doc_type: assignDocData.doc_type, // MSA, MBA, Job
				asana_project_id: assignDocData.asana_project_id,
				client_id: assignDocData.client_id,
				cost_id: assignDocData.cost_id,
				end_date: assignDocData.end_date,
				est_hours: `${assignDocData.est_hours}h`,
				fees: assignDocData.fees,
				invoice_frequency: [...assignDocData.invoice_frequency],
				invoice_milestones: [...assignDocData.invoice_milestones],
				job_cat: assignDocData.job_cat,
				job_client: assignDocData.job_client,
				job_description: assignDocData.job_description,
				job_name: assignDocData.job_name,
				job_number: assignDocData.job_number,
				new_job: assignDocData.new_job,
				qoute_number: assignDocData.qoute_number,
				services: [...services],
				start_date: assignDocData.start_date,
				team_id: assignDocData.team_id,
				asana_task: [],
			},
		};

		const existingJobData = {
			id: assignDocData.id,
			job: {
				panda_doc_id: assignDocData.panda_doc_id,
				doc_type: assignDocData.doc_type, // MSA, MBA, Job
				asana_project_id: assignDocData.asana_project_id,
				client_id: assignDocData.client_id,
				cost_id: assignDocData.cost_id,
				end_date: assignDocData.end_date,
				est_hours: `${assignDocData.est_hours}h`,
				fees: assignDocData.fees,
				invoice_frequency: [...assignDocData.invoice_frequency],
				invoice_milestones: [...assignDocData.invoice_milestones],
				job_cat: assignDocData.job_cat,
				job_client: assignDocData.job_client,
				job_description: assignDocData.job_description,
				job_name: assignDocData.job_name,
				job_number: assignDocData.job_number,
				new_job: assignDocData.new_job,
				qoute_number: assignDocData.qoute_number,
				services: [...services],
				start_date: assignDocData.start_date,
				team_id: assignDocData.team_id,
				asana_task: [],
			},
		};

		if (assignDocData.doc_type === 4) {
			let file = new FormData();
			file.append('panda_doc_id', assignDocData.panda_doc_id);
			file.append('title', assignDocData.title);
			file.append('notes', assignDocData.notes);
			file.append('docs_file', assignDocData.docs_file);
			file.append('client', assignDocData.client_id as string);

			if (!assignDocData.docs_file) {
				toast.info('No attachment is added.');
			} else {
				await assignOtherDoc.mutateAsync(file);
			}
		} else if (assignDocData.new_job === 1) {
			await assignNewDoc.mutateAsync(newJobData, {
				onSuccess: (data) => {
					let file = new FormData();
					file.append('docs_file', assignDocData.docs_file);
					file.append('job_id', data.result.id);
					file.append('title', assignDocData.title);
					file.append('notes', assignDocData.notes);
					file.append('client', assignDocData.client_id as string);

					if (!assignDocData.docs_file) {
						toast.info('No attachment is added.');
						return setOpenLoading(false);
					}
					uploadFileQuery.mutateAsync(file, {
						onSuccess: () => {
							return setOpenLoading(false);
						},
					});
				},
			});
		} else {
			await assignExistingDoc.mutateAsync(existingJobData);

			let file = new FormData();
			file.append('docs_file', assignDocData.docs_file);
			file.append('job_id', assignDocData.id);
			file.append('title', assignDocData.title);
			file.append('notes', assignDocData.notes);
			file.append('client', assignDocData.client_id as string);

			if (assignExistingDoc.isSuccess) {
				if (!assignDocData.docs_file) {
					toast.info('No attachment is added.');
				} else {
					await uploadFileQuery.mutateAsync(file);
				}
			}
		}

		closeModalHandler();
		setOpenLoading(false);
	};

	return {
		// QUERIES
		clientNamesQuery,
		jobTypeQuery,
		teamsQuery,
		nextJobNumberQuery,
		nextQuoteNumberQuery,
		docTypesQuery,
		activeJobsQuery,
		activeJobDetailsQuery,
		// HANDLERS
		changeJobCategoryHandler,
		changeClientHandler,
		changeNewJobTypeHandler,
		changeTeamHandler,
		changeDocTypeHandler,
		changeActiveJobHandler,
		changeInvoiceFrequencyHandler,
		nextHandler,
		backHandler,
		splitHandler,
		milestoneLimitHandler,
		milestoneValueHandler,
		serviceChangeHandler,
		changeTextFieldHandler,
		closeModalHandler,
		uploadDocumentHandler,
		submitAssignDocHandler,
		// SUBMIT
		assignNewDoc,
		assignExistingDoc,
		uploadFileQuery,
		assignOtherDoc,
	};
};
