import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Box, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress } from '@mui/material';
import TableHeaderComponent from '../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../components/TablePaginationComponent/TablePaginationComponent';
import { useGetArchivedInvoices } from '../../../services/api';
import { ArchivedInvoicesRowProps } from './ArchivedInvoices.props';
import { useArchivedinvoicesStyles } from './ArchivedInvoices.styles';
import { ARCHIVED_INVOICES_COLUMNS } from './ArchivedInvoices.columns';

export const ArchivedInvoices = () => {
	const classes = useArchivedinvoicesStyles();
	const { searchTerm }: any = useOutletContext();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	// QUERIES
	const {
		data: archivedInvoices,
		isError: isErrorArchivedInvoices,
		isLoading: isLoadingArchivedInvoices,
		isFetching: isfetchingArchivedInvoices,
	} = useGetArchivedInvoices(pageNumber + 1, limit, searchTerm);

	if (isLoadingArchivedInvoices) {
		return <p>Loading...</p>;
	}

	if (isErrorArchivedInvoices) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	// DATA
	const tableData = archivedInvoices?.message === 'Success' ? archivedInvoices?.result.rows : [];

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={ARCHIVED_INVOICES_COLUMNS} />
				<TableBody sx={{ position: 'relative' }}>
					{isfetchingArchivedInvoices && (
						<TableRow className={classes.loaderContainer}>
							<TableCell>
								<CircularProgress color='error' size={24} />
							</TableCell>
						</TableRow>
					)}

					{tableData.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{tableData?.map((row: ArchivedInvoicesRowProps, index: number) => (
						<TableRow key={`${row.invoice_id}-${index}`}>
							<TableCell>{row.client_name}</TableCell>
							<TableCell>{row.invoiceNumber}</TableCell>
							<TableCell>{row.amountDue}</TableCell>
							<TableCell>{row.amountPaid}</TableCell>
							<TableCell>{row.totalTax}</TableCell>
							<TableCell>{row.total}</TableCell>
							<TableCell>{row.status}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={10}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
