import { useState } from 'react';
import {
	Typography,
	Box,
	Autocomplete,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Divider,
	Button,
} from '@mui/material';

import { useReportsPageStyles } from './ReportsPage.styles';
import { useGetUniqueClientNames, useGetCustomerUniqueNamesList, useGetUniqueActiveJobs } from '../../services/api';
import { toArrayNames } from '../../utils/toArrayNames';
import ConfirmationModal from './ConfirmationModal/ConfirmationModal';

export const ReportsPage = () => {
	const classes = useReportsPageStyles();
	const [open, setOpen] = useState(false);
	const [filters, setFilters] = useState({
		unassignedDocsClientFilters: null,
		unassignedPOsCustomerFilters: null,
		unassignedInvoicesClientFilters: null,
		exportType: 'XLSX',
		activeJobsFilters: {
			jobClients: null,
			activeJobs: null,
			projectType: [
				{
					value: 'ACTIVE_JOBS_PROJECT_JOB',
					status: true,
				},
				{
					value: 'ACTIVE_JOBS_RETAINER_JOB',
					status: true,
				},
			],
			services: [
				{
					value: 'ACTIVE_JOBS_WEB_SERVICE',
					status: true,
				},
				{
					value: 'ACTIVE_JOBS_SEO_SERVICE',
					status: true,
				},
				{
					value: 'ACTIVE_JOBS_PAID_SERVICE',
					status: true,
				},
				{
					value: 'ACTIVE_JOBS_CRO_SERVICE',
					status: true,
				},
			],
			includedDataTypes: [
				{
					value: 'ACTIVE_JOB_TASK',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_TIME_ENTRIES',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_ATTACHMENTS',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_COSTS',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_QUOTES',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_INVOICES',
					status: true,
				},
				{
					value: 'ACTIVE_JOB_MILESTONES',
					status: true,
				},
			],
		},
	});
	const PANDADOC_CLIENT_DATA_SOURCE = 'PANDA_DOCS';
	const XERO_CLIENT_DATA_SOURCE = 'XERO';

	const {
		data: pandaDocClients,
		isError: isErrorPandaDocClients,
		isLoading: isLoadingPandaDocClients,
	} = useGetUniqueClientNames(PANDADOC_CLIENT_DATA_SOURCE);

	const {
		data: xeroClients,
		isError: isErrorXeroClients,
		isLoading: isLoadingXeroClients,
	} = useGetUniqueClientNames(XERO_CLIENT_DATA_SOURCE);

	const {
		data: customers,
		isError: isErrorCustomers,
		isLoading: isLoadingCustomers,
	} = useGetCustomerUniqueNamesList();

	const {
		data: allActiveJobs,
		isError: isErrorAllActiveJobs,
		isLoading: isLoadingAllActiveJobs,
	} = useGetUniqueActiveJobs();

	//RENDER LOADING & ERROR
	if (isLoadingPandaDocClients || isLoadingCustomers || isLoadingXeroClients || isLoadingAllActiveJobs) {
		return <p>Loading...</p>;
	}

	if (isErrorPandaDocClients || isErrorCustomers || isErrorXeroClients || isErrorAllActiveJobs) {
		return <p>Opps! Something went wrong. Please try again.</p>;
	}

	const pandaDocClientNamesArray = toArrayNames(pandaDocClients?.result);
	const xeroClientNamesArray = toArrayNames(xeroClients?.result);
	const customerNamesArray = toArrayNames(customers?.result);
	const allActiveJobsNamesArray = toArrayNames(allActiveJobs?.result);

	const handleChange = (event: any, newValue: any, field: string) => {
		if (field === 'Export Type') return setFilters({ ...filters, exportType: newValue });
		if (field === 'Unassigned Docs') return setFilters({ ...filters, unassignedDocsClientFilters: newValue });
		if (field === 'Unassigned POs') return setFilters({ ...filters, unassignedPOsCustomerFilters: newValue });
		if (field === 'Unassigned Invoices')
			return setFilters({ ...filters, unassignedInvoicesClientFilters: newValue });
		if (field === 'Active Jobs Client')
			return setFilters({
				...filters,
				activeJobsFilters: { ...filters.activeJobsFilters, jobClients: newValue },
			});
		if (field === 'Active Jobs')
			return setFilters({
				...filters,
				activeJobsFilters: { ...filters.activeJobsFilters, activeJobs: newValue },
			});
	};

	const handleProjectTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const newStatus = event.target.checked;

		const updatedProjectType = filters.activeJobsFilters.projectType.map(
			(type: { value: string; status: boolean }) => (type.value === name ? { ...type, status: newStatus } : type)
		);

		return setFilters({
			...filters,
			activeJobsFilters: {
				...filters.activeJobsFilters,
				projectType: updatedProjectType,
			},
		});
	};

	const handleServiceTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const newStatus = event.target.checked;

		const updatedServiceTags = filters.activeJobsFilters.services.map((type: { value: string; status: boolean }) =>
			type.value === name ? { ...type, status: newStatus } : type
		);

		return setFilters({
			...filters,
			activeJobsFilters: {
				...filters.activeJobsFilters,
				services: updatedServiceTags,
			},
		});
	};
	const handleActiveJobDataTypesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const newStatus = event.target.checked;

		const updatedIncludedDataTypes = filters.activeJobsFilters.includedDataTypes.map(
			(type: { value: string; status: boolean }) => (type.value === name ? { ...type, status: newStatus } : type)
		);

		return setFilters({
			...filters,
			activeJobsFilters: {
				...filters.activeJobsFilters,
				includedDataTypes: updatedIncludedDataTypes,
			},
		});
	};

	return (
		<Box className={classes.container}>
			<Typography mb={2} variant='h4'>
				Export Reports
			</Typography>

			<Box>
				<Box>
					<Typography mb={1} variant='h6'>
						Unassigned Docs
					</Typography>
					<Box>
						<Autocomplete
							// multiple
							id='tags-outlined'
							options={pandaDocClientNamesArray}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Unassigned Docs')}
							renderInput={(params) => (
								<TextField {...params} label='Select Client' placeholder='Select Clients' />
							)}
						/>
					</Box>
				</Box>

				<Box sx={{ marginTop: '2rem' }}>
					<Typography mb={1} variant='h6'>
						Unassigned POs
					</Typography>
					<Box>
						<Autocomplete
							// multiple
							id='tags-outlined'
							options={customerNamesArray}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Unassigned POs')}
							renderInput={(params) => (
								<TextField {...params} label='Select Customer' placeholder='Select Customer' />
							)}
						/>
					</Box>
				</Box>

				<Box sx={{ marginTop: '2rem' }}>
					<Typography mb={1} variant='h6'>
						Unassigned Invoices
					</Typography>
					<Box>
						<Autocomplete
							// multiple
							id='tags-outlined'
							options={xeroClientNamesArray}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Unassigned Invoices')}
							renderInput={(params) => (
								<TextField {...params} label='Select Client' placeholder='Select Clients' />
							)}
						/>
					</Box>
				</Box>
				<Box sx={{ marginTop: '2rem' }}>
					<Typography mb={1} variant='h6'>
						Active Jobs
					</Typography>
					<Box>
						<Autocomplete
							// multiple
							id='tags-outlined'
							options={xeroClientNamesArray}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Active Jobs Client')}
							renderInput={(params) => (
								<TextField {...params} label='Select Client' placeholder='Select Client' />
							)}
						/>
					</Box>
					<Box style={{ marginTop: '1rem' }}>
						<Autocomplete
							// multiple
							id='tags-outlined'
							options={allActiveJobsNamesArray}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Active Jobs')}
							renderInput={(params) => (
								<TextField {...params} label='Select Active Job' placeholder='Select Active Job' />
							)}
						/>
					</Box>
					<Box style={{ display: 'flex', gap: '5rem' }}>
						<Box sx={{ marginTop: '3rem' }}>
							<Typography mb={1} style={{ fontSize: '20px' }}>
								Project type
							</Typography>
							<FormGroup>
								<Box style={{ display: 'flex' }}>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_PROJECT_JOB'
												onChange={(e) => handleProjectTypeChange(e)}
											/>
										}
										label='Project'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_RETAINER_JOB'
												onChange={(e) => handleProjectTypeChange(e)}
											/>
										}
										label='Retainer'
									/>
								</Box>
							</FormGroup>
						</Box>
						<Box sx={{ marginTop: '3rem' }}>
							<Typography mb={1} style={{ fontSize: '20px' }}>
								Service tags
							</Typography>
							<FormGroup>
								<Box style={{ display: 'flex' }}>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_WEB_SERVICE'
												onChange={(e) => handleServiceTagsChange(e)}
											/>
										}
										label='Web'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_SEO_SERVICE'
												onChange={(e) => handleServiceTagsChange(e)}
											/>
										}
										label='SEO'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_PAID_SERVICE'
												onChange={(e) => handleServiceTagsChange(e)}
											/>
										}
										label='Paid'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOBS_CRO_SERVICE'
												onChange={(e) => handleServiceTagsChange(e)}
											/>
										}
										label='CRP'
									/>
								</Box>
							</FormGroup>
						</Box>
					</Box>
					<Box style={{ display: 'flex', gap: '3rem', marginBottom: '3rem' }}>
						<Box sx={{ marginTop: '3rem' }}>
							<Typography mb={1} style={{ fontSize: '20px' }}>
								Select the types of data you want to include for active jobs
							</Typography>
							<FormGroup>
								<Box style={{ display: 'flex', gap: '1rem' }}>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_TASK'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Task'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_TIME_ENTRIES'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Time Entries'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_ATTACHMENTS'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Attachments'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_COSTS'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Costs'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_QUOTES'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Quotes'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_INVOICES'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Invoices'
									/>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked
												color='error'
												name='ACTIVE_JOB_MILESTONES'
												onChange={(e) => handleActiveJobDataTypesChange(e)}
											/>
										}
										label='Milestones'
									/>
								</Box>
							</FormGroup>
						</Box>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ marginTop: '2rem' }}>
					<Typography mb={1} variant='h6'>
						Export Type
					</Typography>
					<Box>
						<Autocomplete
							id='tags-outlined'
							defaultValue={filters.exportType}
							options={['CSV', 'XLSX']}
							getOptionLabel={(option) => option}
							filterSelectedOptions
							onChange={(e, newValue) => handleChange(e, newValue, 'Export Type')}
							renderInput={(params) => (
								<TextField {...params} label='Select Type' placeholder='Select Type' />
							)}
						/>
					</Box>
				</Box>
				<Box style={{ marginTop: '2rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
					<Button
						variant='contained'
						size='large'
						style={{ borderRadius: '4rem' }}
						color='error'
						onClick={() => setOpen(true)}
					>
						Export Report
					</Button>
					<Typography style={{ color: 'red' }}>
						If no filters are set above, all options will be included by default.
					</Typography>
				</Box>
				<div style={{ height: '5rem' }} />
			</Box>
			<ConfirmationModal
				open={open}
				setOpen={setOpen}
				filters={filters}
				pandaDocClients={pandaDocClients.result}
				xeroClients={xeroClients.result}
				customers={customers.result}
				allActiveJobs={allActiveJobs.result}
			/>
		</Box>
	);
};
