import { isArray } from 'lodash';
import { formatCurrency } from '../../utils/formatCurrency';

export const toActiveJobsTableData = (rows: any) => {
	const activeJobsTableData = rows.map((row: any) => {
		return {
			id: row.id ? row.id : '',
			asana_project_id: row.asana_project_id ? row.asana_project_id : '',
			job_name: row.job_name ? row.job_name : '-',
			client: row.client ? row.client : '-',
			job_number: row.job_number ? row.job_number : '-',
			description: row.description ? row.description : '-',
			fees: row.fees ? formatCurrency(row.fees) : 0,
			total_cost: row.totalCost ? formatCurrency(row.totalCost) : 0,
			job_type: row.job_category ? row.job_category : '-',
			team_id: row.team_id ? row.team_id : '',
			team_name: row.team_name ? row.team_name : '',
			services:
				row?.services_list && isArray(row?.services_list)
					? row?.services_list?.map((service: any) => service?.service)
					: [],
		};
	});

	return activeJobsTableData;
};
