import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/theme';

export const useAddClientStyles = makeStyles((theme) => ({
	submit: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	addTaskBtn: {
		minWidth: '40%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		color: COLORS.primary.default,
		textDecoration: 'underline',
		fontWeight: 'bold',
		padding: '0 8px',
		marginLeft: '-8px',

		'&:hover': {
			backgroundColor: COLORS.primary.shade100,
		},
	},

	dialogTitle: {
		padding: '2rem',
	},

	closeBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'unset',
		border: 'none',
		width: '100%',
	},

	title: {
		fontWeight: 'bold',
	},

	phone: {
		borderBottom: '1px solid #9b9b9b',
		paddingBottom: '4px',
		width: '100%',

		marginTop: '24px',

		'& input': {
			border: 'none',
			fontSize: '16px',
			outline: 'none',
		},
	},
}));
