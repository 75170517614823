import { Typography, Box, Divider } from '@mui/material';
import ProfileSettings from './ProfileSettings/ProfileSettings';
import NotificationSettings from './NotificationSettings/NotificationSettings';
import { useGetUserData } from '../../services/api/queries';

export const Settings = () => {
	const { isLoading, isError, error } = useGetUserData();

	if (isLoading) {
		return <p>Loading...</p>;
	}

	if (isError) {
		return <p>Opps! Something went wrong. Please try again. {error.message}</p>;
	}

	return (
		<Box>
			<Typography mb={5} variant='h4'>
				Settings
			</Typography>
			<ProfileSettings />
			<Divider sx={{ margin: '40px 0' }} />
			<NotificationSettings />
		</Box>
	);
};
