import React, { useState } from 'react';
import { Box, CircularProgress, TableContainer, Table, TableBody, TableRow, TableCell, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Delete } from '@mui/icons-material';
import TableHeaderComponent from '../../../../components/TableHeaderComponent/TableHeaderComponent';
import TablePaginationComponent from '../../../../components/TablePaginationComponent/TablePaginationComponent';
import { ATTACHMENTS_COLUMNS } from './Attachments.columns';
import AddAttachment from '../AddAttachment/AddAttachment';
import { COLORS } from '../../../../constants/theme';
import { formatDateYearMonthDay } from '../../../../utils/formatDate';
import { useAttachmentsStyles } from './Attachments.styles';
import { useGetPaginatedAttachments, useDownloadDocument, useDeleteDocument } from '../../../../services/api';

export const Attachments = () => {
	const classes = useAttachmentsStyles();
	const { id = 0, asana_project_id = '' } = useParams();
	const [pageNumber, setPageNumber] = useState(0);
	const [limit, setLimit] = useState(10);

	const attachmentsQuery = useGetPaginatedAttachments(pageNumber + 1, limit, Number(id));
	const attachments = attachmentsQuery.isSuccess ? attachmentsQuery.data.result.rows : [];
	const deleteAttachmentQuery = useDeleteDocument(pageNumber + 1, limit, id);
	const downloadAttachmentQuery = useDownloadDocument();

	const deleteAttachmentHandler = (fileId: any) => {
		if (fileId) {
			deleteAttachmentQuery.mutate({ fileId });
		}
	};

	const downloadAttachmentHandler = (id: any, fileName: any) => {
		if (id) {
			downloadAttachmentQuery.mutate({ id: id, file: fileName });
		}
	};

	// HANDLERS
	const changePaginationPageHandler = (event: any, page: number) => {
		setPageNumber(page);
	};

	const changeRowsPerPageHandler = (event: any) => {
		setLimit(parseInt(event.target.value));
	};

	return (
		<TableContainer>
			<Table>
				<TableHeaderComponent headerColumns={ATTACHMENTS_COLUMNS}>
					<TableCell key='add-cost'>
						<AddAttachment
							asana_project_id={asana_project_id}
							job_id={Number(id)}
							limit={limit}
							pageNumber={pageNumber}
						/>
					</TableCell>
				</TableHeaderComponent>

				<TableBody sx={{ position: 'relative' }}>
					{attachmentsQuery.isFetching && (
						<TableRow className={classes.loaderContainer}>
							<TableCell>
								<CircularProgress color='error' size={24} />
							</TableCell>
						</TableRow>
					)}

					{attachments.length < 1 && (
						<TableRow sx={{ textAlign: 'center' }}>
							<TableCell colSpan={7} align='center'>
								No data found
							</TableCell>
						</TableRow>
					)}

					{attachments.map((attachment: any) => (
						<TableRow>
							<TableCell>{formatDateYearMonthDay(attachment.createdAt)}</TableCell>
							<TableCell>{attachment.title}</TableCell>
							<TableCell>{attachment.file_name}</TableCell>
							<TableCell>{attachment.notes}</TableCell>

							<TableCell
								style={{ cursor: 'pointer', textDecoration: 'underline', textUnderlineOffset: '3px' }}
								onClick={() => downloadAttachmentHandler(attachment.id, attachment.file_name)}
							>
								Download file
							</TableCell>
							<TableCell
								style={{ cursor: 'pointer', textDecoration: 'underline', textUnderlineOffset: '3px' }}
							>
								<Button
									sx={{
										minWidth: '40%',
										borderRadius: '50px',
										textTransform: 'capitalize',
										color: COLORS.primary.default,
										textDecoration: 'underline',
										fontWeight: 'bold',
										padding: '0 8px',
										marginLeft: '-8px',

										'&:hover': {
											backgroundColor: COLORS.primary.shade100,
										},
									}}
									variant='text'
									onClick={() => deleteAttachmentHandler(attachment.id)}
								>
									<Delete />
								</Button>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Box className={classes.paginationContainer}>
				<TablePaginationComponent
					count={attachmentsQuery.data?.result.count}
					rowsPerPage={limit}
					pageNumber={pageNumber}
					onChangePage={changePaginationPageHandler}
					onChangeRowsPerPage={changeRowsPerPageHandler}
				/>
			</Box>
		</TableContainer>
	);
};
