import React, { useEffect, useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	InputAdornment,
	Select,
	MenuItem,
	ListItemText,
} from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useGetJobTypes, useGetUniqueClientNames } from '../../../../services/api';
import { toArrayNames } from '../../../../utils/toArrayNames';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { ServiceProps, TeamProps } from './UpdateActiveJobDetails.props';
import InvoiceMilestones from '../../../../components/InvoiceMilestones/InvoiceMilestones';
import { MilestoneProps } from '../../../../components/InvoiceMilestones/InvoiceMilestones.props';
import { useUpdateJobDetails, useGetUniqueTeams } from '../../../../services/api';
import { COLORS } from '../../../../constants/theme';
import { SERVICE_TYPES } from '../../../../constants/job';
import { useUpdateActiveJobDetailsStyles } from './UpdateActiveJobDetails.styles';

const UpdateActiveJobDetails = (props: any) => {
	const classes = useUpdateActiveJobDetailsStyles();
	const { job_type, client_name, id, team_id } = props;
	const [open, setOpen] = useState(false);
	const [isMilestoneLimit, setIsMilestoneLimit] = useState(false);
	const [tags, setTags] = useState([]);
	const jobData = {
		id: id,
		job: {
			job_name: props?.job_name,
			client_id: props?.client_id,
			job_number: props?.job_number,
			quote_number: props?.quote_number,
			job_description: props?.description,
			job_cat: props?.job_category_id,
			start_date: props?.start_date,
			end_date: props?.end_date,
			invoice_milestones: props.invoice_milestones,
			team_id: props?.team_id,
			totalInvoice: props?.totalInvoice,
			services: props?.services_list,
			asana_project_id: props?.asana_project_id,
			invoice_frequency: props?.invoice_frequency,
			estimate: props?.estimate,
			quote_fees: props?.quote_fees,
			invoice_fees: props?.invoice_fees,
		},
	};
	const CLIENT_DATA_SOURCE = 'XERO';

	const [jobDetails, setJobDetails] = useState<typeof jobData>(jobData);
	const [milestones, setMilestones] = useState<MilestoneProps[]>(jobDetails?.job.invoice_milestones);

	// QUERIES
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const teamsQuery = useGetUniqueTeams();
	const { data: jobTypes, isError: isErrorJobTypes, isLoading: isLoadingJobTypes } = useGetJobTypes();
	const { mutate: updateJobDetails, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate } = useUpdateJobDetails();

	// DATA
	const clientNamesArray = toArrayNames(clients?.result);
	const jobTypeNamesArray = toArrayNames(jobTypes?.result);
	const teamList = teamsQuery.isSuccess ? teamsQuery.data.result : [];
	const teamNameOptions = teamList.map((team: TeamProps) => team.name);

	const findRecord = (data: any[], name: string) => {
		const record = data?.find((item) => item?.name === name);

		return record;
	};

	const teamName = teamList.find((item: any) => item.gid === team_id)?.name;
	const estimate = Number(jobDetails.job.estimate.replace(/[h]/g, ''));

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				client_id: props.client_id,
			},
		});

		const tags = props?.services_list.map((tag: ServiceProps) => tag.service);

		if (tags) {
			setTags(tags);
		}
	}, [props]);

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				job_cat: findRecord(jobTypes?.result, job_type)?.id,
			},
		});
	}, [clients, jobTypes]);

	const handleUpdateTags = (event: any) => {
		const {
			target: { value },
		} = event;

		const updatedTags = typeof value === 'string' ? value.split(',') : value;

		const services = updatedTags.map((tag: any) => ({ service: tag }));
		const data = {
			...jobDetails,
			job: {
				...jobDetails.job,
				services: services,
			},
		};

		setTags(updatedTags);
		setJobDetails(data);
	};

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeTextFieldHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				[e.target.name]: e.target.value,
			},
		});
	};

	// ON CHANGE CLIENT
	const changeClientHandler = (name: any) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				client_id: findRecord(clients?.result, name)?.id,
			},
		});
	};

	// ON CHANGE JOB TYPE
	const changeJobTypeHandler = (name: any) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				job_cat: findRecord(jobTypes?.result, name)?.id,
			},
		});
	};

	// MILESTONE
	const milestoneLimitHandler = (value: boolean) => {
		setIsMilestoneLimit(value);
	};

	const milestoneValueHandler = (milestonesArr: MilestoneProps[]) => {
		setMilestones(milestonesArr);
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				invoice_milestones: milestonesArr,
			},
		});
	};

	const updateHandler = (e: any) => {
		closeHandler();
		updateJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				quote_fees: Number(jobDetails.job.quote_fees),
				invoice_fees: Number(jobDetails.job.invoice_fees),
			},
		});
	};

	const changeTeamHandler = (teamList: TeamProps[], name: string | undefined | null) => {
		const team = teamList?.find((team: TeamProps) => team?.name === name);
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				team_id: team ? team.gid : '',
			},
		});
	};

	const changeInvoiceFrequencyHandler = (name: string | undefined | null) => {
		const invoice_freq = [{ range: name ? name : '', notes: '' }];
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				invoice_frequency: invoice_freq,
			},
		});
	};

	return (
		<div>
			<Button
				sx={{ textDecoration: 'underline', color: COLORS.primary.default, textTransform: 'capitalize' }}
				variant='text'
				onClick={clickOpenHandler}
			>
				<EditOutlined />
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Update Details
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '672px' }}>
					<FormControl sx={{ width: '100%' }}>
						{isLoadingClients || isLoadingJobTypes ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Name:</Typography>
									<TextField
										name='job_name'
										value={jobDetails.job.job_name}
										fullWidth
										id='standard-basic'
										variant='standard'
										placeholder='Job name'
										size='small'
										sx={{ marginTop: '16px' }}
										onChange={(e) => changeTextFieldHandler(e)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Client:</Typography>
									<Autocomplete
										options={clientNamesArray}
										defaultValue={client_name}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Client Name' variant='standard' />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeClientHandler(newValue)
										}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Team:</Typography>
									<Autocomplete
										options={teamNameOptions}
										defaultValue={teamName}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Select team' variant='standard' required />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeTeamHandler(teamList, newValue)
										}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='job_description'
										value={jobDetails.job.job_description}
										fullWidth
										id='standard-basic'
										variant='standard'
										placeholder='Description'
										size='small'
										sx={{ marginTop: '16px' }}
										onChange={(e) => changeTextFieldHandler(e)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Number:</Typography>
									<TextField
										name='job_number'
										value={jobDetails.job.job_number}
										fullWidth
										id='standard-basic'
										variant='standard'
										placeholder='Job number'
										size='small'
										sx={{ marginTop: '16px' }}
										onChange={(e) => changeTextFieldHandler(e)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Quote Fees:</Typography>
									<TextField
										name='quote_fees'
										fullWidth
										variant='standard'
										placeholder='00000'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										}}
										value={jobDetails.job.quote_fees}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Invoice Fees:</Typography>
									<TextField
										name='invoice_fees'
										fullWidth
										variant='standard'
										placeholder='00000'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										InputProps={{
											startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										}}
										value={jobDetails.job.invoice_fees}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Quote Number:</Typography>
									<TextField
										name='qoute_number'
										fullWidth
										variant='standard'
										placeholder='Quote number here*'
										size='small'
										sx={{ marginTop: '16px' }}
										required
										value={jobDetails.job.quote_number}
										onChange={(event) => changeTextFieldHandler(event)}
										// disabled={!!existingActiveJobId}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Typography sx={{ width: '55%' }}>Tags:</Typography>
									<FormControl variant='standard' size='small' sx={{ m: 1, width: '100%' }}>
										<Select
											id='demo-multiple-checkbox'
											multiple
											value={tags}
											onChange={handleUpdateTags}
											// input={<OutlinedInput label='Tag' />}
											color='error'
											renderValue={(selected) => selected.join(', ')}
										>
											{SERVICE_TYPES.map((service) => (
												<MenuItem key={service.id} value={service.name}>
													<ListItemText primary={service.name} />
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%', marginBottom: '5px' }}>Job Type:</Typography>
									<Autocomplete
										options={jobTypeNamesArray}
										defaultValue={
											jobTypes?.result?.find((item: any) => item?.id === jobDetails.job.job_cat)
												?.name
										}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Job type' variant='standard' />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeJobTypeHandler(newValue)
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Start Date:</Typography>
									<TextField
										name='start_date'
										fullWidth
										id='start_date'
										type='date'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										value={jobDetails.job.start_date}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>End Date:</Typography>
									<TextField
										name='end_date'
										fullWidth
										id='end_date'
										type='date'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										value={jobDetails.job.end_date}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Estimated Time:</Typography>
									<TextField
										name='estimate'
										type='number'
										fullWidth
										id='task-name'
										variant='standard'
										size='small'
										sx={{ marginTop: '16px' }}
										InputProps={{
											endAdornment: <InputAdornment position='end'>Hours</InputAdornment>,
										}}
										value={estimate}
										onChange={(event) => changeTextFieldHandler(event)}
									/>
								</Box>
								{job_type === 'Retainer Job' && (
									<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
										<Typography sx={{ width: '55%' }}>Invoice Frequency:</Typography>
										<Autocomplete
											options={['Monthly', 'Quarterly', 'Annually']}
											defaultValue={jobDetails.job.invoice_frequency[0]?.range}
											clearOnEscape
											fullWidth
											size='small'
											renderInput={(params) => (
												<TextField
													{...params}
													label='Select frequency'
													variant='standard'
													required
												/>
											)}
											onChange={(event: any, newValue: string | undefined | null) =>
												changeInvoiceFrequencyHandler(newValue)
											}
										/>
									</Box>
								)}

								{job_type !== 'Retainer Job' && (
									<InvoiceMilestones
										setMilestoneLimitHandler={milestoneLimitHandler}
										setMilestoneValueHandler={milestoneValueHandler}
										milestones={milestones}
									/>
								)}
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.archiveBtn}
						variant='contained'
						onClick={(e) => updateHandler(e)}
						disableElevation
						disabled={isMilestoneLimit || isErrorClients || isErrorJobTypes}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UpdateActiveJobDetails;
