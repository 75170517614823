import React, { useEffect, useState } from 'react';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
} from '@mui/material';
import {
	useGetJobTypes,
	useGetUniqueClientNames,
	useUpdateJobDetails,
	useGetNextJobNumber,
} from '../../../services/api';
import { toArrayNames } from '../../../utils/toArrayNames';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { COLORS } from '../../../constants/theme';
import { useUpdateAsanaJobStyles } from './UpdateAsanaJob.styles';
export const INITIAL_STATE_MILESTONE = [
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '30', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
	{ percent: '20', date: '', note: '', status: 'PENDING' },
];

const UpdateAsanaJob = (props: any) => {
	const classes = useUpdateAsanaJobStyles();
	const { job_type, client_name } = props;
	const [open, setOpen] = useState(false);
	const [isMilestoneLimit, setIsMilestoneLimit] = useState(false);
	const jobData = {
		id: props?.id,
		job: {
			job_name: props?.job_name,
			client_id: props?.client_id,
			job_number: props?.job_number,
			job_description: props?.description,
			job_cat: props?.job_category,
			start_date: props?.start_date,
			end_date: props?.end_date,
			invoice_milestones: props?.invoice_milestones,
			team_id: props?.team_id,
			asana_project_id: props?.asana_project_id,
			invoice_frequency: props?.invoice_frequency || [],
		},
	};
	const [jobDetails, setJobDetails] = useState(jobData);
	const CLIENT_DATA_SOURCE = 'XERO';

	// QUERIES
	const {
		data: clients,
		isError: isErrorClients,
		isLoading: isLoadingClients,
	} = useGetUniqueClientNames(CLIENT_DATA_SOURCE);
	const { data: jobTypes, isError: isErrorJobTypes, isLoading: isLoadingJobTypes } = useGetJobTypes();
	const { mutate: updateJobDetails, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate } = useUpdateJobDetails();
	const {
		data: nextJobNumber,
		isError: isErrorNextJobNumber,
		isLoading: isLoadingNextJobNumber,
	} = useGetNextJobNumber();

	// DATA
	const clientNamesArray = toArrayNames(clients?.result);
	const jobTypeNamesArray = toArrayNames(jobTypes?.result);

	const findRecord = (data: any[], name: string) => {
		const record = data?.find((item) => item?.name === name);

		return record;
	};

	useEffect(() => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				client_id: findRecord(clients?.result, client_name)?.id,
				job_cat: findRecord(jobTypes?.result, job_type)?.id,
			},
		});
	}, [clients, jobTypes]);

	useEffect(() => {
		const jobNumber = nextJobNumber.result;

		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				job_number: jobNumber,
			},
		});
	}, [nextJobNumber]);

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeTextFieldHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				[e.target.name]: e.target.value,
			},
		});
	};

	// ON CHANGE CLIENT
	const changeClientHandler = (name: any) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				client_id: findRecord(clients?.result, name)?.id,
			},
		});
	};

	// ON CHANGE JOB TYPE
	const changeJobTypeHandler = (name: any) => {
		setJobDetails({
			...jobDetails,
			job: {
				...jobDetails.job,
				job_cat: findRecord(jobTypes?.result, name)?.id,
			},
		});
	};

	const updateHandler = (e: any) => {
		closeHandler();
		updateJobDetails(jobDetails);
	};

	return (
		<div>
			<Button
				sx={{ textDecoration: 'underline', color: COLORS.primary.default, textTransform: 'capitalize' }}
				variant='text'
				onClick={clickOpenHandler}
			>
				Sync Job
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Update Missing Details
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
					<p style={{ fontSize: '16px' }}>Please update the missing details below to sync job.</p>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '500px' }}>
					<FormControl sx={{ width: '100%' }}>
						{isLoadingClients || isLoadingJobTypes ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Job Name:</Typography>
									<TextField
										name='job_name'
										value={jobDetails.job.job_name}
										fullWidth
										id='standard-basic'
										variant='standard'
										placeholder='Job name'
										size='small'
										sx={{ marginTop: '16px' }}
										onChange={(e) => changeTextFieldHandler(e)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Description:</Typography>
									<TextField
										name='job_description'
										value={jobDetails.job.job_description}
										fullWidth
										id='standard-basic'
										variant='standard'
										placeholder='Description'
										size='small'
										sx={{ marginTop: '16px' }}
										onChange={(e) => changeTextFieldHandler(e)}
									/>
								</Box>
								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%' }}>Client:</Typography>
									<Autocomplete
										options={clientNamesArray}
										defaultValue={client_name}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Client Name' variant='standard' />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeClientHandler(newValue)
										}
									/>
								</Box>

								<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
									<Typography sx={{ width: '55%', marginBottom: '5px' }}>Job Type:</Typography>
									<Autocomplete
										options={jobTypeNamesArray}
										defaultValue={
											jobTypes?.result?.find((item: any) => item?.id === jobDetails.job.job_cat)
												?.name
										}
										clearOnEscape
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField {...params} label='Job type' variant='standard' />
										)}
										onChange={(event: any, newValue: string | undefined | null) =>
											changeJobTypeHandler(newValue)
										}
									/>
								</Box>
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.archiveBtn}
						variant='contained'
						onClick={(e) => updateHandler(e)}
						disableElevation
						disabled={isMilestoneLimit || isErrorClients || isErrorJobTypes}
					>
						Sync
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default UpdateAsanaJob;
