import { useEffect, useState, useContext } from 'react';
import { Box, Typography, Link, TextField, Autocomplete, InputAdornment, Input, Checkbox } from '@mui/material';
// import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { toast } from 'react-toastify';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import NumberFormat from 'react-number-format';

const top100Films = [
	{ title: 'Web', id: 1, indexN: 0 },
	{ title: 'SEO', id: 2, indexN: 1 },
	{ title: 'Content', id: 3, indexN: 2 },
	{ title: 'Paid Media', id: 4, indexN: 3 },
	{ title: 'Email Marketing', id: 5, indexN: 4 },
	{ title: 'Community Management', id: 6, indexN: 5 },
];

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

export const PageTwo = ({
	handleJobNumber,
	editJobNumber,
	jobNumber,
	jobName,
	handleJobName,
	editJobName,
	setEditJobName,
	setEditJobNumber,
	invoice,
	handleInvoice,
	editInvoice,
	setEditInvoice,
	assignAvailableJobNumber,
	jobNumberAvailbale,
	// clientName,
	// handleClient,
	editClient,
	setEditClient,
	handleQuoteNumber,
	quoteNumber,
	editQuote,
	setEditQuote,
	handleStartDate,
	startDate,
	editStartDate,
	setEditStartDate,
	handleEndDate,
	endDate,
	editEndDate,
	setEditEndDate,
	assignJobData,
	handleFirstMilestone,
	clientList,
	setAssignJobData,
	milestoneValidation,
	handleSecondMilestone,
	handleThirdMilestone,
	handleFourthMilestone,
	milestoneData,
	invoiceMilestonePercentage,
	handleAddMilestone,
	handleUpdatePercent,
	handleRemoveMilestone,
	jobType,
	handleAddJob,
	teamList,
	assignJobValidation,
	handleDescription,
	defaultPropsProjectJobs,
	projectJobs,
	selectedProject,
	setSelectedProject,
	defaultPropsRetainerJobs,
	selectedRetainer,
	setSelectedRetainer,
	selectedNewJobType,
	setSelectedNewJobType,
	defaultPropsNewJobType,
	selectedInvFreq,
	setSelectedInvFreq,
	defaultPropsInvFrequency,
	handleActiveJob,
	setSelectedJob,
	selectedJob,
}: any) => {
	const checkList =
		assignJobData.retainerWork && assignJobData.retainerWork.length
			? assignJobData.retainerWork.map((value: any) => {
					return top100Films[value.indexN];
			  })
			: [];

	const defaultProps = {
		options: clientList,
		getOptionLabel: (option: any) => option.name,
	};

	const defaultPropsTeams: any = {
		options: teamList,
		getOptionLabel: (option: any) => option.name,
	};

	const handleTotalInvoicePercent = invoiceMilestonePercentage.length
		? invoiceMilestonePercentage
				.map((item: any) => Number(item.percent))
				.reduce((prev: any, curr: any) => prev + curr, 0)
		: 0;

	const getDefaultTeamSelect = teamList.filter((value: any) => {
		const checkName = value.name ? value.name : String(Math.random());
		const checkExisting =
			assignJobData.teams && assignJobData.teams.name ? assignJobData.teams.name : String(Math.random());
		return checkName.toLowerCase().includes(checkExisting.toLowerCase());
	});

	return (
		<Box style={{ width: '500px', overflow: 'hidden' }}>
			<Box mt={2} mb={3} style={{ display: 'flex' }}>
				<Box className='gs__text-field' style={{ display: 'flex' }}>
					<TextField
						onChange={handleJobName}
						style={{
							width: 'max-content',
						}}
						defaultValue={jobName}
						placeholder='*Please enter job name'
						id='standard-basic'
						variant='standard'
						error={jobName ? false : true}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>Client:</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultProps}
						id='clear-on-escape'
						disableClearable
						value={assignJobData.clientSelected}
						onChange={(value: any, newValue: any) => {
							setAssignJobData({ ...assignJobData, clientSelected: newValue });
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select client'
								error={assignJobData.clientSelected && assignJobData.clientSelected.name ? false : true}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box style={{ display: jobType === '3' ? 'flex' : 'none', marginBottom: '5px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Active Jobs:
				</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultPropsProjectJobs}
						id='clear-on-escape'
						disableClearable
						disabled={assignJobData.clientSelected.id ? false : true}
						value={selectedProject}
						onChange={(value: any, newValue: any) => {
							setSelectedProject(newValue);
							setSelectedJob(newValue);
							// handleActiveJob(newValue.id);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select job'
								// error={selectedProject && selectedProject.name ? false : true}
								error={
									(selectedProject && selectedProject.name) || !assignJobData.clientSelected.id
										? false
										: true
								}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box style={{ display: jobType === '2' ? 'flex' : 'none', marginBottom: '5px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Active Jobs:
				</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultPropsRetainerJobs}
						id='clear-on-escape'
						disableClearable
						disabled={assignJobData.clientSelected.id ? false : true}
						value={selectedRetainer}
						onChange={(value: any, newValue: any) => {
							setSelectedRetainer(newValue);
							setSelectedJob(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select job'
								// error={selectedRetainer && selectedRetainer.name ? false : true}
								error={
									(selectedRetainer && selectedRetainer.name) || !assignJobData.clientSelected.id
										? false
										: true
								}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box style={{ display: jobType === '1' ? 'flex' : 'none', marginBottom: '5px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>New Job:</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultPropsNewJobType}
						id='clear-on-escape'
						disableClearable
						value={selectedNewJobType}
						onChange={(value: any, newValue: any) => {
							setSelectedNewJobType(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select category'
								error={selectedNewJobType && selectedNewJobType.name ? false : true}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>Teams:</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultPropsTeams}
						id='clear-on-escape'
						disableClearable
						disabled={
							jobType !== '1'
								? assignJobData.clientSelected.id && selectedJob.id
									? false
									: true
								: false
						}
						value={getDefaultTeamSelect.length ? getDefaultTeamSelect[0] : { id: 0, name: '' }}
						// defaultValue={getDefaultTeamSelect}
						onChange={(event, newValue) => {
							handleAddJob(newValue, 'teams');
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label='Select teams'
								// error={!getDefaultTeamSelect.length ? true : false}
								error={
									!selectedJob.id && jobType !== '1'
										? false
										: !getDefaultTeamSelect.length
										? true
										: false
								}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Description:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					<TextField
						onChange={handleDescription}
						value={assignJobData.description}
						id='standard-basic'
						variant='standard'
						placeholder='*Please enter description'
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						error={!selectedJob.id && jobType !== '1' ? false : !assignJobData.description ? true : false}
						// error={!assignJobData.description ? true : false}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Job Number:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					<TextField
						// disabled={selectedJob.id ? true : false}
						disabled={
							jobType !== '1'
								? assignJobData.clientSelected.id && selectedJob.id
									? selectedJob.id
										? true
										: false
									: true
								: false
						}
						onChange={handleJobNumber}
						value={assignJobData.job_number}
						id='standard-basic'
						variant='standard'
						placeholder='*Please enter job number'
						error={!assignJobData.job_number ? true : false}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Quote Number:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					<TextField
						onChange={handleQuoteNumber}
						value={assignJobData.qoute_number}
						id='standard-basic'
						variant='standard'
						placeholder='*Please enter quote number'
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						error={assignJobData.qoute_number ? false : true}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>Fees:</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					<NumberFormat
						// className='gs-activeJobs__basic-input'
						thousandsGroupStyle='thousand'
						onValueChange={(values) => {
							// handleAddJob(values, 'fees');
							setAssignJobData({ ...assignJobData, fees: values.floatValue, feeNum: values.floatValue });
						}}
						value={assignJobData.fees}
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						error={!selectedJob.id && jobType !== '1' ? false : !assignJobData.fees ? true : false}
						// error={assignJobData.fees ? false : true}
						decimalSeparator='.'
						customInput={Input}
						displayType='input'
						type='text'
						id='standard-basic'
						startAdornment={<InputAdornment position='start'>$</InputAdornment>}
						thousandSeparator={true}
						allowNegative={false}
					/>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Estimated time:
				</Typography>
				<Box style={{ display: 'flex', alignItems: 'end' }}>
					<TextField
						type='number'
						// className='gs-activeJobs__basic-input'
						style={{ width: '80px' }}
						id='standard-basic'
						variant='standard'
						inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
						onChange={(event: any) =>
							setAssignJobData({
								...assignJobData,
								estimatedTimeHours:
									Number(event.target.value) < 0 || !Number(event.target.value)
										? ''
										: event.target.value,
							})
						}
						defaultValue={assignJobData.estimatedTimeHours}
						value={assignJobData.estimatedTimeHours}
						placeholder='*Hours'
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						error={
							!selectedJob.id && jobType !== '1'
								? false
								: !assignJobData.estimatedTimeHours
								? true
								: false
						}
						// error={assignJobData.estimatedTimeHours ? false : true}
					/>
				</Box>
			</Box>

			{/* <Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Start Date:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					// <LocalizationProvider dateAdapter={AdapterDateFns}>
						<MobileDatePicker
							className='gs__date-picker-container'
							value={startDate}
							onChange={(newValue: any) => {
								handleStartDate(newValue);
							}}
							renderInput={(params: any) => <TextField className={'gs__date-picker'} {...params} />}
						/>
					</LocalizationProvider>
				</Box>
			</Box>

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					End Date:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end' }}>
					// <LocalizationProvider dateAdapter={AdapterDateFns}>
						<MobileDatePicker
							className='gs__date-picker-container'
							value={endDate}
							onChange={(newValue: any) => {
								handleEndDate(newValue);
							}}
							renderInput={(params: any) => <TextField className={'gs__date-picker'} {...params} />}
						/>
					</LocalizationProvider>
				</Box>
			</Box> */}

			<Box style={{ display: 'flex', marginBottom: '5px', height: '48px' }}>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					{jobType === '2' || (jobType === '1' && selectedNewJobType.id === 2) ? 'Contract ' : ''}Dates:
				</Typography>
				<Box className='gs__text-field' style={{ display: 'flex', alignItems: 'end', width: '69%' }}>
					<Typography className='gs-pagetwo__text-mr'>Start</Typography>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
						<MobileDatePicker
							className='gs__date-picker-container'
							value={startDate}
							disabled={
								jobType !== '1'
									? assignJobData.clientSelected.id && selectedJob.id
										? false
										: true
									: false
							}
							onChange={(newValue: any) => {
								handleStartDate(newValue);
							}}
							renderInput={(params: any) => <TextField className='gs__date-picker' {...params} />}
						/>
					{/* </LocalizationProvider> */}
					<Typography className='gs-pagetwo__text-ml-mr'>End</Typography>
					{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
						<MobileDatePicker
							className='gs__date-picker-container'
							value={endDate}
							disabled={
								jobType !== '1'
									? assignJobData.clientSelected.id && selectedJob.id
										? false
										: true
									: false
							}
							onChange={(newValue: any) => {
								handleEndDate(newValue);
							}}
							renderInput={(params: any) => (
								<TextField
									// style={{ width: '90px', minWidth: '90px' }}
									className='gs__date-picker'
									{...params}
								/>
							)}
						/>
					{/* </LocalizationProvider> */}
				</Box>
			</Box>

			<Box
				style={{
					display: `${jobType === '2' || (jobType === '1' && selectedNewJobType.id === 2) ? 'flex' : 'none'}`,
					marginBottom: '5px',
				}}
			>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Invoice Frequency:
				</Typography>
				<Box className='gs__text-field'>
					<Autocomplete
						// className='gs-activeJobs__basic-input--description'
						{...defaultPropsInvFrequency}
						id='clear-on-escape'
						disableClearable
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						value={selectedInvFreq}
						onChange={(value: any, newValue: any) => {
							setSelectedInvFreq(newValue);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								disabled={
									jobType !== '1'
										? assignJobData.clientSelected.id && selectedJob.id
											? false
											: true
										: false
								}
								label='Select invoice frequency'
								error={selectedInvFreq && selectedInvFreq.name ? false : true}
								variant='standard'
							/>
						)}
					/>
				</Box>
			</Box>

			{/* <Box
				style={{
					minHeight: '28px',
					marginTop: '0px',
					marginBottom: '15px',
					display: jobType === '2' ? 'flex' : 'none',
				}}
				className='gs-pagetwo__row-box'
			> */}
			<Box
				style={{
					// minHeight: '28px',
					marginTop: '0px',
					marginBottom: '5px',
					minHeight: '48px',
					display: 'flex',
				}}
				className='gs-pagetwo__row-box'
			>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Job type:
				</Typography>
				<Box style={{ display: 'flex', alignItems: 'end' }} className='gs__text-field'>
					<Autocomplete
						multiple
						id='size-small-standard-multi'
						size='small'
						options={top100Films}
						disableCloseOnSelect
						getOptionLabel={(option: any) => option.title}
						defaultValue={checkList}
						value={checkList}
						disabled={
							jobType !== '1' ? (assignJobData.clientSelected.id && selectedJob.id ? false : true) : false
						}
						onChange={(event, newValue) => {
							setAssignJobData({ ...assignJobData, retainerWork: newValue });
						}}
						// retainerType
						renderOption={(props, option: any, { selected }) => (
							<li {...props}>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
								/>
								{option.title}
							</li>
						)}
						style={{ width: '100%' }}
						renderInput={(params) => (
							<TextField
								{...params}
								disabled={
									jobType !== '1'
										? assignJobData.clientSelected.id && selectedJob.id
											? false
											: true
										: false
								}
								error={checkList.length ? false : true}
								variant='standard'
								placeholder='Select job type'
							/>
						)}
					/>
				</Box>
			</Box>

			<Box
				style={{
					display: `${jobType === '3' || (jobType === '1' && selectedNewJobType.id === 3) ? 'flex' : 'none'}`,
					marginBottom: '15px',
					height: '48px',
				}}
			>
				<Typography style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}>
					Invoice Milestones:
				</Typography>
				<Box style={{ display: 'flex', width: '100%', alignItems: 'end' }}>
					<Typography
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							justifyContent: 'space-between',
							width: '100%',
							color: handleTotalInvoicePercent > 100 ? 'red' : 'black',
						}}
					>
						{invoiceMilestonePercentage.length
							? invoiceMilestonePercentage.map((value: any, index: any) => {
									if (index === invoiceMilestonePercentage.length - 1) {
										return `${value.percent ? value.percent : 0}`;
									} else {
										return `${value.percent ? value.percent : 0}/`;
									}
							  })
							: '0'}{' '}
						={' '}
						{invoiceMilestonePercentage.length
							? invoiceMilestonePercentage
									.map((item: any) => Number(item.percent))
									.reduce((prev: any, curr: any) => prev + curr, 0)
							: 0}
						%
						<span style={{ display: 'flex' }}>
							<AddOutlinedIcon
								className='gs__icon__edit'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									if (
										jobType !== '1'
											? assignJobData.clientSelected.id && selectedJob.id
												? false
												: true
											: false
									) {
										toast.info('Select clieent and active job first');
									} else {
										handleAddMilestone();
									}
								}}
							/>
						</span>
					</Typography>
				</Box>
			</Box>
			{jobType === '3' || (jobType === '1' && selectedNewJobType.id === 3)
				? invoiceMilestonePercentage.map((value: any, index: any) => {
						return (
							<Box key={index} style={{ display: 'flex', marginBottom: '15px' }}>
								<Typography
									style={{ minWidth: '170px', display: 'flex', alignItems: 'flex-end' }}
								></Typography>
								<Box className='gs__text-field' style={{ display: 'flex' }}>
									<div style={{ width: '22%', marginRight: '12px' }}>
										<Input
											onChange={(event) => handleUpdatePercent(event, index, 'percent')}
											value={value.percent}
											id='standard-adornment-amount'
											disabled={
												jobType !== '1'
													? assignJobData.clientSelected.id && selectedJob.id
														? false
														: true
													: false
											}
											// variant='standard'
											// placeholder='%'
											endAdornment={<InputAdornment position='start'>%</InputAdornment>}
											// endAdornment={
											// 	<InputAdornment position='end'>
											// 		<AddOutlinedIcon />
											// 	</InputAdornment>
											// }
											// error={clientName ? false : true}
										/>
									</div>
									<Input
										onChange={(event) => handleUpdatePercent(event, index, 'notes')}
										value={value.notes}
										id='standard-adornment-amount'
										placeholder='Notes'
										multiline
										disabled={
											jobType !== '1'
												? assignJobData.clientSelected.id && selectedJob.id
													? false
													: true
												: false
										}
										endAdornment={
											<InputAdornment position='end'>
												<ClearIcon
													style={{ cursor: 'pointer' }}
													onClick={() => {
														if (
															jobType !== '1'
																? assignJobData.clientSelected.id && selectedJob.id
																	? false
																	: true
																: false
														) {
															toast.info('Select clieent and active job first');
														} else {
															handleRemoveMilestone(index);
														}
													}}
													className='gs__icon__edit'
												/>
											</InputAdornment>
										}
										// error={clientName ? false : true}
									/>
								</Box>
							</Box>
						);
				  })
				: ''}

			{/* <Typography sx={{ mb: 1, fontSize: '16px', mt: 6 }}>
				All remaining attributes will be pulled from the quote.
			</Typography> */}
			{/* <Link href='/#' color='#000' sx={{ fontSize: '14px' }}>
				Edit quote
			</Link> */}
		</Box>
	);
};
