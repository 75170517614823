import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/theme';

export const useConfirmationModalStyles = makeStyles((theme) => ({
	modal: {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '400px',
		backgroundColor: '#fff',
		borderRadius: '4px',
		boxShadow: '24',
		padding: '2rem',
	},
	modalDownload: {
		position: 'absolute' as 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '700px',
		backgroundColor: '#fff',
		borderRadius: '4px',
		boxShadow: '24',
		padding: '2rem',
	},

	btnWrapper: {
		marginTop: '3rem',
		display: 'flex',
		gap: '1rem',
		justifyContent: 'flex-end',
	},

	submit: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	backBtn: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.neutral.shade600,

		'&:hover': {
			backgroundColor: COLORS.neutral.shade700,
		},
	},

	addTaskBtn: {
		minWidth: '40%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		color: COLORS.primary.default,
		textDecoration: 'underline',
		fontWeight: 'bold',
		padding: '0 8px',
		marginLeft: '-8px',

		'&:hover': {
			backgroundColor: COLORS.primary.shade100,
		},
	},

	dialogTitle: {
		padding: '2rem',
	},

	closeBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'unset',
		border: 'none',
		width: '100%',
	},

	title: {
		fontWeight: 'bold',
		fontSize: '32px',
	},

	radioButton: {
		border: `1px solid ${COLORS.neutral.shade700}`,
		padding: '2px 8px',
		borderRadius: '10px',
		width: '100%',
		marginLeft: '0px',
	},
}));
