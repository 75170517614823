function pad(n: any) {
	return ('000000' + n).slice(-4);
}

export const extractInt = (value: string) => {
	return Number(value.replace(/^\D+/g, ''));
};

export const jobNumberHelper = (data = []) => {
	const getLength: any = data[data.length - 1];
	const getNumber = getLength ? Number(getLength.job_number.replace(/^\D+/g, '')) : 0;

	const createAvailableNumber = [getNumber, getNumber, getNumber, getNumber].map(
		(value: any, index: number) => ` GSQ-${pad(value + index + 1)}`
	);

	return {
		assignJobNumber: createAvailableNumber[0],
		assignAvailableJobNumber: createAvailableNumber.toString(),
	};
};
