import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../constants/theme';

export const useArchiveStyles = makeStyles((theme) => ({
	archiveBtn: {
		minWidth: '30%',
		borderRadius: '50px',
		textTransform: 'capitalize',
		backgroundColor: COLORS.primary.default,

		'&:hover': {
			backgroundColor: COLORS.primary.shade600,
		},
	},

	dialogTitle: {
		padding: '2rem',
	},

	closeBtn: {
		display: 'flex',
		justifyContent: 'space-between',
		backgroundColor: 'unset',
		border: 'none',
		width: '100%',
	},

	title: {
		fontWeight: 'bold',
		marginBottom: '1.5rem',
	},

	subTitle: {
		marginBottom: '.5rem',
		color: COLORS.neutral.shade400,
	},
}));
