import { TablePaginationComponentProps } from './TablePaginationComponent.props';
import { TablePagination } from '@mui/material';

// TABLE PAGINATION

const TablePaginationComponent = (props: TablePaginationComponentProps) => {
	const { count, rowsPerPage, pageNumber, onChangePage, onChangeRowsPerPage } = props;
	const rowsPerPageOptions = [10, 20, 30];

	return (
		<TablePagination
			component='div'
			count={count}
			rowsPerPageOptions={rowsPerPageOptions}
			rowsPerPage={rowsPerPage}
			page={pageNumber}
			onPageChange={onChangePage}
			onRowsPerPageChange={onChangeRowsPerPage}
		/>
	);
};

export default TablePaginationComponent;
