import { useState } from 'react';
import { Box, Typography, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Task, TimeEntries, Documents, Costs, Quotes, Invoices } from './Routes';
import SearchIcon from '@mui/icons-material/Search';
import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';

const blue = {
	500: '#007FFF',
};

const grey = {
	400: '#BFC7CF',
	500: '#AAB4BE',
};

const Root = styled('span')`
	font-size: 0;
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	margin: 10px;
	cursor: pointer;
`;

interface StyledTabsProps {
	children?: React.ReactNode;
	value: number;
	onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	fontWeight: theme.typography.fontWeightBold,
	fontSize: theme.typography.pxToRem(15),
	marginRight: theme.spacing(5),
	color: 'rgba(17,17,17,0.7)',
	'&.Mui-selected': {
		color: '#000000',
	},
	'&.Mui-focusVisible': {
		backgroundColor: 'rgba(100,95,228,0.32)',
	},
}));

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ p: 0, mt: 3 }}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

export const JpTabs = ({
	dataJobTask,
	asana_project_id,
	jobDetails,
	loadingJobTask,
	search,
	setSearch,
	searchHandle,
	jobFilter,
	setJobFilter,
	// handleShowSearch,
	handleResetSearchFilter,
	handleTaskDateDisable,
	handleDateFilter,
}: any) => {
	const [value, setValue] = useState(0);
	const [showSearch, setShowSearch] = useState(false);
	// const [search, setSearch] = useState<any>('');
	// const [jobFilter, setJobFilter] = useState<any>({
	// 	taskDate: new Date(),
	// 	taskDateDisable: true,
	// 	filteredData: [],
	// });

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	// const handleResetSearchFilter = () => {
	// 	setJobFilter({ taskDate: new Date(), taskDateDisable: true, filteredData: [] });
	// 	setSearch('');
	// };

	const handleShowSearch = () => {
		setShowSearch(!showSearch);
		handleResetSearchFilter();
	};

	const StyledTabs = styled((props: StyledTabsProps) => (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Tabs
				{...props}
				onClick={handleResetSearchFilter}
				TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }}
			/>
			<SearchIcon
				style={{ width: '50px', color: '#c5332f', cursor: 'pointer' }}
				onClick={() => handleShowSearch()}
			/>
		</div>
	))({
		'& .MuiTabs-indicator': {
			display: 'flex',
			justifyContent: 'center',
			backgroundColor: 'transparent',
		},
		'& .MuiTabs-indicatorSpan': {
			maxWidth: 100,
			width: '100%',
			backgroundColor: '#292929',
		},
	});

	// const searchHandle = (event: any) => {
	// 	event.preventDefault();
	// 	setSearch(event.target.value);
	// };

	// const dateLabel = value === 0 ? 'Due On - Month and Year' : 'Date - Month and Year';
	const dateLabel = value === 0 ? 'Month and Year' : 'Month and Year';

	const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };

	// const handleTaskDateDisable = (event: any) => {
	// 	setJobFilter({ ...jobFilter, taskDateDisable: !(event.target as HTMLInputElement).checked });
	// };

	// const handleDateFilter = (date: any) => {
	// 	setJobFilter({ ...jobFilter, taskDate: date });
	// };

	return (
		<div>
			<Box sx={{ marginLeft: 0, marginTop: 3 }}>
				<StyledTabs value={value} onChange={handleChange} aria-label='job-page-tabs'>
					<StyledTab label='Quotes' {...a11yProps(0)} />
					<StyledTab label='Invoices' {...a11yProps(1)} />
					<StyledTab label='Costs' {...a11yProps(2)} />
					<StyledTab label='Documents' {...a11yProps(3)} />
					<StyledTab label='Time Entries' {...a11yProps(4)} />
					<StyledTab label='Task' {...a11yProps(5)} />
				</StyledTabs>

				<div className={`searchbar-container ${showSearch ? 'show' : ''}`}>
					<div className='gs-unassignedJobs__header-div'>
						<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
							Search :
						</Typography>
						<TextField
							value={search}
							onChange={(event) => searchHandle(event)}
							className='gs-unassignedJobs__basic-input'
							id='standard-basic'
							variant='standard'
						/>
					</div>
					<fieldset className='gs-container__filter'>
						<legend>Filters:</legend>
						<div className='gs-cpntainer__filter-content'>
							{/* <SwitchUnstyled
								checked={!jobFilter.taskDateDisable}
								onChange={(value) => handleTaskDateDisable(value)}
								component={Root}
								{...label}
							/> */}
							{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
							<DatePicker
								disabled={jobFilter.taskDateDisable}
								views={['year', 'month']}
								label={dateLabel}
								minDate={new Date('2018-03-01')}
								maxDate={new Date('2026-06-01')}
								value={jobFilter.taskDate}
								onChange={(value: any) => handleDateFilter(value)}
								renderInput={(params: any) => <TextField {...params} helperText={null} />}
							/>
							{/* </LocalizationProvider> */}
						</div>
					</fieldset>
				</div>
				<TabPanel value={value} index={0}>
					<Quotes jobFilter={jobFilter} tbIndex={value} search={search} asana_project_id={asana_project_id} />
				</TabPanel>
				<TabPanel value={value} index={1}>
					<Invoices jobFilter={jobFilter} tbIndex={value} search={search} jobDetails={jobDetails} />
				</TabPanel>
				<TabPanel value={value} index={2}>
					<Costs jobFilter={jobFilter} tbIndex={value} search={search} asana_project_id={asana_project_id} />
				</TabPanel>
				<TabPanel value={value} index={3}>
					<Documents jobFilter={jobFilter} tbIndex={value} search={search} jobDetails={jobDetails} />
				</TabPanel>
				<TabPanel value={value} index={4}>
					<TimeEntries
						jobFilter={jobFilter}
						tbIndex={value}
						search={search}
						asanaProjectId={asana_project_id}
					/>
				</TabPanel>
				<TabPanel value={value} index={5}>
					<Task
						jobFilter={jobFilter}
						tbIndex={value}
						search={search}
						loadingJobTask={loadingJobTask}
						dataJobTask={dataJobTask}
						asana_project_id={asana_project_id}
						jobDetails={jobDetails}
					/>
				</TabPanel>
			</Box>
		</div>
	);
};
