import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Link, useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Typography, TextField, Button, Box, Chip } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Avatar } from '@mui/material';
import { DataTable } from '../../components';
import { JpTabs } from './Routes';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { toast } from 'react-toastify';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Billing } from './Billing';
import { UpdateDetails } from './UpdateDetails/UpdateDetails';
import { getJobTask, useGetInvoices, usePostBill, usePutRetainerWork, usePutJobDetails } from '../../services/api';
import { handleCurrency } from '../../utils';
import Context from '../../services/context';
import { UpdateWork } from './RetainerWork/UpdateWork';

const budgetsOptions: any = {
	'1': [
		{
			value: '1',
			label: 'No budget',
			type: 'no_budget',
		},
		{
			value: '2',
			label: 'Total hours',
			type: 'time',
		},
		{
			value: '3',
			label: 'Total costs',
			type: 'money',
		},
	],
	'2': [
		{
			value: '1',
			label: 'No budget',
			type: 'no_budget',
		},
		{
			value: '2',
			label: 'Total billable amount',
			type: 'money',
		},
		{
			value: '3',
			label: 'Total hours',
			type: 'time',
		},
		{
			value: '4',
			label: 'Total costs',
			type: 'money',
		},
	],
	'3': [
		{
			value: '1',
			label: 'No budget',
			type: 'no_budget',
		},
		{
			value: '2',
			label: 'Total billable amount',
			type: 'money',
		},
		{
			value: '3',
			label: 'Total hours',
			type: 'time',
		},
		{
			value: '4',
			label: 'Total costs',
			type: 'money',
		},
	],
	'4': [
		{
			value: '1',
			label: "Don't track",
			type: 'no_budget',
		},
		{
			value: '2',
			label: 'By total hours',
			type: 'time',
		},
		{
			value: '3',
			label: 'By project rate',
			type: 'money',
		},
		{
			value: '4',
			label: 'By members rate',
			type: 'money',
		},
		{
			value: '5',
			label: 'By members costs',
			type: 'money',
		},
	],
};

const billings = [
	{
		value: '1',
		label: 'Non-billable',
		type: 'non_billable',
	},
	{
		value: '2',
		label: 'T&M (project rate)',
		type: 'project_rate',
	},
	{
		value: '3',
		label: 'T&M (members rate)',
		type: 'user_rate',
	},
	{
		value: '4',
		label: 'Fixed-fee',
		type: 'fixed_fee',
	},
];

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					color='success'
					variant='determinate'
					{...props}
					sx={{ height: 20, backgroundColor: '#e9ecee' }}
				/>
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant='body2' color='text.success'>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

const blue = {
	500: '#007FFF',
};

const grey = {
	400: '#BFC7CF',
	500: '#AAB4BE',
};

const Root = styled('span')`
	font-size: 0;
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	margin: 10px;
	cursor: pointer;
`;

const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };

export const JobPage = () => {
	const [progress, setProgress] = useState(42);
	const [showSearch, setShowSearch] = useState(false);
	const [value, setValue] = useState(0);
	const [search, setSearch] = useState<any>('');
	const [rows, setRows] = useState<any>([]);
	const [jobId, setJobId] = useState<any>(null);
	const { dispatch, state } = React.useContext(Context);
	const [billingColumnTitle, setBillingColumnTitle] = useState<any>('');
	const [budgetColumnTitle, setBudgetColumnTitle] = useState<any>('');
	const [retainerWork, setRetainerWork] = useState<any>([]);

	const [jobFilter, setJobFilter] = useState<any>({
		taskDate: new Date(),
		taskDateDisable: true,
		filteredData: [],
	});
	const resultState: any = useLocation();
	const asana_project_id = resultState.state.asana_project_id;
	const { data: dataJobTask, isLoading: loadingJobTask } = useQuery(['get-job-task', asana_project_id], () =>
		getJobTask(asana_project_id)
	);
	useGetInvoices();

	useEffect(() => {
		if (resultState.state) {
			const rowFormat = [resultState.state].map((value: any) => ({
				...value,
				billing: value.billing && value.billing.rate ? Number(value.billing.rate) : 0,
				budget:
					value.budget && value.budget.type
						? value.budget.type === 'money'
							? Number(value.budget.total)
							: value.budget.total
						: '0',
			}));

			const billTitle =
				(resultState.state.billing && resultState.state.billing.type === 'non_billable') ||
				resultState.state.billing === '-'
					? 'Non-billable'
					: resultState.state.billing && resultState.state.billing.type === 'fixed_fee'
					? 'Billed fixed-fee'
					: 'Rate/Hr';

			const budgetitle =
				(resultState.state.budget && resultState.state.budget.type === 'no_budget') ||
				resultState.state.budget === '-'
					? 'No budget'
					: resultState.state.budget && resultState.state.budget.type === 'money'
					? 'Total budget cost'
					: 'Total budget hours';

			const serviceList =
				resultState.state.services_list && Object.keys(resultState.state.services_list).length !== 0
					? resultState.state.services_list.map((value: any) => value.service)
					: [];
			const filterServiceList = serviceList.length
				? top100Films.filter((value: any) => serviceList.includes(value.title))
				: [];

			setRetainerWork(filterServiceList);
			setBudgetColumnTitle(budgetitle);
			setRows(rowFormat);
			setBillingColumnTitle(billTitle);
		}

		if (resultState.state && resultState.state.jobId) {
			setJobId(resultState.state.jobId);
		}
	}, [resultState.state]);

	const searchHandle = (event: any) => {
		event.preventDefault();
		setSearch(event.target.value);
	};

	const handleTaskDateDisable = (event: any) => {
		setJobFilter({ ...jobFilter, taskDateDisable: !(event.target as HTMLInputElement).checked });
	};

	const handleDateFilter = (date: any) => {
		setJobFilter({ ...jobFilter, taskDate: date });
	};

	const handleResetSearchFilter = () => {
		setJobFilter({ taskDate: new Date(), taskDateDisable: true, filteredData: [] });
		setSearch('');
	};

	const handleShowSearch = () => {
		setShowSearch(!showSearch);
		handleResetSearchFilter();
	};

	useEffect(() => {
		if (dataJobTask && dataJobTask.error === true) {
			toast.error(
				`${
					dataJobTask.message
						? dataJobTask.message.message
							? dataJobTask.message.message
							: dataJobTask.message
						: ''
				} : error for list of task`
			);
		}
	}, [dataJobTask]);

	const dateLabel = value === 0 ? 'Due On - Month and Year' : 'Date - Month and Year';

	// billing process ===================================================
	const [showBilling, setShowBilling] = useState(false);

	const toggleBillingTask = () => {
		setShowBilling((showBilling) => !showBilling);
	};

	// =====================================================
	const { mutate, data: billAddData, isLoading: billAddLoading } = usePostBill();
	const [budget, setBudget] = useState<any>('1');
	const [billing, setBilling] = useState<any>('1');
	const [billingvalues, setBillingValues] = useState<any>('');
	const [budgetvalues, setbudgetValues] = useState<any>('');

	const [addBill, setAddBill] = useState<any>({
		billValue: '',
		billValueDisplay: '',
		budgetValue: '',
		budgetValueDisplay: '',
		billingOption: '1',
		budgetOption: '1',
		disallowOverBudget: false,
		excludeCostExpenses: false,
		excludeBillableExpenses: false,
		excludeNonBillableTime: false,
		resetBudget: false,
		startBudget: false,
		showBudget: false,
		emailThreshold: false,
	});
	// const [billValidation, setBillValidation] = useState<any>({
	// 	billValue: false,
	// 	budgetValue: false,
	// });
	const [checked, setChecked] = useState(true);

	const onlyNumbers = (str: any) => {
		return /^[0-9]+$/.test(str);
	};

	const handleCheckboxChange = (event: any) => {
		setChecked(event.target.checked);
	};

	const handleNumberStrictChecking = (value: any, assignData: any) => {
		const testMilestone = onlyNumbers(value);

		const assignValue = testMilestone ? value : assignData;
		return assignValue;
	};

	const handleBillData = (event: any, field: any) => {
		switch (field) {
			case 'bill':
				setAddBill({ ...addBill, billValue: event.formattedValue, billValueDisplay: event.floatValue });
				break;
			case 'budget':
				setAddBill({ ...addBill, budgetValue: event.formattedValue, budgetValueDisplay: event.floatValue });
				break;
			case 'billingOption':
				const valueBillOption = (event.target as HTMLInputElement).value;
				setAddBill({
					...addBill,
					billValue: valueBillOption === '1' ? '0' : addBill.billValue,
					budgetValue: valueBillOption === '1' ? '0' : addBill.budgetValue,
					billingOption: valueBillOption,
					budgetOption: '1',
					disallowOverBudget: false,
					excludeCostExpenses: false,
					excludeBillableExpenses: false,
					excludeNonBillableTime: false,
					resetBudget: false,
					startBudget: false,
					showBudget: false,
					emailThreshold: false,
				});
				break;
			case 'budgetOption':
				const valueBudgetOption = (event.target as HTMLInputElement).value;
				setAddBill({
					...addBill,
					budgetValue: valueBudgetOption === '1' ? '0' : addBill.budgetValue,
					budgetOption: valueBudgetOption,
				});
				break;
			case 'disallowOverBudget':
				const disallowOverBudgetValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, disallowOverBudget: disallowOverBudgetValue });
				break;
			case 'excludeCostExpenses':
				const excludeCostExpensesValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, excludeCostExpenses: excludeCostExpensesValue });
				break;
			case 'excludeBillableExpenses':
				const excludeBillableExpensesValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, excludeBillableExpenses: excludeBillableExpensesValue });
				break;
			case 'excludeNonBillableTime':
				const excludeNonBillableTimeValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, excludeNonBillableTime: excludeNonBillableTimeValue });
				break;
			case 'resetBudget':
				const resetBudgetValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, resetBudget: resetBudgetValue });
				break;
			case 'startBudget':
				const startBudgetValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, startBudget: startBudgetValue });
				break;
			case 'showBudget':
				const showBudgetValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, showBudget: showBudgetValue });
				break;
			case 'emailThreshold':
				const emailThresholdValue = (event.target as HTMLInputElement).checked;
				setAddBill({ ...addBill, emailThreshold: emailThresholdValue });
				break;
		}
	};

	const handleBillingSubmit = () => {
		const getBillOption = billings.filter((value: any) => value.value === addBill.billingOption);
		const getBudgetOption = budgetsOptions[addBill.billingOption].filter(
			(value: any) => value.value === addBill.budgetOption
		);

		const billValidation = addBill.billingOption !== '1' && !addBill.billValueDisplay ? false : true;
		const budgetValidation = addBill.budgetOption !== '1' && !addBill.budgetValueDisplay ? false : true;

		if (!billValidation) {
			toast.info('Bill field must not be empty');
		}

		if (!budgetValidation) {
			toast.info('Budget field must not be empty');
		}

		if (billValidation && budgetValidation) {
			let formatBillData = new FormData();
			formatBillData.append('billing_name', getBillOption[0].type);
			formatBillData.append('billing_rate', addBill.billValueDisplay ? addBill.billValueDisplay : 0);
			formatBillData.append('budget_type', getBudgetOption[0].type);
			formatBillData.append('budget_type_rate', addBill.budgetValueDisplay ? addBill.budgetValueDisplay : 0);
			formatBillData.append('disallow_over_budget', String(+addBill.disallowOverBudget));
			formatBillData.append('exclude_non_billable_time', String(+addBill.excludeNonBillableTime));
			formatBillData.append('exclude_expenses', String(+addBill.excludeCostExpenses));
			formatBillData.append('show_budget', String(+addBill.showBudget));
			if (addBill.resetBudget) {
				formatBillData.append('reset_budget_from', 'weekly');
			}
			if (addBill.startBudget) {
				formatBillData.append('appliedFrom', new Date()!.toLocaleDateString('fr-CA'));
			}
			if (addBill.emailThreshold) {
				formatBillData.append('threshold', '70');
			}

			const formatData = {
				id: resultState.state.jobId,
				ev_proj_id: resultState.state.asana_project_id,
				data: formatBillData,
			};
			mutate(formatData);
		}
	};

	useEffect(() => {
		if (billAddData && billAddData.status === 200) {
			toggleBillingTask();

			const getBillOption = billings.filter((value: any) => value.value === addBill.billingOption);
			const getBudgetOption = budgetsOptions[addBill.billingOption].filter(
				(value: any) => value.value === addBill.budgetOption
			);

			if (rows[0].billing) {
				toast.success('Successfully updated billing!');
			} else {
				toast.success('Successfully added billing!');
			}
			const reformatRows = rows.map((value: any) => {
				return {
					...value,
					billing: addBill.billValueDisplay ? addBill.billValueDisplay : 0,
					budget:
						getBudgetOption[0] && getBudgetOption[0].type === 'money' && addBill.budgetValueDisplay
							? addBill.budgetValueDisplay
							: addBill.budgetValueDisplay
							? String(addBill.budgetValueDisplay)
							: 0,
				};
			});

			const billTitle =
				getBillOption[0] && getBillOption[0].type === 'non_billable'
					? 'Non-billable'
					: getBillOption[0] && getBillOption[0].type === 'fixed_fee'
					? 'Billed fixed-fee'
					: 'Rate/Hr';

			const budgetitle =
				getBudgetOption[0] && getBudgetOption[0].type === 'no_budget'
					? 'No budget'
					: getBudgetOption[0] && getBudgetOption[0].type === 'money'
					? 'Total budget cost'
					: 'Total budget hours';
			setRows(reformatRows);
			setBillingColumnTitle(billTitle);
			setBudgetColumnTitle(budgetitle);
		}
	}, [billAddData]);

	const modalPropsBilling = {
		handleSubmit: handleBillingSubmit,
		isShowing: showBilling,
		hide: toggleBillingTask,
		currentPage: 1,
		pageLength: 1,
	};

	// billing process ===================================================

	const serachProps = {
		search,
		searchHandle,
		setSearch,
		jobFilter,
		handleShowSearch,
		handleResetSearchFilter,
		handleTaskDateDisable,
		handleDateFilter,
	};

	const billingProps = {
		modalPropsBilling,
		toggleBillingTask,
		handleBillData,
		addBill,
		billings,
		budgetsOptions,
		billAddLoading,
	};

	const top100Films = [
		{ title: 'Web', id: 1, indexN: 0 },
		{ title: 'SEO', id: 2, indexN: 1 },
		{ title: 'Content', id: 3, indexN: 2 },
		{ title: 'Paid Media', id: 4, indexN: 3 },
		{ title: 'Email Marketing', id: 5, indexN: 4 },
		{ title: 'Community Management', id: 6, indexN: 5 },
	];

	const handleRemoveRetainerType = (value: any) => {
		// console.log({ valuevaluevalue: value });
	};

	const columns = [
		{ field: 'job_number', headerName: 'Job Number' },
		{ field: 'client', headerName: 'Client' },
		{ field: 'description', headerName: 'Description' },
		{ field: 'job_type', headerName: 'Job Type' },
		// { field: 'billing', headerName: 'Rate/Hr' },
		// { field: 'budget', headerName: 'Total budget' },
		{ field: 'billing', headerName: billingColumnTitle },
		{ field: 'budget', headerName: budgetColumnTitle },
		{ field: 'fees', headerName: 'Fees' },
		{ field: 'costs', headerName: 'Costs' },
		{ field: 'totalInvoice', headerName: 'Invoices' },
	];

	// const rowFormat = rows.map((value: any) => ({
	// 	...value,
	// 	billing: value.billing && value.billing.rate ? Number(value.billing.rate) : 0,
	// 	budget:
	// 		value.budget && value.budget.type
	// 			? value.budget.type === 'money'
	// 				? Number(value.budget.total)
	// 				: value.budget.total
	// 			: '0',
	// }));

	// update retainer work ========================================
	const {
		mutate: mutateRetainerUpdate,
		data: retainerUpdateData,
		isLoading: retainerUpdateLoading,
	} = usePutRetainerWork();
	const [showUpdateWork, setShowUpdateWork] = useState(false);

	function toggleUpdateWork() {
		setShowUpdateWork((showUpdateWork) => !showUpdateWork);
		// const serviceList = resultState.state.services_list
		// 	? resultState.state.services_list.map((value: any) => value.service)
		// 	: [];
		// const filterServiceList = top100Films.filter((value: any) => serviceList.includes(value.title));

		// setRetainerWork(filterServiceList);
	}

	const handleUpdateWorkSubmit = (): void => {
		if (resultState.state.jobId) {
			const services = retainerWork.map((value: any) => ({ service: value.title }));
			mutateRetainerUpdate({ id: resultState.state.jobId, job: { services } });
		}
	};

	const modalPropsUpdateWork = {
		handleSubmit: handleUpdateWorkSubmit,
		isShowing: showUpdateWork,
		hide: toggleUpdateWork,
		currentPage: 1,
		pageLength: 1,
	};

	const updateWorkProps = {
		modalPropsUpdateWork,
		toggleUpdateWork,
		retainerWork,
		setRetainerWork,
		retainerUpdateLoading,
		// removeDocument,
		// documentRemoveLoading,
	};

	useEffect(() => {
		if (retainerUpdateData && retainerUpdateData.error === false) {
			setShowUpdateWork((showUpdateWork) => !showUpdateWork);
		}
	}, [retainerUpdateData]);
	// update retainer work ========================================

	// update job details ========================================
	const {
		mutate: mutateJobDetailsUpdate,
		data: jobDetailsUpdateData,
		isLoading: jobDetailsUpdateLoading,
	} = usePutJobDetails();
	const [showAddDocument, setshowAddDocument] = useState(false);
	const [clientList, setClientList] = useState<any>([]);
	const [selectedType, setSelectedType] = useState<any>(resultState.state.job_type_id);
	const [typeList, setTypeList] = useState<any>([
		// { id: 1, name: 'G-Dennis' },
		{ id: 2, name: 'Retainer Job' },
		{ id: 3, name: 'Project Job' },
	]);
	const [invFreq, setInvFreq] = useState<any>([
		{ id: 1, name: 'Monthly' },
		{ id: 2, name: 'Quartely' },
		{ id: 3, name: 'Annually' },
	]);
	// const { mutate, data: documentAddData, isLoading: documentAddLoading } = usePostDocument();

	const [documentData, setdocumentData] = useState<any>({
		jobName: resultState.state.job_name,
		jobNumber: resultState.state.job_number,
		client: { id: 0, name: '' },
		description: resultState.state.description,
		jobType: resultState.state.job_type_id
			? typeList.filter((value: any) => value.id === resultState.state.job_type_id)[0]
			: { id: 2, name: 'Retainer Job' },
		fees: resultState.state.feesInt ? resultState.state.feesInt : 0,
		costs: resultState.state.costs ? resultState.state.costs : 0,
		invoices: resultState.state.totalInvoice,
		start: new Date(resultState.state.start_date),
		end: new Date(resultState.state.end_date),
		invoice_frequency: invFreq.filter((value: any) => {
			const check = resultState.state.invoice_frequency && resultState.state.invoice_frequency[0] ? true : false;

			if (check) {
				return value.name === resultState.state.invoice_frequency;
			} else {
				return false;
			}
		})[0],
	});

	const [documentDataDefault, setdocumentDataDefault] = useState<any>({
		jobName: resultState.state.job_name,
		jobNumber: resultState.state.job_number,
		client: resultState.state.name,
		description: resultState.state.description,
		jobType: typeList.filter((value: any) => value.id === resultState.state.job_type_id)[0],
		fees: resultState.state.feesInt ? resultState.state.feesInt : 0,
		costs: resultState.state.costs ? resultState.state.costs : 0,
		invoices: resultState.state.totalInvoice,
		start: new Date(resultState.state.start_date),
		end: new Date(resultState.state.end_date),
		invoice_frequency: invFreq.filter((value: any) => {
			const check = resultState.state.invoice_frequency && resultState.state.invoice_frequency[0] ? true : false;

			if (check) {
				return value.name === resultState.state.invoice_frequency;
			} else {
				return false;
			}
		})[0],
	});

	const [documentValidation, setdocumentValidation] = useState<any>({
		jobName: resultState.state.job_name ? true : false,
		jobNumber: resultState.state.job_number ? true : false,
		client: false,
		description: resultState.state.description ? true : false,
		jobType: typeList.filter((value: any) => value.id === resultState.state.job_type_id)[0] ? true : false,
		// fees: resultState.state.feesInt ? true : false,
		// costs: resultState.state.costs ? true : false,
		// invoices: resultState.state.totalInvoice ? true : false,
	});
	const [milestonePercentValidate, setMilestonePercentValidate] = useState(true);
	const [invoiceMilestonePercentage, setInvoiceMilestonePercentage] = useState<any>([
		{ percent: '30', notes: '' },
		{ percent: '30', notes: '' },
		{ percent: '20', notes: '' },
		{ percent: '20', notes: '' },
	]);
	const [invoiceMilestonePercentageDefault, setInvoiceMilestonePercentageDefault] = useState<any>(
		resultState.state.invoice_milestones ? resultState.state.invoice_milestones : []
	);

	const handleAddMilestone = () => {
		setInvoiceMilestonePercentage(invoiceMilestonePercentage.concat([{ percent: '0', notes: '' }]));
	};

	const toggleAddDocument = () => {
		setshowAddDocument(!showAddDocument);
		setdocumentData(documentDataDefault);
		setInvoiceMilestonePercentage(invoiceMilestonePercentageDefault);
		// setdocumentData({
		// 	jobName: resultState.state.job_name,
		// 	jobNumber: resultState.state.job_number,
		// 	client: { id: 0, name: '' },
		// 	description: resultState.state.description,
		// 	jobType: typeList.filter((value: any) => value.id === resultState.state.job_type_id)[0],
		// 	fees: resultState.state.feesInt ? resultState.state.feesInt : 0,
		// 	costs: resultState.state.costs ? resultState.state.costs : 0,
		// 	invoices: resultState.state.totalInvoice,
		// 	start: new Date(resultState.state.start_date),
		// 	end: new Date(resultState.state.end_date),
		// });
		// setdocumentValidation({
		// 	jobName: resultState.state.job_name ? true : false,
		// 	jobNumber: resultState.state.job_number ? true : false,
		// 	client: false,
		// 	description: resultState.state.description ? true : false,
		// 	jobType: typeList.filter((value: any) => value.id === resultState.state.job_type_id)[0] ? true : false,
		// });
		// setInvoiceMilestonePercentage(resultState.state.invoice_milestones ? resultState.state.invoice_milestones : []);
	};

	const capitalize = (string: string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	};

	const handleSubmitDocumentAdd = async () => {
		const validateField = Object.keys(documentValidation).filter((key) => !documentValidation[key]);

		// let docs_file = new FormData();
		if (!milestonePercentValidate) {
			toast.info('Invoice milestone should not be greater than 100 percent of total');
		}
		if (validateField.length) {
			toast.info(`${capitalize(validateField[0])} must not be empty`);
		} else {
			const formatData = {
				id: resultState.state.jobId,
				job: {
					job_name: documentData.jobName,
					client_id: documentData.client.id,
					job_number: documentData.jobNumber,
					job_description: documentData.description,
					job_cat: documentData.jobType.id,
					start_date: documentData.start!.toLocaleDateString('fr-CA'),
					end_date: documentData.end!.toLocaleDateString('fr-CA'),
					invoice_milestones: invoiceMilestonePercentage,
					team_id: resultState.state.teamId,
					asana_project_id: asana_project_id,
					invoice_frequency: [
						{
							range:
								documentData.invoice_frequency && documentData.invoice_frequency.name
									? documentData.invoice_frequency.name
									: 'Monthly',
							notes: '',
						},
					],
				},
			};

			mutateJobDetailsUpdate(formatData);
		}
	};

	const handleDocumentData = (event: any, field: any) => {
		switch (field) {
			case 'jobName':
				const valueJobName: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, jobName: valueJobName });
				setdocumentValidation({
					...documentValidation,
					jobName: valueJobName ? true : false,
				});
				break;
			case 'jobNumber':
				const valueJobNumber: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, jobNumber: valueJobNumber });
				setdocumentValidation({
					...documentValidation,
					jobNumber: valueJobNumber ? true : false,
				});
				break;
			case 'client':
				setdocumentData({ ...documentData, client: event });
				setdocumentValidation({
					...documentValidation,
					client: event ? true : false,
				});
				break;
			case 'invoice_frequency':
				setdocumentData({ ...documentData, invoice_frequency: event });
				break;
			case 'description':
				const valueDescription: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, description: valueDescription });
				setdocumentValidation({
					...documentValidation,
					description: valueDescription ? true : false,
				});
				break;
			case 'jobType':
				setdocumentData({ ...documentData, jobType: event });
				setdocumentValidation({
					...documentValidation,
					jobType: event ? true : false,
				});
				break;
			case 'fees':
				const valueFees: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, fees: valueFees });
				setdocumentValidation({
					...documentValidation,
					fees: valueFees ? true : false,
				});
				break;
			case 'start':
				setdocumentData({ ...documentData, start: event });
				break;
			case 'end':
				setdocumentData({ ...documentData, end: event });
				break;
			case 'costs':
				const valueCosts: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, costs: valueCosts });
				setdocumentValidation({
					...documentValidation,
					costs: valueCosts ? true : false,
				});
				break;
			case 'invoices':
				const valueInvoices: any = (event.target as HTMLInputElement).value;
				setdocumentData({ ...documentData, invoices: valueInvoices });
				setdocumentValidation({
					...documentValidation,
					invoices: valueInvoices ? true : false,
				});
				break;
		}
	};

	const formatDataInvoicesJob = (data: any) => {
		return data
			? data.map((list: any, index: number) => ({
					id: list.id,
					name: list.name,
			  }))
			: [];
	};

	const handleRemoveMilestone = (indexArray: any) => {
		setInvoiceMilestonePercentage(invoiceMilestonePercentage.filter((s: any, sidx: any) => indexArray !== sidx));
	};

	const handleMilestoneChecking = (value: any, assignData: any) => {
		const testMilestone = onlyNumbers(value);
		const countString = [...value].length;

		const checkRange = testMilestone
			? Number(value) >= 0 && Number(value) < 101 && countString < 4
				? true
				: false
			: false;

		const assignValue = checkRange ? value : Number(value) === 0 ? '' : assignData;
		return assignValue;
	};

	const handleUpdatePercent = (event: any, indexArray: any, area: string) => {
		const updateValue = (event.target as HTMLInputElement).value;

		const newValue = invoiceMilestonePercentage.map((inviMilestone: any, sidx: any) => {
			if (sidx !== indexArray) return inviMilestone;

			if ('percent' === area) {
				const assignValue = handleMilestoneChecking(updateValue, inviMilestone.percent);

				return { ...inviMilestone, percent: assignValue };
			} else {
				return { ...inviMilestone, notes: updateValue };
			}
		});

		setInvoiceMilestonePercentage(newValue);
	};

	useEffect(() => {
		if (jobDetailsUpdateData && jobDetailsUpdateData.error === false) {
			toggleAddDocument();
			setRows([
				{
					...rows[0],
					job_number: documentData.jobNumber,
					client: documentData.client.name,
					description: documentData.description,
					job_type: documentData.jobType.name,
				},
			]);
			setdocumentDataDefault(documentData);
			setInvoiceMilestonePercentageDefault(invoiceMilestonePercentage);
		}
	}, [jobDetailsUpdateData]);

	useEffect(() => {
		const milestonePercentTotal = invoiceMilestonePercentage.reduce(
			(n: any, { percent }: any) => n + (percent ? Number(percent) : 0),
			0
		);

		if (milestonePercentTotal > 100) {
			setMilestonePercentValidate(false);
			toast.info('Invoice milestone should not be greater than 100 percent of total');
		} else {
			setMilestonePercentValidate(true);
		}
	}, [invoiceMilestonePercentage]);

	useEffect(() => {
		if (rows.length) {
			if (rows[0].client && rows[0].client !== '-' && clientList.length) {
				const getDefaultClient = clientList.filter((value: any) => {
					const checkName = value.name ? value.name : String(Math.random());
					return checkName.toLowerCase().includes(rows[0].client.toLowerCase());
				});

				if (getDefaultClient.length) {
					setdocumentData({ ...documentData, client: getDefaultClient[0] });
					setdocumentValidation({
						...documentValidation,
						client: true,
					});
				}
			}
		}
	}, [showAddDocument, state.clients, rows]);

	useEffect(() => {
		const filter = state.clients && state.clients.length ? formatDataInvoicesJob(state.clients) : [];
		setClientList(filter);
	}, [state.clients]);

	useEffect(() => {
		if (resultState.state.invoice_milestones) {
			setInvoiceMilestonePercentage(resultState.state.invoice_milestones);
		} else {
			setInvoiceMilestonePercentage([]);
		}
	}, [resultState.state.invoice_milestones]);

	// setInvoiceMilestonePercentage

	const modalPropsDocumentAdd = {
		handleSubmit: handleSubmitDocumentAdd,
		isShowing: showAddDocument,
		hide: toggleAddDocument,
		currentPage: 1,
		pageLength: 1,
	};

	const updateDetailsProps = {
		modalPropsDocumentAdd,
		toggleAddDocument,
		// documentAddLoading,
		handleDocumentData,
		clientList,
		documentData,
		selectedType,
		typeList,
		invoiceMilestonePercentage,
		handleAddMilestone,
		handleUpdatePercent,
		handleRemoveMilestone,
		jobDetailsUpdateLoading,
		invFreq,
	};

	const tableData = { columns, rows };

	// update job details ========================================

	return (
		<div className={'gs-jobPage__container'} style={{ marginTop: '1em' }}>
			<Box sx={{ marginBottom: 3 }}>
				<Link to='/active-jobs' style={{ fontSize: '12px', color: '#8d8888', textDecoration: 'none' }}>
					<ChevronLeftIcon fontSize='inherit' /> <span>Back to Active Jobs</span>
				</Link>
			</Box>

			{/* <div className='gs-unassignedJobs__header-div'>
				<SearchIcon />
				<Typography sx={{ fontSize: 14 }} color='text.secondary' gutterBottom>
					Search :
				</Typography>
				<TextField
					value={search}
					onChange={(event) => searchHandle(event)}
					className='gs-unassignedJobs__basic-input'
					id='standard-basic'
					variant='standard'
				/>
				<Typography sx={{ fontSize: 14 }}>
					<span
						style={{
							cursor: 'pointer',
							textDecoration: 'underline',
							marginRight: '5px',
							textUnderlineOffset: '3px',
						}}
						onClick={() => handleShowSearch()}
					>
						Filters
					</span>
				</Typography>
			</div> */}
			<div className={`searchbar-container ${showSearch ? 'show' : ''}`}>
				<fieldset className='gs-container__filter'>
					<legend>Filters:</legend>
					<div className='gs-cpntainer__filter-content'>
						{/* <SwitchUnstyled
							checked={!jobFilter.taskDateDisable}
							onChange={(value: any) => handleTaskDateDisable(value)}
							component={Root}
							{...label}
						/> */}
						{/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
						<DatePicker
							disabled={jobFilter.taskDateDisable}
							views={['year', 'month']}
							label={dateLabel}
							minDate={new Date('2018-03-01')}
							maxDate={new Date('2026-06-01')}
							value={jobFilter.taskDate}
							onChange={(value: any) => handleDateFilter(value)}
							renderInput={(params: any) => <TextField {...params} helperText={null} />}
						/>
						{/* </LocalizationProvider> */}
					</div>
				</fieldset>
			</div>

			{/* <Typography variant='caption' sx={{ marginBottom: 1 }}>
				<CircleIcon fontSize='inherit' sx={{ color: '#3acf41' }} /> In Progress
			</Typography> */}
			{/* display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center; */}

			<Box sx={{ flexGrow: 1 }}>
				<Grid container>
					<Grid item xs>
						<h1 style={{ marginTop: 0, marginBottom: 0, display: 'flex', alignItems: 'center' }}>
							{/* {resultState.state.job_name}{' '} */}
							{documentDataDefault.jobName}
							<OpenInNewIcon
								style={{ color: '#757575', marginLeft: '11px', cursor: 'pointer' }}
								onClick={toggleAddDocument}
							/>
						</h1>
					</Grid>
					<Grid
						item
						xs={6}
						style={{
							paddingTop: '20px',
							display: resultState.state.services_list ? 'flex' : 'none',
							flexDirection: 'column',
							alignItems: 'flex-end',
						}}
					>
						{/* <Typography sx={{ fontSize: 14 }} fontWeight='bold'>
							Type of work :
						</Typography> */}
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								flexWrap: 'wrap',
								alignItems: 'center',
							}}
						>
							{retainerWork
								? retainerWork.map((list: any, index: any) => {
										return (
											<Chip
												style={{
													margin: '3px',
													marginRight: `${index + 1 === top100Films.length ? '0px' : '3px'}`,
												}}
												size='small'
												label={list.title}
												// onDelete={handleRemoveRetainerType}
											/>
										);
								  })
								: '-'}
							<Button
								style={{ height: '22px', paddingLeft: '6px', paddingRight: '10px' }}
								size='small'
								className='gs-global__btn'
								// color='error'
								color='secondary'
								variant='contained'
								onClick={toggleUpdateWork}
							>
								<AddOutlinedIcon /> Work
							</Button>
						</div>

						{/* <LinearProgressWithLabel variant='determinate' value={progress} /> */}
					</Grid>
				</Grid>
			</Box>

			<div className='gs-jobPage__date-start-end' style={{ alignItems: 'center' }}>
				<div style={{ display: 'flex' }}>
					<Typography sx={{ fontSize: 14 }} fontWeight='bold'>
						Start Date :
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{' '}
						{documentDataDefault.start!.toLocaleDateString('fr-CA')}{' '}
					</Typography>
					<Typography sx={{ fontSize: 14 }} fontWeight='bold'>
						End Date :
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{documentDataDefault.end!.toLocaleDateString('fr-CA')}{' '}
					</Typography>
				</div>
				<div style={{ display: `${resultState.state?.job_type_id === 3 ? 'flex' : 'none'}` }}>
					<Typography
						style={{ display: 'flex', alignItems: 'center' }}
						sx={{ fontSize: 14 }}
						fontWeight='bold'
					>
						Invoice milestones :{' '}
						{invoiceMilestonePercentageDefault && resultState.state?.job_type_id === 3
							? invoiceMilestonePercentageDefault.map((value: any, index: any) => {
									if (index === invoiceMilestonePercentageDefault.length - 1) {
										return `${value.percent ? value.percent : 0}`;
									} else {
										return `${value.percent ? value.percent : 0}/`;
									}
							  })
							: '0'}
						{/* <ContactSupportIcon style={{ color: '#757575', marginLeft: '5px' }} /> */}
					</Typography>
				</div>
				<Button
					style={{ height: '22px', paddingLeft: '6px', paddingRight: '10px' }}
					size='small'
					className='gs-global__btn'
					color='error'
					variant='contained'
					onClick={toggleBillingTask}
				>
					<AddOutlinedIcon /> Billing
				</Button>
			</div>
			<div style={{ display: `${resultState.state?.job_type_id === 2 ? 'block' : 'none'}` }}>
				<Typography sx={{ fontSize: 14 }} fontWeight='bold'>
					Invoice Frequency :{' '}
					{documentDataDefault.invoice_frequency && documentDataDefault.invoice_frequency.name
						? documentDataDefault.invoice_frequency.name
						: ''}
				</Typography>
			</div>
			<DataTable {...tableData} noBorder={true} />
			<JpTabs
				{...serachProps}
				dataJobTask={dataJobTask}
				loadingJobTask={loadingJobTask}
				asana_project_id={asana_project_id}
				jobDetails={resultState.state}
			/>
			<Billing {...billingProps} />
			<UpdateDetails {...updateDetailsProps} />
			<UpdateWork {...updateWorkProps} />
		</div>
	);
};
