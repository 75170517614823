import { Modal as UIModal, Box, Stack, Button } from '@mui/material';
import { useModalogic } from '../../hooks/components';

export const Modal = (props: any) => {
	const { attributes, style } = useModalogic(props);

	return (
		<div>
			<UIModal {...attributes.uiModal}>
				<Box sx={style}>
					{props.children}
					<Stack className='add-job-scope__footer-stack' direction='row' spacing={2}>
						<Button color='secondary' variant='contained' disableElevation {...attributes.buttonBack}>
							Back
						</Button>
						<Button color='error' variant='contained' disableElevation {...attributes.buttonNext}>
							Next
						</Button>
						<Button color='error' variant='contained' disableElevation {...attributes.buttonSubmit}>
							Submit
						</Button>
					</Stack>
				</Box>
			</UIModal>
		</div>
	);
};
