import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	Typography,
	Box,
	TextField,
	FormControl,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Autocomplete,
	InputAdornment,
	LinearProgress,
} from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import BillingSettings from './BillingSettings/BillingSettings';
import {
	useUpdateBill,
	useGetBillingEmails,
	useGetBillingDetails,
	useGetBillingTypes,
	useGetBudgetTypes,
} from '../../../../services/api';
import { AddBillingProps, BillingTypeProps, BudgetTypeProps, BillingProps, BillingItemProps } from './AddBilling.props';
import { useAddBillingStyles } from './AddBilling.styles';
import { formatDateYearMonthDay } from '../../../../utils/formatDate';

const AddBilling = (props: AddBillingProps) => {
	const { id, asana_id } = props;
	const classes = useAddBillingStyles();
	const [open, setOpen] = useState(false);
	const [billing, setBilling] = useState<BillingProps>({
		billing_type_name: '',
		billing_type: '',
		billing_type_id: null,
		billing_type_rate: 0,
		budget_type_name: '',
		budget_type: '',
		budget_type_id: null,
		budget_type_rate: 0,
		applied_from: '',
		created_at: '',
		disallow_over_budget: false,
		exclude_expenses: false,
		exclude_non_billable_time: false,
		id: null,
		job_id: '',
		period: '',
		reset_budget: false,
		show_budget: false,
		start_budget_from: false,
		threshold: 0,
		threshold_emails: [],
		updated_at: null,
	});

	const [billingType, setBillingType] = useState<BillingItemProps>({
		created_at: '',
		id: null,
		is_active: false,
		name: '',
		short_code: '',
		type: '',
		updated_at: '',
	});

	const [budgetType, setBudgetType] = useState<BudgetTypeProps>({
		billing_type: null,
		created_at: '',
		id: null,
		is_active: false,
		name: '',
		type: '',
		updated_at: 'string',
		bill_type: {
			created_at: '',
			id: null,
			is_active: false,
			name: '',
			short_code: '',
			type: '',
			updated_at: '',
		},
	});

	// QUERIES
	const emailListQuery = useGetBillingEmails(asana_id);
	const billingDetailsQuery = useGetBillingDetails(Number(id));
	const billingTypesQuery = useGetBillingTypes();
	const budgetTypesQuery = useGetBudgetTypes(billingType.id);
	const updateBillingQuery = useUpdateBill();

	// DATA
	const billingDetails = billingDetailsQuery.status === 'success' ? billingDetailsQuery.data.result : [];
	const billingEmails = emailListQuery.status === 'success' ? emailListQuery.data.result : [];
	const billingTypes = billingTypesQuery.status === 'success' ? billingTypesQuery.data.result : [];
	const budgetTypes = budgetTypesQuery.status === 'success' ? budgetTypesQuery.data.result : [];

	useEffect(() => {
		const billingType = findValue(billingTypes, billingDetails.billing_type_id);
		if (billingType) {
			setBillingType(billingType);
		}
	}, [billing.billing_type_id]);

	useEffect(() => {
		const budgetType = findValue(budgetTypes, billingDetails.budget_type_id);
		if (budgetType) {
			setBudgetType(budgetType);
		}
	}, [billingType.id, budgetTypes]);

	// CLOSE WHEN SUCCESSFULLY UPDATED BILLING
	useEffect(() => {
		if (updateBillingQuery.status === 'success' && !updateBillingQuery.isLoading) {
			setOpen(false);
		}
	}, [updateBillingQuery.status]);

	// UPDATE LOCAL STATE WITH THE BILLING DETAILS FOR DEFAULT VALUES
	useEffect(() => {
		if (billingDetails) {
			setBilling({
				...billing,
				billing_type_rate: billingDetails.billing_rate,
				budget_type_rate: billingDetails.budget_type_rate,
				applied_from: billingDetails.applied_from,
				created_at: billingDetails.created_at,
				disallow_over_budget: billingDetails.disallow_over_budget,
				exclude_expenses: billingDetails.exclude_expenses,
				exclude_non_billable_time: billingDetails.exclude_non_billable_time,
				id: billingDetails.id,
				job_id: billingDetails.job_id,
				period: billingDetails.period,
				reset_budget: billingDetails.reset_budget,
				show_budget: billingDetails.show_budget,
				start_budget_from: billingDetails.start_budget_from,
				threshold: billingDetails.threshold,
				threshold_emails: billingDetails.threshold_emails,
				updated_at: billingDetails.updated_at,
				billing_type_id: billingDetails.billing_type_id,
				budget_type_id: billingDetails.budget_type_id,
			});
		}
	}, [billingDetails]);

	const clickOpenHandler = () => {
		setOpen(true);
	};

	const closeHandler = () => {
		setOpen(false);
	};

	const changeBudgetDropdownHandler = (field: string, value: string | number | null) => {
		return setBilling({ ...billing, [field]: value });
	};

	const changeBillingTextFieldHandler = (field: string, value: string | number | boolean) => {
		if (field === 'threshold_emails') {
			const email = billingEmails.find((email: any) => email.name === value);
			return setBilling({ ...billing, threshold_emails: email ? [email.id] : [] });
		}
		if (field === 'start_budget_from') {
			const dateToday = new Date();
			const start_date = value ? formatDateYearMonthDay(`${dateToday}`) : '';
			return setBilling({ ...billing, start_budget_from: !!value, applied_from: start_date });
		}
		if (field === 'has_threshold') {
			const threshold = value ? 75 : 0;
			return setBilling({ ...billing, threshold: threshold });
		}

		setBilling({ ...billing, [field]: value });
	};

	const changeBillingHandler = (name: string) => {
		const newBillingType = billingTypes.find((type: BillingTypeProps) => type.name === name);
		if (newBillingType) {
			setBillingType(newBillingType);
		}
	};

	const changeBudgetHandler = (name: string) => {
		const newBudgetType = budgetTypes.find((type: BudgetTypeProps) => type.name === name);
		if (newBudgetType) {
			setBudgetType(newBudgetType);
		}
	};

	const updateBillingHandler = () => {
		const data = {
			id: Number(billing.job_id),
			ev_proj_id: asana_id,
			billing_name: billingType.type,
			billing_rate: billing.billing_type_rate,
			budget_type: budgetType.type,
			budget_type_rate: billing.budget_type_rate,
			disallow_over_budget: billing.disallow_over_budget,
			exclude_non_billable_time: billing.exclude_non_billable_time,
			exclude_expenses: billing.exclude_expenses,
			reset_budget_from: billing.period,
			appliedFrom: billing.applied_from,
			show_budget: billing.start_budget_from ? billing.show_budget : null,
			threshold: billing.threshold,
			billing_type_id: billingType.id,
			budget_type_id: budgetType.id,
			threshold_emails: billing.threshold_emails,
		};

		updateBillingQuery.mutate(data);
	};

	function isValidTimeFormat(text: string) {
		const regex = /^(?!0h$)\d+h(\s\d+m)?$/;
		return regex.test(text);
	}

	return (
		<div>
			<Button className={classes.addBillingBtn} onClick={clickOpenHandler}>
				<span>Billing</span>
			</Button>

			<Dialog
				open={open}
				onClose={closeHandler}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				maxWidth={'md'}
			>
				<DialogTitle id='alert-dialog-title' className={classes.dialogTitle}>
					<Box component='button' className={classes.closeBtn}>
						<Typography variant='h4' className={classes.title}>
							Billing
						</Typography>
						<ClearOutlinedIcon onClick={closeHandler} sx={{ cursor: 'pointer' }} />
					</Box>
				</DialogTitle>

				<DialogContent sx={{ padding: '1rem 2rem', minWidth: '600px' }}>
					<FormControl sx={{ width: '100%' }}>
						{updateBillingQuery.status !== 'success' && updateBillingQuery.isLoading && (
							<Box sx={{ width: '100%', paddingBottom: '.5rem' }}>
								<LinearProgress color='error' />
							</Box>
						)}
						{emailListQuery.isLoading || budgetTypesQuery.isLoading ? (
							<div style={{ width: '100%', textAlign: 'center' }}>Loading...</div>
						) : (
							// BILLING TYPE
							<Box component='form' sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
								<Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
									<Autocomplete
										options={billingTypes.map((type: BillingTypeProps) => type.name)}
										disableClearable
										value={billingType.name}
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												label='Billing'
												variant='standard'
												helperText='Please select your billing'
											/>
										)}
										onChange={(event: any, newValue: string) => changeBillingHandler(newValue)}
									/>
									{/* BILLING RATE */}
									{billingType.id !== 1 && (
										<TextField
											variant='standard'
											type='number'
											placeholder='0'
											value={billing.billing_type_rate}
											size='small'
											sx={{ marginTop: '16px', width: '50%' }}
											onChange={(e) =>
												changeBillingTextFieldHandler('billing_type_rate', e.target.value)
											}
											InputProps={{
												startAdornment: <InputAdornment position='start'>$</InputAdornment>,
												endAdornment: budgetType.name !== 'fixed_fee' && (
													<InputAdornment position='end'>/hour</InputAdornment>
												),
											}}
										/>
									)}
								</Box>
								{/* BUDGET TYPE */}
								<Box sx={{ display: 'flex', justifyContent: 'space-between', gap: '2rem' }}>
									<Autocomplete
										options={budgetTypes.map((type: BudgetTypeProps) => type.name)}
										disableClearable
										value={budgetType.name}
										fullWidth
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												label='Budget'
												variant='standard'
												helperText='Please select your budget'
											/>
										)}
										onChange={(event: any, newValue: string) => changeBudgetHandler(newValue)}
									/>
									{/* BUDGET RATE */}
									{[2, 3, 7, 8, 10, 12, 13, 15, 16, null].includes(budgetType.id) && (
										<TextField
											id='standard-basic'
											variant='standard'
											placeholder='0'
											value={billing.budget_type_rate}
											size='small'
											sx={{ marginTop: '16px', width: '50%' }}
											onChange={(e) =>
												changeBillingTextFieldHandler('budget_type_rate', e.target.value)
											}
											error={
												billing.budget_type_rate ||
												billing.budget_type_rate === 0 ||
												billing.budget_type_rate === ''
													? !isValidTimeFormat(billing.budget_type_rate.toString())
													: false
											}
											helperText='Format: 1h 30m or 0h 30m'
											InputProps={{
												startAdornment: [3, 8, 10, 13, 15, 16, null].includes(
													budgetType.id
												) && <InputAdornment position='start'>$</InputAdornment>,
												endAdornment: [10, 13, 16, null].includes(budgetType.id) && (
													<InputAdornment position='end'>/hour</InputAdornment>
												),
											}}
										/>
									)}
								</Box>
								{/* BILLING CHECKBOXES */}
								{[2, 3, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, null].includes(budgetType.id) && (
									<BillingSettings
										onChangeBillingTextField={changeBillingTextFieldHandler}
										onChangeBudgetDropdownHandler={changeBudgetDropdownHandler}
										billing={billing}
										billingEmails={billingEmails}
										budgetTypeId={budgetType.id}
										billingTypeId={billingType.id}
									/>
								)}
							</Box>
						)}
					</FormControl>
				</DialogContent>

				<DialogActions sx={{ padding: '2rem' }}>
					<Button
						className={classes.submit}
						variant='contained'
						onClick={(e) => updateBillingHandler()}
						disableElevation
						disabled={
							billing.budget_type_rate ||
							billing.budget_type_rate === 0 ||
							billing.budget_type_rate === ''
								? !isValidTimeFormat(billing.budget_type_rate.toString())
								: false
						}
					>
						Update
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const findValue = (array: any, id: number) => {
	const item = array.find((item: any) => item.id === id);
	return item;
};

export default AddBilling;
