import BorderColorIcon from '@mui/icons-material/BorderColor';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Tooltip from '@mui/material/Tooltip';

export const editAndRemove = ({ data, handleEdit, handleRemove }: any) => {
	return data.length
		? data.map((value: any, index: any) => {
				return {
					...value,
					actions: (
						<div style={{ width: '100%'}}>
							<span
								className='gs__icon__edit'
								style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '20px' }}
								onClick={() => handleEdit({...value, index})}
							>
								<Tooltip placement='top' title='Edit' arrow>
									<BorderColorIcon />
								</Tooltip>
							</span>
							<span
								className='gs__icon__remove'
								style={{ cursor: 'pointer', textDecoration: 'underline' }}
								onClick={() => handleRemove({...value, index})}
							>
								<Tooltip placement='top' title='Remove' arrow>
									<DeleteForeverIcon />
								</Tooltip>
							</span>
						</div>
					),
				};
		  })
		: [];
};
