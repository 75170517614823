import { useContext, useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { QueryKeys } from './queryKeys';
import { toast } from 'react-toastify';

import {
	putTask,
	putInvoice,
	putAssignPo,
	postAddCost,
	putUpdateCost,
	putAsanaTask,
	putRetainerWork,
	putJobDetails,
	putJobExisting,
	putUserData,
	postAsanaTask,
	postDocument,
	postJob,
	postBill,
	postAssignJob,
	postClient,
	postArchiveJob,
	putArchiveJob,
	postDocumentDownload,
	getActiveJobs,
	getClientActiveJobs,
	getUnassignedJobs,
	getInvoices,
	getUserData,
	getLogout,
	getPOs,
	getTimeEntries,
	getDocument,
	getClients,
	getArchiveJobs,
	getNextQuoteNumber,
	getInvoiceJobSelection,
	getNextJobNumber,
	getTeams,
	getAssignees,
	getProjectJobs,
	getRetainerJobs,
	getActiveJob,
	getDataCounter,
	getActiveJobsByTeam,
	deleteAsanaTask,
	deleteDocument,
	deleteCost,
	deleteInvoice,
	getActiveJobAssigning,
	getPaginatedActiveJobs,
	getClientUniqueNamesList,
	getJobTypes,
	getBillingEmails,
	getPaginatedTasks,
	postUpdateBill,
	getArchivedJobs,
	postNewAsanaTask,
	getPaginatedInvoices,
	getPaginatedCosts,
	getPaginatedQuotes,
	getPaginatedUnassignedInvoices,
	getActiveJobNameList,
	putAssignInvoice,
	getClientList,
	getPaginatedTimeEntries,
	getPaginatedUnassignedDocs,
	postAssignDoc,
	getDocTypes,
	getArchivedInvoices,
	postArchiveInvoice,
	putExistingJobProject,
	getPaginatedAttachments,
	getBillingDetails,
	getBillingTypes,
	getBudgetTypes,
	splitJob,
	getJobTask,
	getUnassignedDocSubtasks,
	getSuppliers,
	postNewSupplier,
	getPaginatedAsanaBoards,
	getInAppNotifications,
	postAssignAsOthers,
	getPaginatedOtherDocuments,
	getPaginatedMilestones,
	getCustomerUniqueNamesList,
	getUniqueActiveJobs,
	exportExcelReport,
	exportCsvReport,
	downloadCsvReport,
	clearInAppNotifications,
} from './';
import { jobNumberHelper } from '../../utils';
import Context from '../context';

// DELETE Method ==========================================
export const useDeleteAsanaTask: any = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteAsanaTask, {
		onSuccess: (data: any) => {
			queryClient.invalidateQueries('get-job-task');
			toast.success('Successfully deleted task!');
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useDeleteDocument: any = (pageNumber: number, limit: number, id: any) => {
	const queryClient = useQueryClient();
	return useMutation(deleteDocument, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_ATTACHMENTS, pageNumber, limit, Number(id)]);
				toast.success('Successfully deleted attachment!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useDeleteCost: any = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteCost, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-pos');

				toast.success('Successfully deleted cost!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useDeleteInvoice: any = () => {
	const queryClient = useQueryClient();
	return useMutation(deleteInvoice, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-invoices-selection');

				toast.success('Successfully deleted invoice!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};
// DELETE Method ==========================================

// PUT Method ==========================================
export const usePutTask: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putTask, {
		onSuccess: (data: any) => {
			queryClient.invalidateQueries('get-task');
			toast.success('Successfully added task!');
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const usePutJobDetails: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putJobDetails, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				toast.success('Successfully updated job details!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const usePutJobExisting: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putJobExisting, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				toast.success('Successfully added to existing job!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const usePutRetainerWork: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putRetainerWork, {
		onSuccess: (data: any) => {
			queryClient.invalidateQueries('get-active-jobs');
			toast.success('Successfully updated retainer work!');
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// export const usePutAsanaTask: any = () => {
// 	const queryClient = useQueryClient();
// 	return useMutation(putAsanaTask, {
// 		onSuccess: (data: any) => {
// 			if (data.error) {
// 				toast.info(data.message);
// 			} else {
// 				queryClient.invalidateQueries('get-job-task');
// 				toast.success('Successfully updated task!');
// 			}
// 		},
// 		onError: (data: any) => {
// 			toast.error(data.message);
// 		},
// 	});
// };

// OLD
export const usePutInvoice: any = () => {
	const { dispatch } = useContext(Context);
	const queryClient = useQueryClient();
	const resultQuery = useMutation(putInvoice, {
		onSuccess: (data: any) => {
			if (!data.error) {
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-invoices-selection');

				toast.success('Successfully added invoice!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_invoice_assign_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const usePostAddCost: any = () => {
	const { dispatch } = useContext(Context);
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postAddCost, {
		onSuccess: (data: any) => {
			if (!data.error) {
				queryClient.invalidateQueries('get-pos');
				queryClient.invalidateQueries('get-active-jobs');
				toast.success('Successfully assign PO!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'posAssignLoading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};
// PUT Method ==========================================

// POST Method ==========================================
export const usePostBill: any = () => {
	const queryClient = useQueryClient();
	return useMutation(postBill, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				// toast.success('Successfully updated billing!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const usePostAsanaTask: any = () => {
	const queryClient = useQueryClient();
	return useMutation(postAsanaTask, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-job-task');
				toast.success('Successfully added task!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const usePostAssignJob: any = () => {
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postAssignJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries('get-unassigned-jobs');
				queryClient.invalidateQueries('get-project-jobs');
				queryClient.invalidateQueries('get-retainer-jobs');
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-next-job-number');
				queryClient.invalidateQueries('get-next-quote-number');

				toast.success('Successfully assigned!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const usePostDocument: any = (job_id: any, pageNumber: number, limit: number) => {
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postDocument, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_ATTACHMENTS, pageNumber, limit, job_id]);
				toast.success('Successfully uploaded attachment!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const usePostClient: any = () => {
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postClient, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries('get-clients');
				toast.success('Successfully created client!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const usePostDocumentDownload: any = () => {
	const resultQuery = useMutation(postDocumentDownload, {
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const usePostArchiveJob: any = () => {
	const { dispatch } = useContext(Context);
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postArchiveJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-archive-jobs');
				// toast.success('Successfully created client!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const usePostJob: any = () => {
	const { dispatch } = useContext(Context);
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries('get-active-jobs');
				queryClient.invalidateQueries('get-project-jobs');
				queryClient.invalidateQueries('get-retainer-jobs');
				queryClient.invalidateQueries('get-next-job-number');
				queryClient.invalidateQueries('get-next-quote-number');

				toast.success('Successfully created job!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};
// POST Method ==========================================

// GET Method ==========================================

export const useGetTeams: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-teams', getTeams, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(`${data.message} : error for team list`);
			} else {
				dispatch({ type: 'set_teams', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};
// GET ACTIVE JOB
export const useGetActiveJob: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useMutation(getActiveJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				dispatch({ type: 'set_active_job', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// GET CLIENT ACTIVE JOBS
export const useGetClientActiveJobs: any = () => {
	const resultQuery = useMutation(getClientActiveJobs, {
		onSuccess: (data: any) => {},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// GET ACTIVE JOBS ASSIGNING
export const useGetActiveJobAssigning: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-active-job-assigning', getActiveJobAssigning, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(`${data.message} : error for active job list for assigning`);
			} else {
				dispatch({ type: 'set_active_job_assigning', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// GET ACTIVE JOBS BY TEAM
export const useGetActiveJobsByTeam: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useMutation(getActiveJobsByTeam, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				dispatch({ type: 'set_active_jobs', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetActiveJobs: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery(QueryKeys.GET_ACTIVE_JOBS, getActiveJobs, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(`${data.message} : error for active job list`);
			} else {
				dispatch({ type: 'set_active_jobs', payload: data.result });
				dispatch({ type: 'set_job_number_helper', payload: jobNumberHelper(data.result) });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_loading_active_jobs', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const useGetUniqueActiveJobs: any = () => {
	const resultQuery = useQuery(QueryKeys.GET_UNIQUE_ACTIVE_JOB, getUniqueActiveJobs, {
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetClients: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-clients', getClients, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_clients', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_clients_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const useGetUnassignedJobs: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-unassigned-jobs', getUnassignedJobs, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_unassigned_job_ticker', payload: data.result.length });
				dispatch({ type: 'add_unassign_jobs', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_loading_unassign_jobs', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const useGetPOs: any = (sort: string, order: string) => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery(['get-pos', sort, order], () => getPOs(sort, order), {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_pos_ticker', payload: data.result.length });
				dispatch({ type: 'set_pos', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_pos_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const useGetLogout: any = () => {
	const resultQuery = useQuery('get-logout', getLogout, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				if (data.result && data.result.redirect_uri) {
					window.location.href = data.result.redirect_uri;
				}
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
		enabled: false,
	});

	return resultQuery;
};

export const useGetTimeEntries: any = (taskId: any) => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery(['get-time-entries', taskId], () => getTimeEntries(taskId), {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_time_entries', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_time_entries_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

// OLD
export const useGetInvoices: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-invoices-selection', getInvoices, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_invoices_selection', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_invoices_selection_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

export const useGetInvoiceJobSelection: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-invoice-job-selection', getInvoiceJobSelection, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_invoice_job_selection', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetArchiveJobs: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-archive-jobs', getArchiveJobs, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_archive_jobs', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	useEffect(() => {
		dispatch({ type: 'set_archive_jobs_loading', payload: resultQuery.isLoading });
	}, [resultQuery.isLoading]);

	return resultQuery;
};

// export const useGetNextJobNumber: any = () => {
// 	const { dispatch } = useContext(Context);
// 	const resultQuery = useQuery('get-next-job-number', getNextJobNumber, {
// 		onSuccess: (data: any) => {
// 			if (data.error) {
// 				// toast.error(data.message);
// 			} else {
// 				dispatch({ type: 'set_next_job_number', payload: data.result });
// 			}
// 		},
// 		onError: (data: any) => {
// 			toast.error(data.message);
// 		},
// 	});

// 	return resultQuery;
// };

// export const useGetNextQuoteNumber: any = () => {
// 	const { dispatch } = useContext(Context);
// 	const resultQuery = useQuery('get-next-quote-number', getNextQuoteNumber, {
// 		onSuccess: (data: any) => {
// 			if (data.error) {
// 				// toast.error(data.message);
// 			} else {
// 				dispatch({ type: 'set_next_quote_number', payload: data.result });
// 			}
// 		},
// 		onError: (data: any) => {
// 			toast.error(data.message);
// 		},
// 	});

// 	return resultQuery;
// };

export const useGetAssignees: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-assignees', getAssignees, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_assignees', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetProjectJobs: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-project-jobs', getProjectJobs, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_project_jobs', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetRetainerJobs: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-retainer-jobs', getRetainerJobs, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_retainer_jobs', payload: data.result });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

export const useGetDataCounter: any = () => {
	const { dispatch } = useContext(Context);
	const resultQuery = useQuery('get-data-counter', getDataCounter, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.error(data.message);
			} else {
				dispatch({ type: 'set_pos_ticker', payload: data.result.length });
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// ====== AUTH / USER DATA ==================================================
// GET USER DATA
export const useGetUserData: any = () => {
	const { dispatch } = useContext(Context);
	const userData = useQuery([QueryKeys.GET_USER_DATA], getUserData, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				const firstName = data.result && data.result.full_name ? data.result.full_name : '';
				if (firstName) {
					dispatch({ type: 'set_user_name', payload: firstName.split(' ')[0] });
				}
			}
		},
	});
	return userData;
};

// UPDATE USER DATA
export const usePutUserData: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putUserData, {
		onSuccess: (data: any) => {
			if (data.error) {
				// toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_USER_DATA]);
				toast.success('Successfully updated user!');
			}
		},
		// onError: (data: any) => {
		// 	toast.error(data.message);
		// },
	});
};

// =================================================================
// UNASSIGNED DOCS
// =================================================================
// NEW
export const useGetPaginatedUnassignedDocs = (
	pageNumber: number,
	limit: number,
	clientName: string,
	term: string,
	sort: string,
	order: string
) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS, term, limit, clientName, pageNumber, sort, order],
		() => getPaginatedUnassignedDocs(pageNumber, limit, clientName, term, sort, order),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const useGetUnassignedDocSubtasks = (parent_doc_id: string, pageNumber: number, limit: number, term: string) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_UNASSIGNED_DOC_SUBTASKS, term, limit, pageNumber, parent_doc_id],
		() => getUnassignedDocSubtasks(parent_doc_id, pageNumber, limit, term),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const usePostSplit = (
	pageNumber: number,
	limit: number,
	term: string,
	clientName: string,
	sort: string,
	order: string
) => {
	const queryClient = useQueryClient();
	//postAssignDoc
	const resultQuery = useMutation(splitJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([
					QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS,
					term,
					limit,
					clientName,
					pageNumber,
					sort,
					order,
				]);

				toast.success('Successfully split document!');
			}
		},
	});

	return resultQuery;
};

// =================================================================
// Docs
// =================================================================
// NEW Add Job Scope
export const usePostAssignDoc = (pageNumber: number, limit: number, term: string) => {
	const queryClient = useQueryClient();
	//postAssignDoc
	const resultQuery = useMutation(postJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS, term, limit, pageNumber]);
				toast.success('Successfully assigned!');
			}
		},
	});

	return resultQuery;
};

// NEW Add Job Scope
export const usePutExistingJobProject = (pageNumber: number, limit: number, term: string) => {
	const queryClient = useQueryClient();
	// putExistingJobProject
	const resultQuery = useMutation(putJobExisting, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS, term, limit, pageNumber]);
				toast.success('Successfully assigned!');
			}
		},
	});

	return resultQuery;
};

// NEW Unassigned Docs
export const usePostUnAssignDoc = (pageNumber: number, limit: number, term: string) => {
	const queryClient = useQueryClient();
	//postJob
	const resultQuery = useMutation(postAssignDoc, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS, term, limit, pageNumber]);
				toast.success('Successfully assigned!');
			}
		},
	});
	return resultQuery;
};

// NEW Unassigned Docs
export const usePutExistingUnassignedJob = (pageNumber: number, limit: number, term: string) => {
	const queryClient = useQueryClient();
	// putJobExisting
	const resultQuery = useMutation(putExistingJobProject, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_DOCS, term, limit, pageNumber]);
				toast.success('Successfully assigned!');
			}
		},
	});

	return resultQuery;
};

// NEW
export const useGetDocTypes = () => {
	const resultQuery = useQuery([QueryKeys.GET_DOC_TYPES], () => getDocTypes(), {
		keepPreviousData: true,
	});
	return resultQuery;
};

// =================================================================
// ACTIVE JOBS
// =================================================================
// NEW - GET PAGINATED ACTIVE JOBS
export const useGetPaginatedActiveJobs = (
	pageNumber: number,
	limit: number,
	teamId: string,
	clientName: string,
	jobTypeName: string,
	term: string,
	sort: string,
	order: string
) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_ACTIVE_JOBS, pageNumber, limit, teamId, clientName, jobTypeName, term, sort, order],
		() => getPaginatedActiveJobs(pageNumber, limit, teamId, clientName, jobTypeName, term, sort, order),
		{ keepPreviousData: true }
	);
	return resultQuery;
};

// NEW
export const useGetActiveJobNameList = () => {
	const resultQuery = useQuery([QueryKeys.GET_ACTIVE_JOB_NAME_LIST], getActiveJobNameList, {
		keepPreviousData: true,
	});
	return resultQuery;
};

// NEW
export const useGetActiveJobDetails = (id: number | null) => {
	const resultQuery = useQuery([QueryKeys.GET_ACTIVE_JOB, id], () => getActiveJob(id), {
		keepPreviousData: true,
		enabled: !!id,
	});
	return resultQuery;
};

// NEW
export const useUpdateJobDetails: any = () => {
	const queryClient = useQueryClient();
	return useMutation(putJobDetails, {
		onSuccess: (data: any) => {
			queryClient.invalidateQueries([QueryKeys.GET_ACTIVE_JOB, data.result.id]);
			queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_ASANA_BOARDS]);
			queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_MILESTONES]);
			toast.success('Successfully updated job details!');
		},
		onError: (data: any) => {
			console.log(data.error);
			toast.error(data.message);
		},
	});
};

// NEW
export const useGetNextJobNumber: any = () => {
	const resultQuery = useQuery('get-next-job-number', getNextJobNumber, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			}
		},
	});

	return resultQuery;
};

// =================================================================
// ARCHIVES
// =================================================================
// NEW - ARCHIVE ACTIVE JOB
export const useArchiveJob: any = () => {
	const queryClient = useQueryClient();
	const resultQuery = useMutation(putArchiveJob, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_ACTIVE_JOBS, QueryKeys.GET_ARCHIVED_JOBS]);
				toast.success('Successfully archived job!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// NEW - GET ARCHIVED JOB LIST
export const useGetArchivedJobs: any = (pageNumber: number, limit: number, term: string) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_ARCHIVED_JOBS, pageNumber, limit, term],
		() => getArchivedJobs(pageNumber, limit, term),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};
// NEW - GET ARCHIVED INVOICES LIST
export const useGetArchivedInvoices: any = (pageNumber: number, limit: number, term: string) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_ARCHIVED_INVOICES, pageNumber, limit, term],
		() => getArchivedInvoices(pageNumber, limit, term),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// =================================================================
// CLIENTS
// =================================================================
// NEW
export const useGetUniqueClientNames: any = (data_source: string) => {
	const resultQuery = useQuery([QueryKeys.GET_UNIQUE_CLIENT_NAMES, data_source], () =>
		getClientUniqueNamesList(data_source)
	);

	return resultQuery;
};

// NEW
export const useGetClientList: any = (pageNumber: number, limit: number, term: string) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_CLIENT_LIST, pageNumber, limit, term],
		() => getClientList(pageNumber, limit, term),
		{
			keepPreviousData: true,
		}
	);

	return resultQuery;
};
// NEW
export const useAddClient: any = (pageNumber: number, limit: number) => {
	const queryClient = useQueryClient();
	const resultQuery = useMutation(postClient, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_CLIENT_LIST, pageNumber, limit]);
				toast.success('Successfully added client!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// =================================================================
// JOB TYPES
// =================================================================
// NEW
export const useGetJobTypes: any = () => {
	const resultQuery = useQuery([QueryKeys.GET_JOB_TYPES], getJobTypes);

	return resultQuery;
};

// =================================================================
// TASKS
// =================================================================
// NEW
export const useGetPaginatedTasks = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_TASKS, pageNumber, limit, jobId],
		() => getPaginatedTasks(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const useAddAsanaTask: any = (pageNumber: number, limit: number, job_id: Number | 0) => {
	const queryClient = useQueryClient();
	return useMutation(postNewAsanaTask, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_TASKS, pageNumber, limit, job_id]);
				toast.success('Successfully added task!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// NEW
export const usePutAsanaTask: any = (pageNumber: number, limit: number, job_id: Number | 0) => {
	const queryClient = useQueryClient();
	return useMutation(putAsanaTask, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_TASKS, pageNumber, limit, job_id]);
				toast.success('Successfully updated task!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// =================================================================
// ASSIGNEES
// =================================================================
// NEW
export const useGetAssigneeList: any = () => {
	const resultQuery = useQuery([QueryKeys.GET_ASSIGNEES], getAssignees, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			}
		},
	});

	return resultQuery;
};

// =================================================================
// INVOICES
// =================================================================
// NEW
export const useGetPaginatedInvoices = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_INVOICES, pageNumber, limit, jobId],
		() => getPaginatedInvoices(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const useGetPaginatedUnassignedInvoices = (
	term: string,
	limit: number,
	clientFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_UNASSIGNED_INVOICES, term, limit, clientFilter, pageNumber, sort, order],
		() => getPaginatedUnassignedInvoices(term, limit, clientFilter, pageNumber, sort, order),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const useAssignInvoice = (term: string, limit: number, pageNumber: number) => {
	const queryClient = useQueryClient();
	return useMutation(putAssignInvoice, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_INVOICES, term, limit, pageNumber]);
				toast.success('Successfully assigned invoice!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};
// NEW
export const useArchiveInvoice = (limit: number, pageNumber: number, jobId: number | 0) => {
	const queryClient = useQueryClient();
	return useMutation(postArchiveInvoice, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				// queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_UNASSIGNED_INVOICES, term, limit, pageNumber]);
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_INVOICES, pageNumber, limit, jobId]);
				toast.success('Successfully archived invoice!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// =================================================================
// COSTS
// =================================================================
// NEW
export const useGetPaginatedCosts = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_COSTS, pageNumber, limit, jobId],
		() => getPaginatedCosts(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

export const useAddCost = (pageNumber: number, limit: number, jobId: number | null) => {
	const queryClient = useQueryClient();
	return useMutation(postAddCost, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_COSTS, pageNumber, limit, jobId]);
				toast.success('Successfully added new cost!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useUpdateCost = (pageNumber: number, limit: number, jobId: number) => {
	const queryClient = useQueryClient();
	return useMutation(putUpdateCost, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_PAGINATED_COSTS, pageNumber, limit, jobId]);
				toast.success('Successfully updated new cost!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useGetSuppliers = (term: string) => {
	const resultQuery = useQuery([QueryKeys.GET_SUPPIERS, term], () => getSuppliers(term), {
		keepPreviousData: true,
	});
	return resultQuery;
};

export const useAddNewSupplier = (term: string) => {
	const queryClient = useQueryClient();
	return useMutation(postNewSupplier, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_SUPPIERS, term]);
				toast.success('Successfully added new supplier!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// =================================================================
// QUOTES
// =================================================================
// NEW
export const useGetPaginatedQuotes = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_QUOTES, pageNumber, limit, jobId],
		() => getPaginatedQuotes(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

export const useGetNextQuoteNumber: any = () => {
	const resultQuery = useQuery('get-next-quote-number', getNextQuoteNumber, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			}
		},
	});

	return resultQuery;
};

// =================================================================
// TIME ENRTRIES
// =================================================================
// NEW
export const useGetPaginatedTimeEntries = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_TIME_ENTRIES, pageNumber, limit, jobId],
		() => getPaginatedTimeEntries(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// =================================================================
// TEAMS
// =================================================================

// NEW
export const useGetUniqueTeams: any = () => {
	const resultQuery = useQuery([QueryKeys.GET_UNIQUE_TEAMS], getTeams, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(`${data.message} : error for team list`);
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// =================================================================
// ATTACHMENTS
// =================================================================
export const useGetDocument: any = (jobId: any) => {
	const resultQuery = useQuery([QueryKeys.GET_ATTACHMENTS, jobId], () => getDocument(jobId), {
		onSuccess: (data: any) => {},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});

	return resultQuery;
};

// NEW
export const useGetPaginatedAttachments = (pageNumber: number, limit: number, jobId: number | 0) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_ATTACHMENTS, pageNumber, limit, jobId],
		() => getPaginatedAttachments(pageNumber, limit, jobId),
		{
			keepPreviousData: true,
		}
	);
	return resultQuery;
};

// NEW
export const useDownloadDocument = () => {
	const queryClient = useQueryClient();
	return useMutation(postDocumentDownload, {
		onSuccess: (data: any) => {},
	});
};

// =================================================================
// BILLING
// =================================================================
// NEW
export const useUpdateBill: any = () => {
	const queryClient = useQueryClient();
	return useMutation(postUpdateBill, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.error(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_ACTIVE_JOB, data.result.id]);
				toast.success('Successfully updated billing!');
			}
		},
	});
};

// NEW
export const useGetBillingDetails = (jobId: number) => {
	const resultQuery = useQuery([QueryKeys.GET_BILLING_DETAILS, jobId], () => getBillingDetails(jobId), {
		keepPreviousData: true,
	});
	return resultQuery;
};

// NEW
export const useGetBillingEmails = (id: string) => {
	const resultQuery = useQuery([QueryKeys.GET_BILLING_EMAILS, id], () => getBillingEmails(id), {
		keepPreviousData: true,
	});
	return resultQuery;
};

// Billing Types
export const useGetBillingTypes = () => {
	const resultQuery = useQuery([QueryKeys.GET_BILLING_TYPES], () => getBillingTypes(), {
		keepPreviousData: true,
	});
	return resultQuery;
};

// Budget Types
export const useGetBudgetTypes = (billing_type_id: number | null) => {
	const resultQuery = useQuery(['budget_types', billing_type_id], getBudgetTypes, {
		keepPreviousData: true,
		enabled: !!billing_type_id,
	});
	return resultQuery;
};

// =================================================================
// TASKS
// =================================================================
// Budget Types
export const useGetJobTasks = (proj_id: string | null) => {
	const resultQuery = useQuery([QueryKeys.GET_JOB_TASKS, proj_id], () => getJobTask(proj_id), {
		keepPreviousData: true,
		enabled: !!proj_id,
	});
	return resultQuery;
};

// =================================================================
// ASANA BOARDS
// =================================================================
export const useGetPaginatedAsanaBoards = (
	term: string,
	limit: number,
	teamFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_ASANA_BOARDS, term, limit, parseInt(teamFilter), pageNumber, sort, order],
		() => getPaginatedAsanaBoards(term, limit, teamFilter, pageNumber, sort, order),
		{ keepPreviousData: true }
	);

	return resultQuery;
};

// =================================================================
// NOTIFICATIONS
// =================================================================
export const useGetInAppNotifications = () => {
	const resultQuery = useQuery([QueryKeys.GET_IN_APP_NOTIFICATIONS], getInAppNotifications, {
		keepPreviousData: true,
	});
	return resultQuery;
};

export const useClearInAppNotifications = () => {
	const queryClient = useQueryClient();
	return useMutation(clearInAppNotifications, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.GET_IN_APP_NOTIFICATIONS]);
				toast.success('Successfully cleared notifications!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

// =================================================================
// OTHER DOCS
// =================================================================
// NEW
export const useAssignAsOthers = () => {
	const queryClient = useQueryClient();
	return useMutation(postAssignAsOthers, {
		onSuccess: (data: any) => {
			if (data.error) {
				toast.info(data.message);
			} else {
				queryClient.invalidateQueries([QueryKeys.POST_OTHER_DOC]);
				toast.success('Successfully assign other doc!');
			}
		},
		onError: (data: any) => {
			toast.error(data.message);
		},
	});
};

export const useGetOtherDocuments = (
	term: string,
	limit: number,
	clientFilter: string,
	pageNumber: number,
	sort: string,
	order: string
) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_ASANA_BOARDS, term, limit, clientFilter, pageNumber, sort, order],
		() => getPaginatedOtherDocuments(term, limit, clientFilter, pageNumber, sort, order),
		{ keepPreviousData: true }
	);

	return resultQuery;
};

// =================================================================
// MILESTONES
// =================================================================
export const useGetPaginatedMilestones = (id: string, limit: number, pageNumber: number) => {
	const resultQuery = useQuery(
		[QueryKeys.GET_PAGINATED_MILESTONES],
		() => getPaginatedMilestones(id, limit, pageNumber),
		{ keepPreviousData: true }
	);

	return resultQuery;
};

// =================================================================
// CUSTOMER
// =================================================================
// NEW
export const useGetCustomerUniqueNamesList: any = () => {
	const resultQuery = useQuery([QueryKeys.GET_UNIQUE_CUSTOMER_NAMES], () => getCustomerUniqueNamesList());

	return resultQuery;
};

// =================================================================
// EXPORT REPORT
// =================================================================
export const useExportExcelReport: any = () => {
	return useMutation(exportExcelReport);
};

export const useExportCSVReport: any = () => {
	return useMutation(exportCsvReport);
};

export const useDownloadCSVReport: any = () => {
	return useMutation(downloadCsvReport);
};
