export const ARCHIVE_REASONS = [
	{
		label: 'Replace with new retainer/job',
		value: 'Replace w/ new retainer/job',
	},
	{
		label: 'Job/retainer complete',
		value: 'Job/retainer complete',
	},
	{
		label: 'Client cancellation',
		value: 'Client cancellation',
	},
	{
		label: 'Other',
		value: 'other',
	},
];
