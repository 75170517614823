import { Route, Navigate, Outlet } from 'react-router-dom';
import { login } from '../api';
import { fethTOken } from '../auth';

export const ProtectedRoute = ({ isAuth, component: Component, ...rest }: any) => {
	return (
		<Route
			{...rest}
			render={(props: any) => {
				if (isAuth) {
					return <Component {...props} />;
				} else {
					return <Navigate to='/login' />;
				}
			}}
		/>
	);
};

export const PrivateRoute = (): JSX.Element => {
	const auth = localStorage.getItem('gsqToken');
	const token = fethTOken();

	if ((!auth && !token) || token === 'token') {
		login();
	}
	// if (!auth && !token) {
	// 	return <Navigate to='/login' />;
	// }

	// if (auth && token) {
	return <Outlet />;
	// }
};
